import React, { useState } from "react";
import '../../../css/workflow.css';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { calculateTime, updateWorkflowName, updateWorkflowAttachedStatus } from '../../../utilities/workflowFunctions';


function WorkflowBlock({setWarning, item, index, formatDate, setEditFlow, setWorkflows}) {
    const [editedIndex, setEditedIndex] = useState(null);
    const [editingItemId, setEditingItemId] = useState(null);
    const [editedText, setEditedText] = useState(null);

    const removeItem = (id,index) => {
        setWarning(id);
    };
    const handleInputChange = (e, index) => {
        setEditedText( e.target.value );
    };
    const handleEditName = (index, text) => {
        setEditedIndex(index);
        //setEditedText({ [index]: text });
        setEditedText(text);
    };
    const setStatusOption = (contentType, content, workflowId) => {
        updateTemplate(workflowId, contentType, content);
    };
    const setWorkflowName = async () => {
        const flowId=item.id;
        await updateWorkflowName(flowId, editedText);
        setWorkflows(prevData =>
            prevData.map(item => {
                if (item.id === flowId) {
                    return { ...item, name: editedText};
                }
                return item;
            })
        );
        setEditedIndex(null);
    };
    const updateTemplate = async (workflow, type, content, newFlow) => {
        setEditedIndex(null);
        try {

            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    flowId: workflow,
                    name: content,
                    type: type
                }) 
            };

            const response = await fetchWithDefaults('/updateWorkflowName', requestOptions);
        
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <div className={`quilityNestedBlockWrapper ${editingItemId === item.id ? 'focusBigBlock' : ''}`} key={`anotherWorkflow${index}`} style={{marginTop: '25px'}}>
            <div className="removeItem" onClick={()=> removeItem(item.id, index)}>X</div>
            <div className="quilityNestedBlockHeader">
                <div className="quilityParentBlockTitle">
                    {editedText ?? item.name}
                </div>
            </div>
            <div className="quilityNestedBlockContent">
                <div className="quilityDataLine">
                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Workflow Name: </span>
                    {editedIndex === index ? (
                        <input
                            type="text"
                            className="standardInputBox"
                            value={editedText || item.name}
                            onChange={(e) => handleInputChange(e, index)}
                            onBlur={() => setWorkflowName('name', editedText, item.id)}
                            autoFocus
                        />
                        ) : (
                        <>
                            <span style={{ marginLeft: "5px",cursor:'pointer' }} onClick={() =>
                                handleEditName(index, editedText)
                            } >
                            {editedText || item.name}
                            </span>
                            <span
                                className="hoverIcon"
                                onClick={() =>
                                    handleEditName(index, editedText)
                                }
                            >
                                {/*pencil*/}
                            </span>
                        </>
                    )}
                </div>
                <div className="quilityDataLine">
                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Total Actions: </span>
                    {item.length}
                </div>
                <div className="quilityDataLine">
                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Total Days: </span>
                    {calculateTime(item.order)}
                </div> 
                <div className="quilityDataLine">
                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Date Created: </span>
                    {item.created==null ? " Uknown" : formatDate(item.created)}
                </div>
                <div className="buttonWrapper" style={{margin: '20px 0px'}}>
                    <div className="editWorkflowButton solidQuilityActiveButton" onClick={()=>setEditFlow(item)}>Edit</div>
                </div>
            </div>
        </div>
    );
}

export default WorkflowBlock;

