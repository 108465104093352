 


import React, { useState, useRef } from "react";
import '../../css/templates.css';
import MessageVariables from '../messageVariables';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function DetailedEdit({ editTemplate, type, editingTemplate }) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const variables=useSelector((state) => state.variables.variables);
    const [editTemplateName, setTemplateName] = useState(editingTemplate.name);
    const [editTemplateContent, setTemplateContent] = useState(editingTemplate.content);
    const [editTemplateSubject, setTemplateSubject] = useState(editingTemplate.subject);
    const textareaRef = useRef(null);


    const setEditingTemplateTitle=(content)=>{
        setTemplateName(content);
    }
    const setEditingTemplateContent=(content)=>{
        setTemplateContent(content);
    }
    const appendVariable = (textToAppend) => {
        const textarea = textareaRef.current;
    
        if (textarea) {
          // Get current caret position
          const caretStart = textarea.selectionStart;
          const caretEnd = textarea.selectionEnd;
    
          // Insert text at the current caret position
          const newText = `${editTemplateContent.substring(0, caretStart)}${textToAppend}${editTemplateContent.substring(caretEnd)}`;
          setEditingTemplateContent(newText);
    
          // Move caret after the inserted text
          const newCaretPos = caretStart + textToAppend.length;
          textarea.setSelectionRange(newCaretPos, newCaretPos);
        }
    };
    const updateTemplate= async (id)=>{

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentId,
                    name: editTemplateName,
                    content: editTemplateContent,
                    subject: editTemplateSubject,
                    id: id
                }) 
            };
          
            const response = await fetchWithDefaults(`/updateTemplate${type}`, requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            editTemplate(false,false,false);

        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    return (
        <div className="mainBlockWrapper focusMainBlock">
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Edit {type}</div>
            </div>
            <span className="closeWindow" onClick={()=>editTemplate(false,false,false)}>X</span>
            <div className="mainBlockContent roundedBottom">
                <div className="bigBlock focusBigBlock" style={{marginTop: '25px'}}>
                    <div className="leadCardSubTitle">Edit {type}</div>
                    
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">{type} Name </div>
                        <input 
                            type="text" 
                            className="standardInputBox largeInput" 
                            value={editTemplateName}
                            onChange={(e) => setEditingTemplateTitle(e.target.value)}
                        /> 
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle"></div>
                        <div className="variableHolder">
                            <MessageVariables appendVariable={appendVariable} data={variables} /> 
                        </div>
                        <textarea 
                            ref={textareaRef}
                            className="messageEdit transitionMessage"
                            value={editTemplateContent}
                            onChange={(e) => setEditingTemplateContent(e.target.value)}
                        />
                    </div>
                </div>
                <div className="buttonWrapper">
                    <div className="accentButton" style={{margin:'auto'}} onClick={()=>updateTemplate(editingTemplate.id)}>Save {type}</div>
                </div>
            </div>
            
        </div> 
    );
}

export default DetailedEdit;

