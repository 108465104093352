import React, {useEffect, useState} from 'react';
import '../css/successfulAlert.css';

export function SuccessAlert({message, successTrigger, setSuccessTrigger}) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(successTrigger !== false){
            const timer1 = setTimeout(() => {
                setVisible(true);
            }, 100);

            const timer2 = setTimeout(() => {
                setVisible(false); 
                setSuccessTrigger(false)
            }, 2500);

            return () => {
                clearTimeout(timer1);
                clearTimeout(timer2);
            };
        }
    }, [successTrigger]);

    return (
        <div className={`successfulFunction ${visible ? 'displaySuccess' : ''}`}>
            <div className="successfulFunctionContent">
                <div className="successfulFunctionTitle">{message}</div>
            </div>
        </div>
    );
}