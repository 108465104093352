import { fetchWithDefaults } from '../config/fetchDefault';

const getMarketInfo= async (fetchWithDefaults, agentIdNumber,setMarketData)=>{
    try {
        const response = await fetchWithDefaults('/getMarket?agent='+agentIdNumber);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setMarketData(jsonData)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const getLeadOrders = async (setPastOrders, agent) => {
    try {
        const response = await fetchWithDefaults(`/marketplace/getLeadOrders?agent=${agent}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setPastOrders(jsonData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const createNewCustomer = async (agent) => {
    try {
        const response = await fetchWithDefaults(`/subscription/createCustomer?agent=${agent}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData.customer;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const makePayment= async (customer,agent,card)=>{
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent:agent,
                card:card,
                customer:customer
            }) 
        };
        const response = await fetchWithDefaults(`/market/submitA2pPayment`, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export { getMarketInfo,getLeadOrders, createNewCustomer,makePayment };