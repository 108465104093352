import { fetchWithDefaults } from '../config/fetchDefault';

const gatherAgentTeams = async (agent) => {
    try {
        const response = await fetchWithDefaults(`/team/teams?agent=${agent}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        return jsonData;
    } 
    catch (error) {
        console.error("Error fetching data:", error);
    }
};
const searchAgents = async (search, agentId, setSearchResults, organization) => {
    try {
        const response = await fetchWithDefaults(`/agents/search?search=${search}&agent=${agentId}&organization=${organization}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        if(jsonData){
            setSearchResults(jsonData)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const removeMember = async (agentId, teamId)=> {
    try {
        
        const response = await fetchWithDefaults(`/team/remove?agentId=${agentId}&teamId=${teamId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const getTeamInvites= async (agentId,setInvites)=>{
    try {
        
        const response = await fetchWithDefaults(`/team/invite?agentId=${agentId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        if(jsonData){
            setInvites(jsonData)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const getTeamInfo=async (teamId,setTeamData)=>{
    try {
        const response = await fetchWithDefaults(`/team/info?teamId=${teamId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        if(jsonData){
            setTeamData(jsonData)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const inviteMembers = async (invitees, teamId, agentId)=> {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                invitees:invitees,
                teamId:teamId,
                agentId:agentId
            }) 
        };
        const response = await fetchWithDefaults(`/team/invite`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        if(jsonData){
            //setTeamData(jsonData)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const inviteResponse= async (answer, inviteId, agentId, teamId, goals)=> {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                answer:answer,
                inviteId:inviteId,
                agentId:agentId,
                teamId:teamId,
                goals:goals
            }) 
        };
        const response = await fetchWithDefaults(`/team/inviteResponse`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export {gatherAgentTeams,searchAgents,removeMember,inviteMembers,getTeamInvites,getTeamInfo,inviteResponse};