import React, {useState} from 'react';
import Warning from '../../../template/warning';

function Member({teamName, member, details}) {
    const range=7;
    const [warning, setWarning] = useState(false);
    const cancel = () => {
        setWarning(false);
    };
    const inviteMember = (id,index) => {
        setWarning(id);
    };
    const confirm = async (id, index) => {

    }
    return (
        <div className="quilityDataLine" style={{display:'flex'}}>
            {warning && 
                <Warning title={"Send Invite"} cancel={cancel} confirm={confirm} message={"This will send a signup email to "+warning+" for this agent, is this what you want?"} buttons={[{name:'Don\'t Send',action:'cancel'},{name:"Send Invite", action:'confirm'}]} />
            }
            <span className="quilityDataLineTitle">{member.first_name} {member.last_name}</span>
            { member.agent_id==='unknown' ? (
                <>
                    <span className="quilityDataLineValue" style={{flex:'1',textAlign: 'center'}}>Not on platform</span>
                    <span className="quilityDataLineValue" style={{flex:'1',textAlign: 'center',cursor:'pointer'}} onClick={()=>inviteMember(member.agent_email)}>Send Invite</span>
                </>
            ) : (
                <>
                    <span className="quilityDataLineValue" style={{flex:'1',cursor:'pointer',textAlign: 'center'}} onClick={(event) => { event.stopPropagation(); details(member.agent_id, member.first_name+' '+member.last_name, range, teamName); }} >View Detail</span>
                </>
            )}
        </div>
    );
}

export default Member;

/*
 <span className="quilityDataLineValue" style={{flex:'1',textAlign: 'center'}}>Dials: {member.total_calls_made==null ? <b>0</b> : <b>{member.total_calls_made}</b>}</span>
                    <span className="quilityDataLineValue" style={{flex:'1',textAlign: 'center'}}>Contacts: {member.total_contacts==null ? <b>0</b> : <b>{member.total_contacts}</b>}</span>
                    */