import React, {useState, useRef} from 'react';
import '../../css/teams.css';
import TeamSection from './teamSection';
import TeamInvite from './teamInvite';

function BigTeamBlock({title, data, details, handleAddTeam, setImoLink, linked, invites, setInvites}) {
    const [isOpen, setIsOpen] = useState(true);
    
    const innerWrapperRef = useRef(0);

    const colors=['#edf9fd','#F0EDFD','#D9F2ED','#FDFFE3'];

    const openUp = () => {
        setIsOpen(!isOpen);
    };
    const linkImo=async()=>{
        setImoLink(true);
    }
    return (
        <div className='quilityParentBlockWrapper ' style={{width:'calc(100% - 20px)'}}>
            <div className="quilityParentBlockHeader" onClick={()=>openUp()}>
                <div className="quilityParentBlockTitle">{title}</div>
                
            </div>
            <div className={`mainBlockContent ${isOpen ? 'expanded' : ''}`} style={{overflow:'auto', maxHeight:'unset', minHeight: 'unset',height: isOpen ? 'fit-content' : '0px'}}> 
                <div className="innerWrapper"  ref={innerWrapperRef}>
                    <div className="quilityParentBlockContent">
                        {invites.length > 0 && title==='Your Teams' && (
                            invites.map((invite, index) => (
                                <TeamInvite invite={invite} key={`teamInvite${index}`}/>
                            ))
                        )}
                        {data.length > 0 ? (
                            data.map((team, index) => (
                                <TeamSection details={details} title={title} team={team} key={`teamsBlock${index}`} color={colors[index]}/>
                            ))
                        ) : (

                            title==='Your Teams' ? (
                                <div style={{fontWeight: '700',fontSize: '20px',margin: '20px'}}>You have no teams</div>
                            ) : ( 
                                linked ? (
                                    <>
                                        <div style={{ fontWeight: '700', fontSize: '20px', margin: '20px', color: linked.color }}>{linked.message}</div>
                                        {!linked.status && (
                                            <div className="clickToLink solidQuilityActiveButton" onClick={()=>linkImo()}>Click Here To Link</div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div style={{fontWeight: '700',fontSize: '20px',margin: '20px'}}>Your IMO info is not linked</div>
                                        <div className="clickToLink solidQuilityActiveButton" onClick={()=>linkImo()}>Click Here To Link</div>
                                    </>
                                )
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{ marginTop: " 5px", marginLeft: "10px" }}
                >
                    <div className="dataButtonBlock">Teams: {data.length}</div>
                    <div className="actionButtonBlock" >
                        {handleAddTeam && (
                            <div className="accentButton solidQuilityActiveButton" onClick={()=>handleAddTeam()}>
                                + Add Team
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>   
    );
}

export default BigTeamBlock;