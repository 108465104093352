import React from 'react';
import '../../css/subscription.css';
//#45bcaa8a
function SubscriptionOption({subscription, handleStartSubcription}) {
   
    return (
        <div className="subscriptionOptionWrapper">
            <div className="quilityNestedBlockHeader subscriptionOptionHeader">
                <div className="subscriptionTitle">Funnel Base Subscription</div>
            </div>
            <div className="subcriptionOptionContent">
                <div className="subscriptionOptionPrice">
                    $59.99 Per Month
                </div> 
                <div className="subscriptionOptionDescription">
                    <div className="subscriptionOptionDescriptionTitle">Your Subscription Includes</div>
                    <div className="quilityDataLine">2,000 Minutes</div>
                    <div className="quilityDataLine">1,000 SMS</div>
                    <div className="quilityDataLine">200 MMS</div>
                    <div className="quilityDataLine">Dynamic Scripting</div>
                    <div className="quilityDataLine">Product Reccomendations</div>
                    <div className="quilityDataLine">Product Quotes</div>
                    <div className="quilityDataLine">Two Numbers</div>
                    <div className="quilityDataLine">
                        <div className="statusItem solidQuilityActiveButton" onClick={()=>handleStartSubcription()}>Subscribe</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionOption;
