import React, {useEffect, useState} from 'react';
import '../../css/leadCard.css';
import { cancelAppointment, gatherLeadAppointmentData } from '../../utilities/appointmentFunctions';
import { unixTommddhia } from '../../utilities/dashFunction';
import Icon from '../../assets/icons/icon';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import { updateSpecificLead } from '../../features/queueSlice';
import { useDispatch } from 'react-redux';

function LeadCardAppointments({appointments, lead, setViewAppointments}) {
    const dispatch=useDispatch();
    const [foundAppts, setFoundAppts] = useState([])
    const removeAppointmentById = (idToRemove) => {
        // Use filter to remove the object with the matching ID
        const updatedAppointments = foundAppts.filter(appointment => appointment.id !== idToRemove);
        setFoundAppts(updatedAppointments);  // Update the state with the new array
    };
    let appointmentHolder=[];
    if(appointments){
        appointmentHolder=appointments.split(',');
    }
    const removeAppointmentFromString = (appointmentsString, idToRemove) => {
        // Split the string into an array
        const appointmentsArray = appointmentsString.split(',');
    
        // Filter out the matching ID
        const updatedArray = appointmentsArray.filter(id => id !== idToRemove);
    
        // Join the array back into a string
        return updatedArray.join(',');
    }
    useEffect(() => {
        ///This funciton is going to gather the actual appointment information for the lead.
        async function fetchData() {
            const response = await gatherLeadAppointmentData(lead);
            setFoundAppts(response)
        }
        fetchData();
    }, []);
    const closeWindow=()=>{
        setViewAppointments(false)
    }
    const handleCancel=async (id)=>{
        const cancelled=await cancelAppointment(id);
        if(cancelled){
            removeAppointmentById(id);
            const apptString=removeAppointmentFromString(id);
            dispatch(updateSpecificLead({ id: lead, field: 'appointments', value: apptString !=='' ? apptString: null }))
        }
    }
    return (
        <div className="signInWrapper">
            <div className="signInContent">
                <div className="loginWrapper" style={{maxheight: '400px',overflow: 'auto'}}>
                    <div className="quilityCloseWindow" onClick={() => closeWindow()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                    <div className="logo"><img src={image} className="imageStyle" alt="Logo" style={{marginTop:'0px'}} /></div>
                    <div style={{margin:'10px'}}>
                        {foundAppts.length > 0 && foundAppts.map((appointment, index) => (
                            <span class="quilityNestedBlockWrapper" style={{height: 'fit-content', margin: '20px 0px'}}>
                                <div class="quilityNestedBlockHeader">
                                    <div class="quilityParentBlockTitle">{appointment.title}</div>
                                </div>
                                <div class="quilityNestedBlockContent">
                                    <div class="quilityDataLine"><span class="quilityDataLineTitle">Type</span> <span class="quilityDataLineValue">{appointment.type}</span></div>
                                    <div class="quilityDataLine"><span class="quilityDataLineTitle">Date</span> <span class="quilityDataLineValue">{unixTommddhia(appointment.date)}</span></div>
                                    <div class="quilityDataLine"><div className="statusItem solidQuilityDestructiveButton" style={{fontSize:'16px', fontWeight:'700'}} onClick={()=>handleCancel(appointment.id)}>Cancel Appointment</div></div>
                                </div>
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default LeadCardAppointments;