import { createSlice } from '@reduxjs/toolkit'

export const reminderSlice = createSlice({
    name: 'reminders',
    initialState: {
        reminders: []
    },
    reducers: {
        setReminders(state, action) {
            state.reminders = action.payload;
        },
    },
})
export const { setReminders } = reminderSlice.actions;

export default reminderSlice.reducer;