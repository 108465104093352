import React, { useState } from "react";

function SoleProfileForm({submitCustomerProfile}) {
    const [formData, setFormData] = useState({
        email: '',
        city: '',
        street: '',
        state: '',
        zip: '',
        businessName: '',
        website: '',
        social: '',
        firstName: '',
        lastName: '',
        number: '',
    });

    const handleChange = (variable, value) => {
        setFormData(prevState => ({
            ...prevState,
            [variable]: value
        }));
    };

    const handleSubmit = () => {
        let isValid = true;
        const emptyFields = [];

        // Check if any input field is empty
        Object.entries(formData).forEach(([key, value]) => {
            if (!value) {
                if(key !== 'website' && key !== 'social'){
                    emptyFields.push(key);
                    isValid = false;
                }
            }
        });

        // If any field is empty, display warnings
        if (!isValid) {
            alert(`Please fill in the following fields: ${emptyFields.join(', ')}`);
            // You can also update the UI to display warnings next to the empty fields
            return;
        }
        submitCustomerProfile(formData,'sole');
    };

    const generalQuestions = [
        { title: 'First Name', variable: 'firstName', clarification:'' },
        { title: 'Last Name', variable: 'lastName', clarification:'' },
        { title: 'Full Name', variable: 'businessName', clarification:'If you have a DBA registered, put it here. If not, put your first and last name' },
        { title: 'Email', variable: 'email', clarification:'' },
        { title: 'Address', variable: 'street', clarification:'' },
        { title: 'City', variable: 'city', clarification:'' },
        { title: 'State', variable: 'state', clarification:'' },
        { title: 'Zip Code', variable: 'zip', clarification:'' },
        { title: 'Phone number', variable: 'number', clarification:'This must be a valid number form a major carrier that recieves text messages'},
        { title: 'Website (optional)', variable: 'website', clarification:'' },
        { title: 'Social Media Link (optional)', variable: 'social', clarification:'' },
    ];
 
    //businessName:businessName,
    return (
        <div className="quilityFormWrapper">
            <div className="trustFormWrapper">
                <div className="trustFormTitle">Contact Information <span className="greyInfo"></span></div>
                {generalQuestions.map((question, index) => (
                    <div className="qualifierFieldWrapper" key={index}>
                        {question.clarification !== '' && (
                            <span className="clarification">{question.clarification}</span>
                        )}
                        <div className="qualifierFieldTitle" style={{ width: '35%', textAlign: 'left' }}>{question.title}</div>
                        <input type="text" className="qualifierFieldInput" onChange={(e) => handleChange(question.variable, e.target.value)} />
                    </div>
                ))}
            </div>
            <div style={{textAlign:'center'}}><button onClick={handleSubmit} className="solidQuilityActiveButton formProfileSubmitButton">Submit</button></div>
        </div>
    );
}

export default SoleProfileForm;