

import React from 'react';
import '../css/warning.css';


function Warning({title, message, buttons, confirm, cancel}) {
    
    return (
        <div className="warningWrapper">
            <div className="warningBody">
                <div className="warningHeader">
                    {title}
                </div>
                <div className="warningContent">
                    {message}
                </div>
                <div className="warningButtonHolder">
                    <div className="warningButtonWrapper">
                        {buttons.map((button, index) => ( //solidQuilityDestructiveButton
                            <div className={`accentButton warningButton ${button.action !== 'confirm' ? 'solidQuilityDestructiveButton': 'solidQuilityActiveButton'}`} key={'warning'+index} onClick={()=>`${button.action !== 'confirm' ? cancel() : confirm()}`}>{button.name}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Warning;