import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowTag({tags }) {
    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }

    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
                Tags
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {tags && tags.map((item, index) => (    
                    <DraggableElement name={item.name} id={item.id} key={`tagDrag${index}`} type="Tag Lead"/>
                ))}
            </div>
        </div>

    );
}

export default WorkflowTag;

