import React, { useState, useEffect } from 'react';
import QueueObjections from './objections';
import { useSelector } from 'react-redux';


function QueueScripts({handleNewObjection, scriptId, currentLead}) {
    const scripts=useSelector((state) => state.scripts.scripts);
    const [currentScript, setCurrentScript] = useState(null);

    function handleSelectChange(event) {
        const selectedValue = event.target.value;
        const selectedScript = scripts.find(script => script.id === selectedValue);
        const modifiedContent = Object.entries(currentLead).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '*<b>unknown</b>*';
            return content.replace(new RegExp(`{${key}}`, 'g'), `${replacement}`);
        }, selectedScript.content);

        setCurrentScript({name:selectedScript.name, id: selectedScript.id, content:modifiedContent});
    }
    function refreshScript(scriptId) {
        const selectedScript = scripts.find(script => script.id === scriptId);
        const modifiedContent = Object.entries(currentLead).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '*<b>unknown</b>*';
            return content.replace(new RegExp(`{${key}}`, 'g'), `${replacement}`);
        }, selectedScript.content);

        setCurrentScript({name:selectedScript.name, id: selectedScript.id, content:modifiedContent});
    }
    useEffect(() => {
        if(currentScript){
            refreshScript(currentScript.id);
        }
    }, [currentLead]);
    useEffect(() => {
        if(currentScript !== undefined && currentScript !==null){
            refreshScript(currentScript.id);
        }
        function intitalScript(id) {
            const selectedValue =id;
            const selectedScript = scripts.find(script => script.id === selectedValue);
            setCurrentScript(selectedScript)
        }
        intitalScript(scriptId);
    }, []);
    return (
        <div className="entireScriptWrapper">
            
            <div className="scriptContainer">
                <div className="scriptContainerHeader">
                <select className="standardSelectBox" onChange={handleSelectChange}>
                    <option value='none'>Select A Script</option>
                    {scripts.map((script, index) => ( 
                        <option value={script.id} key={`scriptId${index}`}>{script.name}</option>
                    ))}
                </select>
                </div>
                <div className="scriptContainerContent"> {currentScript && <div dangerouslySetInnerHTML={{ __html: currentScript.content }}></div>}</div>
            </div>
            <QueueObjections handleNewObjection={handleNewObjection} />
        </div>

    );
    
}

export default QueueScripts;
//<div className="scriptContainerHeader">{currentScript ? currentScript.name : 'No Script Selected'}</div>