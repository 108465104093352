

import React from 'react';
import '../../css/login.css';

function ConsentOptions({consentInfo,handleConsentChange}) {

    return (
        <>
            <div className="quilityNestedBlockWrapper">
                <div className="quilityNestedBlockHeader">
                    <div className="quilityParentBlockTitle">Recording Types</div>
                </div>
                <div className="quilityNestedBlockContent">
                    <div className={`callRecordingOption ${consentInfo && consentInfo.record_side===0 ? 'selectedRecordingOption' : ''}`} onClick={()=>handleConsentChange('record_side',0)}>
                        <div className={`quilityParentBlockTitle ${consentInfo && consentInfo.record_side===0 ? 'titleColor' : ''}`}><b>Agent Side Recording</b> - <span style={{fontSize:'14px'}}>Only records your side of the call. Compliant in all 50 states and territories</span></div>
                    </div>
                    <div className={`callRecordingOption ${consentInfo && consentInfo.record_side===1 ? 'selectedRecordingOption' : ''}`} onClick={()=>handleConsentChange('record_side',1)}>
                        <div className={`quilityParentBlockTitle ${consentInfo && consentInfo.record_side===1 ? 'titleColor' : ''}`}><b>Two Side Recording</b> - <span style={{fontSize:'14px'}}>Records both sides of the call. Notification required in some states</span></div>
                    </div>
                </div>
            </div>

            <div className="quilityNestedBlockWrapper">
                <div className="quilityNestedBlockHeader">
                    <div className="quilityParentBlockTitle">Recording Frequency Options</div>
                </div>
                <div className="quilityNestedBlockContent">
                    <div className={`callRecordingOption ${consentInfo && consentInfo.record_calls===0 ? 'selectedRecordingOption' : ''}`} onClick={()=>handleConsentChange('record_calls',0)}>
                        <div className={`quilityParentBlockTitle ${consentInfo && consentInfo.record_calls===0 ? 'titleColor' : ''}`}><b>Record Select Calls</b> - <span style={{fontSize:'14px'}}>Only records calls when you click the record button on the phone</span></div>
                    </div>
                    <div className={`callRecordingOption ${consentInfo && consentInfo.record_calls===1 ? 'selectedRecordingOption' : ''}`} onClick={()=>handleConsentChange('record_calls',1)}>
                        <div className={`quilityParentBlockTitle ${consentInfo && consentInfo.record_calls===1 ? 'titleColor' : ''}`}><b>Record All Calls</b> - <span style={{fontSize:'14px'}}>Records all incoming and outgoing calls</span></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConsentOptions;