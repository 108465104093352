import React, { useState, useRef } from "react";
import '../../css/elements.css';
import { unixTommddhia} from '../../utilities/dashFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setConvoWindow, setConvoId } from '../../features/conversationSlice';
import {setOpenMessages} from '../../features/topBarSlice';

function ConversationPreview({title}) {
    const dispatch = useDispatch();
    const conversations=useSelector((state) => state.conversations.conversations);
    const convoWindow=useSelector((state) => state.conversations.convoWindow);
    const handleSelectConvo= (convoId)=>{
        if(!convoWindow){
            dispatch(setConvoWindow(true));
        }
        dispatch(setOpenMessages(true));
        dispatch(setConvoId(convoId));
    }
    const handleOpenConvos= ()=>{
        dispatch(setOpenMessages(true));

    }
    return (
        <div className={`frontPageQuilityParentBlockWrapper`}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">{title}</div>
            </div>
            <div className="quilityParentBlockContent" style={{textAlign:'left'}}>
                {conversations.length > 0 ? (
                    <>
                    {conversations.slice(0, 2).map((convo, index) => (
                        <span className="convoPreviewWrap" onClick={()=>handleSelectConvo(convo.convo_id)} key={`convoPreview${index}`}>
                            <div className="quilityDataLine" key={`filterOption${index}`} style={{ display: 'flex',borderBottom: 'none' }}>
                                <div className="quilityDataLineTitle textOverflowControl" style={{ flex: '2' }}>{convo.borrower_first} {convo.borrower_last}</div>
                                <div className="quilityDataLineValue" style={{ flex: '1', cursor: 'pointer' }}></div>
                                <div className="quilityDataLineValue" style={{ cursor: 'pointer' }}>{unixTommddhia(convo.time)}</div>
                            </div>   
                            <div className="frontMessagePreview">{convo.message}</div>
                        </span>
                    ))}
                    <div className="seeMore" onClick={()=>handleOpenConvos()}>See More</div>
                    </>
                ):(
                    "You Have No Conversations"
                )}
            </div>
        </div>
    );
}

export default ConversationPreview;
