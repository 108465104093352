import { createSlice } from '@reduxjs/toolkit'

export const emailSlice = createSlice({
    name: 'email',
    initialState: {
        email: []
    },
    reducers: {
        setEmails(state, action) {
            state.email = action.payload;
        },
    },
})
export const { setEmails } = emailSlice.actions;

export default emailSlice.reducer

