import React, { useState, useEffect } from "react";
import '../../css/trust.css';
import CustomerProfile from './customerProfile';
import CustomerBrand from './brand';
import MessagingService from './services';
import CreateCustomerProfile from "./create/createProfile";
import { fetchWithDefaults } from '../../config/fetchDefault';
import CreateCampaign from "./create/createCampaign";
import AddNumberToService from "./create/addNumbersToService";
import { useSelector } from 'react-redux';
import image from '../../assets/icons/loading-loader.gif';


function TrustHub({data}) {

    const agentId=useSelector((state) => state.agent.userData).agentId;
    const formatString = (inputString) => {
        const parts = inputString.split('_');
        const formattedString = parts.map((part, index) => {
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(); 
        });
        return formattedString.join(' ');
    };
    const [customerProfiles, setCustomerProfiles] = useState(data[0].twilio);
    const [profileInfo, setProfileInfo]=useState(data[0].hub)
    const [addCustProfile, setAddCustProfile]=useState(false)
    const [loading, setLoading]=useState(false)
    const [brandInfo, setBrandInfo]=useState([])
    const [serviceInfo, setServiceInfo]=useState([])
    const [compliant, setAsCompliant] = useState(false);
    const [profileType, setProfileType]=useState(false);
    const [newBrand, setNewBrand]=useState(false);
    const [newCampaign, setNewCampaign]=useState(false);
    const [newService, setNewService]=useState(false);
    const [numberChoice, setNumberChoice]=useState(false);
    const [update, setUpdate]=useState(false);


    const handleAddCustProfile=()=>{
        setAddCustProfile(!addCustProfile)
    }
    const fetchBrand = async () => {
        try {
            const response = await fetchWithDefaults(`/trustHub/brandLookup?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setBrandInfo(jsonData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSubmitBrand= async(fields, brand, type)=>{
        let service=false;
        if(newCampaign && newCampaign !== true){
            service= newCampaign
        }

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    fields:fields,
                    brand:brand,
                    type:type,
                    service:service
                }) 
            };
            const response = await fetchWithDefaults(`/trustHub/submitCampaign?agent=${agentId}`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewService(true);
            setNewCampaign(false);
            setNumberChoice(jsonData);
            fetchServices();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const fetchServices = async () => {
        try {
            const response = await fetchWithDefaults(`/trusthub/serviceLookup?agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            //console.log(jsonData);
            setServiceInfo(jsonData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const submitCustomerProfile = async (formData, type) => {
        try {
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify(formData) 
            };
          
            const response = await fetchWithDefaults(`/trustHub/customerProfile?agent=${agentId}&type=${type}`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            //setAddCustProfile(false);
            setLoading(false);
            window.location.reload();
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchBrand();
        fetchServices();
    },[update])


    return (
        <div className="outerWrapper">
            
            {loading && (
                <div className="loadingScreen">
                    <div className="loaderWrapper">
                        <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                    </div>
                </div>
            )}
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">

                </span>
                TrustHub
            </span>
            {compliant && (
                <div className="compliantWrapper">
                    <div className="compliantHeader">
                        <div className="compliantTitle">You Are Compliant</div>
                    </div>
                    <div className="compliantContent">
                        You are all set and ready to message!!
                    </div>
                </div>
            )}
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >
                <CustomerProfile setLoading={setLoading} profileInfo={profileInfo} setProfileInfo={setProfileInfo} agentId={agentId} setAddCustProfile={setAddCustProfile} formatString={formatString} data={customerProfiles} soleSid={profileInfo[0].soleCustomer} einSid={profileInfo[0].hubCustomer} activePayment={profileInfo[0].activePayment} />
                {customerProfiles.length > 0 && (
                    <CustomerBrand setNewBrand={setNewBrand} agentId={agentId} formatString={formatString} data={profileInfo} brandInfo={brandInfo} fetchBrand={fetchBrand}/>
                )}
                {brandInfo.length > 0 && (
                    <MessagingService  newService={newService} setNumberChoice={setNumberChoice} setNewCampaign={setNewCampaign} agentId={agentId} setAsCompliant={setAsCompliant} formatString={formatString} data={profileInfo} serviceInfo={serviceInfo} fetchServices={fetchServices}/>
                )}
            </div>
            {addCustProfile && (
                <CreateCustomerProfile setUpdate={setUpdate} setAddCustProfile={setAddCustProfile} submitCustomerProfile={submitCustomerProfile} profileType={profileType} setProfileType={setProfileType}/>
            )}
            {newBrand && (
                <CreateCustomerProfile setUpdate={setUpdate} setAddCustProfile={setAddCustProfile} submitCustomerProfile={submitCustomerProfile} profileType={profileType} setProfileType={setProfileType}/>
            )}
            {newCampaign && (
                <CreateCampaign handleSubmitBrand={handleSubmitBrand} brandInfo={brandInfo} setNewCampaign={setNewCampaign} setAddCustProfile={setAddCustProfile} submitCustomerProfile={submitCustomerProfile} profileType={profileType} setProfileType={setProfileType}/>
            )}
            {numberChoice && (
                <AddNumberToService fetchServices={fetchServices} setUpdate={setUpdate} type={numberChoice.type} setNumberChoice={setNumberChoice} service={numberChoice.service} agent={agentId} />
            )}
        </div>
    );
}

export default TrustHub;
