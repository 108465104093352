import React, { useState } from "react";
import '../../css/templates.css';

import FastEdit from './fastEdit';
import AddTemplate from './addTemplate';
import DetailedEdit from './detailedEdit';
import Icon from "../../assets/icons/icon";


function Objection({ data,formatDate }) {
    const [editingTemplate, setEditTemplate] = useState(false);
    const [addScript, setAddScript] = useState(false);
    const [transition, setTransition] = useState(false);
    const [editingItemId, setEditingItemId] = useState(null);
    const [newTemplate, setNewTemplate] = useState(null);

    const addAScript = () => {
        setAddScript(!addScript);
    };
    const editTemplate = (id,name,content, subject) => {
        if(id===false){
            setEditTemplate(false);
        }
        else{
            setEditTemplate({id:id,name:name,content:content, subject:subject});
        }
    };
   
    return (
        <div className="outerWrapper">
                <span className="outerWrapperTitle">
                    <span className="outerWrapperIcon">
                        <span className="fontello" style={{marginTop: '-7px'}}><Icon name={'gratitude-journal'} adjustSize={25} height={34} size={34} className="" adjust="fontello" /></span>
                    </span>
                    Templates
                </span>
                <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
                >
            {editingTemplate ? (
                <DetailedEdit editTemplate={editTemplate} type='Objection' editingTemplate={editingTemplate}/>
            ) : (
                null
            )}

            {addScript ? (
               <AddTemplate addATemplate={addAScript} setNewTemplate={setNewTemplate} type={'Objection'}/>

            ):(
            null
            )}
            <div className={`frontPageQuilityParentBlockWrapper ${addScript ? 'closeMainBlock' : editingTemplate ? 'closeMainBlock' : ''}`} style={{width:'75%'}}>
                        <div className="quilityParentBlockHeader">
                            <div className="quilityParentBlockTitle">Objections</div>
                        </div>
                        <div className="quilityParentBlockContent">
                            {newTemplate == null ? '' : (
                                <div className={`quilityNestedBlockWrapper ${editingItemId === newTemplate.id ? 'focusBigBlock' : ''}`} key={'temp'} style={{marginTop: '25px'}}>
                                    <div className="quilityCloseWindow oulineQuilityDestructiveButton" >X</div>
                                    <div className="quilityNestedBlockHeader">
                                        <div className="quilityParentBlockTitle">
                                            {newTemplate.name}
                                        </div>
                                    </div>
                                    <div className="infoWrapper">
                                        <div className="quilityDataLine">
                                            <span className="itemTitle">Objection Name: </span>
                                            {newTemplate.name}
                                        </div>
                                        <div className="quilityDataLine">
                                            <span className="itemTitle">Content: </span>
                                            <FastEdit 
                                                item={newTemplate}
                                                index={'temp'}
                                                transition={transition}
                                                setTransition={setTransition}
                                                editingItemId={editingItemId}
                                                setEditingItemId={setEditingItemId}
                                                type={'Objection'}
                                                name={newTemplate.name}
                                                editTemplate={editTemplate}
                                            />
                                        </div>
                                        <div className="quilityDataLine">
                                            <span className="itemTitle">Date Created: </span>
                                            Just Now
                                        </div>
                                    </div>
                                </div>
                            )}
                            {data[0].type !== false && data[0].map((item, index) => (    
                                <div className={`quilityNestedBlockWrapper ${editingItemId === item.id ? 'focusBigBlock' : ''}`} key={`objectionsInfo${index}`} style={{marginTop: '25px'}}>
                                    <div className="quilityCloseWindow oulineQuilityDestructiveButton" >X</div>
                                    <div className="quilityNestedBlockHeader">
                                        <div className="quilityParentBlockTitle">
                                            {item.name}
                                        </div>
                                    </div>
                                    <div className="infoWrapper">
                                        <div className="quilityDataLine">
                                            <span className="itemTitle">Objection Name: </span>
                                            {item.name}
                                        </div>
                                        <div className="quilityDataLine">
                                            <span className="itemTitle">Content: </span>
                                            <FastEdit 
                                                item={item}
                                                index={index}
                                                transition={transition}
                                                setTransition={setTransition}
                                                editingItemId={editingItemId}
                                                setEditingItemId={setEditingItemId}
                                                type={'Objection'}
                                                name={item.name}
                                                editTemplate={editTemplate}
                                            />
                                        </div>
                                        <div className="quilityDataLine">
                                            <span className="itemTitle">Date Created: </span>
                                            {formatDate(item.created)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {data[0].type === false && newTemplate != null < 1 && (
                                <div className="title">You have no objections</div>
                            )}
                        </div>
                        <div className="blockFooter">
                            <div
                                className="buttonWrapper"
                                style={{ marginTop: " 5px", marginLeft: "10px" }}
                            >
                                <div className="dataButtonBlock">Objection: {data[0].length ? data[0].length : 0}</div>
                                <div className="actionButtonBlock" >
                                    <div className="accentButton solidQuilityActiveButton" onClick={()=>addAScript()}>
                                    +  New Objection
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
        </div>

    );
}

export default Objection;

