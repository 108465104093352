import React, { useState, useEffect } from 'react';
import App from './App';
//import './appThemeDark.css';
import './appThemeLight.css';
//import './appThemeFunnel.css';
import { addSocketListener, removeSocketListener, getSocket } from './sockets';
import * as amplitude from '@amplitude/analytics-browser';
import { createBrowserHistory } from 'history';
import LoginPage from './template/login';
import { fetchWithDefaults } from './config/fetchDefault';
import ActionAlert from './actionAlert';
import { useDispatch } from 'react-redux';
import { setMissedCall } from './features/missedCallSlice';
import { setUnheard,addVoicemail } from './features/voicemailSlice';
import { setUserData } from './features/agentSlice';
import Signup from './template/signup/signup';
import {gatherTheme,applyTheme, SSO, decrypt} from './utilities/appSetup';
import AdPreview from './adPreview';
import MigrationLandingPage from './template/migrationLandingPage/main';

const history = createBrowserHistory();

const { io } = require("socket.io-client");
amplitude.init('997b97336f09c5b01c8b21db4c10d831');
function Init() {
    const dispatch = useDispatch();
    const [migrateAgent, setMigrateAgent] = useState(false);
    const [signedIn, setSignedIn] = useState(false);
    const [agentUsername, setAgentUsername] = useState('');
    const [agentIdNumber, setAgentIdNumber] = useState(null);
    const [agentPw, setAgentPw] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [agentCode, setAgentCode] = useState(false);
    const [newMessages, setNewMessages] = useState([]);
    const currentURL=window.location.pathname;
    const currentQuery=window.location.search;
    const currentSearch=currentQuery.split("code=")[1];
    const [unreadMessages, setUnreadMessages] = useState(false);

    const saveData = async (cipher) => {
      	localStorage.setItem('keepMeAlive', cipher);
    };

    const saveSSO = async (cipher) => {
		localStorage.setItem('keepMeAlive', cipher);
		window.location.href = '/'
    };
    
    const [ stylePath, setStylePath ] = useState("/appThemeDark.css");

	useEffect(() => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://www.bugherd.com/sidebarv2.js?apikey=j3obszo7mr7scr985d2dgw';
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);
    useEffect(() => {
      	document.title = "Funnel";
    }, []);
    const signOut = () => {
		localStorage.removeItem('keepMeAlive');
		setSignedIn(false)
    };
    const loginUser = async () => {
      	try {
			const requestOptions = {
				method: 'POST',
				body: JSON.stringify({ 
					username: agentUsername,
					password: agentPw,
					source:'web'
				}) 
			};
		
			const response = await fetchWithDefaults('/login/loginUser', requestOptions);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const jsonData = await response.json();
			if(jsonData[0].status=='update'){
				setUpdatePassword(true);
			}
			else if(jsonData[0].status=='sso'){
				window.location.href=`https://auth.quility.com/authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=https://${window.location.hostname}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=init`
			}
			else if(jsonData[0].status===true){

				await saveData(jsonData[0].cipher);
				setAgentIdNumber(jsonData[0].id)
				const root = document.documentElement;
				const theme=await gatherTheme(jsonData[0].organization);
				await applyTheme(theme,root,dispatch);
				setSignedIn(true);
				let admin=false;
				if(jsonData[0].admin===1){
					admin=true;
				} 
				dispatch(setUserData({
					agentId: jsonData[0].id,
					organization: jsonData[0].organization,
					admin:admin,
					organizationName:jsonData[0].orgName,
					permissions:jsonData[0].permissions,
					agentCode:jsonData[0].agentCode,
					beta:jsonData[0].beta===0 ? false : true,
					status:jsonData[0].agentStatus==='inactive' ? false : true,
				}))
			}
			else {
				setLoginError('Your username or password is incorrect!');
				setUpdatePassword(false);
				setSignedIn(false);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
    };
    const handleSetUnread=(amount)=>{
      setUnreadMessages(amount)
    }
    useEffect( () => {
      	if(agentIdNumber){
			const socket = getSocket();
			//const socket = io(`https://${window.location.hostname}:3333`);
			/* socket.on("connect", (sock) => {
				console.log('socket set');
				
			});*/
			
			addSocketListener('connect', (message) => {
				// Show an alert when a new message comes in 
				socket.emit('initialization', agentIdNumber);
			});
			
			socket.on("newText", (arg) => {
				setNewMessages(prevMessages => [arg,...prevMessages]);
				let newUnread;

				if(unreadMessages===false){
					newUnread=1;
				}
				else{
					newUnread=parseInt((parseInt(unreadMessages) + 1));
				}
				handleSetUnread(newUnread);
			});
			socket.on("unread", (arg) => {
				handleSetUnread(arg);
			});

			socket.on("sentText", (arg) => {
				setNewMessages(prevMessages => [arg,...prevMessages]);
			});
			socket.on("missedCall", (arg)=>{
				dispatch(setMissedCall(arg.count)); 
			})
			socket.on("voicemail", (arg)=>{
				dispatch(setUnheard(arg)); 
				//dispatch(addVoicemail(arg.voicemail))
			})
			socket.onAny((eventName, ...args) => {
				//console.log(eventName);
				//console.log(args);
			});
		}
		return () => {
			//setSignedIn(false);
			//notes
		};
    },[signedIn])
    const runSSO=async()=>{
      const cipher= await SSO(currentQuery);
      if(cipher.agentCode){
		console.log(cipher)
        history.push(`/migrate/quility/activate`, { forced: true });
        setMigrateAgent(cipher.agentCode);
      }
      else if(cipher !== undefined){
        await saveData(cipher[0]);
        await decrypt(cipher[0],applyTheme,gatherTheme,dispatch,setAgentIdNumber,setSignedIn,saveData);
        window.location.href = '/'
      }
    }
    const migrationSSO=async()=>{
      const code= await SSO(currentQuery);
      setAgentCode(code)
    }
    const slugs=["/lead","/Call_Recording","/Trust_Hub","/Workflows","/Activity","/Overview","/Market","/dash","/Integrations","/Add_Leads","/Follow_Ups","/Scheduled_Calls","/Scheduled_Appointments","/Subscription","/Number","/Voicemail","/VoiceDrop","/Call_Forwarding","/Tags","/Lead_Types","/Text_Templates","/Email_Templates","/Scripts","/Objections","/Team"];
    return (
        signedIn ? (
          signedIn && (currentURL && !slugs.includes(currentURL)) && currentURL !== '/dash' && currentURL !== '/signin' && currentURL !== '/auth' && currentURL !=='/quilityAuth' && currentURL !=='/ssoLogin' && currentURL !=='/authorization'  && currentURL !=='/signup' ? (
            <>
              <ActionAlert newMessages={newMessages}/>
              <App auth={null} unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} newMessages={newMessages} signedIn={signedIn} agentId={agentIdNumber} signOut={signOut}/>
            </>
          ) : slugs.includes(currentURL) ? ( 
            <App currentQuery={currentQuery} load={currentURL ? currentURL.split('/')[1] : ''} unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} newMessages={newMessages} signedIn={signedIn} agentId={agentIdNumber} signOut={signOut}/>
          ) : currentURL==='/quilityAuth' ? ( 
            window.location.href=`https://auth.quility.com//authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=https://${window.location.hostname}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=init`
          ) : currentURL==='/ssoLogin' && currentSearch ? (
            saveSSO(currentSearch)
          ) : currentURL==='/signup' ? ( 
              <Signup/>
          ) : currentURL==='/authorization' ? ( 
            <>
              <ActionAlert newMessages={newMessages}/>
              <App auth='gather' unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} newMessages={newMessages} signedIn={signedIn} agentId={agentIdNumber} signOut={signOut}/>
            </>
          ) : currentURL==='/dash' ? ( 
            <App auth={null} unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} newMessages={newMessages} signedIn={signedIn} agentId={agentIdNumber} signOut={signOut}/>
            
          ) : currentURL==='/auth' ? ( 
            <App auth='authenticate' unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} newMessages={newMessages} signedIn={signedIn} agentId={agentIdNumber} signOut={signOut}/>
            
          ) : (
            <LoginPage setUpdatePassword={setUpdatePassword} saveData={saveData} loginError={loginError} setLoginError={setLoginError} setSignedIn={setSignedIn} setAgentIdNumber={setAgentIdNumber} updatePassword={updatePassword} agentPw={agentPw} loginUser={loginUser} agentUsername={agentUsername} setAgentUsername={setAgentUsername} setAgentPw={setAgentPw} />
          )
        ) : migrateAgent ? ( 
          <MigrationLandingPage agentCode={migrateAgent}/>
        ) : currentURL==='/signup' ? ( 
          <Signup/>
        ) : currentURL==='/quilityAuth' ? ( 
          window.location.href=`https://auth.quility.com/authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=https://${window.location.hostname}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=init${currentQuery}`
        ) : currentURL==='/adPreview' ? ( 
          <AdPreview/>
        ) : currentURL==='/api/quility/authorize' ? ( 
            runSSO(),
          null
        ) : (
          <LoginPage setUpdatePassword={setUpdatePassword} saveData={saveData} loginError={loginError} setLoginError={setLoginError} setSignedIn={setSignedIn} setAgentIdNumber={setAgentIdNumber} updatePassword={updatePassword} agentPw={agentPw} loginUser={loginUser} agentUsername={agentUsername} setAgentUsername={setAgentUsername} setAgentPw={setAgentPw} />
        )
    )
}

export default Init;