import React, {useState} from 'react';
import '../../css/subscription.css';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import Icon from '../../assets/icons/icon';
import SubscriptionOption from './subscriptionOption';
import AddCard from './addCard';
import { createNewCustomer } from '../../utilities/marketUtil';
import { getPaymentMethods } from '../../utilities/subscriptionFunctions';
import { useSelector, useDispatch } from 'react-redux';
import CardBlock from './cardBlock';
import SubscriptionWarning from './subscriptionWarning';
import { setSubscription } from '../../features/subscriptionSlice';

function SubscribeModal({subscription}) {
    const dispatch=useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const [paymentMethods, setPaymentMethods] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [activeCard, setActiveCard] = useState(false);

    const handleSetAddCard=async ()=>{
        const payments= await getPaymentMethods(agentInfo.agentId)
        if(payments.payment.data.length > 0){
            setPaymentMethods(payments.payment.data)
        }
    }
    const handleStartSubcription=async ()=>{
        if(subscription[0].customer){
            const payments= await getPaymentMethods(agentInfo.agentId)
            if(payments.payment.data.length > 0){
                setPaymentMethods(payments.payment.data)
            }
            setAddCard(true)
        }
        else{
            const newCustomer= await createNewCustomer(agentInfo.agentId);
            dispatch(setSubscription([{customer:newCustomer,activeSubscription:false}]));
            setAddCard(true)
        }
    }
    const handleSelectCard= async(methodId)=>{
        setActiveCard(methodId);
    }
    const handleAddNewCard=()=>{
        setPaymentMethods(false);
    }
    return (
        <div className="subscribeModalWrapper">
            <div className="subscribeModalContent">
                {/*<div className="quilityCloseWindow" style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>*/}
                <div className="logo"><img src={image} className="subscribeLogo" alt="Logo" /></div>
                <div className="smallTitle" style={{textAlign:'center'}}>
                    {addCard && !paymentMethods ? (
                        'Add a Payment Method'
                    ) :addCard &&  !activeCard ? (
                        <>Choose a Card To Use <div className="addNewCard solidQuilityActiveButton" onClick={()=>handleAddNewCard()}>+ Add Card</div></>
                    ) : activeCard ? (
                        'You are almost done'
                    ) : (
                        'Please Pick a Subscription'
                    )}
               
                </div>
                {addCard ? (
                    subscription[0].customer && (
                        paymentMethods ? (
                            activeCard ? (
                                <SubscriptionWarning card={activeCard} customer={subscription[0].customer} agent={agentInfo.agentId}/>
                            ) : (
                                <div className="cardWrapper">
                                    {paymentMethods.map((card, index) => (
                                        <CardBlock card={card} handleSelectCard={handleSelectCard}/>
                                    ))}
                                </div>
                            )
                            
                        ) : (
                            <div className="cardModalAdjustment">
                                <AddCard cust={subscription[0].customer} addACardDone={handleSetAddCard}/>
                            </div>
                        )
                    ) 
                ) : (
                    <div className="subscriptionHolder">
                        <SubscriptionOption handleStartSubcription={handleStartSubcription} />
                    </div>
                )}
                
            </div>
        </div>
    );
}

export default SubscribeModal;