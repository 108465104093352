import React, { useState, useEffect } from 'react';
import { gatherCalendars,convertSmallStamp } from '../../../../utilities/calendar';
import { unixTommddhia } from '../../../../utilities/dashFunction';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector } from 'react-redux';
import Icon from '../../../../assets/icons/icon';

function ScheduleCall({leadData, clientName,futureCall}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [selectedOptions, setSelectedOptions] = useState(['In-App']);
    const calendar=agentInfo.defaultCalendar;
    const [selectedCal, setSelectedCal] = useState(calendar);
    const [selectCal, setSelectCal] = useState(false);
    const [availableCalendars, setAvailableCalendars] = useState(false);
    const [hours, setHours] = useState('');
    const [date, setDate] = useState('');
    const [minutes, setMinutes] = useState('');
    const [ampm, setAmpm] = useState('');
    const [error, setError] = useState('');
    const [textError, setTextError] = useState('');
    const [upcoming, setUpcoming] = useState(false);
    const [actualUpcoming, setActualUpcoming] = useState(false);
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const noCal=['Holidays in United States', 'Weather','Birthdays','Hindu Holidays','Holidays in India'];
    

    const handleHoursChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 12)) {
            setHours(value);
        }
    };

    const handleMinutesChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
            setMinutes(value);
        }
    }
    const handleDateChange = (e) => {
        const value = e.target.value;
        setDate(value);
    }
    const handleOptionClick = async (option) => {
        
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter(item => item !== option));

        } else {
            if(option==='Calendar'){
                //alert(calendar);
               // alert(selectedCal);
                //if(!calendar && !selectedCal){
                    setSelectedCal(false);
                    const cals=await gatherCalendars(agentInfo.agentId);
                    setAvailableCalendars(cals);
               // }
            }
            setSelectedOptions([...selectedOptions, option]);
        }
    };
     
    const scheduleCall = async () => {
        
        if(selectedCal){
            if(date ===''){
                setError('date')
            }
            else if(hours ===''){
                setError('hours')
            }
            else if(minutes ===''){
                setError('minutes')
            }
            else if(ampm ===''){
                setError('ampm')
            }
            else{
                setError(false);
                //convert the date to googles format
                const dateString=date+'T'+convertSmallStamp(`${hours}:${minutes} ${ampm}`);
                try {
                    const requestOptions = {
                        method: 'POST',
                        body: JSON.stringify({ 
                            agent: agentInfo.agentId,
                            lead:leadData.lead_id,
                            time:`${hours}:${minutes} ${ampm}`,
                            date:date,
                            clientName:leadData.borrower_first+' '+leadData.borrower_last,
                            timezone:agentInfo.timezone,
                            calendar:selectedCal,
                            team:agentInfo.team,
                            dateString:dateString,
                            selectedOptions:selectedOptions
                        }) 
                    };
                
                    const response = await fetchWithDefaults('/google/setCallBack', requestOptions);
        
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const jsonData = await response.json();
                    if(jsonData.status){
                        if(actualUpcoming){
                            setActualUpcoming([...actualUpcoming,jsonData.callObj]);
                        }
                        else{
                            setActualUpcoming([jsonData.callObj]);
                        }
                        setUpcoming(true); 
                        setTextError(false);
                        setAmpm(false);
                        setMinutes(false);
                        setDate(false);
                        setHours(false);
                    }
                    else{
                        setTextError("Unable to add to calendar");
                    }
                } 
                catch (error) {
                    console.error('Error fetching data:', error); 
                }
                
            }
        }
        else{
            setSelectCal(true);
        }
    };
    const deleteCallBack = async (call)=>{
        try {
            const requestOptions = {
                method: 'DELETE',
            };
            const response = await fetchWithDefaults(`/scheduledCalls/deleteCallback?callId=${call.id}`, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData){
                setActualUpcoming((prevAlerts) => prevAlerts.filter((alert) => alert.id !== call.id));
            }
            else{
                setTextError("Unable To Remove From Calendar");
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error); 
        }
    }
    const handleampmChange = (option) => {
        setAmpm(option);
    };
    useEffect(() => {
        if((unixTimestamp - 500) <= futureCall){
            setActualUpcoming([futureCall]);
            setUpcoming(true);
        }
    }, []);
    const getCalendars= async ()=>{
        const cals= await gatherCalendars(agentInfo.agentId);
        setAvailableCalendars(cals);
    }
    const toggleSelectedCalendar= async (calendarId) => {
        setSelectCal(false)
        setSelectedCal(calendarId)
    }
    useEffect(() => {
        if(selectCal){
            setUpcoming(false);
            getCalendars();
        }
    }, [selectCal]);
    const getCalls=async ()=>{
        try {
            const response = await fetchWithDefaults(`/scheduledCalls?lead=${leadData.lead_id}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData){
                setActualUpcoming(jsonData);
                setUpcoming(true);
            }
            else{
                setTextError("Unable to add to calendar");
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error); 
        }
    }
    useEffect(() => {
        if(leadData){
            setActualUpcoming(false)
            setUpcoming(false);
            getCalls();
        }
    }, [leadData.lead_id]);
    return (
        <>
        {selectCal && (
            <div className="selectCalendarPopup">
                <div className="quilityCloseWindow" onClick={()=>setSelectCal(false)}>X</div>
                <div className="title">Select a Calendar</div>
                {availableCalendars && availableCalendars.map((calendar, index) => (
                    !noCal.includes(calendar.summary) && (
                        <div className="calendarSelectLine" key={`availCal${index}`}>
                            <span class="calendarSelectLineTitle" style={{textAlign: 'left'}}>{calendar.summary} </span>
                            <span class="calendarSelectData" style={{cursor:'pointer'}} onClick={()=>toggleSelectedCalendar(calendar.id)}>Select Calendar</span>
                        </div>
                    )
                ))}
            </div>
        )}
        {selectedCal===false && (
            <div className="calSelectionWrapper">
                <div className="calSelectionContent">
                    {availableCalendars && availableCalendars.map((calendar, index) => (
                        !noCal.includes(calendar.summary) && (
                            <div className="calendarSelectLine" key={`calsAvail${index}`}>
                                <span class="calendarSelectLineTitle" style={{textAlign: 'left'}}>{calendar.summary} </span>
                                <span class="calendarSelectData" style={{cursor:'pointer'}} onClick={()=>setSelectedCal(calendar.id)}>Select Calendar</span>
                            </div>
                        )
                    ))}
                </div>
            </div>
        )}

        {upcoming ? (
                <>
                    <div className="title">Upcoming Scheduled Calls</div>
                    <div className="upcomingOutterWrapper">
                        {actualUpcoming && actualUpcoming.map((eventTime, index) => (
                            <div className="upcomingCallWrapper outlineQuilityActiveButton" key={`actualUpcoming${index}`}>{unixTommddhia(eventTime.time)}<span className="quilityCloseWindow smallClose oulineQuilityDestructiveButton" onClick={()=>deleteCallBack(eventTime)}><span className="fontello" style={{marginTop: '-10px'}}><Icon name={'x-circle-q'} size={24} className="closeIcon" adjust="fontello" /></span></span></div>
                        ))}
                    </div>
                    <div className="upcomingCallWrapper solidQuilityActiveButton" onClick={()=>setUpcoming(false)}>Schedule Another Call</div>
                </>
        ) : (
            <div className="scheduledCallWrapper">
                <div className="subTitle" style={{fontWeight:'700'}}>Schedule Future Call</div>
                <div className="topBarWrapper">
                    <div className="topBarInputWrapper">
                        <input 
                            type="date" 
                            className={`topBarInput ${error==='date' ? 'timeError' : ''}`}
                            placeholder='mm/dd/yyyy' 
                            onChange={handleDateChange} 
                        />
                    </div>
                    @
                    <div className="topBarWrapper">
                    <div>
                        <input 
                            type="number" 
                            className={`numberInput ${error==='hours' ? 'timeError' : ''}`}
                            placeholder="Hours" 
                            value={hours} 
                            onChange={handleHoursChange} 
                            min={1} 
                            max={12} 
                        />
                        <span> : </span>
                        <input 
                            type="number"
                            className={`numberInput ${error==='minutes' ? 'timeError' : ''}`}
                            placeholder="Minutes" 
                            value={minutes} 
                            onChange={handleMinutesChange} 
                            min={0} 
                            max={59} 
                        />
                        <span className={`ampmSelect ${error==='ampm' ? 'timeError' : ''}`}>
                            <span className={`amSelect ampmSelector ${ampm==='am' ? 'active' : ''}`} onClick={()=> handleampmChange('am')}>AM</span>
                            <span className={`amSelect ampmSelector ${ampm==='pm' ? 'active' : ''}`} onClick={()=> handleampmChange('pm')}>PM</span>
                        </span>
                    </div>
                    </div>
                    <div className="topBarWrapper">
                        <div className="subTitle">Reminder Type</div>
                        <div className="buttonWrapper">
                            <div className={`selectionOption ${selectedOptions.includes('In-App') ? 'selectedItem' : ''}`} onClick={() => handleOptionClick('In-App')}>
                                {selectedOptions.includes('In-App') && <span className="iconWrapper"></span>}
                                In-App
                            </div>
                            <div className={`selectionOption ${selectedOptions.includes('Email') ? 'selectedItem' : ''}`} onClick={() => handleOptionClick('Email')}>
                                {selectedOptions.includes('Email') && <span className="iconWrapper"></span>}
                                Email
                            </div>
                            <div className={`selectionOption ${selectedOptions.includes('Calendar') ? 'selectedItem' : ''}`} onClick={() => handleOptionClick('Calendar')}>
                                {selectedOptions.includes('Calendar') && <span className="iconWrapper"></span>}
                                Calendar
                            </div>
                        </div>
                    </div>
                </div>
                <div className="topBarWrapper">
                    <div className="accentButton solidQuilityActiveButton" style={{display: 'inline-block',marginTop: '20px'}} onClick={()=>scheduleCall()}>Schedule Call</div>
                </div>
            </div>
        )}
        
        </>
    );
    
}

export default ScheduleCall;
