import React, { useState } from "react";
import FilteringOptions from "./filteringOptions";


function EmptyQueue({appInfo}) {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen=()=>{

        setIsOpen(true);
    }
    return (
        <div className={`emptyQueue ${isOpen ? '':''}`}>
            <div className="emptyQueueBorder">
            <div class="filterButtons" style={{textAlign: 'center',fontSize: '24px',lineHeight: '50px'}}>Your Queue is Empty!</div>
                {isOpen ? (
                    <FilteringOptions appInfo={appInfo}/>
                ) : (
                    <div className="openFilters" onClick={()=>handleOpen()}>open filters</div>
                )}
               
                
            </div>
        </div>
    );
}

export default EmptyQueue;

