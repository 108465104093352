import React, { useState, useRef, useEffect } from "react";
import '../../css/frontPage.css';
import List from "./component/list";
import EngagedList from "./component/engaged";

function TaskLists({filters,getMyFilter,title, open}) {
    return (
        <span className="taskListsWrapper">
           

                {filters && filters.map((filter, index) => (
                    filter.type==='Engaged' ? (
                        <EngagedList getMyFilter={getMyFilter} filter={filter} index={index} key={`engaged${index}`}/>
                    ) : (
                        <List getMyFilter={getMyFilter} filter={filter} index={index}  key={`regList${index}`}/>
                    )
                ))}

        </span>
    );
}

export default TaskLists; 
