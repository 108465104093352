import React from 'react';
import LeadCard from './leads/leadCard';

function WorkflowFollowup({title, data, openLead, isMin, leadCard, maxList}) {
    return (
        <>
            <div className="outerWrapper">
            <span className="outerWrapperTitle">{title}</span>
                <div className={`mainBoxWrapper ${isMin ? 'minimized' : ''}`}>
                    {isMin && <div className="circleTitle" onClick={() => maxList()}>Follow-Up</div>}
                    <div className="mainBoxHeader">
                        <div className='mainBoxTitle'>
                            Leads That Need Follow-Ups
                        </div>
                    </div>
                    <div className="mainBoxContent">
                    {data[0].status === 'noresult' ? (
                            <div className="noresultBig">You Have No {title}</div>
                        ) : (
                            <>
                        {data.map((lead, index) => (
                            <div className="individualLeadWrapper" key={'individualLead'+index} onClick={() => openLead(lead.lead_id)}>
                                <div className="leadListStatus leadItem">{lead.name}</div>
                                <div className="leadListName leadItem">{lead.borrower_last+', '+lead.borrower_first}</div>
                                <div className="leadListState leadItem">{lead.state}</div>
                                <div className="leadListLastContact leadItem">{lead.last_contacted}</div>
                                <div className="leadListDateAssigned leadItem"></div>
                                <div className="leadListAmountContacted leadItem">{lead.position}</div>
                            </div>
                        ))}
                        </>
                    )}
                    </div>
                </div>
            </div>
            {leadCard.length > 0 && 
                <LeadCard data={leadCard} maxList={maxList}/>
            }
        </>
    );
}

export default WorkflowFollowup;