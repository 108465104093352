import React,{useState,useRef,useEffect} from 'react';
import '../../css/frontPage.css';
import LeadSort from './leadSort';

function LeadList({data,title,openLead, isMin, addToQueue, setSelectedLeads, selectedLeads, batch, maxList}) {
    const [pages, setPages] = useState(0);
    const [display, setDisplay] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [leadOrder, setLeadOrder] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    //const [selectedLeads, setSelectedLeads] = useState([]);
    const lastCheckedLead = useRef(null);


    function formatAssigned(inputDate, inputDate2) {
        if(inputDate=="" || inputDate==null){
            inputDate=inputDate2;
        }
        const date = new Date(inputDate);
        
        // Extract day, month, and year
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear().toString();
        
        // Create the formatted date string
        const formattedDate = `${month}/${day}/${year}`;
        
        return formattedDate;
    }
    
    const handleSort = (criteria) => {

        if (sortBy === criteria) {
            // If already sorted by this criteria, toggle sort direction
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // If sorting by a new criteria, set sort criteria and direction
            setSortBy(criteria);
            setSortDirection('asc');
        }
  
        const sortedData = leadOrder.slice().sort((a, b) => {
            if (sortBy) {
            // Custom comparison function to handle null values
            const compareFunction = (valueA, valueB) => {
                if (Number.isInteger(valueA)) {
                    valueA = valueA.toString();
                } 
                if (Number.isInteger(valueB)) {
                    valueB = valueB.toString();
                } 
                if (valueA === null && valueB === null) return 0;
                if (valueA === null) return sortDirection === 'asc' ? 1 : -1;
                if (valueB === null) return sortDirection === 'asc' ? -1 : 1;
                return valueA.localeCompare(valueB);
            };
        
            // Sort by the selected criteria using the custom comparison function
            return compareFunction(a[sortBy], b[sortBy]) * (sortDirection === 'asc' ? 1 : -1);
            } 
            else {
                // If no criteria selected, maintain original order
                return 0;
            }
        });
        setLeadOrder(sortedData)
    };
  
    

    const handlePageClick = (pageIndex) => {
        setCurrentPage(pageIndex);
    };
    const handleCheckBox = () => {
        setDisplay(!display);
    };
    const handleCheckboxChange = (leadId, shiftKeyPressed) => {
        let updatedSelectedLeads;

        if (shiftKeyPressed && lastCheckedLead.current !== null) {
            const lastIndex = leadOrder.findIndex(lead => lead.lead_id === lastCheckedLead.current);
            const currentIndex = leadOrder.findIndex(lead => lead.lead_id === leadId);
            const start = Math.min(lastIndex, currentIndex);
            const end = Math.max(lastIndex, currentIndex);

            updatedSelectedLeads = [...selectedLeads];
            for (let i = start; i <= end; i++) {
                const leadId = leadOrder[i].lead_id;
                if (!updatedSelectedLeads.includes(leadId)) {
                    updatedSelectedLeads.push(leadId);
                }
            }
        } else {
            updatedSelectedLeads = selectedLeads.includes(leadId)
                ? selectedLeads.filter(id => id !== leadId)
                : [...selectedLeads, leadId];
        }

        setSelectedLeads(updatedSelectedLeads);
        lastCheckedLead.current = leadId;
    };

    const handleMouseDown = () => {
        lastCheckedLead.current = null;
    };
    useEffect(() => {
        if(data){
            const selectAll=[];
            data.map((lead, index) => (
                selectAll.push(lead.lead_id)
            ))
            setSelectedLeads(selectAll);
        }
    }, []);
    return (

        <div className="outerWrapper"  onMouseDown={handleMouseDown}>
            <span className="outerWrapperTitle"><span className="outerWrapperIcon"></span>{title}</span>
            <div style={{textAlign:'center'}}><div className="addToQueueButton accentButton" style={{margin:'auto'}}  onClick={()=>addToQueue(batch,'batch')}>Add Group To Queue</div></div>
            <div className={`leadListWrapper ${isMin ? 'minimized' : ''}`}>
                {isMin && <div className="circleTitle" onClick={() => maxList()}>Lead List</div>}
                <span className="leadListSort">
                    <LeadSort handleSort={handleSort} sortDirection={sortDirection} sortBy={sortBy}/>
                </span>
                <div className="leadListContentWrapper">
                {data && data[0].status === 'No Results' ? (
                        <div className="noresultBig">You Have No {title}</div>
                    ) : (
                        <>
                            {data.map((lead, index) => (
                                console.log(selectedLeads.includes(lead.lead_id)),
                                <div className="individualLeadWrapper" key={'individualLead'+index} onClick={() => openLead(lead.lead_id)}>
                                    <div className={`checkBoxWrapper ${display ? 'display' : ''}`} onClick={(e)=> e.stopPropagation()}>
                                        <input 
                                            type="checkbox" 
                                            value={lead.lead_id}
                                            checked={selectedLeads.includes(lead.lead_id)}
                                            onChange={(e) => handleCheckboxChange(lead.lead_id, e.shiftKey)}
                                        />
                                    </div>
                                    <div className="leadListName leadItem" id="leadBlockName">{lead.borrower_last+', '+lead.borrower_first}</div>
                                    {lead.status=='' || lead.status==null ? (
                                        <div className="leadListStatus leadItem">No Status</div>
                                    ) : (
                                        <div className="leadListStatus leadItem">{lead.statusName}</div>
                                    )}
                                    <div className="leadListState leadItem" id="leadBlockState">{lead.state}</div>
                                    {lead.last_contacted=='' || lead.last_contacted==null ? (
                                        <div className="leadListLastContact leadItem" id="leadBlockContacted">Never Contacted</div>
                                    ) : (
                                        <div className="leadListLastContact leadItem" id="leadBlockContacted">{lead.last_contacted}</div>
                                    )}
                                    <div className="leadListDateAssigned leadItem">{formatAssigned(lead.date_entered, lead.date_uploaded)}</div>
                                    <div className="leadListAmountContacted leadItem">{lead.amount_contacted}</div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div> 
            {pages > 0 && (
                <div className="pages">
                    {pages > 0 && (
                        <div className="pages">
                            {Array.from({ length: pages }, (_, index) => {
                                // Display the current page and adjacent pages
                                if (index === currentPage || index === currentPage - 1 || index === currentPage + 1 ||
                                    index === 0 || index === pages - 1 || index === currentPage - 2 || index === currentPage + 2) {
                                    return (
                                        <div
                                            className={`page ${index === currentPage ? 'current' : ''}`}
                                            key={index + 1}
                                            onClick={() => handlePageClick(index)}
                                        >
                                            {index + 1}
                                        </div>
                                    );
                                }
                                // Display ellipses for pages not adjacent to the current page
                                else if (index === 1 && currentPage > 3) {
                                    return <div className="ellipsis" key={index}>...</div>;
                                } else if (index === pages - 2 && currentPage < pages - 4) {
                                    return <div className="ellipsis" key={index}>...</div>;
                                }
                                // Return null for pages not included in the display
                                else {
                                    return null;
                                }
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default LeadList;


/*
    {pages > 0 && (
        <div className="pages">
            {Array.from({ length: pages }, (_, index) => (
                <div className="page" key={index + 1}>{index + 1}</div>
            ))}
        </div>
    )}
*/