import React, {useState} from 'react';
import '../../../css/scheduledCalls.css';
import { fetchWithDefaults } from '../../../config/fetchDefault';


function ScheduledAppointment({data, openLead, isMin, maxList}) {
    const [newData, setData] = useState(data);
    const cancel= async(apptId)=>{
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    appointment: apptId,
                }) 
            };
          
            const response = await fetchWithDefaults(`/appointment/cancel`, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData){
                // Remove the canceled call from the data prop
                const updatedTasksState = newData.map(dateGroup => ({
                    ...dateGroup,
                    tasks: dateGroup.tasks.filter(task => task.id !== apptId)
                }));
                setData(updatedTasksState);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const formatUnixTimestamp = (unixTimestamp) => {
        if (!unixTimestamp || isNaN(unixTimestamp)) {
            return 'Never Contacted';
        }
        
        // Convert Unix timestamp to milliseconds
        const timestampInMilliseconds = unixTimestamp * 1000;
      
        // Create a new Date object
        const date = new Date(timestampInMilliseconds);
      
        // Format the date
        const month = date.getMonth() + 1; // Months are zero-based
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
      
        // Convert 24-hour time to 12-hour time
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;
      
        // Format the output
        const formattedDate = `${month}/${day} @ ${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${ampm}`;
      
        return formattedDate;
    };
    let totalItems=0;
    if(newData[0].type !== false){
        newData.map((item, index) => (
            totalItems=totalItems + item['tasks'].length
        ))
    }
    
    return (
        <div className="outerWrapper" style={{textAlign: 'center'}}>
            <span className="outerWrapperTitle"><span className="outerWrapperIcon"></span>Scheduled Appointments</span>
            <div className={`frontPageQuilityParentBlockWrapper ${isMin ? 'minimized' : ''}`} style={{width:'70%'}}>
                {isMin && <div className="circleTitle" onClick={() => maxList()}>Appts</div>}
                <div className="quilityParentBlockHeader">
                    <div className='quilityParentBlockTitle'>
                        Upcoming Appointments
                    </div>
                </div>
                <div className="quilityParentBlockContent">
                {data[0].type === false ? (
                        <div className="noresultBig">No Scheduled Appointments</div>
                    ) : (
                        <>
                    {newData.map((item, index) => (
                        <>
                        {item.tasks.map((call, index) => (
                            <div className="quilityNestedBlockWrapper " key={call.callId}>
                                <div class="quilityNestedBlockHeader">
                                    <div class="quilityParentBlockTitle">Meeting With {call.borrower_first} {call.borrower_last}</div>
                                </div>
                                <div className="infoWrapper">
                                    <div className="quilityDataLine">
                                        <span className="scheduledName">{call.borrower_first} {call.borrower_last}</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="scheduledDate">{formatUnixTimestamp(call.date)}</span>
                                    </div>

                                    <div className="quilityDataLine">
                                        <span className="scheduledLastContacted">
                                            {call.type === 'meet' ? (
                                                <span>Google Meet</span>
                                            ) : call.type === 'zoom' ? (
                                                <span>Zoom</span>
                                            ) : call.type === 'phone' ? (
                                                <span>Phone</span>
                                            ) : call.type === 'home' ? (
                                                <span>Home</span>
                                            ) : (
                                                <span>Unknown Type</span>
                                            )}
                                        </span>
                                    </div>
                                    {call.link && (
                                        <div className="quilityDataLine">
                                            <span className="scheduledCallType">{call.link}</span>
                                        </div>
                                    )}
                                </div>
                                <span className="schedulefunctionButtons" style={{marginBottom:'30px', marginTop:'20px'}}>
                                    <span className="wideBlockButton accentButton solidQuilityDestructiveButton" onClick={() => cancel(call.id)}>Cancel</span>
                                    <span className="wideBlockButton accentButton solidQuilityActiveButton" onClick={() => openLead(call.lead_id)}>View Lead</span>
                                </span>
                            </div>
                        ))}
                        </>
                    ))}
                    </>
                )}
                </div>
                <div className="blockFooter">
                    <div className="buttonWrapper" style={{marginTop:'5px', marginLeft:'10px'}}><div className="dataButtonBlock">Total: {totalItems}</div></div>
                </div>
            </div>
            
        </div>
    );
}

export default ScheduledAppointment;