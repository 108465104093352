import React, {useState} from 'react';
import '../../css/subscription.css';
import TermsOfService from '../../terms/tos';
import image from '../../assets/icons/loading-loader.gif';
import { subscribeToProduct } from '../../utilities/subscriptionFunctions';
//#45bcaa8a
function SubscriptionWarning({card, customer, agent}) {
    const [terms, setTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const handleSubscribe=async ()=>{
        setLoading(true)
        const subscription = await subscribeToProduct('product', agent, card, customer);
        if(subscription){
            window.location.reload();
        }
        else{
            alert('there was a problem with your payment');
            setLoading(false)
        }

    }
    return (
        <div className="subscriptionWarningWrapper">
            <div className="subscriptionWarningTitle">Thank you for wanting to join the <b>Fun</b>nel Team <br/><br/><b>$59.99 per month</b></div>
            <div className="subscribeInfoWrapper">
                <div className="quilityDataLine">
                    <TermsOfService terms={terms} setTerms={setTerms} />
                </div>
                <div className="quilityDataLine">
                    <div className={`${terms ? 'solidQuilityActiveButton' : 'inactiveButton'} subscribeButton`} onClick={()=>terms ? handleSubscribe() : null}>Submit & Subscribe</div>
                </div>
            </div>
            {loading && (
                <div className="loadingScreen">
                    <div className="loaderWrapper">
                        <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default SubscriptionWarning;
