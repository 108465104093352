import { createSlice } from '@reduxjs/toolkit'

export const callSlice = createSlice({
    name: 'call',
    initialState: {
        call: [],
        ringState:'disconnected',
        sessionStarted:false
    },
    reducers: {
        setCall(state, action) {
            state.call = action.payload;
        },
        setStartSession(state, action) {

            state.sessionStarted = action.payload;
        },
        setRingState(state, action) {
            state.ringState = action.payload;
        },
    },
})
export const { setCall, setRingState,setStartSession } = callSlice.actions;

export default callSlice.reducer

