import React, { useState, useRef, useEffect } from "react";
import '../../css/templates.css';
import MessageVariables from '../messageVariables';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import Variables from './variables';
import Icon from "../../assets/icons/icon";


function AddTemplate({ addATemplate, type, setNewTemplate }) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const variables=useSelector((state) => state.variables.variables);
    const [templateName, setTemplateName] = useState('');
    const [templateSubject, setTemplateSubject] = useState('');
    const [templateContent, setTemplateContent] = useState('');
    const textareaRef = useRef(null);
    const agentId=agentInfo.agentId;
    const teamId=agentInfo.teamId;
    const editorRef = useRef(null);
    const [editedContent, setEditedContent] = useState('');

    const setAddTemplateName= async (content)=>{
        setTemplateName(content);
    }
    const setAddTemplateSubject= async (content)=>{
        setTemplateSubject(content);
    }
    const setAddTemplateContent= async (content)=>{
        setTemplateContent(content);
    }
    const appendVariable = (textToAppend) => {
        const textarea = textareaRef.current;
    
        if (textarea) {
          // Get current caret position
          const caretStart = textarea.selectionStart;
          const caretEnd = textarea.selectionEnd;
    
          // Insert text at the current caret position
          const newText = `${templateContent.substring(0, caretStart)}${textToAppend}${templateContent.substring(caretEnd)}`;
          setTemplateContent(newText);
    
          // Move caret after the inserted text
          const newCaretPos = caretStart + textToAppend.length;
          textarea.setSelectionRange(newCaretPos, newCaretPos);
        }
    };
    const appendVariable2=(code)=>{
        const editor = editorRef.current.editor; // Get the TinyMCE editor instance
    
        // Get the current cursor position
        const cursorPosition = editor.selection.getRng();
    
        // Insert the text at the cursor position
        editor.selection.setRng(cursorPosition);
        editor.insertContent(code);
    }
    const addNewTemplate= async (id)=>{

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    name: templateName,
                    content: templateContent || value,
                    subject: templateSubject,
                    team: teamId,
                    admin:agentInfo.admin,
                    organization:agentInfo.organization
                }) 
            };
            const response = await fetchWithDefaults(`/addNew${type}`, requestOptions);
          
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            setNewTemplate({id:jsonData.id, name:templateName, content:templateContent||value, subject: templateSubject})
            addATemplate();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const [value, setValue] = useState(editedContent ?? '');
    useEffect(() => setValue(editedContent ?? ''), [editedContent]);
    return (
        <div className="mainBlockWrapper focusMainBlock">
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Add a New {type}</div>
            </div>

            <div className="quilityCloseWindow" style={{top: '0px'}}><span className="fontello" style={{marginTop: '-7px'}} onClick={()=>addATemplate()}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            <div className="mainBlockContent roundedBottom">
                <div className="bigBlock focusBigBlock" style={{marginTop: '25px'}}>
                    <div className="leadCardSubTitle"></div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Name The {type}</div>
                        <input 
                            className="standardInputBox largeInput" 
                            type="text"
                            value={templateName}
                            onChange={(e) => setAddTemplateName(e.target.value)}
                        />
                    </div>
                    {type=='Email'? (
                        <>
                            <div className="standardInputWrapper">
                                <div className="standardInputTitle">Subject</div>
                                <input 
                                    className="standardInputBox largeInput" 
                                    type="text"
                                    value={templateSubject}
                                    onChange={(e) => setAddTemplateSubject(e.target.value)}
                                />
                            </div>
                            <div className="variableHolder">
                            </div>
                            <div className="standardInputWrapper">
                                <div className="buttonWrapper">
                                    <span className="accentButton solidQuilityDestructiveButton editorOption">Cancel</span>
                                    <span className="accentButton solidQuilityActiveButton editorOption" onClick={()=>addNewTemplate()}>Save</span>
                                    <Variables appendVariable={appendVariable2} data={variables} /> 
                                </div>
                                
                                <Editor
                                    apiKey='4zy762hh33o9pxugauf7noqs1ga21gsxcoghnt3t0t5stfsm'
                                    ref={editorRef}
                                    init={{
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Author name',
                                        mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                        ],
                                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                    }}
                                    initialValue={ editedContent }
                                    value={value}
                                    onEditorChange={(newValue, editor) => setValue(newValue)}
                                />
                            </div>
                        </>
                    ):(
                        <>
                            <div className="variableHolder">
                                <MessageVariables appendVariable={appendVariable} data={variables} /> 
                            </div>
                            <div className="standardInputWrapper">
                                <div className="standardInputTitle">content</div>
                                <textarea 
                                    ref={textareaRef} 
                                    value={templateContent}
                                    onChange={(e) => setAddTemplateContent(e.target.value)}
                                    className="largeTextarea"
                                />
                            </div>
                        </>
                    )}
                    
                </div>
                <div className="buttonWrapper"><div className="accentButton solidQuilityActiveButton" style={{margin:'auto'}} onClick={()=>addNewTemplate()}>Save Template</div></div>
            </div>
            
        </div> 
    );
}

export default AddTemplate;

/*<textarea 
                                    ref={textareaRef} 
                                    value={templateContent}
                                    onChange={(e) => setAddTemplateContent(e.target.value)}
                                    className="largeTextarea"
                                />*/