import React, { useState, useEffect } from 'react';
//import Calendar from './calendar';
import '../../../../css/calendar.css'; 
import ScheduleCall from './scheduleCall';
import AttachFile from './attachFile';
import Calendar2 from './calendar2';
import { useSelector } from 'react-redux';


function LeadTopBarButton({title, handleSetScripting, setApptSet, qualifier, leadData, newEventPackage, defaultCalendar, setEvent, newAppt, setNewAppt, update}) {
    const [isOpen, setIsOpen] = useState(false);
    const triggerCalender=useSelector((state) => state.appointment.triggerCalendar);
    const [selected, setSelected] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const [calIsOpen, setCalIsOpen] = useState(false);
    
    const handleClick = (link) => {
        window.open(link, '_blank');
    };

    const handleOpen=(title)=>{
        setCalIsOpen(!calIsOpen);
        if(title===selected){
            setSelected(false);
        }
        else{
            setSelected(title)
        }
    }
    const calendarChange=(calendar)=>{
        setCalendar(calendar);
    }
    useEffect(() => {
        if(triggerCalender){
            setCalIsOpen(true)
        }
    }, [triggerCalender]);
    return (
        <div className="leadCardButtonOuterWrapper" style={{flex: '1'}}>
            {title==="Lead PDF" ?(
                 <div className="leadTopBarButton outlineQuilityActiveButton"><a href="#" onClick={()=>handleClick(leadData.pdf)} className="pdfLink">{title}</a></div>
            ): title==='Appointment' ? (
                <>
                    <div className="leadTopBarButton outlineQuilityActiveButton" onClick={()=>handleOpen(title)}>{title}</div>
                    <div className={`calendarWrapper ${calIsOpen ? 'openCal' : ''}`}>
                        {calIsOpen && (
                            <Calendar2 setApptSet={setApptSet} update={update} newAppt={newAppt} setNewAppt={setNewAppt} setIsOpen={setCalIsOpen} defaultCalendar={defaultCalendar} calendarChange={calendarChange} newEventPackage={newEventPackage} setEvent={setEvent}/>
                        )}
                    </div>
                </>
            ) :  title==='Qualifier' ? (
                    <div className={`leadTopBarButton outlineQuilityActiveButton ${qualifier ? 'activeLeadCardButton' : ''}`} onClick={()=>handleSetScripting(true)}>{title}</div>
            ) : title==="Schedule Call" ? (
                <>
                    <div className={`leadTopBarButton outlineQuilityActiveButton${selected===title ? 'focusButton' : ''}`} onClick={()=>setIsOpen(!isOpen)}>{title}</div>
                    <div className={`dialogBox ${isOpen ? 'openBox' : ''}`}>
                        <ScheduleCall leadData={leadData}/>
                    </div>
                </>
            ) : title==="Attach File" ? (
                <>
                    <div className={`leadTopBarButton outlineQuilityActiveButton ${selected===title ? 'focusButton' : ''}`} onClick={()=>setIsOpen(!isOpen)}>{title}</div>
                    <div className={`dialogBox ${isOpen ? 'openBox' : ''}`} style={{overflow: 'auto'}}>
                        <AttachFile files={leadData.file_link} leadId={leadData.lead_id}/>
                    </div>
                </>
            ) : (
                <>
                    <div className={`leadTopBarButton outlineQuilityActiveButton ${selected===title ? 'focusButton' : ''}`} onClick={()=>handleOpen(title)}>{title}</div>
                    <div className={`dialogBox ${isOpen ? 'openBox' : ''}`}>
                    </div>
                </>
            )}
        </div>
    );
    
}

export default LeadTopBarButton;
