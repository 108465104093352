import { createSlice } from '@reduxjs/toolkit'

export const objectionSlice = createSlice({
    name: 'objections',
    initialState: {
        objections: []
    },
    reducers: {
        setObjections(state, action) {
            state.objections = action.payload;
        },
    },
})
export const { setObjections } = objectionSlice.actions;

export default objectionSlice.reducer

