import React, {useRef,useState} from 'react';
import '../../css/recording.css';
import AudioPlayer from 'react-audio-player';
import Icon from '../../assets/icons/icon';
import { unixTommddhia } from '../../utilities/dashFunction';


function RecordingBlock({recording, handleDeleteRecording, from}) {
    const audioPlayerRef = useRef(null);
    function formatDuration(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
    
        if (minutes > 0) {
            return `${minutes} Minute${minutes > 1 ? 's' : ''} and ${remainingSeconds} Second${remainingSeconds !== 1 ? 's' : ''}`;
        } else {
            return `${remainingSeconds} Second${remainingSeconds !== 1 ? 's' : ''}`;
        }
    }
    return (
        <div class="quilityNestedBlockWrapper">
            <div class="quilityNestedBlockHeader">
                <div className="deleteRecording oulineQuilityDestructiveButton" onClick={()=>handleDeleteRecording(recording.recordingSid)}><span className="fontello" style={{marginTop: '-10px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                <div class="quilityParentBlockTitle">{from==='leadCard' ? 'Recording on '+unixTommddhia(recording.title) : recording.title}</div>
            </div>
            <div class="quilityNestedBlockContent">
                <div class="quilityDataLine">
                    <span class="quilityDataLineTitle">Date</span> 
                    <span class="quilityDataLineValue">{unixTommddhia(recording.date)}</span>
                </div>
                <div class="quilityDataLine">
                    <span class="quilityDataLineTitle">Duration</span> 
                    <span class="quilityDataLineValue">{formatDuration(recording.duration)}</span>
                </div>
                <div class="quilityDataLine">
                    <span class="quilityDataLineTitle" style={{verticalAlign: 'top'}}>Recording</span> 
                    <span class="quilityDataLineValue">
                        <AudioPlayer
                            ref={audioPlayerRef}
                            src={recording.url}
                            autoPlay={false}
                            controls
                            className="audioPlayerStyles"
                        />
                    </span>
                </div>
                <div className="buttonWrapper"></div>
            </div>
        </div>
    );
}

export default RecordingBlock;