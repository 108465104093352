
import React, { useState, useEffect, useRef } from "react";
import '../../css/templates.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { Editor } from '@tinymce/tinymce-react';
import Variables from './variables';
import { useSelector } from 'react-redux';


function FastEdit({ editTemplate,item, index, setTransition, transition,editingItemId, setEditingItemId, type }) {

    const variables=useSelector((state) => state.variables.variables);
    const [editedContent, setEditedContent] = useState('');
    const [quickEditName, setQuickEditName] = useState(item.name);
    const [quickEditSubject, setQuickEditSubject] = useState(item.subject);
    const [currentIndex, setCurrentIndex] = useState(null);
    const textareaRef = useRef(null);
    const editorRef = useRef(null);

    const appendVariable2=(code)=>{
        const editor = editorRef.current.editor; // Get the TinyMCE editor instance
    
        // Get the current cursor position
        const cursorPosition = editor.selection.getRng();
    
        // Insert the text at the cursor position
        editor.selection.setRng(cursorPosition);
        editor.insertContent(code);
    }
    const appendVariable = (textToAppend) => {
        const textarea = textareaRef.current;
    
        if (textarea) {
          // Get current caret position
          const caretStart = textarea.selectionStart;
          const caretEnd = textarea.selectionEnd;
    
          // Insert text at the current caret position
          const newText = `${editedContent.substring(0, caretStart)}${textToAppend}${editedContent.substring(caretEnd)}`;
          setEditedContent(newText);
    
          // Move caret after the inserted text
          const newCaretPos = caretStart + textToAppend.length;
          textarea.setSelectionRange(newCaretPos, newCaretPos);
        }
    };
    useEffect(() => {
        if (editingItemId !== null) {
            textareaRef.current?.focus();
        }
    }, [editingItemId]);
    useEffect(() => {
        setEditedContent(item.content);
    }, []);

    const handleEditClick = (itemId, content, index, name) => {
        setEditingItemId(itemId);
        setEditedContent(content);
        setQuickEditName(name);
        setTransition(true);
        setCurrentIndex(index);
    };
    const [value, setValue] = useState(editedContent ?? '');
    useEffect(() => setValue(editedContent ?? ''), [editedContent]);
  
    const quickEditUpdate= async (id)=>{
        setEditedContent(value);
        setEditingItemId(null);
        setTransition(false);
        editTemplate(false,false,false);
       
       item.content= editedContent;

        setCurrentIndex(null);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    name: quickEditName,
                    content: value,
                    subject: quickEditSubject,
                    id:id
                }) 
            };
        
            const response = await fetchWithDefaults(`/updateTemplate${type}`, requestOptions);
        
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const closeEdit=()=>{
        setValue(editedContent);
        setEditingItemId(null);
        setTransition(false);
        editTemplate(false,false,false);
    }
    return (
        <>
        {editingItemId === item.id ? (
            <>
                
                {type==='Text' ? (
                    <>
                        <div className="buttonWrapper">
                            <span className="accentButton solidQuilityDestructiveButton editorOption" onClick={()=>closeEdit()}>Cancel</span>
                            <span className="accentButton solidQuilityActiveButton editorOption" onClick={()=> quickEditUpdate(editingItemId)}>Save</span>
                            <Variables appendVariable={appendVariable} data={variables} /> 
                        </div>
                        <textarea 
                            ref={textareaRef}
                            className={`messageEdit ${transition  ? 'transitionMessage' : ''}`}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </>
                ) : ( 
                    <>
                        <div className="buttonWrapper">
                            <span className="accentButton solidQuilityDestructiveButton editorOption" onClick={()=>closeEdit()}>Cancel</span>
                            <span className="accentButton solidQuilityActiveButton editorOption" onClick={()=> quickEditUpdate(editingItemId)}>Save</span>
                            <Variables appendVariable={appendVariable2} data={variables} /> 
                        </div>
                        <Editor
                            apiKey='4zy762hh33o9pxugauf7noqs1ga21gsxcoghnt3t0t5stfsm'
                            ref={editorRef}
                            init={{
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                tinycomments_author: 'Author name',
                                mergetags_list: [
                                    { value: 'First.Name', title: 'First Name' },
                                    { value: 'Email', title: 'Email' },
                                ],
                                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                            }}
                            initialValue={ editedContent }
                            value={value}
                            onEditorChange={(newValue, editor) => setValue(newValue)}
                        />
                    </>
                )}
                
            </>
        ) : (
            <span
                className="messagePreview"
                onClick={() => handleEditClick(item.id, editedContent, index, item.name)}
            >
            {type=='script' || type=='Objection' || type=='Email' ?  <div dangerouslySetInnerHTML={{ __html: editedContent }} /> : editedContent}
            </span>
        )}
        </>
    );
}

export default FastEdit;
/*<textarea 
                ref={textareaRef}
                className={`messageEdit ${transition  ? 'transitionMessage' : ''}`}
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
            />
            
            onClick={()=> editTemplate(item.id, item.name, editedContent, item.subject)}
            
            */

