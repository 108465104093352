import React from "react";
import '../../css/workflow.css';


function NameFlow({flowName, flowNameError, handleFlowName, tags, setAttachedStatus, attachedStatus}) {

    return (
        <>
            <div className="quilityNestedBlockWrapper">
                <div className="quilityNestedBlockHeader">
                    <div class="quilityParentBlockTitle">
                    {flowName != '' ? (
                        flowName
                    ) : (
                        "Name Workflow"
                    )}
                    </div>
                </div>
                <div className="quilityNestedBlockContent" style={{minHeight:'200px'}}>
                    <div className="leadInfoLine">
                        <div className="errorMessage">{flowNameError}</div>
                        <span className="dataFieldTitle">Name: </span>
                        <span class="leadData" style={{marginTop: '5px'}}>
                            <input type="text" className="qualifierFieldInput" value={flowName&&(flowName)} onChange={(e) => handleFlowName(e.target.value)}/>
                        </span>
                    </div>
                </div>
            </div>
            <div className="quilityNestedBlockWrapper" >
                <div className="quilityNestedBlockHeader">
                    <div className="quilityParentBlockTitle">
                        Attach Workflow To Trigger From Tag
                    </div>
                </div>
                <div className="leadCardBlock">
                    <div className="quilityNestedBlockContent" style={{minHeight:'200px'}}>
                        <div className="systemInfoItem">
                            <span className="dataFieldTitle">Attach a Tag (optional) </span>
                            <div style={{marginTop:'5px'}}>
                                <select type="text" className="outlineButton filterButton" value={attachedStatus ? (attachedStatus):(null)} onChange={(e)=>setAttachedStatus(e.target.value)}>
                                    <option value="none">Optionally attach to a tag</option>
                                    {tags.map((item, index) => (  
                                        console.log(item.workflow),
                                        item.workflow ? (
                                            <option value={item.id}  key={`nameFlow${index}`} disabled>{item.name}</option>
                                        ) :(
                                            <option value={item.id} key={`nameFlow${index}`}>{item.name}</option>
                                           
                                        )
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    );
}

export default NameFlow;

