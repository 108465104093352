

import React from 'react';


function StartSession({handleStartSession,clickToDial}) {
    
    
    return (
        <div className="warningWrapper">
            <div className="warningBody" style={{height:'35%'}}>
                <div className="warningHeader">
                    Session Ready
                </div>
                <div className="warningContent">
                    Are you ready to start your session?
                </div>
                <div className="warningButtonHolder" style={{margin: '0px 0px', position: 'unset'}}>
                    <div className="warningButtonWrapper">
                        <div className="standardButton" key={'sess1'} onClick={()=>handleStartSession()}>Start Auto Dial Session</div>
                        <div className="standardButton" key={'sess2'} onClick={()=>clickToDial()}>Click To Dial</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StartSession;