import React, { useState, useRef } from "react";
import '../../css/header.css';
import { useSelector } from 'react-redux';
import Icon from '../../assets/icons/icon';

function AgentInfo({title, fetchNewPage, signOut}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [isOpen, setIsOpen] = useState(false);
    const open=()=>{
        setIsOpen(!isOpen);
    }
    return (
        <div className={`agentInfoWrapper ${isOpen ?  'openAgent': 'closeAgent'}`} style={{backgroundColor: isOpen ? '#ffffff' : ''}} onClick={()=>open()}>
            <div className="agentInfoLabel">{agentInfo.firstName.charAt(0)}{agentInfo.lastName.charAt(0)}</div>
            <div className="agentDetail">
                <div className="agentInfoName">{agentInfo.firstName} {agentInfo.lastName}</div>
                <div className="agentInfoAgency">{agentInfo.lastName} Agency</div>
            </div>
            <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px',lineHeight: '50px',transition:' 0.5s',width: 'calc(25% - 20px)',padding: 'unset',float: 'unset',display: 'inline-block', verticalAlign: 'middle'}}><span className="fontello"><Icon name={'q-up'} size={24} className="closeIcon" adjust="fontello" /></span></span>
            {isOpen && (
                <div className="additionalAgentInfo">
                    <div className="additionalInfoLine" onClick={()=>fetchNewPage('Subscription')}><span className="fontello"><Icon name={'wallet-q'} size={18} className="dropDownIcon" adjust="fontello" /></span> Subscription</div>
                    <div className="additionalInfoLine" onClick={()=>fetchNewPage('Integrations')}><span className="fontello"><Icon name={'q-resources'} size={18} className="dropDownIcon" adjust="fontello" /></span> Integrations</div>
                    <div className="additionalInfoLine logout" onClick={()=>signOut()}><span className="fontello"><Icon name={'q-logout'} size={18} className="dropDownIcon" adjust="fontello" /></span> Logout</div>
                </div>
            )}
            
        </div>
    );
}

export default AgentInfo;
