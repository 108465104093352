import React, { useState } from "react";
import '../../css/templates.css';
import Warning from '../warning';
import { fetchWithDefaults } from '../../config/fetchDefault';

function Reminder({ data,formatDate, addAFlow, addFlow }) {
    const [editingTemplate, setEditTemplate] = useState(false);
    const [reminders, setReminders] = useState(data[0]);
    const [editingItemId, setEditingItemId] = useState(null);
    const [newTemplate, setNewTemplate] = useState(null);
    const [warning, setWarning] = useState(false);
    const [editedText, setEditedText] = useState({});
    const [editedIndex, setEditedIndex] = useState(null);
    const calculateTime = (inputString) => {
        return inputString;
    };
    const cancel = () => {
        setWarning(false);
    };
    const confirm = async (id, index) => {
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    reminderId: warning
                }) 
            };
          
            const response = await fetchWithDefaults('/deleteReminder', requestOptions);
           
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
         
            console.log(jsonData);
            if(jsonData.status==true){
                const newArray = [...reminders];

                // Find the index of the object with the specified ID
                const indexToRemove = newArray.findIndex(obj => obj.id === warning);

                // Remove the object at the found index
                if (indexToRemove !== -1) {
                    newArray.splice(indexToRemove, 1);
                    setReminders(newArray);
                }
                setWarning(false);
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
   
    const handleInputChange = (e, index) => {
        setEditedText({ ...editedText, [index]: e.target.value });
    };
    const handleEditName = (index, text) => {
        setEditedIndex(index);
        //setEditedText({ [index]: text });
        setEditedText({ ...editedText, [index]: text });
    };
    const setStatusOption = (contentType, content, reminderId) => {
        //setCurrentStatusReminder(content);
        updateTemplate(reminderId, contentType, content);
    };
    const setStatusReminder= (contentType, content, reminderId) => {
        //setCurrentStatusReminder(content);
        updateTemplate(reminderId, contentType, content);
    };
    const removeItem = (id,index) => {
        setWarning(id);
    };
    
    const updateTemplate = async (reminder, type, content) => {
        setEditedIndex(null);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    reminder: reminder,
                    content: content,
                    type: type
                }) 
            };
                
            const response = await fetchWithDefaults('/updateReminder', requestOptions);
           
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
       <>
            {warning && 
                <Warning title={"Delete Reminder"} cancel={cancel} confirm={confirm} message={"Are you sure you want to remove this reminder?"} buttons={[{name:'Don\'t Delete',action:'cancel'},{name:"Confirm Delete", action:'confirm'}]} />
            }
            
            
            <div className={`frontPageQuilityParentBlockWrapper twoBlock ${addFlow ? 'closeMainBlock' : editingTemplate ? 'closeMainBlock' : ''}`} style={{flex: '1'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Reminder</div>

                </div>
                <div className="quilityParentBlockContent" style={{height: '600px', overflow: 'auto'}}>
                    {newTemplate == null ? '' : (
                        <div className={`quilityNestedBlockWrapper  ${editingItemId === newTemplate.id ? 'focusBigBlock' : ''}`} key={'temp'} style={{marginTop: '25px'}}>
                            <div className="removeItem" onClick={()=> removeItem(newTemplate.id, 'temp')}>X</div>
                            <div className="leadCardSubTitle">
                                {newTemplate.name}
                            </div>
                            <div className="infoWrapper">
                                <div className="systemInfoItem">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Reminder Name: </span>
                                    {newTemplate.name}
                                </div>
                                <div className="systemInfoItem">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Date Created: </span>
                                    Just Now
                                </div>
                            </div>
                        </div>
                    )}
                    {reminders.map((item, index) => (    
                        <div className={`quilityNestedBlockWrapper ${editingItemId === item.id ? 'focusBigBlock' : ''}`} key={`reminders${index}`} style={{marginTop: '25px'}}>
                            <div className="removeItem" onClick={()=> removeItem(item.id, index)}>X</div>
                            <div className="quilityNestedBlockHeader">
                                <div className="quilityParentBlockTitle">
                                    {editedText[index] ?? item.name}
                                </div>
                            </div>
                            <div className="quilityNestedBlockContent">
                                <div className="quilityDataLine">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Reminder Name: </span>
                                    {editedIndex === index ? (
                                        <input
                                            type="text"
                                            className="standardInputBox"
                                            value={editedText[index] || item.name}
                                            onChange={(e) => handleInputChange(e, index)}
                                            onBlur={() => setStatusOption('name',editedText[index], item.id)}
                                            
                                            autoFocus
                                        />
                                        ) : (
                                        <>
                                            <span style={{ marginLeft: "5px" }} onClick={() =>
                                                handleEditName(index, editedText[index])
                                            }>
                                            {editedText[index] || item.name}
                                            </span>
                                            <span
                                            className="hoverIcon"
                                            onClick={() =>
                                                handleEditName(index, editedText[index])
                                            }
                                            >
                                            {/*pencil*/}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className="quilityDataLine">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Total Actions: </span>
                                    {item.length}
                                </div>
                                <div className="quilityDataLine">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Total Days: </span>
                                    {calculateTime(item.order)}
                                </div> 
                                <div className="quilityDataLine">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Date Created: </span>
                                    {item.created==null ? " Uknown" : formatDate(item.created)}
                                </div>
                            
                            </div>
                        </div>
                    ))}
                </div>
                <div className="blockFooter">
                    <div
                        className="buttonWrapper"
                        style={{ marginTop: " 5px", marginLeft: "10px" }}
                    >
                        <div className="dataButtonBlock">Reminders: {reminders.length}</div>
                        <div className="actionButtonBlock">
                            <div className="accentButton solidQuilityActiveButton">
                            +  New Reminder
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </>

    );
}

export default Reminder;

