import React, {useState} from 'react';
import mail from '../../assets/icons/mail.svg';
import close from '../../assets/icons/x-close.svg';

function MigrationIneligible({setCloseModal}) { 

    return (
        <div className="ineligibilityScreen">
            <div className="landingModal">
                <div className="closeModalIconWrapper" onClick={()=>setCloseModal(true)}><img src={close} style={{color:'black'}} alt="mail logo" /></div>
                <div className="descriptionModalIcon"><img src={mail} style={{color:'black'}} alt="mail logo" /></div>
                <div className="modalMainText">You're on the Funnel waiting list and will be eligible to migrate soon.</div>
                <div className="modalSmallText">Please wait for email confirmation that it's your turn.</div>
            </div>
        </div>
    );
}

export default MigrationIneligible;
