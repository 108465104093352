import React, { useState, useEffect, useRef } from "react";
import '../../css/email.css';
import { Editor } from '@tinymce/tinymce-react';
import {sendEmail} from '../../utilities/emailFunctions';
import { useSelector } from 'react-redux';


function Email({ email, setIsEmailOpen }) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const editorRef = useRef(null);
    const [editedContent, setEditedContent] = useState('');
    const [subject, setSubject] = useState('');
    const [recipient, setRecipient] = useState('');
    const [value, setValue] = useState(editedContent ?? '');
    useEffect(() => {
        setRecipient(email);
        setValue(editedContent ?? '')
    }, []);
    
    const handleSubject=(subject)=>{
        setSubject(subject)
    }
    const handleRecipient=(recipient)=>{
        setRecipient(recipient);
    }
   
    return (
        <div style={{color:'black',height: 'calc(100% - 95px)',overflow: 'auto'}}>
            <div className="inputFieldWrapper">
                <div class="qualifierFieldTitle emailEnhance">To: </div>
                <input type="text" className="qualifierFieldInput" style={{ width: '50%',maxWidth: 'unset'}} value={recipient} onChange={(e)=>handleRecipient(e.target.value)}/>
            </div>
            <div className="inputFieldWrapper">
                <div class="qualifierFieldTitle emailEnhance">Subject: </div>
                <input type="text" className="qualifierFieldInput" style={{ width: '50%',maxWidth: 'unset'}} value={subject} onChange={(e)=>handleSubject(e.target.value)}/>
            </div>
            <div className="inputFieldWrapper" style={{height: '65%'}}>
                <div class="qualifierFieldTitle emailEnhance">Content: </div>
                <Editor
                    apiKey='4zy762hh33o9pxugauf7noqs1ga21gsxcoghnt3t0t5stfsm'
                    ref={editorRef}
                    init={{
                        height: '100%',
                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: 'Author name',
                        mergetags_list: [
                            { value: 'First.Name', title: 'First Name' },
                            { value: 'Email', title: 'Email' },
                        ],
                    }}
                    initialValue={ editedContent }
                    value={value}
                    onEditorChange={(newValue, editor) => setValue(newValue)}
                />
                <div className="buttonWrapper" style={{margin:"20px 0px"}}>
                    <div className="accentButton solidQuilityActiveButton" onClick={()=>sendEmail(recipient, subject, value, agentInfo.agentId, agentInfo.firstName, agentInfo.lastName, setIsEmailOpen)}>Send Email</div>
                </div>
            </div>
        </div>
    );
}

export default Email;

