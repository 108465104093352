import React, {useState,useEffect} from 'react';
import '../../css/consent.css';
import { gatherRecordings } from '../../utilities/recordingFunctions';
import { useSelector } from 'react-redux';
import RecordingBlock from './recordingBlock';
import {deleteRecording} from '../../utilities/recordingFunctions';

function RecordedCalls({data}) {
    const [recordings, setRecordings] = useState(false);
    const agentInfo=useSelector((state) => state.agent.userData);
    useEffect(()=>{
        gatherRecordings(agentInfo.agentId,setRecordings);
	},[])
    const handleDeleteRecording= async (sid)=>{
        const recordingDeleted=await deleteRecording(agentInfo.agentId,sid);
        if(recordingDeleted){
            setRecordings(prevState => {
                return prevState.filter(recording => recording.recordingSid !== sid);
            });
        }
    }
    return (
        recordings ? (
            recordings.map((recording, index) => (
                <RecordingBlock handleDeleteRecording={handleDeleteRecording} recording={recording} />
            ))
        ) : (
            <div className="title">You have no recent recordings</div>
        )
    );
}

export default RecordedCalls;