import React, { useState, useEffect } from 'react';
import '../../css/queue.css';
import LeadsQueue from '../leads/leadsQueue.js';
import areaCodes from '../areaCodes';
import DialModule from './dialModule.js';
import { useSelector, useDispatch } from 'react-redux';
import { setQueue, addCurrentLead } from '../../features/queueSlice';
import Icon from '../../assets/icons/icon.js';
import { setStartSession } from '../../features/callSlice.js';
import { runVM } from '../../utilities/virtualMentor.js';
import LeadCard from '../leads/leadCard.js';


function QueueModule({jumpIntoQueue,maxList, setCallerId, setLoading, newAppt, setNewAppt, setLeadCard, expandSession, textingNumbers,handleUpdateQueueLead, openQueue, holdingQueue, expanded, open, setMyQueue, workParams, device, formatDate}) {
    const dispatch = useDispatch();
    const [updateQueuePos, setUpdateQueuePos] = useState(false);
    const [permission, setPermission] = useState(false);
    const [term, setTerm] = useState('15');
    const [results, setResults] = useState(false);
    const [profile, setProfile] = useState(false);
    const [faceAmount, setFaceAmount] = useState('100000');
    const queue=useSelector((state) => state.queue.queue);
    const resetQueuePositions = () => {
        const updatedData = queue.map((item, index) => {
            return {
                ...item,
                queuePos: index,
            };
        });
        setCurrentLead(updatedData[0]);
        setCurrentScript(updatedData[0].script);
        setCurrentLeadId(updatedData[0].lead_id);
        setUpdateQueuePos(true);
        dispatch(setQueue(updatedData));
    };
    const agentInfo=useSelector((state) => state.agent.userData);
    const numberInfo=useSelector((state) => state.agent.phoneNumbers);
    const [selected, setSelected] = useState(1);
    const [smartQueue, setSmartQueue] = useState('smart');
    const [dialAmount, setDialAmount]=useState(false);
    const [currentScript, setCurrentScript]=useState(null);
    const [amountSelector, setAmountSelector]=useState(false);
    const [currentLead, setCurrentLead]=useState([]);
    const [currentLeadId, setCurrentLeadId]=useState(false);
    const [dialGroupDone, setDialGroupDone]=useState(false);

    const [callAccepted, setCallAccepted]=useState(false);
    const [newObjection, setNewObjection]=useState(false);
    
    const agentId = agentInfo.agentId;
    const teamId = agentInfo.teamId;
    const [call, setCall] = useState(null);
  
    const defaultNumber = numberInfo[0] ? numberInfo[0].number : null;
    const [selectedNumber, setSelectedNumber] = useState(defaultNumber);
    const [callState, setCallState] = useState('Disconnected');

     
    

        
    function searchAreaCode(areaCode) {
        const code = areaCode.toString().substring(0, 3);
        const foundState = areaCodes.find(state => {
        return Object.values(state).flat().includes(parseInt(code));
        });

        // If the area code is found, return the array of area codes
        // Otherwise, return an empty array
        return foundState ? Object.values(foundState)[0] : [];
    }
    function getFirstNonNullValue(...values) {
        for (const value of values) {
            if (value !== null && value !== undefined) {
                return value;
            }
        }
        return null; // Return null if all values are null or undefined
    }
    useEffect(() => {
        if(currentLead ){
            //gatherConversation();
            if(currentLead.call_from !== null && currentLead.call_from !==undefined){
                setSelectedNumber(currentLead.call_from);
            }
            else{
                if(currentLead.borrower_cell && numberInfo){
                    const clientNumberToSearch=getFirstNonNullValue(currentLead.borrower_cell,currentLead.borrower_home,currentLead.borrower_work);
                    if(clientNumberToSearch !== null){
                        const callingState=searchAreaCode(clientNumberToSearch)
                        let callFrom;
                        for (let i = 0; i < numberInfo.length; i++) {
                            const number = numberInfo[i].number;
                            const areaCode = parseInt(number.substring(2, 5));
                            if (callingState && callingState.includes(areaCode)) {
                                callFrom = number;
                                setSelectedNumber(callFrom);
                                break; 
                            }
                        }
                        
                    }
                }
            }
        }
  
    }, [currentLeadId]);
    const isStateEmpty = (state) => {
        return !Object.values(state).some(value => value !== null);
    };
    useEffect(() => {
        if(!isStateEmpty(profile)){
           // runVM(profile, setResults, currentLead.lead_id, dispatch);
        }
    }, [profile]);
    /*const incrementQueue=(currentIndex)=>{
        if (currentIndex >= 0 && currentIndex < queue.length - 1) {
            // Get the next lead based on the currentIndex
            const nextLead = queue[currentIndex + 1];
         
            const updatedQueue = queue.map(item => {
                if (item.lead_id === currentLead.lead_id) {
                    return { ...item, called: true };
                }
                return item;
            });
            setHoldingQueue(updatedQueue);
            dispatch(setQueue(updatedQueue));
            setCurrentLead(nextLead);
            alert(nextLead['lead_id']);
            setCurrentLeadId(nextLead['lead_id']);
        } else {
            dispatch(setQueue(false));
            jumpIntoQueue();
            setCalledQueue([]);
        }
    }*/
    
    
    const openDialAmount=()=>{
        setAmountSelector(!amountSelector);
    }
    
    const closeObjection=()=>{
        setNewObjection(false);
    }

    useEffect(() => {
        if(queue){
            dispatch(addCurrentLead(queue[0]));
            setCurrentLead(queue[0]);
            //setCurrentScript(queue[0].script);
            setCurrentLeadId(queue[0].lead_id);
        }
    },[queue])
    
    useEffect(() => {
        if(expanded){
            resetQueuePositions();
        }
    },[expanded])
    let outgoingCall='';
    const makeDial= async (toNumber, leadName, fromNum)=>{
        let dialFrom;
        if(fromNum){
            dialFrom=fromNum;
        }
        else{
            dialFrom=selectedNumber;
        }
        outgoingCall= await device.connect({ params: { To: '+1'+toNumber , callerId: dialFrom, user:agentId, tone: "default", leadId:currentLeadId }});
        setCall(outgoingCall);

    }
    const dialPackage=[{makeDial:makeDial,setDialGroupDone:setDialGroupDone, incrementQueue:'incrementQueue', myQueue: queue, currentLead:currentLead, dialAmount:dialAmount, device:device}];
    const handlCallAccepted=(type)=>{
        setCallAccepted(type);
    }

    const parseWeight = (input) => {
        // Use a regular expression to extract the numeric part
        const parsedWeight = parseInt(input.match(/\d+/), 10);
        return parsedWeight;
    };

    useEffect(() => {
        if(currentLead){
            setProfile({
                borrower_age:currentLead ? currentLead.borrower_age:null,
                state:currentLead ? currentLead.state : null,
                feet: currentLead ? currentLead.feet : null,
                inches: currentLead ? currentLead.inches : null,
                weight: currentLead && currentLead.weight ? parseWeight(currentLead.weight) : null,
                faceAmount: faceAmount,
                tobacco: currentLead ? currentLead.tobacco : 'no',
                term: term,
                productOption:7,
                qualifiers:[]
            })
        }
    }, [currentLead.lead_id]);
   

    const stopSession=()=>{
        openQueue();
        dispatch(setStartSession(false));
        jumpIntoQueue();
    }
    if(queue && updateQueuePos){
        return (
            <div className={` ${open ? 'queueModuleWrapper' : null}`}>         
                {open ? (
                    <div className={`queueWrapper ${expanded ? 'fullScreen' : ''}`}>
                        <div className="innerQueueWrapper">
                            <div className="actualQueue">
                                <div className="queueTabs">
                                    <div className={`queueTitle quilityActive`}>
                                        <div className="titleInner">My Queue</div>
                                    </div>
                                </div>
                                <DialModule callState={callState} setSelectedNumber={setSelectedNumber} expanded={expanded} dialAmount={dialAmount} setMyQueue={setMyQueue} amountSelector={amountSelector} device={device} selectedNumber={selectedNumber} call={call} currentLead={currentLead}  setDialAmount={setDialAmount} openDialAmount={openDialAmount} handlCallAccepted={handlCallAccepted} expandSession={expandSession} />
                                
                                {smartQueue == 'smart' ? (
                                    <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>
                                ) : (
                                    <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>
                                )}
                            </div>
                            {expanded ? (
                                <div className="centerQueue">
                                    <div className="quilityCloseWindow oppositeColor" onClick={()=>stopSession()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                                    <LeadCard theCall={call} setDialAmount={setDialAmount} jumpIntoQueue={jumpIntoQueue} dialPackage={dialPackage} from='session' permission={permission} setLoading={setLoading} textingNumbers={textingNumbers} newAppt={newAppt} setNewAppt={setNewAppt} data={[currentLead]} maxList={maxList} setCallerId={setCallerId} setCall={setCall} device={device} />
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                   
                    null
                )}
            
                {newObjection &&(
                    <div className="objectionViewWrapper">
                        <div className="objectionInnerWrapper">
                            <div className="objectionTitle">{newObjection.name}</div>
                            <div className="quilityCloseWindow" style={{top: '5px'}}onClick={()=>closeObjection()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                            <div className="objectionContent" dangerouslySetInnerHTML={{ __html: newObjection.content }}></div>
                        </div>
                    </div>
                )}
                
            </div>
        );
    }
}

export default QueueModule;
