import React, {useState, useEffect } from 'react';
import '../../css/leadCard.css';
import { useSelector } from 'react-redux';
import { getLeadRecording, deleteRecording } from '../../utilities/recordingFunctions';
import RecordingBlock from '../recording/recordingBlock';


function LeadCallRecordings({lead}) {
    const [recordings, setRecordings] = useState(false);
    const agentInfo=useSelector((state) => state.agent.userData);

    useEffect(() => {
        getLeadRecording(lead, setRecordings);
    }, []);
    const handleDeleteRecording=async(recordingId)=>{
        const recordingDeleted=await deleteRecording(agentInfo.agentId,recordingId);
        if(recordingDeleted){
            setRecordings(prevState => {
                return prevState.filter(recording => recording.recordingSid !== recordingId);
            });
        }
    }

    return (
        <div className="recordingWrapper">
            {recordings && (
                recordings.map((recording, index) => (
                    <RecordingBlock recording={recording} handleDeleteRecording={handleDeleteRecording} from='leadCard'/>
                ))
            )}
        </div>
    );
}

export default LeadCallRecordings;
