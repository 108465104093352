import { fetchWithDefaults } from '../config/fetchDefault';

const sendEmail = async (recipient, subject, value, agentId, first, last, setIsEmailOpen) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent:agentId,
                first:first,
                last:last,
                to:recipient,
                body:value,
                subject:subject,
            }) 
        };
      
        const response = await fetchWithDefaults('/google/gmail/send', requestOptions);
        
        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        setIsEmailOpen(false);
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
};
export {sendEmail};