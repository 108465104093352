import React, {useState, useEffect} from 'react';
import '../../css/callLog.css';
import { gatherCallInfo } from '../../utilities/phoneRelatedFunctions';
import { formatPhoneNumber, unixTommddhia } from '../../utilities/dashFunction';
import {setOpenCalls} from '../../features/topBarSlice';
import { useSelector, useDispatch } from 'react-redux';
import image from '../../assets/icons/loading-loader.gif';
import Icon from '../../assets/icons/icon';



function MissedCalls({openLead}) {
    const dispatch = useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const missedCallState=useSelector((state) => state.topBar.calls);
    const missedCall=useSelector((state) => state.missedCall.missedCall);
    const [callInfo, setCallInfo] = useState(false);
    const [mode, setMode] = useState('missed');
    const [callsLoading, setCallsLoading] = useState(false);
    useEffect( () => {
        if(missedCallState){
            gatherCallInfo(agentInfo.agentId, mode, setCallInfo, setCallsLoading);
        }
    },[missedCallState,mode])
    

    const handleClose=()=>{
        dispatch(setOpenCalls(false));
    }
    return (
        <>
            <div className={`leaderBoardWrapper ${missedCallState ? 'openedLeaderBoard': ''}`}>
                {callsLoading && (
                    <div className="smallLoadingWrapper">
                        <div className="innerSmallLoadingWrapper">
                            <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                        </div>
                    </div>
                )} 
                <div className="quilityCloseWindow" onClick={()=>handleClose()} style={{zIndex: '2'}}><span className="fontello"><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div> 
                <div className="leaderBoardHeader">
                    <div className="leaderBoardTitle">Call Log</div>
                    
                </div>
                <div className="convoEntireWrapper">
                    <div className={`conversationLeft`}>
                        <div className="convoListInnerWrapper">
                            <div className="buttonWrapper" style={{textAlign:'center'}}>
                                <div className={`leaderType accentButton ${mode==='missed' ? 'activeButton' : null}`} style={{width:'100px'}} onClick={()=>setMode('missed')}>Missed Calls</div>
                                <div className={`leaderType accentButton ${mode==='all' ? 'activeButton' : null}`} style={{width:'100px'}} onClick={()=>setMode('all')}>All Calls</div>
                            </div>
                            <div className="callInfoBody">
                                {callInfo && callInfo.map((callItem, index) => (
                                    <div className="callItemWrapper" onClick={()=>openLead(callItem.lead_id)}>
                                        <div class="callLogBlock">
                                            {callItem.borrower_first ? (
                                                <span class="quilityDataLineTitle">{callItem.borrower_first} {callItem.borrower_last}</span>
                                            ) : (
                                                <span class="quilityDataLineTitle">{callItem.call_with}</span>
                                            )}
                                            <div className="subLineWrapper">
                                                {callItem.status==='missed-call' ? (
                                                    <span className={`directionIndication missed`}><span className="fontello"><Icon name={'q-phone-call-missed'} size={18} className="callLogIcon" adjust="fontello" /></span></span>
                                                ) : (
                                                    <span className={`directionIndication ${callItem.direction}`}><span className="fontello"><Icon name={'q-phone-call-outgoing'} size={18} className="outgoingIcon" adjust="fontello" /></span></span>
                                                )}
                                                
                                                <span class="callWithItem">{formatPhoneNumber(callItem.number_used)}</span> - 
                                                <span class="callWithItem">{unixTommddhia(callItem.date)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MissedCalls;
