import React, {useState, useEffect} from 'react';
import '../../../css/scheduledCalls.css';
import MarketBlock from './marketBlock';
import { fetchWithDefaults } from '../../../config/fetchDefault';

function LeadsMarket({ data, handleNavigation, setPathData}) {
    const [newData, setData] = useState(false);
    const [warning, setWarning] = useState(false);

    const gatherData= async()=>{
        try {
            const response = await fetchWithDefaults(`/leadMarketData`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            // add LightHouse Lead to top of the type and vendor arrays
            if(jsonData[0].vendors[0] !== undefined && jsonData[0].vendors[0] !== null){
                jsonData[0].vendors[0].unshift({"name":'Lighthouse Leads'})
            }

            if(jsonData[0].types[0] !== undefined && jsonData[0].types[0] !== null){
                jsonData[0].types[0].unshift({"name":'Lighthouse Leads'})
            }

            setData(jsonData);
            setPathData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
     }
    useEffect(() => {
        gatherData();
    },[])

    return (

        <>
        {newData ? (
            <>
                <MarketBlock adjustclassName={'twoX'} handleNavigation={handleNavigation} title='Lead Types' data={newData[0].types}/>
                <MarketBlock adjustclassName={'twoX'} handleNavigation={handleNavigation} title='Vendors' data={newData[0].vendors}/>
            </>
        ) : (
            null
        )}

        </>
    );
}

export default LeadsMarket;
