import { fetchWithDefaults } from '../config/fetchDefault';

const calculateTime = (inputString) => {
    const items = inputString.split(',');

    let totalSeconds = 0;

    items.forEach(item => {
        if (item.startsWith('wa_')) {
        const floatValue = parseFloat(item.split('wa_')[1]);
        if (!isNaN(floatValue)) {
            const secondsToAdd = Math.round(floatValue * 86400);
            totalSeconds += secondsToAdd;
        }
        }
    });

    let totalDays = totalSeconds / 86400;

    // Round up to the nearest integer if less than 1, otherwise round to the whole integer
    totalDays = totalDays < 1 ? 1 : Math.ceil(totalDays);
    return totalDays;
};
const updateWorkflow = async (flow, exitConditions, agentId, teamId, flowId) => {
    try {
        const requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({ 
                flow: flow,
                exit: exitConditions,
                agent: agentId,
                team: teamId,
                flowId: flowId,
            }) 
        };
      
        const response = await fetchWithDefaults('/workflow/updateWorkflow', requestOptions);
        
        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        if(jsonData.status){
            return jsonData;
        }
        else{
            return false;
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
};
const updateWorkflowName = async (flowId, name) => {
    try {

        const requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({ 
                flowId: flowId,
                name: name
            }) 
        };

        const response = await fetchWithDefaults('/updateWorkflowName', requestOptions);
    
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
    } 
    catch (error) {
        console.error("Error fetching data:", error);
    }
};
const updateWorkflowAttachedStatus = async (flowId, status) => {
    try {

        const requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({ 
                flowId: flowId,
                status: status
            }) 
        };

        const response = await fetchWithDefaults('/updateWorkflowStatus', requestOptions);
    
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
    } 
    catch (error) {
        console.error("Error fetching data:", error);
    }
};
export {calculateTime, updateWorkflow, updateWorkflowName, updateWorkflowAttachedStatus};