import React, { useState } from "react";
import '../../css/templates.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';

function AddStatus({ addATemplate, type, agentId, teamId, setNewTemplate }) {
    const workflows=useSelector((state) => state.workflows.workflows);
    const reminders=useSelector((state) => state.reminders.reminders);
    const [templateName, setTemplateName] = useState('');
    const [statusWorkflow, setStatusWorkflow] = useState('');
    const [statusReminder, setStatusReminder] = useState('');
    const [statusType, setStatusType] = useState('');
    const [statusContact, setStatusContact] = useState('');
    const [statusAppointment, setStatusAppointment] = useState('');
    const [statusApplication, setStatusApplication] = useState('');
    const [statusTypeError, setStatusTypeError] = useState(false);
    const [statusContactError, setStatusContactError] = useState(false);
    const [statusAppointmentError, setStatusAppointmentError] = useState(false);
    const [statusApplicationError, setStatusApplicationError] = useState(false);
    const [statusNameError, setStatusNameError] = useState(null);
  

    const setAddTemplateName= async (content)=>{
        setTemplateName(content);
        setStatusNameError(false);
    }
    const setUpStatusWorkflow= async (content)=>{
        setStatusWorkflow(content);
    }
    const setUpStatusReminder= async (content)=>{
        setStatusReminder(content);
    }
    const setUpStatusType= async (content)=>{
        setStatusType(content);
        setStatusTypeError(false);
    }
    const setUpStatusContact= async (content)=>{
        setStatusContact(content);
        setStatusContactError(false);
    }
    const setUpStatusAppointment= async (content)=>{
        setStatusAppointment(content);
        setStatusAppointmentError(false);
    }
    const setUpStatusApplication= async (content)=>{
        setStatusApplication(content);
        setStatusApplicationError(false);
    }
    
   
    const addNewTemplate= async (id)=>{
        let error=false;
        if(statusAppointment== '' || statusAppointment== 'none' ){
            setStatusAppointmentError("You need to choose and option");
            error=true;
        }
        if(statusApplication== '' || statusApplication== 'none' ){
            setStatusApplicationError("You need to choose and option");
            error=true;
        }
        if(statusContact== '' || statusContact== 'none' ){
            setStatusContactError("You need to choose and option");
            error=true;
        }
        if(statusType=='' || statusType=='none'){
            setStatusTypeError("You need to choose and option");
            error=true;
        }
        if(templateName=='' || templateName==null){
            setStatusNameError("You need to give the status a name");
            error=true;
        }
        if(error==false){
            try {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ 
                        agent:agentId,
                        name: templateName,
                        appointment: statusAppointment,
                        application: statusApplication,
                        contact: statusContact,
                        team: teamId,
                        type:statusType,
                        workflow:statusWorkflow,
                        reminder:statusReminder
                    }) 
                };
              
                const response = await fetchWithDefaults(`/addNew${type}`, requestOptions);
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                setNewTemplate({id:jsonData.id, name:templateName, type:statusType, contact: statusContact, appointment: statusAppointment, workflow:statusWorkflow, reminder:statusReminder})
                addATemplate();
            } 
            catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }
    return (
        <div className="mainBlockWrapper focusMainBlock">
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Add a New {type}</div>
            </div>
            <span className="closeWindow" onClick={()=>addATemplate()}>X</span>
            <div className="mainBlockContent roundedBottom">
                <div className="bigBlock focusBigBlock" style={{marginTop: '25px'}}>
                    <div className="leadCardSubTitle"></div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Name The {type}</div>
                        {statusNameError != (null || '')  ? <div className="errorMessage">{statusNameError}</div> : null}
                        <input 
                            className="standardInputBox largeInput" 
                            type="text"
                            value={templateName}
                            onChange={(e) => setAddTemplateName(e.target.value)}
                        />
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Would you like to attach a workflow to the status?<span className="hoverInfo">i<span className="hoverContent">You can attach a workflow to a status. When you status a lead with a workflow attached, that lead will automatically be put into the workflow.</span></span></div>
                        <select 
                            className="standardSelectBox" 
                            value={statusWorkflow}
                            onChange={(e) => setUpStatusWorkflow(e.target.value)}
                        >
                            <option value="none">Select A Workflow (Optional)</option>
                            {workflows.map((item, index) => (
                                <option key={`statusInfo${index}`} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Would you like to attach a reminder to the status?<span className="hoverInfo">i<span className="hoverContent">You can attach a reminder to a status. When you status a lead with a reminder attached, that lead will automatically be put into the reminder workflow, but *only* if the lead has an appointment set.</span></span></div>
                        <select 
                            className="standardSelectBox" 
                            value={statusReminder}
                            onChange={(e) => setUpStatusReminder(e.target.value)}
                        >
                            <option value="none">Select A Reminder (Optional)</option>
                            {reminders.map((item, index) => (
                                <option key={`reminderInfo${index}`} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">This status will make which type of lead?<span className="hoverInfo">i<span className="hoverContent">Which 'bucket' will this lead fall in. New Leads - typically uncalled, unworked leads. Active Leads - the opportunity is still there and these leads have typically been worked. Dead Leads - the opportunity is over for these ones. Client - these leads have been sold a policy (good job!).</span></span></div>
                        {statusTypeError != (false || '')  ? <div className="errorMessage">{statusTypeError}</div> : null}
                        <select 
                            className="standardSelectBox" 
                            value={statusType}
                            onChange={(e) => setUpStatusType(e.target.value)}
                        >
                            <option value="none">Select a Type (Required)</option>
                            <option value="active">Active</option>
                            <option value="new">New</option>
                            <option value="client">Client</option>
                            <option value="dead">Dead</option>
                        </select>
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Select whether this status would count as you contacting the lead <span className="hoverInfo">i<span className="hoverContent">This helps count your contacts in the system. It's the most accurate metric!</span></span></div>
                        {statusContactError != (false || '')  ? <div className="errorMessage">{statusContactError}</div> : null}
                        <select 
                            className="standardSelectBox" 
                            value={statusContact}
                            onChange={(e) => setUpStatusContact(e.target.value)}
                        >
                            <option value="none">Select Contact Type (Required)</option>
                            <option value="1">Contact</option>
                            <option value="0">No Contact</option>
                        </select>
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Select whether this status will count as an appointment <span className="hoverInfo">i<span className="hoverContent">This helps count your appointments in the system. It's the most accurate metric!</span></span></div>
                        {statusAppointmentError != (false || '')  ? <div className="errorMessage">{statusAppointmentError}</div> : null}
                        <select 
                            className="standardSelectBox" 
                            value={statusAppointment}
                            onChange={(e) => setUpStatusAppointment(e.target.value)}
                        >
                            <option value="none">Select Appointment Type (Required)</option>
                            <option value="1">Appointment</option>
                            <option value="0">No Appointment</option>
                        </select>
                    </div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Select whether this status will count as an application <span className="hoverInfo">i<span className="hoverContent">This helps count your applications in the system. It's the most accurate metric!</span></span></div>
                        {statusApplicationError != (false || '')  ? <div className="errorMessage">{statusApplicationError}</div> : null}
                        <select 
                            className="standardSelectBox" 
                            value={statusApplication}
                            onChange={(e) => setUpStatusApplication(e.target.value)}
                        >
                            <option value="none">Select Application Type (Required)</option>
                            <option value="1">Application</option>
                            <option value="0">No Application</option>
                        </select>
                    </div>
                </div>
                <div className="buttonWrapper"><div className="accentButton" style={{margin:'auto'}} onClick={()=>addNewTemplate()}>Save Status</div></div>
            </div>
        </div> 
    );
}

export default AddStatus;

