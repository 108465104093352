import { fetchWithDefaults } from '../config/fetchDefault';

const getPaymentMethods= async (agent)=>{
    try {
        const response = await fetchWithDefaults(`/subscription/paymentInfo?agent=${agent}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const subscribeToProduct= async (product, agent, method, customer)=>{

    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent: agent,
                product:product,
                method:method,
                customer:customer
            }) 
        };
      
        const response = await fetchWithDefaults(`/subscription/subscribe`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.result){
            return jsonData.result
        }
        else{
            return false
        }
        //return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export { 
    getPaymentMethods,
    subscribeToProduct
};