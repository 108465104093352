import { fetchWithDefaults } from '../config/fetchDefault';
const removeQualifierById = (idToRemove,setProfile) => {
    setProfile((prevState) => {
      const updatedQualifiers = prevState.qualifiers.filter(
        (qualifier) => qualifier.id !== idToRemove
      );
  
      return {
        ...prevState,
        qualifiers: updatedQualifiers,
      };
    });
};
const removeFormCondition= async (medical,condition,indexToRemove,setMedicalForm,setProfile)=>{
    removeQualifierById(medical.vm_id,setProfile)
    setMedicalForm(prevMedicalForm => prevMedicalForm.filter((_, index) => index !== indexToRemove));
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({ 
                condition:condition,
            }) 
        };
      
        const response = await fetchWithDefaults('/medicalForm/deleteCondition', requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const fetchMedForm= async (setMedicalForm,setProfile,leadId)=>{
    try {
        const response = await fetchWithDefaults(`/medicalForm/fetchMedForm?lead=${leadId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.status !== false){
            const extractValues = (dataArray) => {
                return dataArray
                .filter(item => item.vm_id !== null)  // Filter out items with null vm_id
                .map(item => ({
                    parent_id: item.parent_id,
                    id: item.vm_id,
                    answer: item.answer
                }));
            };
            const extractedValues = extractValues(jsonData);

            setProfile(prevProfile => ({
                ...prevProfile,
                qualifiers: extractedValues
            }));
            
            setMedicalForm(jsonData);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export {
    removeFormCondition,
    removeQualifierById,
    fetchMedForm
};
