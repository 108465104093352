import React, { useState, useEffect} from 'react';

function ActionAlert({newMessages}) {

    const [showMessage, setShowMessage] = useState(false);
    useEffect( () => {
        if(newMessages.length > 0){
            setShowMessage(true);
            const timeoutId = setTimeout(() => {
                setShowMessage(false);
            }, 3000);
    
            // Clear the timeout when the component unmounts or when newMessages changes
            return () => {
                clearTimeout(timeoutId);
            };
        }
    },[newMessages])
    let name;
    if(newMessages.length > 0){
        if(newMessages[0].borrower_first){
            name=newMessages[0].borrower_first+' '+newMessages[0].borrower_last;
        }
        else{
            name=newMessages[0].from;
        }
    }
    return (
        newMessages.length > 0 && (
        <div className={`newAlertWrapper ${showMessage ? 'displayMessageAlert':''}`}>
            <div className="newAlertHeader">
                <div className="newAlertTitle">New Text Message</div>
            </div>
            <div className="newAlertContent">
                <div className="newAlertLine">
                    <span className="newAlertLineTitle">From:</span><span className="lineText">{name}</span>
                </div>
                <div className="newAlertLine">
                    <span className="newAlertLineTitle">Message:</span><span className="lineText">{newMessages[0].message}</span>
                </div>
            </div>
        </div>
        )
    )
}

 

export default ActionAlert;