import React, {useState,useEffect} from 'react';
import '../../css/subscription.css';
import Warning from '../../template/warning';
import Subscription from './subscription';
import Cards from './cards';
import AddCard from './addCard';
import { fetchWithDefaults } from '../../config/fetchDefault';

function AgentSubscription({data, formatDate}) {
    const [cards, setCards]= useState(false);
    const [subscriptions, setSubscriptions]= useState(false);
    const [warning, setWarning] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [cust, setCust] = useState(false);

    const addACard = () => {
        setAddCard(true);
    };
    const addACardDone = () => {
        setAddCard(false);
        getPaymentMethods();
    };
    
    const cancel = () => {
        setWarning(false);
    };
    const confirm = async (id, index) => {
        
    };
    
    const getPaymentMethods= async (number) => {
        try {
            const response = await fetchWithDefaults(`/paymentMethods?cust=${cust}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setCards(jsonData.data);
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
		setCust(data[0].customer);
        setSubscriptions(data[2].subscriptions.data);
        setCards(data[1].cards.data);
	}, []);
    return (
        <>
            {warning && 
                <Warning title={"Send Invite"} cancel={cancel} confirm={confirm} message={"This will send a signup email to "+warning+" for this agent, is this what you want?"} buttons={[{name:'Don\'t Send',action:'cancel'},{name:"Send Invite", action:'confirm'}]} />
            }
            <div className="outerWrapper">
                <span className="outerWrapperTitle">
                    <span className="outerWrapperIcon">

                    </span>
                    Subscription
                </span>
                
                <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around", width: '95%', margin: 'auto' }}
                >
                {addCard ? ( 
                    <>
                    <AddCard addACardDone={addACardDone} cust={cust}/>
                    <Cards setCards={setCards} addACard={addACard} formatDate={formatDate} flex="1" data={cards}/>
                    </>
                ) : (
                    <>
                    <Subscription setSubscriptions={setSubscriptions} formatDate={formatDate} flex="2" data={subscriptions}/>
                    <Cards setCards={setCards} addACard={addACard} formatDate={formatDate} flex="1" data={cards}/>
                    </>
                )}
                </div>
            </div>
        </>
    );
}

export default AgentSubscription;
