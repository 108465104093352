import { fetchWithDefaults } from '../config/fetchDefault';

const gatherCallInfo= async (agent, type, setCallInfo, setCallsLoading)=>{
    try {
        setCallsLoading(true);
        const response = await fetchWithDefaults(`/callLog?agent=${agent}&type=${type}`);
        
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setCallsLoading(false);
        setCallInfo(jsonData.data);
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const getVoicemails= async (agent,setVoicemailLoading, setVoicemails, dispatch)=>{
    try {
        setVoicemailLoading(true);
        const response = await fetchWithDefaults(`/voicemail/get?agent=${agent}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();

        dispatch(setVoicemails(jsonData));
        setVoicemailLoading(false);

    } 
    catch (error) {
        setVoicemailLoading(false);
        console.error("Error fetching data:", error);
        return false;
    }
}
const voicemailHeard = async (id)=>{
    try {
        const requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({ 
                voicemail:id,
            }) 
        };
      
        const response = await fetchWithDefaults('/voicemail/updateHeard', requestOptions);
        
        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        return;
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
    
}
const removeVoicemail = async (voicemailId)=>{
    try {
        const requestOptions = {
            method: 'DELETE'
        };
      
        const response = await fetchWithDefaults(`/voicemail/delete/voiceMessage?voicemailId=${voicemailId}`, requestOptions);
        
        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        console.log(jsonData);
        console.log("!!!");
        return jsonData;
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
    
}

export {
    gatherCallInfo,
    getVoicemails,
    voicemailHeard,
    removeVoicemail
};
