import React from "react";
import '../../../css/frontPage.css';
import Icon from "../../../assets/icons/icon";
import { useDispatch } from "react-redux";
import { deleteFilter } from "../../../utilities/filterFunctions";

function EngagedList({filter, getMyFilter, index, className, from}) {
    const dispatch=useDispatch();
    return (
        from ? (
            <div className="deleteableListWrapper">
                <div className="deleteList"><div className="quilityCloseWindow oulineQuilityDestructiveButton" style={{position:'unset'}} onClick={()=>{deleteFilter(filter.id,dispatch)}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div></div>
                <div className="quilityOtherListWrapper" key={`filterOption${index}`}>
                    <div className="listButtonHolder">
                    <div className="quilityOtherListTitle" style={{flex: '2'}}>{filter.name}</div>
                        <div className="listIcon selectedIcon" onClick={()=>getMyFilter(filter.params,true,filter.name, filter)}><Icon name='q-phone-call-outgoing' className={className} height='34' adjustSize='15'/></div>
                        <div className="listIcon" onClick={()=>getMyFilter(filter.params,false,filter.name, filter)}><Icon name='expand-q' className={className} adjustSize='15' height='34'/></div>
                    </div>
                </div> 
            </div>
        ) : (
            <div className="quilityOtherListWrapper" key={`filterOption${index}`}>
                <div className="listButtonHolder">
                <div className="quilityOtherListTitle" style={{flex: '2'}}>{filter.name}</div>
                    <div className="listIcon selectedIcon" onClick={()=>getMyFilter(filter.params,true,filter.name, filter)}><Icon name='q-phone-call-outgoing' className={className} height='34' adjustSize='15'/></div>
                    <div className="listIcon" onClick={()=>getMyFilter(filter.params,false,filter.name, filter)}><Icon name='expand-q' className={className} adjustSize='15' height='34'/></div>
                </div>
            </div> 
        )
    );
}

export default EngagedList;
