import React from 'react';
import '../../css/lead.css';
import Icon from '../../assets/icons/icon';

function LeadSort({handleSort, sortDirection, sortBy, expanded, queueExpanded}) {
    const blocks=[
        {name:'Name', value:'borrower_last'},
        {name:'Status', value:'lead_status'},
        {name:'State', value:'state'},
        {name:'Contacted', value:'last_contacted'},
        {name:'Assigned', value:'date_uploaded'},
        {name:'Call Amount', value:'amount_contacted'}
    ];
    const blocksShort=[
        {name:'Name', value:'borrower_last'},
        {name:'State', value:'state'},
        {name:'Contacted', value:'last_contacted'},
        {name:'Assigned', value:'date_uploaded'},
        {name:'Calls', value:'amount_contacted'}
    ];
    const blockQueue=[
        {name:'Name', value:'borrower_last'},
        {name:'Contacted', value:'last_contacted'},
    ];
    const renderSortIcon = (criteria) => {
        if (sortBy === criteria) {
          return sortDirection === 'asc' ? <span className="sortIcon"><span className="fontello" style={{height:'100%'}}><Icon name={'q-sort-b-t'} size={18} height='35' className="" adjust="fontello" /></span></span>: <span className="sortIcon"><span className="fontello" style={{height:'100%'}}><Icon name={'q-sort-b-t'} size={18} height='35' className="" adjust="fontello" /></span></span>;
        }
        return null;
    };
    return (
        <div className="sortWrapper">
            {expanded ? (
                <>
                    {blocks.map((item, index) => (
                        <div className="sortBlock" id={'sortBlock'+item.name} key={'sort'+index} onClick={() => handleSort(item.value)}>
                        <div className="sortBlockTitle">{item.name} {renderSortIcon(item.value)}</div>
                        </div>
                    ))}
                </>
            ) : queueExpanded ? (
                <>
                    {blockQueue.map((item, index) => (
                        <div className="sortBlock" id={'sortBlock'+item.name} key={'sort'+index} onClick={() => handleSort(item.value)}>
                        <div className="sortBlockTitle">{item.name} {renderSortIcon(item.value)}</div>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {blocksShort.map((item, index) => (
                        <div className="sortBlock" id={'sortBlock'+item.name} key={'sort'+index} onClick={() => handleSort(item.value)}>
                        <div className="sortBlockTitle">{item.name} {renderSortIcon(item.value)}</div>
                        </div>
                    ))}
                </>
            )}
            
        </div>
    );
}

export default LeadSort;
