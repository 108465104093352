

import React from "react";
import Icon from "../../../assets/icons/icon";
import { makePayment } from "../../../utilities/marketUtil";



function TrustPayment({setLoading, cards, setCards, cust,setAddCustProfile, agentId, setProfileInfo, profileInfo}) {
    const handleMakePayment= async ()=>{
        setLoading(true);
        const paymentMade=await makePayment(cust,agentId,cards[0].id);
        if(paymentMade.status===true){
            setLoading(false)
            //setProfileInfo()
            const updatePayment = profileInfo.map((item) => ({
                ...item,
                activePayment: 'active', // Update activePayment
            }));
            setProfileInfo(updatePayment);

            setCards(false);
        }
        else{
            alert(paymentMade.message);
        }
    }
    return (
        <div className={`mainBlockWrapper`}>
        
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Pay For A2P10DLC</div>
                <div className="quilityCloseWindow" onClick={() => {setCards(false);setAddCustProfile(false);}} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            </div>
            
            <div className="mainBlockContent">
                <div style={{textAlign:'center'}}>
                    {cards && (
                        <div className="cardTitle" style={{fontSize: '20px',fontWeight: '500'}}>Paying with your {cards[0].card.brand} Ending in {cards[0].card.last4}</div>
                    )}
                    <div className="subTitle">One time payment of $20.00 for A2P10DLC submission</div>
                    <div className="buttonWrapper">
                        <div className="bulkActionFooterButton solidQuilityActiveButton" style={{width: '300px',margin: 'auto'}} onClick={()=>handleMakePayment()}>Submit Payment</div>
                    </div>
                </div>
            </div>
            <div className="blockFooter">
                
            </div>
        </div>
    );
}

export default TrustPayment;