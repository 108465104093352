import { fetchWithDefaults } from '../config/fetchDefault';
import { addTag } from '../features/tagSlice';


const removeTagFromLead= async (tagId, agentId, leadId)=>{
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({ 
                tagId:tagId,
                agent:agentId,
                lead:leadId
            }) 
        };
        const response = await fetchWithDefaults('/removeTagFromLead', requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData)
        if(jsonData.status===false){
            return false;
        }
        else{
            return true;
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const saveTag = async (tag, agentId, leadId, dispatch, add) => {
    
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                tagName: tag.name,
                tagId:tag.id,
                agent:agentId,
                lead:leadId,
                tag: tag
            }) 
        };
        const response = await fetchWithDefaults('/addTagToLead', requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if(jsonData.status===false){
            return false;
        }
        else{
            if(add){
                dispatch(addTag({name:tag.name,id:tag.id}));
            }
            return jsonData.tagId;
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
    }
};
const addTagToLead = async (setSelectedTags, selectedTags, tag) => {
    setSelectedTags([...selectedTags, tag]);
};

export {removeTagFromLead, saveTag, addTagToLead};
