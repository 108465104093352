import React, { useState,useEffect } from 'react';

const DateSelector = ({ onStartDateChange, onEndDateChange }) => {
    const [startDate, setStartDate] = useState(getLastSaturday());
    const [endDate, setEndDate] = useState(getNextFriday(startDate));

    // Function to get the date for the upcoming Saturday
    
    function getLastSaturday() {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const daysSinceLastSaturday = (dayOfWeek + 1) % 7; // Calculate the number of days since last Saturday
        const lastSaturday = new Date(today);
        lastSaturday.setDate(today.getDate() - daysSinceLastSaturday); // Subtract days to go back to the last Saturday
        return lastSaturday;
    }
    // Function to get the date for the following Friday
    function getNextFriday(fromDate) {
        const dayOfWeek = fromDate.getDay();
        const daysUntilFriday = dayOfWeek < 5 ? 5 - dayOfWeek : 5 + (7 - dayOfWeek); // Calculate the number of days until the next Friday
        const nextFriday = new Date(fromDate);
        nextFriday.setDate(fromDate.getDate() + daysUntilFriday); // Add the days until the next Friday
        return nextFriday;
    }
    // Function to handle previous week button click
    function handlePreviousWeek() {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(endDate);
        newStartDate.setDate(newStartDate.getDate() - 7);
        newEndDate.setDate(newEndDate.getDate() - 7);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }

    // Function to handle next week button click
    function handleNextWeek() {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(endDate);
        newStartDate.setDate(newStartDate.getDate() + 7);
        newEndDate.setDate(newEndDate.getDate() + 7);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }

    const isCurrentWeek = endDate >= new Date();
    useEffect(() => {
        if (onStartDateChange && startDate) {
            onStartDateChange(startDate);
        }
        if (onEndDateChange && endDate) {
            onEndDateChange(endDate);
        }
    }, [startDate, endDate, onStartDateChange, onEndDateChange]);

    return (
        <div>
            <div className="dateWrapper">
                <span>{startDate.toDateString()}</span>
                <span> - </span>
                <span>{endDate.toDateString()}</span>
            </div>
            <div className="buttonWrapper" style={{textAlign: 'center'}}>
                <button onClick={handlePreviousWeek} className="leaderType" style={{width:'120px'}}>Previous Week</button>
                { !isCurrentWeek && <button onClick={handleNextWeek} className="leaderType" style={{width:'120px'}}>Next Week</button> }
            </div>
        </div>
    );
};

export default DateSelector;
