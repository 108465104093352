import React, {useState, useEffect} from 'react';
import '../../css/teams.css';
import AgentDetail from './agentDetail';
import BigTeamBlock from './bigTeamBlock';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { gatherAgentTeams,getTeamInvites } from '../../utilities/teamFunctions';
import { useSelector } from 'react-redux';
import AddTeam from './addTeam';
import Icon from '../../assets/icons/icon';


function Teams({ data, isMin, openLead, maxList}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const agentId=agentInfo.agentId;
    const [agencies, setAgencies] = useState((data && data[1] && data[1].agencies) || []);
    const [imoEmail, setImoEmail] = useState('');
    const [teams, setTeams] = useState((data && data[0] && data[0].teams) || []);
    const [imoLink, setImoLink] = useState(false);
    const [myImo, setMyImo] = useState(null);
    const imo = ['Quility'];
    const [smallTeam, setSmallTeam] = useState(false);
    const [addTeam, setAddTeam] = useState(false);
    const [agentName, setAgentName] = useState();
    const [linked, setLinked] = useState(false);
    const [agentDetail, setAgentDetail] = useState([]);
    const [invites, setInvites] = useState([]);
    const [refreshTeams, setRefreshTeams] = useState(false);
    const handleInputChange = (e) => {
        setImoEmail(e.target.value);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
    
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
    
        const formattedDate = `${month}/${day} @ ${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}${hours >= 12 ? 'pm' : 'am'}`;
    
        return formattedDate;
    };

    const details= async (agent, name, range, teamName)=>{
        let leadPermission=false;
        if(teamName==='Base Shop' || teamName==='Direct Agents'){
            leadPermission=true;
        }
        setAgentName(name);
        const currentDate = new Date();
        const dateRange = new Date(currentDate);
        dateRange.setDate(currentDate.getDate() - range);
        const yyyymmdd = dateRange.toISOString().split('T')[0];
    
        // Calculate the date and time 7 days ago
    
        dateRange.setDate(currentDate.getDate() - range);
        dateRange.setHours(0, 0, 0, 0); // Set time to midnight
    
        // Get the Unix timestamp (in seconds) for the calculated date and time
        const unixTimestamp = Math.floor(dateRange.getTime() / 1000);
        try {
            const response = await fetchWithDefaults(`/activity/agentDetail?agent=${agent}&unixTimestamp=${unixTimestamp}&yyyymmdd=${yyyymmdd}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setAgentDetail([{detail:jsonData,permission:leadPermission}]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setSmallTeam(true);
    }
    const findAgent= async ()=>{
        try {
            const response = await fetchWithDefaults(`/team/searchImo?email=${imoEmail}&imo=${myImo}&agent=${agentId}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status){
                setLinked({status:true, message:'Your IMO has been linked! Please Refresh the page to see your team.', color:'#008000'});
                setMyImo(null);
                setImoLink(false);
                setImoEmail('');
            }
            else{
                setLinked({status:false, message:'Your email could not be found in your IMO', color:'#b80f03'});
                setMyImo(null);
                setImoLink(false);
                setImoEmail('');
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const enlarge=()=>{
        setAgentDetail([]);
    }
    const closeLink=()=>{
        setMyImo(null);
        setImoLink(false);
        setImoEmail('');
    }
    const handleAddTeam=()=>{
        setAddTeam(!addTeam);
    }
    const getAgentTeams= async()=>{
        const gatheredTeams=await gatherAgentTeams(agentId);
        setTeams(gatheredTeams)
    }
    useEffect(() => {
		getAgentTeams();
        getTeamInvites(agentId,setInvites);
	}, [refreshTeams]);
    useEffect(() => {
        setAgencies(data[0].agencies);

	}, []);
    return (
        <div className="outerWrapper" style={{width: '90%',marginLeft: '5%',height: 'calc(100% - 200px)'}}>
            <span className="outerWrapperTitle"><span className="outerWrapperIcon">

                <span className={`fontello`} style={{marginTop: '-7px'}}><Icon name={'q-team'} size={26} className={`selectBoxIcon`} adjust="fontello" /></span>
            </span>Teams</span>
            {addTeam && (
                <AddTeam handleAddTeam={handleAddTeam} refreshTeams={refreshTeams} setRefreshTeams={setRefreshTeams}/>
            )}
            <div className="teamsWrapper mainBlockOuterWrapper">
                {agentDetail.length > 0 ? ( 
                    <AgentDetail 
                        enlarge={enlarge} 
                        smallTeam={smallTeam}
                        agentDetail={agentDetail[0].detail}
                        permission={agentDetail[0].permission}
                        formatDate={formatDate}
                        agentName={agentName}
                        openLead={openLead}
                        isMin={isMin}
                        maxList={maxList}
                        from={'agency'}
                    />
                ) : (
                    <>
                        <div className="dashSplit">
                            <BigTeamBlock invites={invites} setInvites={setInvites} details={details} data={agencies} linked={linked} setImoLink={setImoLink} title={`${agentInfo.organizationName} Team`} />
                        </div>
                        <div className="dashSplit">
                            <BigTeamBlock invites={invites} setInvites={setInvites} handleAddTeam={handleAddTeam} details={details} data={teams} title="Your Teams"/>
                        </div>
                    </>
                )}
                
            </div>
            {imoLink && (
                <div className="imoLinkWrapper">
                    <div className="imoLinkContent">
                        <div className="quilityCloseWindow" onClick={()=>closeLink()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                        {myImo===null ? imo.map((imo, index) => (
                            <>
                                <div className="title">Choose Your IMO</div>
                                <div className="imoOptionButton solidQuilityActiveButton" key={`imo${index}`} onClick={()=>setMyImo(imo)}>{imo}</div>
                            </>
                        )) : (
                            <>
                                <div className="title">Find yourself</div>
                                <div className="inputWrapper" style={{textAlign: 'center'}}>
                                    <div className="subTitle">Enter email associated with {myImo}</div>
                                    <input 
                                        className="emailInput qualifierFieldInput"
                                        value={imoEmail}
                                        onChange={handleInputChange}
                                        placeholder="Enter Email"
                                    />
                                    <div className="imoOptionButton solidQuilityActiveButton" onClick={()=>findAgent()}>Find Me</div>
                                </div>
                            </>
                        )} 
                    </div>
                </div>
            )}
        </div>
    );
}

export default Teams;


