import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import '../../css/scripting.css';
import { removeKeys, updateLeadData } from '../../utilities/leadFunctions';
import { handleSelectedProducts } from '../../utilities/virtualMentor';
import ReccomendationBlock from '../reccomendation/reccomendationBlock';
import ExtraInfo from '../reccomendation/extraInfo';
import FdaBlock from '../qualifier/sections/fdaBlock';
import Icon from '../../assets/icons/icon';
//import { triggerCalendar } from '../../features/appointmentSlice';
import { useDispatch } from 'react-redux';


function Scripting({handleSelectedTab, quoteTab, handleProfileUpdate, setFaceAmount, selectedProducts, setLead, possibleConditions, setPossibleConditions, setProfile, profile, lead, handleLeadData, setSelectedProducts}) {
    const dispatch=useDispatch();
    const profileAttr=['borrower_age','feet','inches','weight','borrower_sex','tobacco']
    const keysToRemove = ["ID","Switchboard", "pushed_to_sb", "SB_Contact_ID", "Tags","PushedToOptDate", "IsSubsequentLeadInd", "campaigntype", "ExternalLeadCode", "caller id"];
    const [customInfo, setCustomInfo] = useState(false);
    const [extraInfo, setExtraInfo] = useState(false);
    const [selectedTab, setSelectedTab] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState([]);
    const [settingAppt, setSettingAppt] = useState(0);
    const verify =['address','city','state','zip','mortgage','lender','height','weight','borrower_age'];
    const [selectedCarrier, setSelectedCarrier] = useState('none');
    const containerRef = useRef(null);
    const cqfRef = useRef(null);
    const suitabilityRef = useRef(null);
    const cqfFindingRef = useRef(null);
    const rpRef = useRef(null);
    const thinkRef = useRef(null);
    const saleRef = useRef(null);
    const startRef = useRef(null);
    const critRef = useRef(null);
    const scrollToDiv = (ref) => {
        setSelectedTab(ref.current.textContent)
        if (containerRef.current && ref.current) {
            containerRef.current.scrollTo({
                top: ref.current.offsetTop - 100, // Scroll to the target div's offset, minus 30px
                behavior: 'smooth'
            });
        }
    };
    const handleCriticalQuote=(period)=>{
        if(parseInt(lead[0].payment) > 0){
            if(quoteTab !=='whole'){
                handleSelectedTab('whole')
            }
            setSelectedPeriod((prevSelectedPeriod) => {
                // Check if the value already exists in the array
                if (prevSelectedPeriod.includes(period)) {
                    // If it exists, remove it
                    return prevSelectedPeriod.filter((item) => item !== period);
                } else {
                    // If it doesn't exist, add it
                    if(selectedPeriod.length < 2){
                        const payment=lead[0].payment ? lead[0].payment : 0;
                        const quoteAmount=Math.ceil(payment * period);
                        setFaceAmount(quoteAmount);
                        handleProfileUpdate('faceAmount',quoteAmount)
                        return [...prevSelectedPeriod, period];
                    }
                    else{
                        return selectedPeriod
                    }
                }
            });
        }
    }
    const handleSelectChange = (event) => {
        setSelectedCarrier(event.target.value);
    };
    const agentInfo=useSelector((state) => state.agent.userData);
    const [selectedSections, setSelectedSections] = useState([]);
    const carrier=[
        {id:'SBLI',data:'You will receive an email with a link to my.sbli.com register your account and download your policy.'},
        {id:'LGA',data:'You have the option of downloading the policy now, or I can send you can follow the link they emailed you. It will take you to the policy owner portal where you register and download your policy.'},
        {id:'AmAm',data:'Would you like your policy mailed or emailed to you? (Mail delivery-5-6 days)'},
        {id:'Corebridge/AIG',data:'Would you like your policy mailed or emailed to you? (Mail delivery-7-9 days)'},
        {id:'transamerica',data:'(Fast Doc)-You will receive your policy shortly via email. (Non Fast Doc) You will receive your policy in the mail in 6-9 days.'},
        {id:'F&G',data:'You will receive your policy in the mail in 6-9 days. ( be sure to indicate in the special instructions on the application to deliver to PI)'},
        {id:'Americo',data:'Would you like your policy mailed or emailed to you? (Mail delivery 5-6 days)'},
        {id:'UHL',data:'You will receive your policy in the mail in 5-7 days.'},
        {id:'Foresters',data:'(Email) You will receive your policy in your email shortly. (Mail) You policy will be delivered in 5-7 days.'},
        {id:'MOO',data:'You will receive your policy in your email shortly, and a copy will be mailed to you-you will receive the mailed copy within 5-7 days.'},
    ];
    const selectedCarrierData = carrier.find(item => item.id === selectedCarrier)?.data || '';
    const handleDropDown = (field, value) => {
        if(field==='ben_type' && value==='spouse'){
            handleUpdateLead('marital_status', 'Married')
        }
        if(profileAttr.includes(field)){
            handleSetProfile(field,value)
        }
        updateLeadData(field, value, lead[0].lead_id, agentInfo.agentId);
        handleLeadData(field,value);
    }
    const handleSectionClick = (index) => {
        setSelectedSections((prevSelectedSections) => {
            return [...prevSelectedSections, index];
            if (prevSelectedSections.includes(index)) {
                // Unselect the section
                return //prevSelectedSections.filter((i) => i !== index);
            } 
            else {
                // Select the section
                return [...prevSelectedSections, index];
            }
        });
    };
    
    const handleScriptInput=(field,value)=>{
        handleLeadData(field,value);
    }
    const handleUpdateLead=(field,value)=>{
        if(profileAttr.includes(field)){
            handleSetProfile(field,value)
        }
        updateLeadData(field, value, lead[0].lead_id, agentInfo.agentId);
    }
    const handleButtonInput=(field,value)=>{
        if(field==='settingAppt'){
            setSettingAppt(value);
        }
        else{
            handleLeadData(field,value);
            updateLeadData(field, value, lead[0].lead_id, agentInfo.agentId);
        }
    }
    useEffect(() => {
        if(lead[0].custom_fields){
            const newObj=removeKeys(JSON.parse(lead[0].custom_fields), keysToRemove)
            if(newObj.length > 0){
                setCustomInfo(newObj[0]);
            }
            else{
                setCustomInfo(newObj);
            }
        }
    },[lead[0].lead_id])
    const calculateFinancialValue = (lead) => {
        const borrowerIncome = parseInt(lead[0].borrower_income) || 0;
        const coBorrowerIncome = parseInt(lead[0].co_borrower_income) || 0;
        const debt = parseInt(lead[0].expenses) || 0;
        const debt2 = parseInt(lead[0].debt) || 0;
        const employerInsCost = parseInt(lead[0].employer_ins_cost) || 0;
        const payment = parseInt(lead[0].payment) || 0;
        const retirement = parseInt(lead[0].retirement) || 0;
        const binsurance = parseInt(lead[0].borrower_coverage_cost) || 0;
        const cbinsurance = parseInt(lead[0].co_borrower_coverage_cost) || 0;
      
        return (borrowerIncome + coBorrowerIncome) - (debt + debt2 + employerInsCost + payment + retirement + binsurance + cbinsurance);
    };
 
    const handleSetProfile= (column, value)=>{
        setProfile(prevProfile => ({...prevProfile,[column]:value}));
    }
    const closePopup=()=>{
        setExtraInfo(false)
    }
    const removeReccProduct=(faceAmount,product,setSelectedProducts)=>{
        handleSelectedProducts(faceAmount, product, setSelectedProducts)
    }
    const excludedKeys = ['PushedToOptDate', 'eventDetails','OrderNumber']; // Array of keys to exclude




    return (
        <>
            <div className="sideTabs">
                {/*<div onClick={()=>handleSetAppt(true)} className="sideTab outlineQuilityActiveButton">Set Appointment</div>*/}
                <div onClick={()=>scrollToDiv(cqfRef)} className={`sideTab  ${selectedTab==='CQF Verification' && 'selectedSideTab'}`}>CQF</div>
                <div onClick={()=>scrollToDiv(suitabilityRef)} className={`sideTab  ${selectedTab==='Financial Suitability' && 'selectedSideTab'}`}>Suitability</div>
                <div onClick={()=>scrollToDiv(cqfFindingRef)} className={`sideTab  ${selectedTab==='Why-CQF Findings' && 'selectedSideTab'}`}>Findings</div>
                <div onClick={()=>scrollToDiv(rpRef)} className={`sideTab  ${selectedTab==='Role and Purpose' && 'selectedSideTab'}`}>Role & Purpose</div>
                <div onClick={()=>scrollToDiv(thinkRef)} className={`sideTab  ${selectedTab==='Think About it' && 'selectedSideTab'}`}>Think About it</div>
                <div onClick={()=>scrollToDiv(saleRef)} className={`sideTab  ${selectedTab==='Full Payoff' && 'selectedSideTab'}`}>Full Payoff</div>
                <div onClick={()=>scrollToDiv(critRef)} className={`sideTab  ${selectedTab==='Critical Period/Equity Protection' && 'selectedSideTab'}`}>Critical Period</div>
            </div> 
            <div className="scriptingWrapper scriptAdjustment" ref={containerRef}>
                
                {extraInfo && (
                    <ExtraInfo extraInfo={extraInfo} closePopup={closePopup} />
                )}
                <div className="subTitle">Intro</div>
                <div
                    className={`scriptSection ${selectedSections.includes(0) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(0)}
                >
                    <div className="scriptLineBreak">
                        Hello, <b>{lead[0].borrower_first}</b> This is {agentInfo.firstName} {agentInfo.lastName},<br/><br/>
                        I was following up with your request for Mortgage Protection coverage options to protect your family and mortgage. I just need to verify the info you submitted to get your options to you.
                    </div>
                    <div className="scriptLineBreak">
                        <div className="inscript">Verify the following</div>
                        
                        

                        {customInfo && JSON.stringify(customInfo) !== '{}' ? (
                            <div className="customLeadData">
                                {Object.entries(customInfo)
                                    .filter(([key, value]) => 
                                        !key.startsWith('utm_') &&              // Exclude keys that start with 'utm_'
                                        !excludedKeys.includes(key) &&          // Exclude specific keys
                                        value !== null                          // Exclude keys with null values
                                    )
                                    .map(([key, value]) => (
                                        <div key={key} className="keyValue">
                                            <span className="customInfoTitle">{key.replace(/_/g, ' ')}</span>: 
                                            {value}
                                        </div>
                                    ))
                                }
                            </div>
                        ) : JSON.stringify(customInfo) === '{}' ? (
                            <div className="customLeadData">
                                {verify.map((item, index) => (
                                    lead[0][`${item}`] && ( 
                                        <div key={`verify${index}`} className="keyValue">
                                            <span className="customInfoTitle">{item.replace(/_/g, ' ')}</span>: 
                                            {lead[0][`${item}`]}
                                        </div>
                                    )
                                ))}
                            </div>
                        ) : (
                            <div className="customLeadData">
                                {verify.map((item, index) => (
                                    lead[0][`${item}`] && ( 
                                        <div key={`verify${index}`} className="keyValue">
                                            <span className="customInfoTitle">{item.replace(/_/g, ' ')}</span>: 
                                            {lead[0][`${item}`]}
                                        </div>
                                    )
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`scriptSection ${selectedSections.includes(1) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(1)}
                >   
                    <div className="scriptLineBreak">
                        <div className="inscript">**Verify clients sex</div>
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <select className="outlineButton filterButton" onChange={(e)=>handleDropDown('borrower_sex', e.target.value)} value={lead[0].borrower_sex ? lead[0].borrower_sex : ''}>
                                <option value="">Select Sex</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Now, one of the most common needs my clients have is that if something were to happen, their family would need to be able to continue paying bills, manage their life and kids, and to take care of the mortgage. Is that how you feel?
                    </div>
                    <div className="scriptLineBreak">God forbid something were to happen to you, who would be financially responsible for the bills? 
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <select className="outlineButton filterButton" onChange={(e)=>handleDropDown('ben_type', e.target.value)} value={lead[0].ben_type ? lead[0].ben_type : ''}>
                                <option value="">Select Relationship</option>
                                <option value="spouse">Spouse</option>
                                <option value="child">Child</option>
                                <option value="grand child">Grand Child</option>
                                <option value="sibling">Sibling</option>
                                <option value="friend">Friend</option>
                            </select>
                        </div>
                        {/*<div>
                            Would <b>{lead[0].beneficiary}</b> be your beneficiary on this plan?  
                        </div>*/}
                    </div> 
                    <div className="scriptLineBreak">What is your <b>{lead[0].ben_type}'s</b> name?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            {lead[0].ben_type==='spouse' ? (
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>[handleUpdateLead('beneficiary', e.target.value),handleUpdateLead('co_borrower_first', e.target.value)]} onChange={(e)=>[handleScriptInput('beneficiary', e.target.value),handleScriptInput('co_borrower_first', e.target.value)]} value={lead[0].beneficiary ? lead[0].beneficiary : ''} placeholder='Beneficiary Name'/>
                            ) : (
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('beneficiary', e.target.value)} onChange={(e)=>handleScriptInput('beneficiary', e.target.value)} value={lead[0].beneficiary ? lead[0].beneficiary : ''} placeholder='Beneficiary Name'/>
                            )}
                        </div>
                        <div><br/>
                            Would <b>{lead[0].beneficiary}</b> be your beneficiary on this plan?  
                        </div>
                        <br/>
                    </div> 
                    
                </div>

                {lead[0].lead_type==='mp' || lead[0].lead_type==='Mortgage Protection' ? (
                    <div
                        className={`scriptSection ${selectedSections.includes(2) ? 'selectedSection' : ''}`}
                        onClick={() => handleSectionClick(2)}
                    >
                        <div className="scriptLineBreak">
                            <div>Can I ask you-did you guys just buy your home, or did you re-finance it? </div>
                            <div>(-Congratulations!)</div>
                            <div>How do you like living there? </div>
                            <div>What made you choose that area?</div>
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('loan_type', e.target.value)} onChange={(e)=>handleScriptInput('loan_type', e.target.value)} value={lead[0].loan_type ? lead[0].loan_type : ''} placeholder='Type of Loan'/>
                            </div>
                        </div>
                        <div className="scriptLineBreak">
                            Congratulations! How do you like living there? 
                        </div>
                    </div>
                ) : (
                <div
                        className={`scriptSection ${selectedSections.includes(3) ? 'selectedSection' : ''}`}
                        onClick={() => handleSectionClick(3)}
                    >
                        <div className="scriptLineBreak">
                            <div><b>{lead[0].borrower_first}</b>, what made you want to start looking at Protection for your family? <span className="smallScrit">(Problem)</span></div>
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('motivation', e.target.value)} onChange={(e)=>handleScriptInput('motivation', e.target.value)} value={lead[0].motivation ? lead[0].motivation : ''} placeholder='Client Motivation'/>
                            </div>
                        </div>
                        <div className="scriptLineBreak">
                            <div>Ideally, what do you want this coverage to accomplish for you? <span className="smallScrit">(Solution)</span></div>
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('concerns', e.target.value)} onChange={(e)=>handleScriptInput('concerns', e.target.value)} value={lead[0].concerns ? lead[0].concerns : ''} placeholder='Client Concerns'/>
                            </div>
                        </div>
                        <div className="scriptLineBreak">
                                That makes complete sense <b>{lead[0].borrower_first}</b>
                        </div>
                        
                    </div>
                )}
                <div ref={cqfRef} className="subTitle">CQF Verification</div>
                <div
                    className={`scriptSection ${selectedSections.includes(4) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(4)}
                >
                    
                    <div className="scriptLineBreak">
                        Insurance companies qualify people in two different ways - by their health, and financially. I just need to verify a few very basic questions
                    </div>

                    {lead[0].ben_type !=='spouse' ? (
                        <div className="scriptLineBreak">
                            Are you married, or have a significant other?
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('marital_status', e.target.value)} onChange={(e)=>handleScriptInput('marital_status', e.target.value)} value={lead[0].marital_status ? lead[0].marital_status : ''} placeholder='Marital Status'/>
                            </div>
                        </div>
                    ) : (
                        <>
                            
                        </>
                    )}
                    {lead[0].co_borrower_first ? (
                        <div className="scriptLineBreak">
                            To confirm, your spouses name is <b>{lead[0].co_borrower_first}</b>, corrrect?
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_first', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_first', e.target.value)} value={lead[0].co_borrower_first ? lead[0].co_borrower_first : ''} placeholder='Co Lead First Name'/>
                            </div>
                        </div>
                    ) : lead[0].marital_status && (
                        <div className="scriptLineBreak">
                            What is your spouses name?
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_first', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_first', e.target.value)} value={lead[0].co_borrower_first ? lead[0].co_borrower_first : ''} placeholder='Co Lead First Name'/>
                            </div>
                        </div>
                    )}
                    
                    <div className="scriptLineBreak">
                        What is your current age?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_age', e.target.value)} onChange={(e)=>handleScriptInput('borrower_age', e.target.value)} value={lead[0].borrower_age ? lead[0].borrower_age : ''} placeholder='Lead Age'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        How tall are you?
                        <br/>
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <div>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('feet', e.target.value)} onChange={(e)=>handleScriptInput('feet', e.target.value)} value={lead[0].feet ? lead[0].feet : ''} placeholder='Lead Height - Feet'/>
                            </div>
                            <br/>
                            <div>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('inches', e.target.value)} onChange={(e)=>handleScriptInput('inches', e.target.value)} value={lead[0].inches ? lead[0].inches : ''} placeholder='Lead Height - inches'/>
                            </div>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        About how much do you weigh?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('weight', e.target.value)} onChange={(e)=>handleScriptInput('weight', e.target.value)} value={lead[0].weight ? lead[0].weight : ''} placeholder='Lead Weight'/>
                        </div>
                    </div>
                    {/*<div className="scriptLineBreak">
                        What prescription medications are you taking?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('medications', e.target.value)} onChange={(e)=>handleScriptInput('medications', e.target.value)} value={lead[0].medications ? lead[0].medications : ''} placeholder='Lead Medications'/>
                        </div>
                    </div>*/}
                    <div className="scriptLineBreak">
                        Do you use tobacco?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <select className="outlineButton filterButton" onChange={(e)=>handleDropDown('tobacco', e.target.value)} value={lead[0].tobacco ? lead[0].tobacco : ''}>
                                <option value="no">Non-Tobacco</option>
                                <option value="yes">Tobacco</option>
                            </select>
                            
                        </div>
                    </div>
                    {(lead[0].co_borrower_first || lead[0].marital_status) && lead[0].marital_status !=='no' && (
                            <div className="scriptLineBreak">
                            How about <b>{lead[0].co_borrower_first}</b>, do they use tobacco?<br/>
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <select className="outlineButton filterButton" onChange={(e)=>handleDropDown('co_borrower_tobacco', e.target.value)} value={lead[0].co_borrower_tobacco ? lead[0].co_borrower_tobacco : ''}>
                                    <option value="no">Non-Tobacco</option>
                                    <option value="yes">Tobacco</option>
                                </select>
                            </div>
                        </div>
                    )}

                   {/*<div className="scriptLineBreak">
                        Do you have any health conditions?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('medical_conditions', e.target.value)} onChange={(e)=>handleScriptInput('medical_conditions', e.target.value)} value={lead[0].medical_conditions ? lead[0].medical_conditions : ''} placeholder='Lead Conditions'/>
                        </div>
                    </div>*/}
                    <div className="scriptLineBreak">
                        Do you have any Medications or health conditions?
                        <FdaBlock setPossibleConditions={setPossibleConditions} possibleConditions={possibleConditions} setLead={setLead} lead={lead} setProfile={setProfile} profile={profile} />
                    </div>
                    {(lead[0].co_borrower_first || lead[0].marital_status) && lead[0].marital_status !=='no' && (
                            <div className="scriptLineBreak">
                            How about <b>{lead[0].co_borrower_first}</b>, do they take any medications?<br/>
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_medications', e.target.value)} onChange={(e)=>handleScriptInput('co_medications', e.target.value)} value={lead[0].co_medications ? lead[0].co_medications : ''} placeholder='Co Lead Medications'/>
                            </div>
                        </div>
                    )}
                    {(lead[0].co_borrower_first || lead[0].marital_status) && lead[0].marital_status !=='no' && (
                            <div className="scriptLineBreak">
                            And what about any major medical conditions for <b>{lead[0].co_borrower_first}</b>?<br/>
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_medical_conditions', e.target.value)} onChange={(e)=>handleScriptInput('co_medical_conditions', e.target.value)} value={lead[0].co_medical_conditions ? lead[0].co_medical_conditions : ''} placeholder='Co Lead Conditions'/>
                            </div>
                        </div>
                    )}
                </div>
                




                
                <div
                    className={`scriptSection ${selectedSections.includes(6) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(6)}
                >
                    <div className="scriptLineBreak">
                        Do we have children in the house/ You had mentioned you have children
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('dependants', e.target.value)} onChange={(e)=>handleScriptInput('dependants', e.target.value)} value={lead[0].dependants ? lead[0].dependants : ''} placeholder='Dependants'/>
                        </div>
                    </div>

                </div>
                <div
                    className={`scriptSection ${selectedSections.includes(21) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(21)}
                >
                    <div className="scriptLineBreak">
                    We have addressed a lot of things, and if you can stay with me, I can go over some options that will solve the issues we discussed. Does that work?
                    </div>
                    <div className="scriptLineBreak">
                        <div className="inscript">Select their answer (If they don't have time, set the appointment within 24 hrs)</div>
                        <div className="buttonWrapper">
                            <div className={`jumpIntoButton outlineQuilityActiveButton ${settingAppt===0 ? 'activeButton': ''}`} onClick={()=>handleButtonInput('settingAppt', 0)}>No</div>
                            <div className={`jumpIntoButton outlineQuilityActiveButton ${settingAppt===1 ? 'activeButton': ''}`} onClick={()=>handleButtonInput('settingAppt', 1)}>Yes</div>
                        </div>
                    </div>
                    {settingAppt===1 && (
                        <div className="scriptLineBreak">
                            Is this something that you would want to discuss with someone else before making a decision on protecting <b>{lead[0].beneficiary}</b>?
                        </div>
                    )}
                    {settingAppt===0 && lead[0].co_borrower_first && (
                        <div className="scriptLineBreak">
                            I have (two times from your schedule) tomorrow for you and <b>{lead[0].co_borrower_first}</b> will you both be available?
                        </div>
                    )}
                </div>
            
                {settingAppt===0 ? (
                    <>
                    <div className="subTitle">Find a time that works within 24 hours. 48 hours max - If not, offer to call them back</div>
                    <div
                        className={`scriptSection ${selectedSections.includes(25) ? 'selectedSection' : ''}`}
                        onClick={() => handleSectionClick(25)}
                    >
                        {lead[0].co_borrower_first && (
                        <div className="scriptLineBreak">
                            It's very important that <b>{lead[0].co_borrower_first}</b> is on the call because if something happens, they are going to call me.
                        </div>
                        )}
                    </div>
                    <div className="subTitle">Set appointment</div>
                    <div
                    className={`scriptSection ${selectedSections.includes(26) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(26)}
                >
                    <div className="scriptLineBreak">
                        Ok great, I really value our time and do my best to keep a tight schedule for everyone. If we can't meet at (time they indicated) it will impact my ability to help another family during that time. Can you grab a pen really quick and write that time down, so we don't miss each other? To confirm; We are meeting (tomorrow/next day) at (designated time), I will see you then. Thank you
                    </div>
                        
                </div>
                <div className="subTitle" ref={suitabilityRef}>Financial Suitability</div>
                    </>
                ) : (
                    <div className="subTitle" ref={suitabilityRef}>Financial Suitability</div>
                )}
                <div
                    className={`scriptSection ${selectedSections.includes(5) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(5)}
                >
                    <div className="scriptLineBreak">
                        I need to ask a few basic financial questions for suitability, I don't want to over-insure you and ethically I wouldn't under insure you
                    </div>
                    <div className="scriptLineBreak">
                        What do you do for a living?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_occupation', e.target.value)} onChange={(e)=>handleScriptInput('borrower_occupation', e.target.value)} value={lead[0].borrower_occupation ? lead[0].borrower_occupation : ''} placeholder='Lead Occupation'/>
                        </div>
                    </div>

                    <div className="scriptLineBreak">
                        <b>What do those hours look like? </b>
                    </div>
                    <div className="scriptLineBreak">
                        Monthly mortgage payment?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('payment', e.target.value)} onChange={(e)=>handleScriptInput('payment', e.target.value)} value={lead[0].payment ? lead[0].payment : ''} placeholder='Mortgage Payment'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Total mortgage loan amount?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('mortgage', e.target.value)} onChange={(e)=>handleScriptInput('mortgage', e.target.value)} value={lead[0].mortgage ? lead[0].mortgage : ''} placeholder='Mortgage Total'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Total home value?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('home_value', e.target.value)} onChange={(e)=>handleScriptInput('home_value', e.target.value)} value={lead[0].home_value ? lead[0].home_value : ''} placeholder='Home Value'/>
                        </div>
                    </div>
                    {lead[0].home_value && lead[0].mortgage && (
                        <div className="scriptLineBreak">
                            That gives you <b>${(parseInt(lead[0].home_value.replace(/\D/g, '')) - parseInt(lead[0].mortgage.replace(/\D/g, ''))).toLocaleString()}</b> in equity, correct?
                        </div>
                    )}
                </div>
                
                <div
                    className={`scriptSection ${selectedSections.includes(20) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(20)}
                >
                    
                    <div className="scriptLineBreak">
                        You had mentioned that you are a <b>{lead[0].borrower_occupation}</b> for work, what's your monthly income?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_income', e.target.value)} onChange={(e)=>handleScriptInput('borrower_income', e.target.value)} value={lead[0].borrower_income ? lead[0].borrower_income : ''} placeholder='Lead Monthly Income'/>
                        </div>
                    </div>

                    {lead[0].co_borrower_first && (
                        <div className="scriptLineBreak">
                            What is <b>{lead[0].co_borrower_first}'s</b>?
                            <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_income', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_income', e.target.value)} value={lead[0].co_borrower_income ? lead[0].co_borrower_income : ''} placeholder='Co Lead Monthly Income'/>
                            </div>
                        </div>
                    )}
                    
                    <div className="scriptLineBreak">
                        Roughly, if you had to estimate your utilities costs, groceries, car payment, cellphone, internet- all of your bills - what would that run you per month?”
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('expenses', e.target.value)} onChange={(e)=>handleScriptInput('expenses', e.target.value)} value={lead[0].expenses ? lead[0].expenses : ''} placeholder='Expenses'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Total Debt (Cc/Revolving Loans/Student debt)?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('debt', e.target.value)} onChange={(e)=>handleScriptInput('debt', e.target.value)} value={lead[0].debt ? lead[0].debt : ''} placeholder='Total Debt'/>
                        </div>
                    </div>
                </div>
                <div
                    className={`scriptSection ${selectedSections.includes(7) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(7)}
                >
                    <div className="scriptLineBreak">
                        Do you or <b>{lead[0].co_borrower_first}</b> have any Retirement Contribution?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('retirement', e.target.value)} onChange={(e)=>handleScriptInput('retirement', e.target.value)} value={lead[0].retirement ? lead[0].retirement :''} placeholder='Retirement Contribution'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Do you have existing life insurance ?
                    </div>
                    <div className="scriptLineBreak">
                        What about <b>{lead[0].co_borrower_first}</b>?
                    </div>
                    <div className="scriptLineBreak">
                        Is that offered through your employer?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <div className="buttonWrapper">
                                <div className={`jumpIntoButton outlineQuilityActiveButton ${lead[0].employer_insurance===0 ? 'activeButton': ''}`} onClick={()=>handleButtonInput('employer_insurance', 0)}>No</div>
                                <div className={`jumpIntoButton outlineQuilityActiveButton ${lead[0].employer_insurance===1 ? 'activeButton': ''}`} onClick={()=>handleButtonInput('employer_insurance', 1)}>Yes</div>
                            </div>
                        </div>
                    </div>
                    {lead[0].employer_insurance===1 && (
                        <span className="inset">
                            <div className="scriptLineBreak">
                                Perfect. That usually covers the loss of one year's income if you die while you are employed there.
                            </div>
                            <div className="scriptLineBreak">
                                How much does it cover?
                                <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                    <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('employer_ins_amount', e.target.value)} onChange={(e)=>handleScriptInput('employer_ins_amount', e.target.value)} value={lead[0].employer_ins_amount ? lead[0].employer_ins_amount :''} placeholder='Employer Coverage Amount'/>
                                </div>
                            </div>
                            <div className="scriptLineBreak">
                                What is the cost of that monthly?
                                <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                    <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('employer_ins_cost', e.target.value)} onChange={(e)=>handleScriptInput('employer_ins_cost', e.target.value)} value={lead[0].employer_ins_cost ? lead[0].employer_ins_cost : ''} placeholder='Employer Coverage Cost'/>
                                </div>
                            </div>
                        </span>
                    )}
                    <div className="scriptLineBreak">
                        What do you have in the way of Personal life insurance to protect your mortgage and family?
                        <div className="buttonWrapper">
                            <div className={`jumpIntoButton outlineQuilityActiveButton ${lead[0].borrower_is_covered==='no' ? 'activeButton': ''}`} onClick={()=>[handleUpdateLead('borrower_is_covered', 'no'),handleScriptInput('borrower_is_covered', 'no')]}>No Insurance</div>
                            <div className={`jumpIntoButton outlineQuilityActiveButton ${lead[0].borrower_is_covered==='yes' ? 'activeButton': ''}`} onClick={()=>[handleUpdateLead('borrower_is_covered', 'yes'),handleScriptInput('borrower_is_covered', 'yes')]}>Has Insurance</div>
                        </div>
                    </div>
                    {lead[0].borrower_is_covered && (lead[0].borrower_is_covered !=='no' && lead[0].borrower_is_covered !=='none') && (
                        <>
                            <div className="scriptLineBreak">
                                Great, what is that coverage amount?
                                <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                    <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_coverage', e.target.value)} onChange={(e)=>handleScriptInput('borrower_coverage', e.target.value)} value={lead[0].borrower_coverage ? lead[0].borrower_coverage : ''} placeholder='Existing Insurance Amount'/>
                                </div>
                            </div>
                            <div className="scriptLineBreak">
                                What is the monthly cost?
                                <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                    <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_coverage_cost', e.target.value)} onChange={(e)=>handleScriptInput('borrower_coverage_cost', e.target.value)} value={lead[0].borrower_coverage_cost ? lead[0].borrower_coverage_cost : ''} placeholder='Existing Insurance Cost'/>
                                </div>
                            </div>
                        </>
                    )}
                    {lead[0].co_borrower_first && (
                        <>
                            <div className="scriptLineBreak">
                                What about <b>{lead[0].co_borrower_first}</b>, do they have personal insurance?
                                <div className="buttonWrapper">
                                    <div className={`jumpIntoButton outlineQuilityActiveButton ${lead[0].co_borrower_is_covered==='no' ? 'activeButton': ''}`} onClick={()=>[handleUpdateLead('co_borrower_is_covered', 'no'),handleScriptInput('co_borrower_is_covered', 'no')]}>No Insurance</div>
                                    <div className={`jumpIntoButton outlineQuilityActiveButton ${lead[0].co_borrower_is_covered==='yes' ? 'activeButton': ''}`} onClick={()=>[handleUpdateLead('co_borrower_is_covered', 'yes'),handleScriptInput('co_borrower_is_covered', 'yes')]}>Has Insurance</div>
                                </div>
                            </div>
                            {lead[0].co_borrower_is_covered && (lead[0].co_borrower_is_covered !=='no' && lead[0].co_borrower_is_covered !=='none') && (
                                <>
                                    <div className="scriptLineBreak">
                                        What is the coverage amount?
                                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_coverage', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_coverage', e.target.value)} value={lead[0].co_borrower_coverage ? lead[0].co_borrower_coverage : ''} placeholder='Co Lead Existing Cost'/>
                                        </div>
                                    </div>
                                    <div className="scriptLineBreak">
                                        What is the monthly cost?
                                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_coverage_cost', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_coverage_cost', e.target.value)} value={lead[0].co_borrower_coverage_cost ? lead[0].co_borrower_coverage_cost : ''} placeholder='Co Lead Existing Cost'/>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                
                {/*<div
                    className={`scriptSection ${selectedSections.includes(15) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(15)}
                >
                    <div className="scriptLineBreak">
                        Insurance companies qualify people in two different ways - by their health, and financially. I just need to verify a few very basic questions
                    </div>
                    <div className="scriptLineBreak">
                        Are you married?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('marital_status', e.target.value)} onChange={(e)=>handleScriptInput('marital_status', e.target.value)} value={lead[0].marital_status} placeholder='Maritial Status'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Spouses Name?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('co_borrower_first', e.target.value)} onChange={(e)=>handleScriptInput('co_borrower_first', e.target.value)} value={lead[0].co_borrower_first} placeholder='Spouses First Name'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Your age?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('borrower_age', e.target.value)} onChange={(e)=>handleScriptInput('borrower_age', e.target.value)} value={lead[0].borrower_age} placeholder='Lead Age'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Your Height?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <div>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('feet', e.target.value)} onChange={(e)=>handleScriptInput('feet', e.target.value)} value={lead[0].feet} placeholder='Lead Height - Feet'/>
                            </div>
                            <br/>
                            <div>
                                <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('inches', e.target.value)} onChange={(e)=>handleScriptInput('inches', e.target.value)} value={lead[0].inches} placeholder='Lead Height - inches'/>
                            </div>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Your weight?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('weight', e.target.value)} onChange={(e)=>handleScriptInput('weight', e.target.value)} value={lead[0].weight} placeholder='Lead Weight'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Medical Conditions?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('medical_conditions', e.target.value)} onChange={(e)=>handleScriptInput('medical_conditions', e.target.value)} value={lead[0].medical_conditions} placeholder='Lead Medications'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Your Medications?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <input className="qualifierFieldInput" type="text" onBlur={(e)=>handleUpdateLead('meds', e.target.value)} onChange={(e)=>handleScriptInput('meds', e.target.value)} value={lead[0].meds} placeholder='Lead Medications'/>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        Do you use tobacco?
                        <div className="leadData" style={{width: '50%',margin: '10px 0px', display:'block'}}>
                            <select className="outlineButton filterButton" onChange={(e)=>handleDropDown('tobacco', e.target.value)}>
                                <option value="no">Non-Tobacco</option>
                                <option value="yes">Tobacco</option>
                            </select>
                            
                        </div>
                    </div>
                </div>*/}
                
                
                <div ref={cqfFindingRef} className="inscript">
                    <b>Why-CQF Findings</b>
                </div>
                <div
                    className={`scriptSection ${selectedSections.includes(8) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(8)}
                >
                    <div className="scriptLineBreak">
                        Based on the information you gave me; it looks like we have about <b>${calculateFinancialValue(lead).toLocaleString()}</b> in extra income per month-does that sound right to you?
                    </div>
                    <div className="scriptLineBreak">
                        Earlier you said that the beneficiary of this plan would be <b>{lead[0].beneficiary}</b>. When you requested this protection, was having protection and providing financial resources for <b>{lead[0].beneficiary}</b> your main concern?
                    </div>
                    {lead[0].beneficiary && (
                        <div className="scriptLineBreak">
                            <b>{lead[0].borrower_first}</b>, if you passed away yesterday and your <b>${parseInt(lead[0].borrower_income).toLocaleString()}</b> income was no longer coming into your household, how long would it take before life would become difficult for <b>{lead[0].beneficiary}</b>? Would the kids need to change schools?
                        </div>
                    )}
                    <div className="scriptLineBreak">
                        <b>{lead[0].borrower_first}</b>, if something happened to you yesterday how would <b>{lead[0].beneficiary}</b> take care of them selves?
                    </div>
                    <div className="scriptLineBreak">
                        Can we agree that our family, like most families, would have some tough decisions to make with loss of an income? 
                    </div>

                </div>

                {/*<div
                    className={`scriptSection ${selectedSections.includes(9) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(9)}
                >
                    <div className="scriptLineBreak">
                        After looking at this, it makes sense. You are a lot like the families I meet with each week. We would be in a tough spot with the loss of an income. I completely understand why you reached out and this is why I love my job. It allows me to work with responsible people. We have addressed a lot of things, and if you can stay with me, I can go over some options that will solve the issues we discussed. Does that work?
                    </div>
                    <div className="scriptLineBreak">
                        Is this something that you would want to discuss with someone else before making a decision on protecting <b>{lead[0].beneficiary}</b>?
                    </div>
                </div>*/}


                <div className="inscript">(If they don't have time, set the appointment within 24 hrs)</div>

                {/*<div
                    className={`scriptSection ${selectedSections.includes(10) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(10)}
                >
                    <div className="scriptLineBreak">
                        I completely understand, I have a busy day too.
                    </div>
                    <div className="scriptLineBreak">
                        I have <div className="smallScrit">(two times from your schedule)</div> tomorrow for you and <b>{lead[0].co_borrower_first}</b> will you both be available? <div className="smallScrit">(Find a time that works within 24 hours. 48 hours max - If not, offer to call them back.)</div>
                    </div>
                    {lead[0].ben_type==='spouse' && (
                        <div className="scriptLineBreak">
                            It is very important that <b>{lead[0].co_borrower_first}</b> is on the call because if something happens, they are going to call me.
                        </div>
                    )}
                    <div className="scriptLineBreak">
                        Ok great, I really value our time and do my best to keep a tight schedule for everyone. If we aren't able to meet at <div className="smallScrit">(time they indicated)</div> it will impact my ability to help another family during that time. Can you grab a pen really quick and write that time down, so we don't miss each other? To confirm; We are meeting <div className="smallScrit">(tomorrow/next day) at (designated time)</div>, I will see you then. Thank you.
                    </div>
                

                </div>*/}


                <div className="subTitle" ref={rpRef}>Role and Purpose</div>

                <div
                    className={`scriptSection ${selectedSections.includes(11) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(11)}
                > 
                    <div className="scriptLineBreak">
                        Let me briefly explain my role here - we help 1000s of families every week in your same situation. I make sure they are able to continue paying bills, take care of the mortgage, and avoid some really tough, and in most cases, very unnecessary decisions when a family member dies. 
                        {/*<br/><br/>
                        Based on what you shared with me, it sounds like it's critical to look at a couple solutions that will make sure your family has protection in place. I'm going to show you a couple of the best options available. All I ask is that you are direct with me and ask questions. It's not only important that this plan fits your needs today, but also through the holidays, tax season, and life in general. As we go through the plans, simply let me know which plan fits your family's needs the best. Fair enough? */}
                    </div>
                </div>
                <div className="subTitle" ref={thinkRef}>Think About it</div>
                <div
                    className={`scriptSection ${selectedSections.includes(16) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(16)}
                > 
                    <div className="scriptLineBreak">
                        Based on what you just shared with me, let's look at some solutions that can help. I am going to start with the MOST comprehensive coverage that's going to include every benefit that you might qualify for. I'm not doing that to try to talk you into spending more money than you are comfortable with, I just want to show you all of your options.
                        <br/><br/>
                        I'm going to count on you to be honest about your budget. Sometimes people will say things like, 'I need to think about It, sleep on It, etc.' I've been doing this long enough to know what that means…the price Isn't right. So, if what I'm showing you doesn't fit the budget, I can count on you to tell me, right? 
                    </div>
                </div>
                <div className="subTitle" ref={saleRef}>Full Payoff</div>
                <div
                    className={`scriptSection ${selectedSections.includes(12) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(12)}
                >
                    <div className="scriptLineBreak">
                        So, I have two solutions here and I want to see which you like better. We will still have to apply to qualify, but I want to see which solution fits your family's needs
                    </div>
                    <div className="scriptLineBreak">
                        <div className="subTitle">Full Payoff with Living Benefits</div>
                        This will make sure that the family keeps the home and pays the mortgage off in its entirety. It also provides Living Benefits for Critical and Chronic Illness. If you get diagnosed with a Critical Illness like Cancer, or have a Heart Attack, you can access the death benefit while you are alive so that you can recover while not having to worry about the loss of work, or your income. My clients see a lot of value in this feature
                        {(lead[0].lead_type==='mp' || lead[0].lead_type==='Mortgage Protection') ? (
                            " pay your monthly bills for you. "
                        ) : (
                            "pay your monthly bills for you. "
                        )}
                        If you get sick it could pay up to the total amount of the death benefit directly to <b>{lead[0].co_borrower_first}</b>.
                    </div>
                    <div className="scriptLineBreak">
                        <div className="subTitle">Partial Payoff</div>
                        This will payoff half the mortgage amount so the monthly payment can be cut in half, making it more affordable for one person
                    </div>

                    <div className="inscript">
                        Go over two options they qualify for (good/better/best).  Try to make the “B” option about 10% of their mortgage payment amount.
                    </div>
                    <div className="scriptLineBreak">
                        Let me go over the best options I have for you!
                        {selectedProducts && selectedProducts.map((reccomendation, index) => (
                            <div className="productHolder" key={`recc${index}`}>
                                <div className="quilityCloseWindow" style={{top:'5px'}} onClick={()=>removeReccProduct(reccomendation.face, reccomendation.product, setSelectedProducts)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                                <span className="faceHolder">${parseInt(reccomendation.face).toLocaleString()} - {reccomendation.product.tempId.split('tl_')[1].replace(/_/g, ' ')}</span>
                                <ReccomendationBlock reccomendation={reccomendation.product} from='script' setExtraInfo={setExtraInfo}/>
                            </div>
                        ))}
                    </div>
                    {/*<div className="inscript">
                        If there is resistance, go over how their life would change losing the primary earners income
                    </div>
                    <div className="scriptLineBreak">
                        What would it look like if <b>{lead[0].borrower_first}</b> passed away and you lost the <b>{lead[0].borrower_income}</b> coming in monthly? Would that make it hard for <b>{lead[0].co_borrower_first}</b>?
                        <div className="demonstration">
                            Currently, you have <b>${parseInt(calculateFinancialValue(lead)).toLocaleString()}</b> available per month, correct?<br/><br/>
                            What would it look like if you lost the <b>${parseInt(lead[0].borrower_income).toLocaleString()}</b>? Your family would be left with <b>${parseInt(calculateMisisngFinancialValue(lead)).toLocaleString()}</b> every month after your bills.
                            <br/><br/>
                            If you had your home and debt paid off and nothing else changing, that would leave your family with <b>${parseInt(calculateMisisngHomeFinancialValue(lead)).toLocaleString()}</b> every month. Does that feel more comfortable?
                        </div>
                    </div>*/}
                </div>
                <div className="subTitle" ref={critRef}>Critical Period/Equity Protection</div>
                <div
                    className={`scriptSection ${selectedSections.includes(22) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(22)}
                >
                    
                    <div className="scriptLineBreak">
                        This is a very popular solution with my clients because it makes a lot of sense. If something were to happen to you, the bank isn't going to pause your mortgage payment. They will still expect your payment regardless of any circumstances. This solution will allow you to 
                        {lead[0].mortgage && lead[0].home_value ? (
                            ' protect your $'+(parseInt(lead[0].home_value.replace(/\D/g, '')) - parseInt(lead[0].mortgage.replace(/\D/g, ''))).toLocaleString()+" equity and"
                        ) : (
                            null
                        )}
                        give <b>{lead[0].beneficiary}</b> the time needed to decide what to do with the home. When there is a death in the family, time and options are everything.
                    </div>
                    <div className="faceOptionTitle" style={{margin: '20px'}}>Quote Protection Period</div>
                    <div className="faceOptions" style={{textAlign:'center',margin:'10px'}}>

                        <div className={`recLink solidQuilityActiveButton ${selectedPeriod.includes(6) ? 'activeOptionButton' : ''}`} onClick={()=>handleCriticalQuote(6)}>
                            6 Months
                        </div>
                        <div className={`recLink solidQuilityActiveButton ${selectedPeriod.includes(12) ? 'activeOptionButton' : ''}`} onClick={()=>handleCriticalQuote(12)}>
                            12 Months
                        </div>
                        <div className={`recLink solidQuilityActiveButton ${selectedPeriod.includes(18) ? 'activeOptionButton' : ''}`} onClick={()=>handleCriticalQuote(18)}>
                            18 Months
                        </div>
                        <div className={`recLink solidQuilityActiveButton ${selectedPeriod.includes(24) ? 'activeOptionButton' : ''}`} onClick={()=>handleCriticalQuote(24)}>
                            24 Months
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        This plan gives you the ability to cover your mortgage payments for a set amount of time. 
                        <div className="inscript">Show only 2 options. Examples:<br/>
                            1 year/2 years, 2 years/3 years, or even 6 months/12 months.
                        </div>
                        {selectedPeriod.map((period, index) => (
                            <>
                                <div className="subTitle">{period} month of mortgage payments</div>
                                {selectedProducts[index] ? (
                                    <div className="productHolder">
                                        <div className="quilityCloseWindow" style={{top:'5px'}} onClick={()=>removeReccProduct(selectedProducts[index].face, selectedProducts[index].product, setSelectedProducts)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                                        <span className="faceHolder">${parseInt(selectedProducts[index].face).toLocaleString()} - {selectedProducts[index].product.tempId.split('tl_')[1].replace(/_/g, ' ')}</span>
                                        <ReccomendationBlock reccomendation={selectedProducts[index].product} from='script' setExtraInfo={setExtraInfo}/>
                                    </div>
                                ) : (
                                    <b>No Product Selected</b>
                                )}
                            </>
                        ))}
                        
                    <div className="scriptLineBreak">
                        Which option best fits your family's needs? 
                    </div>
                       
                        <div className="inscript">Don't speak until they speak first. Once they pick one, repeat it indicating a decision is made.</div>    
                    </div>
                </div>
                <div className="subTitle">Carrier Choice</div>
                <div
                    className={`scriptSection ${selectedSections.includes(15) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(15)}
                > 
                    <div className="scriptLineBreak">
                        <div className="inscript">Select the carrier you plan on working with?</div>
                        <div className="carrierSelectHolder">
                            <select id="carrier" value={selectedCarrier} onChange={handleSelectChange} className="outlineButton filterButton">
                                <option value="none">Choose Carrier</option>
                                <option value="SBLI">SBLI</option>
                                <option value="LGA">LGA</option>
                                <option value="AmAm">AmAm</option>
                                <option value="Corebridge/AIG">Corebridge/AIG</option>
                                <option value="transamerica">Transamerica</option>
                                <option value="F&G">F&G</option>
                                <option value="UHL">UHL</option>
                                <option value="Foresters">Foresters</option>
                                <option value="MOO">MOO</option>
                            </select>
                        </div>
                    </div>
                    <div className="scriptLineBreak">
                        {selectedCarrier !== 'none' && (
                            selectedCarrierData
                        )}
                        
                    </div>
                </div>
                

                <div className="subTitle">Referral</div>
                <div
                    className={`scriptSection ${selectedSections.includes(13) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(13)}
                >

                    <div className="scriptLineBreak">
                        Before I let you go I wanted to let you know that there are billions of dollars for life insurance benefits that go unclaimed each year. This happens when beneficiaries don't receive the death benefit after the insured person dies, possibly because they don't know about the policy or who issued it. 
                    </div>
                    <div className="scriptLineBreak">
                        So, here's what I want for you: 4-5 additional names of people you love and have trusted over the past several years. I'm going to call them and let them know that they're on my file for your policy, and I want to make sure that they have my information saved. These are the people who will help make sure your family gets the money. 
                    </div>
                    <div className="scriptLineBreak">
                        So, go ahead with those 4-5 people. Also, please shoot them a message and let them know that I'll be contacting them, so I'm not just some random reaching out.
                    </div>
                    
                </div>

                <div className="subTitle">Reset</div>
                <div
                    className={`scriptSection ${selectedSections.includes(14) ? 'selectedSection' : ''}`}
                    onClick={() => handleSectionClick(14)}
                >
                    <div className="scriptLineBreak">
                        We do a lot more than life insurance; we also have debt elimination plans where you can pay off your 
                        debt without spending any more monthly than you are now and build wealth, we can help you with building
                        your retirement, or we can help you protect a portion of your assets from 
                        the market volatility. 
                    </div>
                    <div className="scriptLineBreak">
                        If you could payoff all of your debts including your mortgage in half the time-is that something you want to learn more about?
                    </div>
                    <div className="inscript">
                        If they say none, and that are fine right now, ask
                    </div>
                    <div className="scriptLineBreak">
                        Do you feel like your debt is preventing you from contributing to your retirement?
                    </div>
                </div>
                <div ref={startRef} className="subTitle"></div>
            </div>
        </>
    );
}





export default Scripting;

/*
“What do you do for a living?”

7. Occupation- what do those hours look like? Q13 IB
D. Coverage you really own (if through employer) Q26 IB

”


*/