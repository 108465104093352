import React,{useState,useEffect} from 'react';
import { fetchMedForm, removeFormCondition } from '../../../utilities/qualifierFunctions';
import ConditionInfo from '../conditionSearch';
import MedicationInfo from '../medicationSearch';
import Condition from './condition';
import Medication from './medication';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { useSelector } from 'react-redux';


function FdaBlock({setPossibleConditions, possibleConditions, setProfile, handleSetProfile, lead ,setLead, profile}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [medicalForm, setMedicalForm] = useState(false);
    const [usedMedications, setUsedMedications] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState(false);
    const [searchMed, setSearchMed] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [currentPendingCondition, setCurrentPendingCondition] = useState(false);
    const [promptQuestion, setPromptQuestion] = useState(false);
    const isQuestionSelected = (question) => {
        return profile.qualifiers.some(qualifier => qualifier.id === question.id);
    };
    const handleAnswer=(newQualifier)=>{
        setCurrentAnswer(newQualifier);
        setProfile(prevProfile => {
            const existingIndex = prevProfile.qualifiers.findIndex(
                qualifier => qualifier.parent_id === newQualifier.parent_id
            );

            let updatedQualifiers;

            if (existingIndex !== -1) {
                // Overwrite existing qualifier
                updatedQualifiers = [...prevProfile.qualifiers];
                updatedQualifiers[existingIndex] = newQualifier;
            } else {
                // Add new qualifier
                updatedQualifiers = [...prevProfile.qualifiers, newQualifier];
            }

            return {
                ...prevProfile,
                qualifiers: updatedQualifiers
            };
        });
    }
    const removeCondition=(indexToRemove)=>{
        setConditions(prevState => prevState.filter((_, index) => index !== indexToRemove));
    }
    const addUsedMedications=async (med)=>{
        if (!usedMedications.includes(med)) {
            setUsedMedications(prevState => [...prevState, med]);
            try {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ 
                        medication:med,
                        form:lead[0].medicalForm,
                        agent:agentId,
                        lead:lead[0].lead_id
                    }) 
                };
              
                const response = await fetchWithDefaults('/medicalForm/medicalFormChange', requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();

                //setLead('medicalForm',jsonData.form)
                setUsedMedications([]);
                fetchMedForm(setMedicalForm,setProfile, lead[0].lead_id);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
    const handleOpenSearch=()=>{
        setSearchMed(true);
    }
    const saveConditionQuestion = async (answer)=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    condition:currentPendingCondition.search_name,
                    questions:'not needed',
                    answer:answer,
                    form:lead[0].medicalForm,
                    agent:agentId,
                    lead:lead[0].lead_id
                }) 
            };
          
            const response = await fetchWithDefaults('/medicalForm/medicalFormChange', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            //setLead('medicalForm',jsonData.form)
            
            
            setConditions([]);
            setCurrentPendingCondition(false);
            //setInputValues(Array(questions.length).fill(''));
            setPromptQuestion(false);
            await fetchMedForm(setMedicalForm,setProfile, lead[0].lead_id);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeFormMedication= async (med,indexToRemove)=>{
        setMedicalForm(prevMedicalForm => prevMedicalForm.filter((_, index) => index !== indexToRemove));

        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    medication:med,
                }) 
            };
          
            const response = await fetchWithDefaults('/medicalForm/deleteMedications', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeMed=(indexToRemove)=>{
        setUsedMedications(prevState => prevState.filter((_, index) => index !== indexToRemove));
    }
    const addCondition=(condition)=>{
        if (conditions && !conditions.includes(condition)) {
            setPromptQuestion(JSON.parse(condition.question));
            setCurrentPendingCondition(condition);
            setConditions(prevState => [...prevState, condition]);
        }
    }
    useEffect(() => {
        fetchMedForm(setMedicalForm,setProfile, lead[0].lead_id);
    }, []);
    //  ref={innerWrapperRef3}
    return (
        <>
        <div className="quilityNestedBlockContent" style={{border: 'solid 1px #f1f1f1', textAlign:'center', height:'fit-content', paddingBottom:'10px',backgroundColor:'var(--quility-block-header-background-color)'}}>
            <div className="qualifierSection" style={{display:'flex',justifyContent: 'space-around'}}>
                <Medication removeFormMedication={removeFormMedication} medicalForm={medicalForm} removeMed={removeMed} usedMedications={usedMedications} handleOpenSearch={handleOpenSearch}  searchMed={searchMed}/>
                <Condition setProfile={setProfile} handleSetProfile={handleSetProfile} medicalForm={medicalForm} setMedicalForm={setMedicalForm} removeCondition={removeCondition} conditions={conditions} addCondition={addCondition}/>
            </div>
            <MedicationInfo addUsedMedications={addUsedMedications} addCondition={addCondition} medication={'ozempic'}/>
            <ConditionInfo possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} addCondition={addCondition} medication={'ozempic'}/>
        </div>
        {promptQuestion && (
                <div className="conditionQuestionWrapper">
                    <div className="conditionQuestionContent">
                        {promptQuestion.map((question, index) => {
                            const isSelected = isQuestionSelected(question);
                            return (
                                <div
                                    className={`qualifierFieldWrapper ${isSelected ? 'selectedQuestion' : ''}`}
                                    key={`conditionQuestion${index}`}
                                    style={{ width: '100%',cursor:'pointer'}}
                                >
                                    <div
                                        className="qualifierFieldQuestionTitle"
                                        onClick={() => handleAnswer(question)}
                                    >
                                        {question.text}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="buttonWrapper" style={{textAlign:'center'}}>
                            <div className="saveAsQueue solidQuilityDestructiveButton" onClick={()=>setPromptQuestion(false)}>Cancel</div>
                            <div className="saveAsQueue solidQuilityActiveButton" onClick={()=>saveConditionQuestion(currentAnswer)}>Save</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

}

export default FdaBlock;
