import React, { useState } from "react";
import '../../css/tags.css';
import { saveTag } from '../../utilities/tagFunctions';
import { useSelector, useDispatch } from 'react-redux';
import Icon from "../../assets/icons/icon";

function TagComponent({ setSelectedTags, selectedTags, leadId}) {
    const dispatch=useDispatch();
    const tags=useSelector((state) => state.tags.tags);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [inputValue, setInputValue] = useState('');
    const [filteredTags, setFilteredTags] = useState([]);
    
    const handleInputChange = (e) => {
        if(e.nativeEvent.data !== ','){
            const value = e.target.value.toLowerCase();
            setInputValue(value);
            // Filter tags based on input value
            const filtered = tags.filter(tag => tag.name.toLowerCase().includes(value));
            setFilteredTags(filtered.slice(0, 5)); // Limit to first 5 results
        }
    };
   

    const handleTagSelection = (tag) => {
        //const tag={name:tagName, id:tagId};
        // Add the selected tag to the selectedTags array
        saveTag(tag, agentId, leadId, dispatch, false);
        setSelectedTags([...selectedTags, tag]);
        // Clear the input value and filtered tags list
        setInputValue('');
        setFilteredTags([]);
    };
    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            const tagToAdd = inputValue;
            if (tagToAdd !== '') {
                const newTag=await saveTag({name:tagToAdd,id:null}, agentId, leadId, dispatch,true);
                if(newTag){
                    const updatedTag={name:tagToAdd,id:newTag};
                    setSelectedTags([...selectedTags, updatedTag]);
                    setInputValue('');
                    setFilteredTags([]);
                }
                else{
                    alert("Unable to add tag");
                }
            }
        }
    };
  
    return (
        <div className="taggingWrapper">
            <div className="tagIcon" style={{marginTop:'-5px'}}><span className="fontello"><Icon name={'tag-01'} size={20} className="closeIcon" adjust="fontello" /></span></div>
            <div className="tagInputWrapper">
                <input
                    type="text"
                    className="tagInput"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Press 'Enter' or use ',' to save."
                />
                {filteredTags.length > 0 && inputValue !=='' && (
                    <div className="suggestionList">
                        {filteredTags.map(tag => (
                            <div
                                key={tag.id}
                                className="suggestionItem"
                                onClick={() => handleTagSelection(tag)}
                            >
                                {tag.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default TagComponent;

