import React, {useState} from 'react';
import '../../css/login.css';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import Icon from '../../assets/icons/icon';


  
function InfoAlert({information, setInfoAlert}) {
    const closeWindow=()=>{
        setInfoAlert(false)
    }
  
    return (
        <div className="signInWrapper">
            <div className="signInContent">
            
                <div className="loginWrapper">
                    <div className="quilityCloseWindow" onClick={() => closeWindow()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                    <div className="logo"><img src={image} className="imageStyle" alt="Logo" style={{marginTop:'0px'}} /></div>
                    <div className="smallTitle">{information.title}</div>
                    <div style={{margin:'10px'}}>{information.info}</div>
                    <div className="buttonWrapper" style={{marginTop: '20px'}}>
                        <button type="submit" className="loginButton" name="submitSignin" onClick={() => closeWindow()}>Close Window</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoAlert;