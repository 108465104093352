import React, { useState, useEffect } from "react";
import '../../css/elements.css';
import { useSelector } from 'react-redux';
import { getLast12MonthsTimestamps } from '../../utilities/smartSort';
import FilterHolderBlock from "./component/filterHolderBlock";
import TaskLists from "./taskLists";

function FrontPremadeFilterBlock({title, getMyFilter}) {
    const filters=useSelector((state) => state.filters.filters);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const org=useSelector((state) => state.agent.userData).organization;
    const [systemFilters, setSystemFilters] = useState(false);
    const taskLists=
        org === 'master' 
        ? [
            {name:'8 Day', type:'Digital', params:'list', search:"d08"},
            {name:'11+ Day', type:'Digital', params:'list', search:"d11"},
            {name:'3 Day', type:'Analog', params:"list", search:"a08"},
            {name:'4+ Day', type:'Analog', params:'list', search:"a11"},
            {name:'Actively Engaged', type:'Engaged', params:'list', search:"resp"},
            {name:'Policy Pending', type:'Engaged', params:'list', search:"pp"},
            {name:'Policy Lost', type:'Engaged', params:'list', search:"pl"},
            {name:'Clients', type:'Engaged', params:'list', search:"cl"},
        ]
        :
        [
            {name:'10 Day', type:'Digital', params:'list', search:"d10"},
            {name:'14+ Day', type:'Digital', params:'list', search:"d14"},
            {name:'10 Day', type:'Analog', params:"list", search:"a10"},
            {name:'14+ Day', type:'Analog', params:'list', search:"a14"},
            {name:'Actively Engaged', type:'Engaged', params:'list', search:"resp"},
            {name:'Monthly Dials', type:'Engaged', params:'list', search:"1m"},
            {name:'Clients', type:'Engaged', params:'list', search:"cl"}
        ]

    useEffect( () => {
        const dates= getLast12MonthsTimestamps();
        setSystemFilters(dates);
    },[])
    return (
        <div className={`frontPageQuilityParentBlockWrapper`}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">{title}</div>
            </div>
            <div className="quilityParentBlockContent" >
                <TaskLists filters={taskLists} open={true} getMyFilter={getMyFilter} title='Task Lists'/>
                <FilterHolderBlock filters={filters} open={false} getMyFilter={getMyFilter} title='Custom Filters'/>
                <FilterHolderBlock filters={systemFilters} open={false} getMyFilter={getMyFilter} title='System Filters'/>
            </div>
        </div>
    );
}

export default FrontPremadeFilterBlock;





