import React, {useState} from 'react';
import image from '../../assets/icons/QSF.svg';

function MigrationComplete({setCloseModal}) { 

    return (
        <>
            <div className="logoSpacer"><img src={image} className="migrationLogo" alt="Logo" /></div>
            <div className="thankyouWrapper" style={{paddingTop: '0'}}>
                <div className="thankyouIconWrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 36 36" fill="none">
                        <path d="M30 9L13.5 25.5L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="thankyouTextWrapper">
                    <div className="thankyouText">
                        Thank You!
                    </div>
                    <div className="thankyouMessage">
                        We've received your migration request.
                    </div>
                </div>
            </div>
            <div className="activeBottomWrapper" style={{paddingBottom: '150px'}}>
                <div className="activeBottomBlock">
                    <div className="activeBlockInnerContent">
                        <div className="blockInnerContentTitle">Next Steps & Timeline</div>
                        <div className="checkWrapper">
                            <div className="actualCheck">
                                
                                <div className="checkText">
                                    <div className="migrateSpacer">
                                        The migration process will take five to ten minutes to complete, and you will receive an email from the Switchboard® Funnel team when your account is processed. 
                                    </div>
                                    <div className="migrateSpacer"> 
                                        After migration is complete, you can log in to your new Funnel account and unlock all the new tools to maximize your sales success!
                                    </div>
                                    <div className="migrateSpacer">
                                        If you have any questions, our support team is here to help. Reach out to us at <span className="linkStyle">funnelhelp@funnel.quility.com</span>.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="activeBottomBlock">
                    <div className="activeBlockInnerContent">
                        <div className="graphicLineWrapper">
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                            <div className="graphicLine"></div>
                        </div>
                        <div className="graphicLineWrapper2">
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                            <div className="graphicLine2"></div>
                        </div>
                        <div className="yellowSquare2"></div>
                        <div className="blueSquare"></div>
                        <div className="largeBottomIconWrapper2">
                            <div className="largeBottomIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 200 200" fill="none">
                                <rect x="0.000976562" y="-0.000244141" width="200" height="200" rx="100" fill="#46C3B2"/>

                                <path d="M132.432 87.2531L132.432 87.2535C134.174 91.5343 135.055 96.0624 135.055 100.701C135.055 105.34 134.165 109.868 132.432 114.15C130.635 118.586 127.995 122.564 124.584 125.975C123.071 127.488 121.421 128.872 119.679 130.082C119.316 130.334 118.899 130.467 118.487 130.494L118.48 130.48H118.326C117.582 130.48 116.848 130.129 116.389 129.47L116.389 129.47C115.647 128.412 115.913 126.943 116.982 126.2C118.5 125.148 119.933 123.948 121.245 122.636C127.108 116.773 130.338 108.983 130.338 100.692C130.338 92.4101 127.108 84.6108 121.245 78.7483L121.245 78.7483C109.136 66.6504 89.4413 66.6503 77.3414 78.7483C76.0289 80.0607 74.8285 81.5028 73.777 83.0099L73.7761 83.0113C73.0424 84.0783 71.5741 84.3459 70.5056 83.6031L70.5046 83.6024C69.4374 82.8688 69.1698 81.4007 69.9127 80.3325L69.9127 80.3325C71.1227 78.591 72.5073 76.9408 74.0204 75.4279C77.4314 72.0175 81.4101 69.3777 85.8474 67.5811L85.8478 67.5809C90.1292 65.8393 94.658 64.9592 99.2977 64.9592C103.937 64.9592 108.466 65.8484 112.748 67.5811C117.185 69.3777 121.164 72.0175 124.575 75.4279L124.575 75.4282C127.995 78.8385 130.635 82.8166 132.432 87.2531Z" fill="white" stroke="#46C3B2" stroke-width="0.5"/>
                                <path d="M82.4938 91.3511H106.045V91.3603H106.295C106.911 91.3603 107.513 91.6103 107.956 92.0533C108.399 92.4966 108.649 93.0888 108.649 93.7135V117.511C108.649 118.813 107.597 119.864 106.295 119.864C104.993 119.864 103.942 118.813 103.942 117.511V99.9906V99.3871L103.515 99.8138L56.5952 146.726C56.1353 147.186 55.5345 147.419 54.9347 147.419C54.3348 147.419 53.734 147.186 53.2741 146.726C52.3562 145.809 52.3562 144.315 53.2741 143.397L100.194 96.4842L100.621 96.0574H100.017H82.4938C81.1915 96.0574 80.1402 95.0062 80.1402 93.7042C80.1402 92.4023 81.1915 91.3511 82.4938 91.3511Z" fill="white" stroke="#46C3B2" stroke-width="0.5"/>
                                <path d="M62.9122 100.061V100.665V111.668C62.9122 112.97 63.9636 114.021 65.2658 114.021C66.568 114.021 67.6194 112.97 67.6194 111.668V94.3874C67.6194 93.7627 67.3697 93.1705 66.9263 92.7272C66.4833 92.2842 65.8819 92.0342 65.2658 92.0342H47.9733C46.671 92.0342 45.6197 93.0854 45.6197 94.3874C45.6197 95.6893 46.671 96.7405 47.9733 96.7405H58.9784H59.5821L59.1552 97.1673L47.6653 108.655L42.8924 113.428L41.4616 114.858L41.0742 115.246L40.9737 115.346L40.948 115.372L40.9416 115.378L40.94 115.38L40.9396 115.38C40.9395 115.38 40.9394 115.38 40.763 115.204L40.9394 115.38C40.0215 116.298 40.0215 117.792 40.9394 118.71C41.3993 119.17 42.0001 119.403 42.5999 119.403C43.1998 119.403 43.8005 119.17 44.2604 118.71L44.2651 118.715C44.2605 118.71 44.2605 118.71 44.2605 118.71L44.2609 118.709L44.2625 118.708L44.269 118.701L44.2946 118.676L44.3952 118.575L44.7826 118.188L46.2134 116.756C47.4248 115.544 49.1063 113.862 50.9863 111.982C54.7462 108.221 59.3001 103.668 62.4762 100.497L62.9122 100.061ZM62.9122 100.061L62.4854 100.488L62.4764 100.497L62.9122 100.061Z" fill="white" stroke="#46C3B2" stroke-width="0.5"/>
                                <path d="M84.6253 159.059C84.6252 159.059 84.6252 159.059 84.6299 159.064L84.6252 159.059C84.1653 159.519 83.5646 159.752 82.9647 159.752C82.3648 159.752 81.7641 159.519 81.3042 159.059C80.3862 158.141 80.3862 156.647 81.3042 155.729L81.1274 155.553C81.3042 155.729 81.3042 155.729 81.3043 155.729L81.3047 155.729L81.3063 155.727L81.3128 155.721L81.3384 155.695L81.439 155.595L81.8263 155.207L83.2571 153.777L88.0301 149.005L99.52 137.516L99.9468 137.09H99.3432H88.338C87.0358 137.09 85.9844 136.038 85.9844 134.736C85.9844 133.435 87.0358 132.383 88.338 132.383H105.371V132.393H105.621C106.237 132.393 106.839 132.643 107.282 133.086C107.725 133.529 107.975 134.121 107.975 134.746V152.026C107.975 153.328 106.924 154.38 105.621 154.38C104.319 154.38 103.268 153.328 103.268 152.026V141.023V140.42L102.841 140.846C99.665 144.017 95.111 148.57 91.3511 152.331C89.4711 154.211 87.7896 155.893 86.5781 157.105L85.1473 158.537L84.7599 158.924L84.6593 159.025L84.6337 159.05L84.6273 159.057L84.6257 159.059L84.6253 159.059Z" fill="white" stroke="#46C3B2" stroke-width="0.5"/>
                            </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MigrationComplete;
