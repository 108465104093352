import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
    name: 'filters',
    initialState: {
        filters: [],
        activeFilter: ''
    },
    reducers: {
        setFilters(state, action) {
            state.filters = action.payload;
        },
        addFilter(state, action) {
            state.filters.unshift(action.payload);
        },
        setActiveFilterName(state, action) {
            state.activeFilter = action.payload;
        },
        removeFilter(state, action) {
            const idToRemove = action.payload;
            const thing=state.filters.filter(filter => filter.id !== idToRemove);
            state.filters = thing
        }
    },
})
export const { setFilters, setActiveFilterName, addFilter, removeFilter } = filterSlice.actions;

export default filterSlice.reducer

