import { fetchWithDefaults } from '../config/fetchDefault';

const recording= async (callSid, status, agent, lead)=>{
  
    try {
        const response = await fetchWithDefaults(`/twilio/recording?callSid=${callSid}&status=${status}&agent=${agent}&lead=${lead}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData);
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}
const getVoiceDrops= async (agent, setVoiceDrops)=>{
  
    try {
        const response = await fetchWithDefaults(`/appInfo/voicedrop?agent=${agent}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setVoiceDrops(jsonData);
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}
const sendVoiceDrop= async (id, ext , call, agent)=>{
    try {
        const response = await fetchWithDefaults(`/twilio/sendAudio?agent=${agent}&callSid=${call}&recId=${id}&ext=${ext}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}
export {recording,getVoiceDrops, sendVoiceDrop};