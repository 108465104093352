import React, { useState, useRef, useEffect } from 'react';

function TextInputBar({ from, inputValue, handleChange, handleHeight, handleCursorPosition}) {
    const [inputValue2, setInputValue2] = useState('');
    const inputRef = useRef(null);
    const [height, setHeight] = useState(0); 
    const [cursorPosition, setCursorPosition] = useState(0);
    // Function to handle input change
    const handleInputChange = () => {
        const input = inputRef.current.textContent;
        setInputValue2(input);
        handleChange(input);
    };
    useEffect(() => {
        const updateHeight = () => {
            const height = inputRef.current.clientHeight;
            if(from==='bigConvo'){
                handleHeight((height + 50))
            }
            else{
                handleHeight(height)
            }
        };
        const updateCursorPosition = () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const preSelectionRange = range.cloneRange();
                preSelectionRange.selectNodeContents(inputRef.current);
                preSelectionRange.setEnd(range.startContainer, range.startOffset);
                handleCursorPosition(preSelectionRange.toString().length);
            }
        };
        updateHeight(); // Update height when component mounts
        inputRef.current.addEventListener('input', updateHeight); // Update height when content changes
        inputRef.current.addEventListener('input', updateCursorPosition);
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('input', updateHeight); // Cleanup
                inputRef.current.removeEventListener('input', updateCursorPosition);
            }
        };
    }, []);
    return (
        <div
            className="demo" // Changed 'class' to 'className'
            contentEditable="true"
            ref={inputRef}
            dangerouslySetInnerHTML={{ __html: inputValue }}
            suppressContentEditableWarning={true}
            onBlur={handleInputChange} // Added onBlur event to trigger input change
        >

        </div>
    );
}

export default TextInputBar;
