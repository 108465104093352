import React from 'react';
import '../../css/teams.css';
import AppointmentDetail from './detailComponents/appointments';
import CallbackDetail from './detailComponents/callback';
import StatusChange from './detailComponents/statusChange';
import Dials from './detailComponents/dials';
import Applications from './detailComponents/applications';
import Unread from './detailComponents/unread';
import Icon from '../../assets/icons/icon';




function AgentDetail({permission, agentName, agentDetail, smallTeam, enlarge, formatDate, openLead, isMin, maxList, from}) {

    let count=agentDetail[0].scheduled.length + agentDetail[0].scheduledCalls.length + agentDetail[0].statusChange.length + agentDetail[0].dials.length + agentDetail[0].applications.length + agentDetail[0].unreadMessages.length;
    
    return (
        <div className= {`frontPageQuilityParentBlockWrapper ${smallTeam ? '' : 'hideMe'}`} style={{width:'60%',position: 'relative'}}>
            <div className="quilityCloseWindow" onClick={enlarge}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">{agentName} Detail <span className="totalCount">{count} Activities</span></div>
            </div>
            <div className="quilityParentBlockContent">
                <Dials from={from} formatDate={formatDate} details={agentDetail[0].dials}/>
                <AppointmentDetail from={from} formatDate={formatDate} details={agentDetail[0].scheduled} isMin={isMin} maxList={maxList} openLead={openLead}/>
                <CallbackDetail from={from} formatDate={formatDate} details={agentDetail[0].scheduledCalls} isMin={isMin} maxList={maxList} openLead={openLead}/>
                <StatusChange from={from} formatDate={formatDate} details={agentDetail[0].statusChange} isMin={isMin} maxList={maxList} openLead={openLead}/>
                <Applications from={from} formatDate={formatDate} details={agentDetail[0].applications} isMin={isMin} maxList={maxList} openLead={openLead}/>
                <Unread from={from} formatDate={formatDate} details={agentDetail[0].unreadMessages} isMin={isMin} maxList={maxList} openLead={openLead}/>
            </div>
        </div>
    );
}

export default AgentDetail;