import React from 'react';
import { useSelector } from 'react-redux';

function QueueObjections({handleNewObjection}) {
    const objections=useSelector((state) => state.objections.objections);
    return (
        <div className="quilityNestedBlockWrapper " style={{height: '33%'}}>
            <div className="quilityNestedBlockHeader">
                <div className="quilityParentBlockTitle">Objections</div>

            </div>
            <div className="quilityNestedBlockContent" style={{height: '82%',overflow: 'scroll'}}>
                {objections.map((objection, index) => ( 
                    <div value={objection.id} className="objection" key={`objectionId${index}`} onClick={()=>handleNewObjection(objection)}>{objection.name.replace(/\\/g, '')}</div>
                ))}
            </div>
        </div>
    );
    
}

export default QueueObjections;
