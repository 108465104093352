import React, {} from 'react';
import '../../css/leaderBoard.css';
import Icon from '../../assets/icons/icon';



function BoardSlot({data, place}) {

    return (
        <div className="leaderWrapper">
            {place=== 0 || place=== 1 || place=== 2 ? (
                <div className="innerLeaderWrapper">
                    <span className="leaderMedal"><span className={`innerLeaderMedal place${place}`} style={{fontSize:'20px'}}><span className="fontello" style={{marginTop: '4px'}}><Icon name={'q-award-medal'} size={24} height='35' className="" adjust="fontello" /></span></span></span>
                    <span className="leaderAvatar"><span className="innerLeaderAvatar"><span className="fontello" style={{marginTop: '-5px'}}><Icon name={'user-q'} size={24} height='35' className="" adjust="fontello" /></span></span></span>
                    <span className="leaderName">{data.first_name} {data.last_name}</span>
                    <span className="leaderCount">{data.count}</span>
                </div>
            ) : (
                <div className="innerLeaderWrapper">
                    <span className="leaderMedal"><span className="innerLeaderMedal">{(place+1)}</span></span>
                    <span className="leaderAvatar"><span className="innerLeaderAvatar"><span className="fontello" style={{marginTop: '-5px'}}><Icon name={'user-q'} size={24} height='35' className="" adjust="fontello" /></span></span></span>
                    <span className="leaderName">{data.first_name} {data.last_name}</span>
                    <span className="leaderCount">{(data.count)}</span>
                </div>
            )}
        </div>
    );
}

export default BoardSlot;
