const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:3333' : `https://${window.location.hostname}:3333`;
//const csrfToken = localStorage.getItem('keepMeAlive');



export const fetchWithDefaults = async (url, options = {}) => {
  const csrfToken = await localStorage.getItem('keepMeAlive');
  let defaultHeaders = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken, 
  };
  const headers = { ...defaultHeaders, ...options.headers };
  const mergedOptions = { ...options, headers };

  // Call the function to continuously update X-CSRF-Token header
  updateCSRFToken(defaultHeaders, csrfToken);

  return fetch(`${apiUrl}${url}`, mergedOptions);
};



function updateCSRFToken(headers, csrfToken) {
  headers['X-CSRF-Token'] = csrfToken;

  if (headers['X-CSRF-Token'] !== null) {

  } 
  else {
      setTimeout(() => updateCSRFToken(headers), 500);
  }
}
