import React from "react";
import '../../../css/frontPage.css';
import Icon from "../../../assets/icons/icon";

function List({filter, getMyFilter, index}) {
    return (
        <div className="quilityListWrapper" key={`filterOption${index}`}>
            <div className="quilityListType" style={{flex: '2'}}>{filter.type}</div>
            <div className="quilityListTitle" style={{flex: '2'}}>{filter.name}</div>
            <div className="listButtonHolder" style={{marginTop: '5px',marginLeft: '-5px'}}>
                <div className="listIcon selectedIcon" onClick={()=>getMyFilter(filter.params,true,filter.name, filter)}><Icon name='q-phone-call-outgoing'  height='34' adjustSize='15'/></div>
                <div className="listIcon" onClick={()=>getMyFilter(filter.params,false,filter.name, filter)}><Icon name='expand-q' adjustSize='15' height='34'/></div>
            </div>
        </div> 
    );
}

export default List;
