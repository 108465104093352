import React, {useState} from 'react';
import '../../css/teams.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import Icon from '../../assets/icons/icon';
import { searchAgents } from '../../utilities/teamFunctions';


function AddTeam({handleAddTeam, setRefreshTeams, refreshTeams}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const agentInfo=useSelector((state) => state.agent.userData);
    const [agentSearch, setAgentSearch] = useState('');
    const [goalType, setGoalType] = useState([]);
    const [myGoals, setMyGoals] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [teamObjective, setTeamObjective] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [invitees, setInvitees] = useState([]);
    

    const [warning, setWarning] = useState(false);
    const cancel = () => {
        setWarning(false);
    };
    const handleAgentSearch = (event) => {
        setAgentSearch(event.target.value);
    };
    const inviteMember = (id,index) => {
        setWarning(id);
    };
    /*const searchAgents = async () => {
        try {
            const response = await fetchWithDefaults(`/agents/search?search=${agentSearch}&agent=${agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            if(jsonData){
                setSearchResults(jsonData)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };*/
    const handleAddGoal=(goal)=>{
        setGoalType((prevGoalTypes) => {
            // Check if the newGoalType exists in the array
            console.log(prevGoalTypes);
            if (prevGoalTypes.includes(goal)) {
              // If it exists, remove it
                return prevGoalTypes.filter((goals) => goals !== goal);
            } else {
              // If it does not exist, add it
                return [...prevGoalTypes, goal];
            }
        });
        const myGoal={
            name: goal,
            response: ''
        }
        setMyGoals((prevMyGoals) => {
            // Check if the goal with the same name already exists
            const exists = prevMyGoals.some(goals => goals.name === myGoal.name);
      
            if (exists) {
              // If it exists, remove it
              return prevMyGoals.filter(goals => goals.name !== myGoal.name);
            } else {
              // If it does not exist, add it
              return [...prevMyGoals, myGoal];
            }
        });
    }
    const updateGoalResponse=(name, value)=>{
        setMyGoals((prevGoalTypes) =>
            prevGoalTypes.map((goal) =>
              goal.name === name ? { ...goal, response: value } : goal
            )
        );
    }
    const save= async ()=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent:agentId,
                    myGoals:myGoals,
                    goals:goalType,
                    name:teamName,
                    objective:teamObjective,
                    invitees:invitees,
                }) 
            };
          
            const response = await fetchWithDefaults('/team/create', requestOptions);
            
            if (!response.ok) {
                return false;
            }
            const jsonData = await response.json();
            handleAddTeam();
            setRefreshTeams(!refreshTeams);
            
            if(jsonData){
                //setResults(jsonData);
                
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
            return false;
        }
    }
    const handleTeamName=(name)=>{
        setTeamName(name);
    }
    const handleTeamObjective=(objective)=>{
        setTeamObjective(objective);
    }
    const addToInvite= (invitee)=>{

        setInvitees((prevInvitee) => {
            // Check if the goal with the same name already exists
            const exists = prevInvitee.some(invitees => invitees.id === invitee.id);
      
            if (exists) {
              // If it exists, remove it
              return prevInvitee.filter(invitees => invitees.id !== invitee.id);
            } else {
                console.log(invitee)
              // If it does not exist, add it
              return [...prevInvitee, invitee];
            }
        });
    }
    return (
        <div className="teamModule">
            <div className="quilityCloseWindow" onClick={()=>handleAddTeam()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            <div className="title">Create a Team</div>
           
            <div className="teamInnerWrapper">
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Team Name</div>
                    <input 
                        type="text" 
                        class="addTeamFieldInput"
                        value={teamName} 
                        onChange={(e)=>handleTeamName(e.target.value)}
                    />
                </div>
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Team Objective</div>
                    <input 
                        type="text" 
                        class="addTeamFieldInput"
                        value={teamObjective} 
                        onChange={(e)=>handleTeamObjective(e.target.value)}
                    />
                </div>
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Add Required Goals (optional)</div>
                    <div className="goalsWrapper">
                        <div className={`goalOption ${goalType.includes('Dials') ? 'selectedOption':''}`} onClick={()=>handleAddGoal('Dials')}>Dials</div>
                        <div className={`goalOption ${goalType.includes('Contacts') ? 'selectedOption':''}`} onClick={()=>handleAddGoal('Contacts')}>Contacts</div>
                        <div className={`goalOption ${goalType.includes('Appointments') ? 'selectedOption':''}`} onClick={()=>handleAddGoal('Appointments')}>Appointments</div>
                        <div className={`goalOption ${goalType.includes('APV') ? 'selectedOption':''}`} onClick={()=>handleAddGoal('APV')}>APV</div>
                    </div>
                </div>
                {myGoals.length > 0 && (
                    <div classname="teamFieldWrapper">
                        <div className="teamFieldTitle">Enter your goals</div>
                        <div className="goalsWrapper">
                            {myGoals.map((goal, index) => (
                                <div className="goalInputWrapper">
                                    <div className="goalInputTitle">{goal.name}</div>
                                    <input 
                                        type="text" 
                                        className="addTeamFieldInput smallGoal" 
                                        value={goal.response}
                                        onChange={(e) => updateGoalResponse(goal.name, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Add Agents (You can always do this later)</div>
                    <div style={{width:'70%',margin:'auto',position:'relative'}}>
                        <input 
                            type="text" 
                            class="addTeamFieldInput" 
                            placeholder="Search for Agents to Add"
                            value={agentSearch} 
                            onChange={handleAgentSearch}
                            style={{maxWidth: 'unset',width: '98%'}}
                        /> 
                        <span className="teamSearch" onClick={()=>searchAgents(agentSearch, agentId, setSearchResults, agentInfo.organization)}>
                        <span className={`fontello`} style={{marginTop: '-7px'}}><Icon name={'q-search'} size={20} className={`selectBoxIcon`} adjust="fontello" /></span>
                        </span>
                    </div>
                    
                    <div className="resultsBox">
                        {searchResults && (
                            searchResults.map((result, index) => (
                                <div className={`teamResult ${invitees.find(invitee => invitee.id === result.id) ? 'hideMe' : ''}`} onClick={()=>addToInvite(result)}>
                                    {result.first_name} {result.last_name}
                                </div>
                            ))
                        )}
                    </div>

                    
                </div>
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle">Invited Agents</div>
                    {invitees.length > 0 && (
                        invitees.map((invitee, index) => (
                            <div className="invitee">{invitee.first_name} {invitee.last_name} <span className="removeInvitee" onClick={()=>addToInvite(invitee)}>X</span></div>
                        ))
                    )}
                </div>
                <div className="buttonWrapper">
                    <div className="accentButton solidQuilityActiveButton" onClick={()=>save()} style={{margin: 'auto', fontSize:'17px',padding:' 3px 20px' }}>
                        Create Team
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTeam;