import React, { useState, useEffect } from "react";
import '../../css/elements.css';
import Icon from "../../assets/icons/icon";
import { useSelector } from 'react-redux';


function FilterButton({ activeFilterName, dropdownOpen, setDropdownOpen, handleDisplayClick, handleOptionClick, title, type, values, search, id}) {
    const selectedOptions = useSelector(state => state.filterSearch.selectedOptions);
    //const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = (id) => {
        setDropdownOpen((prevState) => ({
            filteringOptions0: id === 'filteringOptions0' ? !prevState.filteringOptions0 : false,
            filteringOptions1: id === 'filteringOptions1' ? !prevState.filteringOptions1 : false,
            filteringOptions2: id === 'filteringOptions2' ? !prevState.filteringOptions2 : false,
            filteringOptions3: id === 'filteringOptions3' ? !prevState.filteringOptions3 : false,
        }));
    };
    return (
        
        <span className="filterButtonWrapper">
            <div className={`filterButton ${type==='solid' ? 'solidButton':'outlineButton' }`}>
                <div className="filterButtonSelectedItem" onClick={()=>toggleDropdown(id)}>
                    <span className="filterButtonTitle">{title}</span>
                    <span className={`selectBoxIconWrapper ${dropdownOpen.id ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="" adjust="fontello" /></span></span>

                </div>
                {dropdownOpen[id] && (
                    <div className="filterOptionWrapper">
                        {values.values[0].map((option, index) => {
                            console.log(title, option)
                            const isChecked = selectedOptions.some(item => {
                                if(values.search===item.option){
                                    return (item.name || item.state || item.lead_type || item.search) === (option.states || option.name || option.lead_type || option.id);
                                }
                            });
                            return (
                                <div
                                    key={`filterButton${index}`}
                                    className={`multiSelectOption ${isChecked ? 'chosen' : ''}`}
                                    onClick={() => (title==='Lead Tag' || title==='Lead Status') ? handleDisplayClick(option.id, search, option.name) : handleOptionClick(option.id || option.name || option.states || option.lead_type, search)}
                                >
                                    <span className="QuilityCheckBoxWrapper" style={{verticalAlign: 'sub'}}>
                                        <input 
                                            type="checkbox" 
                                            className="quilityCheck" 
                                            onChange={(e)=> {}} 
                                            checked={isChecked}
                                        />
                                    </span> 
                                    {option.name || option.states || option.lead_type || option.id}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div> 
        </span>
    );
}

export default FilterButton;
