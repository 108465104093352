import React, { useState, useRef,useEffect } from "react";
import '../../css/elements.css';
import QuilityNestedBlock from "./quilityNestedBlock";
import { fetchWithDefaults } from '../../config/fetchDefault';
import LeadsQueue from "../leads/leadsQueue";
import FilteringOptions from "../dashboard/frontPageOptions/filteringOptions";
import { SuccessAlert } from '../successAlert';
import BulkActionFooter from "./bulkActionsFooter";
import { useSelector, useDispatch } from 'react-redux';
import { setQueue, setBackup, setWholeQueue } from '../../features/queueSlice';
import { isWithin24Hours } from "../../utilities/timeFunctions";
import { clearSearch, clearSelectedOption } from "../../features/leadFilterSlice";


function QuilityParentBlock({jumpIntoQueue, setLeadCard, textingNumbers, unixTommddyyyy, filteredOptions, setRefreshQueue, formatDate, handleToggle,isToggled, filterOpen, data, bulkOpen, title, nested, addToQueue, setMyQueue, setHoldingQueue, holdingQueue, myQueue, openLead }) {
    const dispatch=useDispatch();
    const queue=useSelector((state) => state.queue.queue);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const activeFilterName=useSelector((state) => state.filters.activeFilter);
    const [isOpen, setIsOpen] = useState(false);
    const [successTrigger, setSuccessTrigger] = useState(false);
    const [pastLeadsPeriod, setPastLeadsPeriod] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [removeOptions, setRemoveOptions] = useState(false);
    const [selectedLeads, setSelectedLeads] = useState([]);
    const innerWrapperRef = useRef(null);
 
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    
    const renderInner=(title, index)=>{

        return(<QuilityNestedBlock openLead={openLead} unixTommddyyyy={unixTommddyyyy} handleToggle={handleToggle} isToggled={isToggled} addToQueue={addToQueue} title={title} key={`quilNestedBlock`+index+title} index={index} data={data}/>)
    }
    const handleExpand=()=>{
        setExpanded(!expanded);
    }
    useEffect( () => {
        
    },[])
    const frontBlock=['My Leads','My Business At a Glance'];
    const grabMoreLeads= async (e)=>{
        const timePeriod=e.target.value;
        setPastLeadsPeriod(timePeriod);
        try {
            const response = await fetchWithDefaults(`/queue/daysPast?days=${timePeriod}&agent=${agentId}`);
                if (!response.ok) {

                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                dispatch(setQueue(jsonData));
                dispatch(setBackup(jsonData));
                dispatch(setWholeQueue(jsonData));
                dispatch(clearSearch());
                dispatch(clearSelectedOption([]))
          } catch (error) {
              console.error('Error fetching data:', error);
          }
    }
    const filterRecent=()=>{
        const filteredLeads = queue.filter((lead) => !isWithin24Hours(lead.last_contacted));
        dispatch(setQueue(filteredLeads));
    }
    return (
        <span className={`${frontBlock.includes(title) ? 'frontPageQuilityParentBlockWrapper' : 'frontPageQuilityParentBlockWrapper'} ${expanded ? 'expanded' : ''}`} style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : 'calc(100% - 50px)'}} >
            <SuccessAlert message={'Bulk Actions Applied'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle" style={{fontSize: '18px'}}>{title} { title==='My Leads' && (<span className="leadCount">{myQueue.length} leads</span>)}</div>

                {title==='My Queue' ? <span className="quilityBlockIcon" onClick={(e) => {e.stopPropagation();handleExpand();}}></span> : ''}
            </div>
            <div className="quilityParentBlockContent"  ref={innerWrapperRef}>
                {nested.length > 0 && (
                    nested.map((item, index) => (
                        renderInner(item,index)
                    ))
                )}
                {title==='My Leads' && (
                    <span style={{marginTop:'20px'}}>  
                        <div className="filterName">
                            {activeFilterName==='Last 90 Days' ? (
                                <>
                                    <div>Leads From</div> 
                                    <select 
                                        value={pastLeadsPeriod} 
                                        className="outlineButton filterButton" 
                                        style={{width:'50%'}} 
                                        onChange={(e)=>grabMoreLeads(e)}
                                    >
                                        <option value="90">Last 90 Days</option>
                                        <option value="180">Last 6 Months</option>
                                        <option value="270">Last 9 Months</option>
                                        <option value="365">Last 1 Year</option>
                                        <option value="730">Last 2 Years</option>
                                        <option value="1095">Last 3 Years</option>
                                    </select>
                                </>
                            ) : (
                                activeFilterName
                            )}
                        </div>
                        <FilteringOptions activeFilterName={activeFilterName} jumpIntoQueue={jumpIntoQueue} filteredOptions={filteredOptions} myQueue={queue} setMyQueue={setMyQueue} removeOptions={removeOptions} setHoldingQueue={setHoldingQueue} filterOpen={filterOpen}/>
                        {activeFilterName==='Last 90 Days' && (
                            <div className="filterOutWrapper">
                                <div className="jumpIntoButton solidQuilityActiveButton" onClick={()=>filterRecent()}>Filter Recently Called</div>
                            </div>
                        )}
                        <div className="filterOuterWrapper">
                            <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} setSelectedLeads={setSelectedLeads} selectedLeads={selectedLeads} bulkOpen={bulkOpen} expanded={expanded} setMyQueue={setMyQueue} data={myQueue} title='My Queue' openLead={openLead} />
                        </div>
                        <div style={{margin:'15px'}}>
                            <BulkActionFooter setRefreshQueue={setRefreshQueue} jumpIntoQueue={jumpIntoQueue} textingNumbers={textingNumbers} selectedLeads={selectedLeads} size="small"/>
                        </div>
                    </span>
                )}
            </div>
        </span>
    );
}

export default QuilityParentBlock;

