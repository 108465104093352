import React, { useState } from "react";
import '../../css/templates.css';
import { fetchWithDefaults } from '../../config/fetchDefault';


function AddTag({ addATemplate, type, agentId, setNewTemplate }) {

    const [templateName, setTemplateName] = useState('');
    const setAddTemplateName= (content)=>{
        setTemplateName(content)
    }
    
   
    const addNewTemplate= async (id)=>{
        let error=false;
       
        if(error==false){
            try {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ 
                        agent: agentId,
                        name: templateName,
                    }) 
                };
              
                const response = await fetchWithDefaults(`/addNew${type}`, requestOptions);
        
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                setNewTemplate({id:jsonData.id, name:templateName})
                addATemplate();
            } 
            catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }
    return (
        <div className="mainBlockWrapper" style={{ width: '50%'}}>
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Add a New {type}</div>
            </div>
            <span className="closeWindow" onClick={()=>addATemplate()}>X</span>
            <div className="mainBlockContent roundedBottom">
                <div className="bigBlock focusBigBlock" style={{marginTop: '25px'}}>
                    <div className="leadCardSubTitle"></div>
                    <div className="standardInputWrapper">
                        <div className="standardInputTitle">Name The {type}</div>
                        
                        <input 
                            className="standardInputBox largeInput" 
                            type="text"
                            value={templateName}
                            onChange={(e) => setAddTemplateName(e.target.value)}
                        />
                    </div>
                    
                </div>
                <div className="buttonWrapper"><div className="accentButton" style={{margin:'auto'}} onClick={()=>addNewTemplate()}>Save {type}</div></div>
            </div>
        </div> 
    );
}

export default AddTag;

