import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {cancelGoogleEvent, convertToTimezone} from '../../../../utilities/calendar'
import { fetchWithDefaults } from '../../../../config/fetchDefault';

import Icon from '../../../../assets/icons/icon';

function EditEvent({setEvent,event,setUpdate}) {
    let incomingEvent=[];
    incomingEvent=event[0];
    const calendarBeingUsed=useSelector((state) => state.agent.activeCalendar);
    const [wholeEvent, setWholeEvent] = useState(false);
    const [altered, setAltered] = useState(false);
    const [timeAdjust, setTimeAdjust] = useState(false);
    const [duration, setDuration] = useState(60);
    const agentInfo=useSelector((state) => state.agent.userData);
    const agentId = agentInfo.agentId;
    const teamId = agentInfo.teamId;
    const agent=useSelector((state) => state.agent);


    const handleInputChange = (fieldName, value) => {
        if(fieldName==='start'){
            setWholeEvent(prevEvent => ({
                ...prevEvent,
                start:{
                    dateTime: value,
                    timeZone: wholeEvent.start.timeZone
                  } // Use computed property name to update the specific field
            }));
        }
        else{
            setWholeEvent(prevEvent => ({
                ...prevEvent,
                [fieldName]: value // Use computed property name to update the specific field
            }));
        }
    };
    
    const handleParticipantEmailChange = (index, value) => {
        const updatedParticipants = [...wholeEvent.participant]; // Copy the array of participants
        updatedParticipants[index] = {
            ...updatedParticipants[index],
            email: value // Update the email of the specific participant
        };
        setWholeEvent(prevEvent => ({
            ...prevEvent,
            participant: updatedParticipants // Update the participants array in the wholeEvent object
        }));
    };
    const handleRemoveParticipant = (index) => {
        const updatedParticipants = wholeEvent.participant.filter((_, i) => i !== index);
        setWholeEvent(prevEvent => ({
            ...prevEvent,
            participant: updatedParticipants
        }));
    };

    function splitDateTime(dateTimeString) {
        const [datePart, timePart] = dateTimeString.split('T');
        return {
            date: datePart,
            time: timePart ? timePart.split(/[-+]/)[0] : ''
            //const time = timePart ? timePart.split(/[-+]/)[0] : ''
        };
    }
    const generateTimeSlots = () => {
        const timeSlots = [];
        const startTime = new Date();
        startTime.setHours(1, 0, 0, 0); // Set to midnight
        const endTime = new Date();
        endTime.setHours(23, 45, 0, 0); // Set to 11:45 PM

        const interval = 15 * 60 * 1000; // 15 minutes in milliseconds

        let currentTime = new Date(startTime);
        while (currentTime <= endTime) {
        timeSlots.push(currentTime);
        currentTime = new Date(currentTime.getTime() + interval);
        }

        return timeSlots;
    };
    const formatTime = (time) => {
        const hours = time.getHours();
        const minutes = time.getMinutes();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert 0 to 12
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
    };
    function convertTo24HourTime(dateString) {
        const date = new Date(dateString);
        const formattedTime = date.toLocaleTimeString('en-US', {hour12: false});
        return formattedTime;
    }
    const handleCancel=()=>{
        setEvent(false);
    }
    function getMinutesBetween(dateTime1, dateTime2) {
        const date1 = new Date(dateTime1);
        const date2 = new Date(dateTime2);
        
        const differenceInMilliseconds = date2 - date1;
        const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
        
        return differenceInMinutes;
    }
    const cancelEvent= ()=>{
        const eventId=wholeEvent.id;
        const calendarId=agent.activeCalendar;
        const agentId=agent.userData.agentId;
        cancelGoogleEvent(eventId,calendarId,agentId,handleCancel,setUpdate);
        
    }
    const updateEvent= async (event,eventId)=>{
        
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: teamId,
                    calendarId:calendarBeingUsed,
                    eventId:eventId,
                    event:event,
                    duration:duration
                }),
            };
            const response = await fetchWithDefaults('/google/edit/event', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setEvent(false);
            setUpdate(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        setAltered(true)
        if(altered){
            setDuration(getMinutesBetween(incomingEvent.start.dateTime, incomingEvent.end.dateTime));
            const newStart=convertToTimezone(incomingEvent.start.dateTime,incomingEvent.start.timeZone)
            const newEnd=convertToTimezone(incomingEvent.end.dateTime,incomingEvent.start.timeZone)
            incomingEvent.start.dateTime=newStart;
            incomingEvent.end.dateTime=newEnd;
            setWholeEvent(incomingEvent)
            //
        }

    }, [altered]);
    if(wholeEvent){
        console.log(splitDateTime(wholeEvent.start.dateTime).time);
    }
    return (
        wholeEvent && (
        <div className="eventWindow">
            <div className="cancelEvent" onClick={()=>cancelEvent()}>Cancel Event!</div>
            <div className="quilityCloseWindow" style={{top: '5px'}}><span className="fontello" style={{marginTop: '-7px'}} onClick={()=>handleCancel()}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Meeting Title</div>
                <input
                    type="text"
                    className="eventInput"
                    value={wholeEvent.summary}
                    onChange={e => handleInputChange("summary", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Timezone</div>
                <input
                    type="text"
                    className="eventInput"
                    value={wholeEvent.start.timeZone}
                    onChange={e => handleInputChange("start", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Location</div>
                <input
                    type="text"
                    className="eventInput"
                    value={wholeEvent.location}
                    onChange={e => handleInputChange("location", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Participants</div>
                {wholeEvent.attendees && wholeEvent.attendees.map((person, index) => (
                    <div className="participantWrapper">

                        <span className="fontello" style={{marginTop: '-7px',lineHeight: '27px',paddingRight: '10px', cursor:'pointer'}} onClick={()=>handleRemoveParticipant(index)}><Icon name={'x-circle-q'} size={28} className="closeIcon oulineQuilityDestructiveButton" adjust="fontello" /></span>
                        <input
                            type="text"
                            className="eventInput"
                            value={person.email}
                            onChange={e => handleParticipantEmailChange(index, e.target.value)}
                        />
                    </div>
                ))}
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Meeting Comments</div>
                <textarea
                    type="text"
                    className="eventInput eventTextarea"
                    value={wholeEvent.description}
                    onChange={e => handleInputChange("description", e.target.value)}
                />
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Meeting Date & Time</div>
                <input
                    type="date"
                    className="eventInput halfSize"
                    value={splitDateTime(wholeEvent.start.dateTime).date}
                    onChange={e => handleInputChange("start", e.target.value+'T'+splitDateTime(wholeEvent.start.dateTime).time)}
                    style={{width:'30%'}}
                />
                <span className="spacer">
                    @ 
                </span>
                <select className="timeSelect" 
                    //onChange={(e)=>setChangeTime(e)}
                    onChange={e => handleInputChange("start",splitDateTime(wholeEvent.start.dateTime).date+'T'+e.target.value)}
                >
                    {generateTimeSlots().map((time, index) => {
                        const selected = convertTo24HourTime(time) === splitDateTime(wholeEvent.start.dateTime).time;
                        return (
                            <option key={index} value={convertTo24HourTime(time)} selected={selected}>
                                {formatTime(new Date(time))}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="eventItemWrapper">
                <div className="editEventItemTitle">Duration</div>
                <input
                    type="text"
                    className="eventInput"
                    value={duration}
                    onChange={e => setDuration(e.target.value)}
                    style={{width:'75px'}}
                />
                <span className="spacer" style={{padding: '5px 10px'}}>Minutes</span>
            </div>
            <div className="buttonWrapper" style={{textAlign: 'center', padding:'40px'}}>
                <div className="standardButton cancelButton" onClick={()=>handleCancel()}>Cancel</div>
                <div className="standardButton saveButton" onClick={()=>updateEvent(wholeEvent,event.id)}>Save</div>
            </div>
        </div>
        )
    );
    
}

export default EditEvent;
