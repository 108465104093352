import { useDrag } from 'react-dnd';

// Draggable Element
const DraggableElement = ({ id, name, type }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ELEMENT',
    item: { id, name, type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className='workflowBuilderSelectionItem'
    >
      {name}
    </div>
  );
};

export default DraggableElement;