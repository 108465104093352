

import React from 'react';
import '../../css/login.css';
import Icon from '../../assets/icons/icon';

function CallConsent({handleOpenConsent, handleConsentChange}) {
    return (
        <div className='consentWrapper'>
            <div className="consentContent">
                <div className="quilityCloseWindow" onClick={() => handleOpenConsent()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                    <h2>Consent Terms and Conditions</h2>
                    <h3>Acknowledgment of Call Recording Laws</h3>
                    <p>By using our services, you, the user, acknowledge and agree to the following terms regarding the recording of phone calls:</p>

                    <h4>1. Awareness of Recording Laws</h4>
                    <p>You understand that call recording laws vary by state within the United States. Some states require only one party to consent to the recording of a conversation, while others require that all parties involved provide their consent.</p>

                    <h4>2. Two-Party Consent States</h4>
                    <p>If you are making calls to or from a state that requires two-party consent, you agree to inform all parties involved in the call that the conversation will be recorded. You acknowledge your responsibility to obtain explicit consent from all parties before proceeding with the recording.</p>

                    <h4>3. One-Party Consent States</h4>
                    <p>If you are making calls to or from a state that follows one-party consent laws, you agree to inform the other party that the call may be recorded. While the law may not require you to notify the other party, you acknowledge that transparency is encouraged and that informing the other party is considered good practice.</p>

                    <h4>4. Legal Compliance</h4>
                    <p>You are solely responsible for understanding and complying with the call recording laws applicable to the state in which you and the other party are located. Failure to adhere to these laws may result in legal consequences, for which you bear full responsibility.</p>

                    <h4>5. Informing Parties</h4>
                    <p>You agree to provide clear and concise notification to the other party before the recording begins. This notification should include information that the call will be recorded and an opportunity for the other party to provide or withhold their consent.</p>

                    <h4>6. Liability</h4>
                    <p>Our company is not liable for any legal repercussions resulting from the user's failure to comply with the applicable call recording laws. The user assumes full responsibility for ensuring that all necessary consents are obtained and that the recording is conducted lawfully.</p>

                    <h3>Resources on Call Recording Laws</h3>
                    <p>For more information on call recording laws and best practices, you may refer to the following resources:</p>
                    <ul>
                        <li><a href="http://www.dmlp.org/legal-guide/state-law-recording" target="_blank">Digital Media Law Project: State Law: Recording</a></li>
                        <li><a href="https://www.justia.com/50-state-surveys/recording-phone-calls-and-conversations/" target="_blank">Justia: State Recording Laws</a></li>
                        <li><a href="https://www.nolo.com/legal-encyclopedia/is-it-legal-record-a-conversation.html" target="_blank">Nolo: Is it Legal to Record a Conversation?</a></li>
                    </ul>

                <div className="buttonWrapper" style={{textAlign:'center'}}>
                    <div className="standardButton solidQuilityDestructiveButton" style={{display: 'inline-block',margin:'0px 5px'}} onClick={()=>handleOpenConsent()}>I disagree</div>
                    <div className="standardButton solidQuilityActiveButton" style={{display: 'inline-block',margin:'0px 5px'}} onClick={()=>handleConsentChange('recording_consent',1)}>I consent</div>
                </div>
            </div>
        </div>
    );
}

export default CallConsent;