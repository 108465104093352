import React, { useState, useEffect} from 'react';
import image1 from './assets/ads/quility_1.png';
import image2 from './assets/ads/quility_2.png';
import image10 from './assets/ads/quility_10.png';
import image11 from './assets/icons/qsblogo.png';

function AdPreview({newMessages}) {
    
    return (
        <>
            <div className="title">This is where an OPT in for texting is made from a facebook ad. Scroll down for the ad in facebook</div>
            <div className="logo"><img src={image10} className="imageStyle" alt="Logo" /></div>
            <div className="logo"><img src={image1} className="imageStyle" alt="Logo" /></div>
            <div className="logo"><img src={image2} className="imageStyle" alt="Logo" /></div>
            <div className="logo"><img src={image11} className="imageStyle" alt="Logo" /></div>
        </>
    )
}

 

export default AdPreview;