import React, { useState } from "react";

function EinProfileForm({submitCustomerProfile}) {
    const [formData, setFormData] = useState({
        friendlyName: '',
        email: '',
        city: '',
        street: '',
        state: '',
        zip: '',
        businessName: '',
        businessType: 'Limited Liability Corporation',
        businessRegistrationNumber: '',
        website: '',
        social: '',
        jobPos: 'Director',
        repFirstName: '',
        repLastName: '',
        repPhone: '',
        repEmail: '',
        repTitle: ''
    });

    const handleChange = (variable, value) => {
        setFormData(prevState => ({
            ...prevState,
            [variable]: value
        }));
    };

    const handleSubmit = () => {
        let isValid = true;
        const emptyFields = [];

        // Check if any input field is empty
        Object.entries(formData).forEach(([key, value]) => {
            if (!value) {
                emptyFields.push(key);
                isValid = false;
            }
        });

        // If any field is empty, display warnings
        if (!isValid) {
            alert(`Please fill in the following fields: ${emptyFields.join(', ')}`);
            // You can also update the UI to display warnings next to the empty fields
            return;
        }

        // If all fields are filled, proceed with form submission
        submitCustomerProfile(formData,'ein');
    };
    const regTypes=['Limited Liability Corporation','Sole Proprietorship','Partnership','Corporation','Co-operative','Limited Liability Partnership','Non-profit'];
    const jobPositions=['Director' , 'GM' , 'VP' , 'CEO' , 'CFO' , 'General Counsel']
    const generalQuestions = [
        { title: 'Your Name', variable: 'friendlyName' },
        { title: 'Business Email', variable: 'email' },
        { title: 'Business Address', variable: 'street' },
        { title: 'Business City', variable: 'city' },
        { title: 'Business State', variable: 'state' },
        { title: 'Business Zip Code', variable: 'zip' }
    ];

    const businessQuestions = [
        { title: 'Business Name', variable: 'businessName', clarification: 'Must be identical to formation paper. e.g. Ninja Technologies, LLC vs Ninja Technologies' },
        { title: 'Business Type', variable: 'businessType', clarification: '' },
        { title: 'EIN Number', variable: 'businessRegistrationNumber', clarification: 'Must be 9 digits in this format 12-3456789' },
        { title: 'Business Website', variable: 'website', clarification: 'full url. Needs to be your website that is registered in your name. Not your parent companies' },
        { title: 'Social Media Link', variable: 'social', clarification: '' },
    ];

    const repInfo = [
        { title: 'Job Position', variable: 'jobPos' },
        { title: 'First Name', variable: 'repFirstName'},
        { title: 'Last Name', variable: 'repLastName'},
        { title: 'Phone Number', variable: 'repPhone' },
        { title: 'Email', variable: 'repEmail' },
        { title: 'Business Title', variable: 'repTitle'},
    ];

    return (
        <div className="quilityFormWrapper">
            <div className="trustFormWrapper">
                <div className="trustFormTitle">Business Address <span className="greyInfo">All information in this section needs to be exactly what is on formation papers</span></div>
                {generalQuestions.map((question, index) => (
                    <div className="qualifierFieldWrapper" key={index}>
                        <div className="qualifierFieldTitle" style={{ width: '35%', textAlign: 'left' }}>{question.title}</div>
                        <input type="text" className="qualifierFieldInput" onChange={(e) => handleChange(question.variable, e.target.value)} />
                    </div>
                ))}
            </div>
            <div className="trustFormWrapper">
                <div className="trustFormTitle">Business Registration Information</div>
                {businessQuestions.map((question, index) => (
                    <div className="qualifierFieldWrapper" key={index}>
                        <span className="clarification">{question.clarification}</span>
                        <div className="qualifierFieldTitle" style={{ width: '35%', textAlign: 'left' }}>{question.title}</div>
                        {question.variable==='businessType' ? ( 
                            <select 
                                className="qualifierFieldInput" 
                                style={{height: '32px'}}
                                onChange={(e) => handleChange(question.variable, e.target.value)} 
                            >
                                {regTypes.map((type, index) => (
                                    <option key={`regType${index}`} value={type}>{type}</option>
                                ))}
                            </select>
                        ) : (
                            <input type="text" className="qualifierFieldInput" onChange={(e) => handleChange(question.variable, e.target.value)} />
                        )}
                    </div>
                ))}
            </div>
            <div className="trustFormWrapper">
                <div className="trustFormTitle">Authorized Representative <span className="greyInfo">An authorized representative of your company. This can be you</span></div>
                {repInfo.map((question, index) => (
                    <div className="qualifierFieldWrapper" key={index}>
                        <div className="qualifierFieldTitle" style={{ width: '35%', textAlign: 'left' }}>{question.title}</div>
                        {question.variable==='jobPos' ? ( 
                            <select 
                                className="qualifierFieldInput" 
                                style={{height: '32px'}}
                                onChange={(e) => handleChange(question.variable, e.target.value)} 
                            >
                                {jobPositions.map((type, index) => (
                                    <option key={`repType${index}`} value={type}>{type}</option>
                                ))}
                            </select>
                        ) : (
                            <input type="text" className="qualifierFieldInput" onChange={(e) => handleChange(question.variable, e.target.value)} />
                        )}
                        
                    </div>
                ))}
            </div>
            <div style={{textAlign:'center'}}><button onClick={handleSubmit} className="solidQuilityActiveButton formProfileSubmitButton">Submit</button></div>
        </div>
    );
}

export default EinProfileForm;