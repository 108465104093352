import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowWait() {
    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }
    const [wait, setWaits] = useState([
        {name:'5 Seconds', id:('wa_'+5/86400)},
        {name:'10 Seconds', id:('wa_'+10/86400)},
        {name:'20 Seconds', id:('wa_'+20/86400)},
        {name:'30 Seconds', id:('wa_'+30/86400)},
        {name:'45 Seconds', id:('wa_'+45/86400)},
        {name:'1 Minute', id:('wa_'+60/86400)},
        {name:'2 Minutes', id:('wa_'+120/86400)},
        {name:'3 Minutes', id:('wa_'+180/86400)},
        {name:'4 Minutes', id:('wa_'+240/86400)},
        {name:'5 Minutes', id:('wa_'+300/86400)},
        {name:'10 Minutes', id:('wa_'+600/86400)},
        {name:'20 Minutes', id:('wa_'+1200/86400)},
        {name:'30 Minutes', id:('wa_'+1800/86400)},
        {name:'45 Minutes', id:('wa_'+2700/86400)},
        {name:'1 Hour', id:('wa_'+3600/86400)},
        {name:'2 Hours', id:('wa_'+7200/86400)},
        {name:'3 Hours', id:('wa_'+10800/86400)},
        {name:'4 Hours', id:('wa_'+14400/86400)},
        {name:'5 Hours', id:('wa_'+18000/86400)},
        {name:'6 Hours', id:('wa_'+21600/86400)},
        {name:'7 Hours', id:('wa_'+25200/86400)},
        {name:'8 Hours', id:('wa_'+28800/86400)},
        {name:'9 Hours', id:('wa_'+32400/86400)},
        {name:'10 Hours', id:('wa_'+36000/86400)},
        {name:'12 Hours', id:('wa_'+43200/86400)},
        {name:'24 Hours', id:'wa_1'},
        {name:'2 Days', id:'wa_2'},
        {name:'3 Days', id:'wa_3'},
        {name:'4 Days', id:'wa_4'},
        {name:'5 Days', id:'wa_5'},
        {name:'6 Days', id:'wa_6'},
        {name:'7 Days', id:'wa_7'},
        {name:'14 Days', id:'wa_14'},
        {name:'21 Days', id:'wa_21'},
        {name:'28 Days', id:'wa_28'},
        {name:'30 Days', id:'wa_30'},
        {name:'31 Days', id:'wa_31'}
    ]);
   
    
    
    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
                Wait
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {wait && wait.map((item, index) => (    
                    <DraggableElement name={item.name} key={`draggableWait${index}`} id={item.id} type="Wait"/>
                ))}
            </div>
        </div>
    );
}

export default WorkflowWait;

