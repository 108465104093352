import { createSlice } from '@reduxjs/toolkit'

export const convoFlowSlice = createSlice({
    name: 'convoFlow',
    initialState: {
        convoFlow: []
    },
    reducers: {
        setConvoFlow(state, action) {
            state.convoFlow = action.payload;
        },
    },
})

export const { setConvoFlow } = convoFlowSlice.actions;

export default convoFlowSlice.reducer

