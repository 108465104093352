import React, { useEffect } from 'react';
import '../../css/qualifier.css';
import QualifierBlock from './sections/qualifierBlock';
import { getNearestAge } from '../../utilities/timeFunctions';
import moment from 'moment';


function Qualifier({handleProfileUpdate, lead, handleLeadData, handleSetQualifier, setResults, profile, setProfile, possibleConditions, setPossibleConditions, from}) {

    const leadData=[
        {name:'First',column:'borrower_first', type:'input'},
        {name:'Last',column:'borrower_last', type:'input'},
        {name:'Sex',column:'borrower_sex', type:'drop', options:[{title:'Select Sex',value:''},{title:'Male',value:'Male'},{title:'Female',value:'Female'}]},
        {name:'DOB',column:'borrower_dob', type:'input'},
        {name:'Age',column:'borrower_age', type:'input'},
        {name:"Feet (height)", column:'feet', type:'input'},
        {name:"Inches (height)", column:'inches', type:'input'},
        {name:'Weight',column:'weight', type:'input'},
        {name:'Has Insurance',column:'borrower_is_covered', type:'input'},
        {name:'Current Coverage',column:'borrower_coverage', type:'input'},
        {name:'Occupation', column:'borrower_occupation', type:'input'},
        {name:'Primary Concern',column:'concerns', type:'input'},
        {name:'Tobacco Use',column:'tobacco', type:'drop', options:[{title:'Select Tobacco Use',value:''},{title:'Non-Tobacco',value:'no'},{title:'Tobacco',value:'yes'}]},
    ]

    const coLeadData=[
        {name:'First',column:'co_borrower_first', type:'input'},
        {name:'Last',column:'co_borrower_last', type:'input'},
        {name:'DOB',column:'co_borrower_dob', type:'input'},
        {name:'Age',column:'co_borrower_age', type:'input'},
        {name:'Height',column:'co_borrower_height', type:'input'},
        {name:'Weight',column:'co_borrower_weight', type:'input'},
        {name:'Has Insurance',column:'co_borrower_is_covered', type:'input'},
        {name:'Current Coverage',column:'co_borrower_coverage', type:'input'},
        {name:'Occupation', column:'co_borrower_occupation', type:'input'},
        {name:'Tobacco Use',column:'co_borrower_tobacco', type:'input'}
    ]
    const mortgage=[
        {name:'Mortgage Value',column:'mortgage', type:'input'},
        {name:'Home Value',column:'home_value', type:'input'},
        {name:'Loan Type',column:'loan_type', type:'input'},
        {name:'Loan Term',column:'term_length', type:'input'},
        {name:'Home Payment',column:'payment', type:'input'}
    ]
    const handleSetProfile= (column, value)=>{
        setProfile(prevProfile => ({...prevProfile,[column]:value}));
    }
    // auto-calculate borrower_age whenever a valid DOB is enterd
    // only update the age when the year is a valid 4 numerical year
    // this help cut down on the amount of re-renders and calls to run VM
    // that get made
    useEffect(() => {
        // const isValidDate = (lead[0].borrower_dob !== undefined && lead[0].borrower_dob !== null) ? lead[0].borrower_dob.split('/')[2].length === 4 : false
        let isValidDate = false

        if(lead[0].borrower_dob !== undefined && lead[0].borrower_dob !== null){
            const splitDates = lead[0].borrower_dob.split('/')

            if(splitDates[2] !== undefined){
                isValidDate = splitDates[2].length === 4
            }
        }


        const date = new Date(lead[0].borrower_dob);
        let age = -1

        if(isValidDate && date instanceof Date && !isNaN(date)){
            age = getNearestAge(lead[0].borrower_dob)

            handleLeadData('borrower_age', age)
            handleSetProfile('borrower_age', age)
        }

    },[lead[0].lead_id, lead[0].borrower_dob])

    // update to make sure that the DOB field
    // is properly formatted
    useEffect(() => {
        // check against this regex to make sure that
        // we only trigger the formating call when
        // the date isn't already in the MM/DD/YYYY format
        const reg = /^\d{2}\/\d{2}\/\d{4}$/

        if(lead[0].borrower_dob !== undefined && lead[0].borrower_dob !== null && !reg.test(lead[0].borrower_dob)){
            handleLeadData('borrower_dob', moment(lead[0].borrower_dob).format('MM/DD/YYYY'))
        }
    }, [lead[0].lead_id])

    return (
        <div className="qualifierData" style={{margin: 'unset', position:'relative'}}>
            {!from && (
                <>
                    <div className='returnToLeadCard solidQuilityActiveButton' onClick={()=>handleSetQualifier()}>Return To Lead</div>
                    <div className="title">Qualifier</div>
                </>
            )}
            <QualifierBlock possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} lead={lead} setLead={handleLeadData} title="Lead Information" startOpen={true} leadMainData={leadData}/>
            <QualifierBlock possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} lead={lead} setLead={handleLeadData} title="Mortgage Info" startOpen={true} leadMainData={mortgage}/>
            <QualifierBlock possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} lead={lead} setLead={handleLeadData} title="Co Lead Information" startOpen={false} leadMainData={coLeadData}/>
        </div>
    );
}

export default Qualifier;