import { fetchWithDefaults } from '../config/fetchDefault';

const deleteTemplate = async (type, template, agent, saveTemplates) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({ 
                type: type,
                templateId:template,
                agent:agent,
            }) 
        };
        const response = await fetchWithDefaults('/deleteTemplate', requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if(jsonData.status===false){
            return false;
        }
        else{
            return jsonData.results;
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
    }
};

export {deleteTemplate};