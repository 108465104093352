import { createSlice } from '@reduxjs/toolkit'

export const textSlice = createSlice({
    name: 'texts',
    initialState: {
        texts: []
    },
    reducers: {
        setTexts(state, action) {
            state.texts = action.payload;
        },
    },
})
export const { setTexts } = textSlice.actions;

export default textSlice.reducer

