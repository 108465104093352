import React, {useState, useEffect} from 'react';
import LeadTopBarButton from './leadTopBarButton';
import { useSelector } from 'react-redux';
import { openAuthPopup } from '../../../../utilities/calendar';
import { useDispatch } from 'react-redux';

function LeadCardTopBar({from, setApptSet, setQualifier, handleSetScripting, qualifier, leadData, newEventPackage, calendarUsing, defaultCalendar, setEvent, newAppt, setNewAppt, update}) {
    const scopes=useSelector((state) => state.scopes.scopes);
    const dispatch=useDispatch();
    const [buttonInfo, setButtonInfo] = useState(['Attach File', 'Schedule Call', 'Appointment', 'Qualifier', 'Lead PDF']);
    const agentInfo = useSelector((state) => state.agent.userData);
    useEffect(() => {
        if (from === 'conversations') {
            setButtonInfo(prevButtonInfo => prevButtonInfo.filter(item => item !== 'Qualifier'));
        }
    }, [from]);

    return (
        <div className="leadTopBarWrapper">
            <div className="leadTopBarInnerWrapper">
                {buttonInfo.map((field, index) => (
                    (field === 'Lead PDF' && leadData.pdf !== null) || field !== 'Lead PDF' ? (
                        scopes.length < 1  && field==='Appointment' ? (
                            <div key={`topBarButton${index}`} className="leadCardButtonOuterWrapper" style={{flex: '1'}} onClick={()=>openAuthPopup(agentInfo.agentId, 'Google Auth', dispatch)}>
                                <div className="leadTopBarButton outlineQuilityInactiveButton">Appointment</div>
                            </div>
                        ) : (
                            <LeadTopBarButton setApptSet={setApptSet} handleSetScripting={handleSetScripting} update={update} key={`topBarButton${index}`} setQualifier={setQualifier} qualifier={qualifier} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} defaultCalendar={defaultCalendar} newEventPackage={newEventPackage} setEvent={setEvent} leadData={leadData} title={field} />
                        )
                    ) : null
                ))}
            </div>
        </div>
    );
}

export default LeadCardTopBar;