import React, { useState, useRef, useEffect } from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import { SuccessAlert } from '../../../successAlert';
import { incrementQueue } from '../../../../features/queueSlice';
import image from '../../../../assets/icons/confetti.gif';
import logo from '../../../../assets/icons/QuilitySwitchboardFunnel.svg';
import { setStartSession } from '../../../../features/callSlice';
import { triggerCalendar } from '../../../../features/appointmentSlice';
import Icon from '../../../../assets/icons/icon';


function DispositionBlock({apptSet, jumpIntoQueue, type, handleLeadData, setCurrentDial, theCall, from, updateStatusName, click, leadId, tags }) {
    const dispatch = useDispatch();
    const queue=useSelector((state) => state.queue.queue);
    const agentInfo = useSelector((state) => state.agent.userData);
    const textingNumbers = useSelector((state) => state.agent.textingNumbers);
    const statuses = useSelector((state) => state.status.status);
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const [complete, setComplete] = useState(false);
    const innerWrapperRef = useRef(null);
    const [successTrigger, setSuccessTrigger] = useState(false);
    const [warning, setWarning] = useState(false);
    const formatTags = (tagsArray) => {
        return tagsArray
            .map(tag => `${tag.id}*${tag.name}`) // Format each object as "id*name"
            .join(','); // Join all formatted strings with a comma
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);

    useEffect(() => {
        if (from === 'session') {
            setIsOpen(true);
        }
    }, []);

    const statusLead = async (status) => {
        
        setCurrentDial(100);
        theCall.disconnect();
        //handlCallAccepted(false);
        const statusId = status.id;
        const workflowId = status.workflow;
        const reminderId = status.reminder;
        let callTag
        if (tags) {
            callTag = tags.split(',').map(pair => {
                const [id, name] = pair.split('*');
                return { id, name };
            });
        }
        else {
            callTag = null;
        }
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({
                    agent: agentInfo.agentId,
                    leadId: leadId,
                    statusId: statusId,
                    workflowId: workflowId,
                    reminderId: reminderId,
                    status: status,
                    teamId: agentInfo.teamId,
                    from: from,
                    tags: callTag,
                    textingNumbers: textingNumbers
                }),
            };

            const response = await fetchWithDefaults('/lead/status', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setSuccessTrigger(true);
            const jsonData = await response.json();

            const newTags = formatTags(jsonData.newTags);
            handleLeadData('tag_ids', newTags);
            if(queue.length ===1){
                handleLeadData('tag_ids', newTags);
                setComplete(true)
            }
            else{
                handleLeadData('tag_ids', newTags);
                dispatch(incrementQueue(leadId));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const statusLead2 = async (status) => {
        if((status.id === 'si_master8' && apptSet) || status.id !== 'si_master8'){
            //splitting the tags and find the the ones that are associated with dial amounts
            let callTag
            if (tags) {
                callTag = tags.split(',').map(pair => {
                    const [id, name] = pair.split('*');
                    return { id, name };
                });
            }
            else {
                callTag = null;
            }

            const statusId = status.id;
            const workflowId = status.workflow;
            const reminderId = status.reminder;

            try {
                const requestOptions = {
                    method: 'PATCH',
                    body: JSON.stringify({
                        agent: agentInfo.agentId,
                        leadId: leadId,
                        statusId: statusId,
                        workflowId: workflowId,
                        reminderId: reminderId,
                        status: status,
                        teamId: agentInfo.teamId,
                        from: from,
                        tags: callTag,
                        textingNumbers: textingNumbers
                    }),
                };
                //setSuccessTrigger(true);
                const response = await fetchWithDefaults('/lead/status', requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setSuccessTrigger(true);

                //incrementQueue(queuePos);
                const newTags = formatTags(jsonData.newTags);
                if(queue.length ===1){
                    handleLeadData('tag_ids', newTags);
                    setComplete(true)
                }
                else{
                    handleLeadData('tag_ids', newTags);
                    dispatch(incrementQueue(leadId));
                }
                

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        else{
            setWarning("You need to set an appointment before selecting the appointment status/disposition");
            dispatch(triggerCalendar(true));
            setTimeout(() => {
                setWarning(false);
            }, 1750);
        }
    }
    const statusLead3 = async (status) => {
        let callTag
        if (tags) {
            callTag = tags.split(',').map(pair => {
                const [id, name] = pair.split('*');
                return { id, name };
            });
        }
        else {
            callTag = null;
        }

        const statusId = status.id;
        const workflowId = status.workflow;
        const reminderId = status.reminder;
        updateStatusName(status.name,handleLeadData, setSuccessTrigger);

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({
                    agent: agentInfo.agentId,
                    leadId: leadId,
                    statusId: statusId,
                    workflowId: workflowId,
                    reminderId: reminderId,
                    status: status,
                    teamId: agentInfo.teamId,
                    from: '',
                    tags: callTag,
                    textingNumbers: textingNumbers
                }),
            };

            const response = await fetchWithDefaults('/lead/status', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            const newTags = formatTags(jsonData.newTags);
            handleLeadData('tag_ids', newTags);


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const finishSession=()=>{
        jumpIntoQueue(false);
        setComplete(false);
        dispatch(setStartSession(false));
        dispatch(incrementQueue(leadId));
    }
    const closeWindow=()=>{
        setWarning(false);
    }
    return (
        <>
            <SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger} />
            <div className="statusButtonBlock" >
                {statuses.map((status, index) => (
                    type && type.toLowerCase() === 'client' && status.section === 'client' ? (
                        <div key={`innerStatus${index}`} className="statusItem solidQuilityActiveButton" onClick={() => click ? statusLead2(status) : from === 'dash' ? statusLead3(status) : statusLead(status)}>{status.name}</div>
                    ) : status.section === 'active' && (type && type.toLowerCase() !== 'client') && (
                        <div key={`innerStatus${index}`} className="statusItem solidQuilityActiveButton" onClick={() => click ? statusLead2(status) : from === 'dash' ? statusLead3(status) : statusLead(status)}>{status.name}</div>
                    )
                ))}
            </div>
            {complete && (
                <div className="loadingScreen" onClick={()=>finishSession()} style={{backgroundColor: '#c7c7c773'}}>
                    <img src={image} alt="Loading Gif" style={{width: '100%',position: 'absolute',zIndex: '2'}} />
                    <div className="completeSession">
                        Congratulations! You dominated that session!!
                    </div>
                </div>
            )}
            {warning && (
                <div className="signInWrapper">
                    <div className="signInContent">
                    
                        <div className="loginWrapper">
                            <div className="quilityCloseWindow" onClick={() => closeWindow()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                            <div className="logo"><img src={logo} className="imageStyle" alt="Logo" style={{marginTop:'0px'}} /></div>
                            <div className="smallTitle">{warning}</div>
                            <div style={{margin:'10px'}}></div>
                            <div className="buttonWrapper" style={{marginTop: '20px'}}>
                                {/*<button type="submit" className="loginButton" name="submitSignin" onClick={() => closeWindow()}>Close Warning</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

}

export default DispositionBlock;
