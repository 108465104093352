import { createSlice } from '@reduxjs/toolkit'

export const scriptSlice = createSlice({
    name: 'scripts',
    initialState: {
        scripts: []
    },
    reducers: {
        setScripts(state, action) {
            state.scripts = action.payload;
        },
    },
})
export const { setScripts } = scriptSlice.actions;

export default scriptSlice.reducer;