import React from 'react';

const TermsOfService = ({terms, setTerms}) => {
  const toggleTerms= ()=>{
    setTerms(!terms);
  }

  const handleChange = (e) => {
    if(e.target.checked === true){
        window.open('https://hq.quility.com/api/public/document/315272/view/general-terms-of-service','_blank')
    }

    setTerms(!terms);
  }

  return (
    <div>
      <h2>Terms of Service</h2>
      <div className="description">You will need to view our Terms of Service before you can sign up. Please click the link below.</div>
      <div className="terms">
        <input
            type='checkbox'
            style={{fontSize: '16px',color:'#008c00'}}
            onChange={handleChange}
            checked={terms}
        />
          <a className="termsLink" target="_blank" href='https://hq.quility.com/api/public/document/315272/view/general-terms-of-service' onClick={()=>toggleTerms()}>Terms of Service</a>
        </div>
    </div>

  );
};

export default TermsOfService;
