import React, { useState } from "react";
import '../../css/recording.css';
import AddAudioElement from "./recordingElement";


function AddRecording({setVoicemails, voicemails, setAddRecording, type}) {
    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    }
    
    return (
        <div className="quilityNestedBlockWrapper">
            <span className="quilityNestedBlockHeader" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className="quilityParentBlockTitle"></div>
            </span>
            <div className={`quilityNestedBlockContent`}>
                <div className="subTitle" style={{textAlign:'center', fontWeight:'700'}}>Click the microphone to record</div>
                <div className="audioElementAdjustment">
                    <AddAudioElement setVoicemails={setVoicemails} voicemails={voicemails} setAddRecording={setAddRecording} type={type}/>
                </div>
            </div>
        </div>
    );
}

export default AddRecording;

