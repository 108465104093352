import React, {useState} from 'react';
import Warning from '../../template/warning';
import { fetchWithDefaults } from '../../config/fetchDefault';

function Cards({data, flex,formatDate, addACard, setCards}) {
    const [warning, setWarning] = useState(false);
    const [card, setCard] = useState(false);
    const [cust, setCust] = useState(false);
   
    const cancel = () => {
        setWarning(false);
    };

    const removeCard= async (card, cust) => {
        setCard(card);
        setCust(cust);
        setWarning(true);
    }
    const confirm= async () => {
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    card: card,
                    cust:cust,
                }) 
            };
          
            const response = await fetchWithDefaults('/deletePaymentMethod', requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            setCards(jsonData.data);
            setWarning(false);
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    
    
    return (
        <>

        {warning && 
            <Warning title={"Delete Payment Method"} cancel={cancel} confirm={confirm} message={"Are you sure you want to permanently delete this payment method from your account?"} buttons={[{name:'Don\'t Delete',action:'cancel'},{name:"Delete Method", action:'confirm'}]} />
        }
        
            <div className={`frontPageQuilityParentBlockWrapper`} style={{flex: flex, height: 'fit-content'}}> 
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Your Cards</div>
                </div>
                
                <div className="quilityParentBlockContent">

                {data.length > 0 ? ( 
                    
                    data.map((card, index) => (
                        <div className="quilityNestedBlockWrapper" key={'cards'+index} style={{marginTop: '25px'}}>
                            <div className="removeItem" onClick={()=> removeCard(card.id,card.customer)} >X</div>
                            <div className="quilityNestedBlockHeader">
                                <div className="quilityParentBlockTitle">
                                    {card.card.brand} ending in {card.card.last4}
                                </div>
                            </div>
                            <div className="quilityNestedBlockContent">
                                <div className="innerContentWrapper">
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle">Name: </span>
                                        {card.billing_details.name}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle">Expiration Date: </span>
                                        {card.card.exp_month}/{card.card.exp_year}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle">Date Added: </span>
                                        {formatDate(card.created)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    'You have no cards'
                )}
                    
                </div>
                <div className="blockFooter">
                    <div
                        className="buttonWrapper"
                        style={{ marginLeft: "10px" }}
                    >
                        <div className="dataButtonBlock">Cards: {data.length}</div>
                        <div className="actionButtonBlock">
                            <div className="accentButton solidQuilityActiveButton" onClick={()=>addACard()}>
                                + Add Card
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cards;
