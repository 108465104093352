import React, {useState, useEffect} from 'react';
import '../../css/leadCard.css';
import { useSelector } from 'react-redux';
import WorkflowInfo from '../workflow/workflowInfo';
import LeadCardAppointments from '../appointment/leadCardAppointments';

import TagComponent from '../tags/tagComponent';
import LeadCardTags from './tags';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { unixTommddhia } from '../../utilities/dashFunction';
import { removeTagFromLead } from '../../utilities/tagFunctions';
import Icon from '../../assets/icons/icon';



function LeadCardHeader({lead, setFlowData}) {

    const [selectedTags, setSelectedTags] = useState([]);
    const [viewAppointments, setViewAppointments] = useState(false);
    const agentInfo=useSelector((state) => state.agent.userData);

    const viewFlowData= async (id)=>{
        try {
            const response = await fetchWithDefaults(`/flowInfo?lead=${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setFlowData(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeTag= async (tagIdToRemove)=>{
        const tagRemoved=await removeTagFromLead(tagIdToRemove, agentInfo.agentId, lead.lead_id);
        if(tagRemoved){
            const updatedTags = selectedTags.filter(tag => tag.id !== tagIdToRemove);
            // Update the state with the filtered array
            setSelectedTags(updatedTags);
        }
    }
    const handleAppointmentView=()=>{
        setViewAppointments(true)
    }
    
    return (
        lead && (
        <>
            <div className="leadCardHeader" >
                <span className="leadName">{lead.borrower_first ? lead.borrower_first.replace(/\\/g, '') : 'unknown'} {lead.borrower_last ? lead.borrower_last.replace(/\\/g, '') : 'unknown'}</span> 
                    <LeadCardTags tagIds={lead.tag_ids} leadId={lead.lead_id}/>
                {selectedTags.length > 0 && (
                    selectedTags.map((tag, index) => (
                        <span className="tagWrapper" key={`taggedLead${tag.id}`}>
                            {tag.name}
                            <span className="removeTag solidQuilityDestructiveButton" onClick={()=>removeTag(tag.id)}>x</span>
                        </span>
                    ))
                )}
                <div style={{position: 'absolute',right: '0',top: '0'}}><TagComponent selectedTags={selectedTags} setSelectedTags={setSelectedTags} leadId={lead.lead_id}/></div>
                {lead.appointments && (
                    {/*<LeadCardAppointments appointments={lead.appointments} lead={lead.lead_id}/>*/},
                    <div className="tagIcon" style={{position: 'absolute',right: '-12px',marginTop: '-22px'}} onClick={()=>handleAppointmentView()}>
                        <span className="fontello" style={{marginRight: '5px',lineHeight: '0px'}}><Icon name={'calendar-date-selection-q'} size={20} height='24' className="" adjust="fontello" /></span>
                    </div>
                )}
            </div>
            {lead.status_name && (
                <span className="statusName">{lead.status_name}</span>
            )}
            <div className="leadCardAddressWrapper">
                <div className="addressHolder">
                    <span className="addressSlot">{lead.address+',' ?? 'Click To Add'}</span>
                    <span className="addressSlot">{lead.city+',' ?? 'Click To Add'}</span>
                    <span className="addressSlot">{lead.state ?? 'Click To Add'}</span>
                    <span className="addressSlot">{lead.zip ?? 'Click To Add'}</span>
                </div>
                <div className="badgeHolder">
                    <span className="queueInnerBadgeHolder">
                        {lead.wf_name && (
                            <WorkflowInfo type='workflow' title={lead.wf_name} workflowId={lead.wf_id} hopperId={lead.wf_hopper_id} />
                        )}
                        {lead.convoFlowName && (
                            <WorkflowInfo type='conversation' title={lead.convoFlowName} workflowId={lead.pathway} hopperId={lead.wf_hopper_id} />
                        )}
                        <span className="badgeButton">Lead Type <span style={{fontWeight:'normal'}}>{lead.lead_type}</span></span>
                        <span className="badgeButton">Lead Level <span style={{fontWeight:'normal'}}>{lead.lead_level}</span></span>
                    </span>
                </div>
                {lead.exclusivity && (
                    <div className="exclusivity" style={{marginTop:'unset'}}>Exclusive until - {unixTommddhia(lead.exclusivity)}</div>
                )}
            </div>
            {viewAppointments && (
                <LeadCardAppointments setViewAppointments={setViewAppointments} appointments={lead.appointments} lead={lead.lead_id}/>
            )}
        </>
        )
    )
}

export default LeadCardHeader;
