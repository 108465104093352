import React, { useState } from "react";


function MessageVariables({ data, appendVariable }) {
    const [open, setOpen]=useState(false);
    const openUp=()=>{
        setOpen(!open);
    }
    const append=(code)=>{
        appendVariable(code);
        openUp();
    }
    return (
        <div className={`variableWrapper ${open  ? 'openedVariables' : ''}`}>
            <div className="variablesTitle" onClick={()=>openUp()}>Message Variables</div>
            <div className={`variables ${open  ? 'viewVariables' : ''}`}>
                {data.map((item, index) => (    
                    <div className="variable" key={`openMessageVariables${index}`} onClick={()=> append(item.code)}>{item.name}</div>
                ))}
            </div>
        </div>
    );
}

export default MessageVariables;

