import { fetchWithDefaults } from '../config/fetchDefault';

const setRecordingConsentOptions= async (agent, key, value)=>{
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent: agent,
                key:key,
                value:value
            }) 
        };
      
        const response = await fetchWithDefaults(`/call_recording/options`, requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData);
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const deleteRecording=async(agent,recordingSid)=>{
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({ 
                agent: agent,
                recordingSid:recordingSid
            }) 
        };
      
        const response = await fetchWithDefaults(`/call_recording/delete`, requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if(jsonData){
            return recordingSid
        }
        else{
            return false;
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const gatherRecordings= async (agent, setRecordings)=>{
    try {
        const response = await fetchWithDefaults(`/call_recording/recordings?agent=${agent}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if(jsonData){
            setRecordings(jsonData);

        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const getLeadRecording= async (agent, setRecordings)=>{
    try {
        const response = await fetchWithDefaults(`/call_recording/lead/recordings?lead=${agent}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();

        if(jsonData){
            setRecordings(jsonData);
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const deleteVoicemail= async (id,type,removeVoicemail)=>{
    const requestOptions = {
        method: 'DELETE'
    };
    try {
        const response = await fetchWithDefaults(`/voicemail/delete/${type}?recordingId=${id}`,requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();

        if(jsonData){
            removeVoicemail(id);
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
export {
    setRecordingConsentOptions,
    gatherRecordings,
    deleteRecording,
    getLeadRecording,
    deleteVoicemail
};
