import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';

function Unread({details,formatDate,openLead}) {

    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 
    return (
        <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
            <div className='quilityNestedBlockHeader'>
                <div className="quilityParentBlockTitle" style={{width: '170px'}}>Unread Messages</div>
                <span style={{marginLeft:'20px'}}>
                    <span className="typeBlock">{details.length} Messages</span>
                </span>
                <span className={`quilityBlockIcon ${open ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
        
            <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                {details.map((detail, index) => (
                    <div className="detailItem" key={"callback"+index}>
                        <span className="detailTitle">{detail.unreadLeadFirst} {detail.unreadLeadLast}</span>
                        <span className="detailDate">{formatDate(detail.unreadMessageTime)}</span>
                        <span className="detailTimezone">{detail.unreadMessage}</span>
                        <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.statusChangeLead); }}>View Lead</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Unread;