import React, {useState,useEffect} from 'react';
import '../../css/consent.css';
import { setRecordingConsentOptions} from '../../utilities/recordingFunctions';
import CallConsent from './consent';
import ConsentOptions from './consentOptions';
import { useSelector } from 'react-redux';
import RecordedCalls from './recordedCalls';

function CallRecording({data}) {
    console.log(data);
    const [consentInfo, setConsentInfo] = useState(false);
    const [openConsent, setOpenConsent] = useState(false);
    const agentInfo=useSelector((state) => state.agent.userData);
    const handleConsentChange = (key,value) => {
        setConsentInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
        setOpenConsent(false);
        setRecordingConsentOptions(agentInfo.agentId,key,value);
    };
    const handleOpenConsent=()=>{
        setOpenConsent(!openConsent);
    }
    useEffect(()=>{
        setConsentInfo(data[0]);
	},[])

    return (
        <div className='outerWrapper'>
              <div className="outerWrapperTitle">Call Recording</div>
              <div className="frontPageQuilityParentBlockWrapper" style={{width:'70%',marginLeft:'15%'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Call Recording Settings</div>
                </div>
                <div className="quilityParentBlockContent">
                    {consentInfo && consentInfo.recording_consent===1 ?(
                        <ConsentOptions consentInfo={consentInfo} handleConsentChange={handleConsentChange}/>
                    ) : (
                        <div className="consentAlert">
                            <div className="title">You still need to consent to record</div>
                            <div className="buttonWrapper" style={{marginTop: '20px'}}>
                                <button type="submit" className="loginButton" style={{cursor:'pointer'}} onClick={() => handleOpenConsent()}>Consent</button>
                            </div>
                        </div>  
                    )}
                </div>
              </div>
              <div className="frontPageQuilityParentBlockWrapper" style={{width:'70%',marginLeft:'15%'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Recent Recordings</div>
                </div>
                <div className="quilityParentBlockContent">
                    <div className="subTitle">Recordings are removed after 7 days if they are not saved</div>
                    <RecordedCalls/>
                </div>
              </div>
              {openConsent && (
                    <CallConsent handleOpenConsent={handleOpenConsent} handleConsentChange={handleConsentChange}/>
              )}
        </div>
    );
}

export default CallRecording;