import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowSpecTime() {
    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }
    const timeSlots=[
        { name: '9am', id: 'time_9' },
        { name: '9:15am', id: 'time_915' },
        { name: '9:30am', id: 'time_930' },
        { name: '9:45am', id: 'time_945' },
        { name: '10am', id: 'time_10' },
        { name: '10:15am', id: 'time_1015' },
        { name: '10:30am', id: 'time_1030' },
        { name: '10:45am', id: 'time_1045' },
        { name: '11am', id: 'time_11' },
        { name: '11:15am', id: 'time_1115' },
        { name: '11:30am', id: 'time_1130' },
        { name: '11:45am', id: 'time_1145' },
        { name: '12pm', id: 'time_12' },
        { name: '12:15pm', id: 'time_1215' },
        { name: '12:30pm', id: 'time_1230' },
        { name: '12:45pm', id: 'time_1245' },
        { name: '1pm', id: 'time_13' },
        { name: '1:15pm', id: 'time_1315' },
        { name: '1:30pm', id: 'time_1330' },
        { name: '1:45pm', id: 'time_1345' },
        { name: '2pm', id: 'time_14' },
        { name: '2:15pm', id: 'time_1415' },
        { name: '2:30pm', id: 'time_1430' },
        { name: '2:45pm', id: 'time_1445' },
        { name: '3pm', id: 'time_15' },
        { name: '3:15pm', id: 'time_1515' },
        { name: '3:30pm', id: 'time_1530' },
        { name: '3:45pm', id: 'time_1545' },
        { name: '4pm', id: 'time_16' },
        { name: '4:15pm', id: 'time_1615' },
        { name: '4:30pm', id: 'time_1630' },
        { name: '4:45pm', id: 'time_1645' },
        { name: '5pm', id: 'time_17' },
        { name: '5:15pm', id: 'time_1715' },
        { name: '5:30pm', id: 'time_1730' },
        { name: '5:45pm', id: 'time_1745' },
        { name: '6pm', id: 'time_18' },
        { name: '6:15pm', id: 'time_1815' },
        { name: '6:30pm', id: 'time_1830' },
        { name: '6:45pm', id: 'time_1845' },
        { name: '7pm', id: 'time_19' },
        { name: '7:15pm', id: 'time_1915' },
        { name: '7:30pm', id: 'time_1930' },
        { name: '7:45pm', id: 'time_1945' },
        { name: '8pm', id: 'time_20' },
        { name: '8:15pm', id: 'time_2015' },
        { name: '8:30pm', id: 'time_2030' },
        { name: '8:45pm', id: 'time_2045' },
        { name: '9pm', id: 'time_21' }
    ];
    const startTime = new Date();
    startTime.setHours(9, 0, 0, 0); // Set the start time to 9 AM

   /* for (let i = 0; i <= 43; i++) {
        // 36 slots for 15-minute intervals from 9 AM to 9 PM
        const time = new Date(startTime.getTime() + i * 15 * 60 * 1000);
        const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

        timeSlots.push({
        name: formattedTime,
        id: formattedTime.replace(/\s/g, ''), // Remove spaces for unique IDs
        });
    }*/
   
    
    
    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
                Send at Specific Time
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {timeSlots && timeSlots.map((item, index) => (
                    <DraggableElement name={item.name} id={item.id} key={`timeSlot${index}`} type="Send Next Message at"/>
                ))}
            </div>
        </div>
    );
}

export default WorkflowSpecTime;

