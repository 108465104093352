import React, { useState, useEffect } from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import Icon from '../../assets/icons/icon';

const ConditionInfo = ({ addCondition, possibleConditions, setPossibleConditions }) => {
    const [usedCondition, setUsedCondition] = useState('');
    
    const [filteredConditions, setFilteredConditions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const fetchData = async () => {
        try {
            const response = await fetchWithDefaults(`/medicalForm/medicalConditions`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setPossibleConditions(jsonData);
            setFilteredConditions(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        if(input===''){
            setIsDropdownOpen(false);
        }
        setUsedCondition(input);

        // Filter conditions based on user input
        const filtered = possibleConditions.filter(condition =>
            condition.search_name && condition.search_name.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredConditions(filtered);

        // Open the dropdown if there are filtered conditions
        setIsDropdownOpen(filtered.length > 0);
    };

    const handleConditionSelect = (condition) => {
        addCondition(condition);
        setUsedCondition('');
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ width: '50%',display:'inline-block',verticalAlign: 'top'}}>
            <div className="hybridInputWrapper">
                <input
                    type="text"
                    className="qualifierFieldInput"
                    value={usedCondition}
                    onChange={handleInputChange}
                    placeholder="Condition name..."
                    style={{ paddingRight: '40px',maxWidth: '200px' }}
                />
                <span className="medSearchIcon" onClick={()=>handleConditionSelect(usedCondition)}><span className="fontello" style={{height:'100%'}}><Icon name={'math-plus-q'} size={20} height='35' className="" adjust="fontello" /></span></span>
            </div>
            {isDropdownOpen && (
                <div className="qualDropDownMenu">
                    {filteredConditions.map((condition, index) => (
                        <div key={`filteredCondition${index}`} className="qualDropdownItem" onClick={() => handleConditionSelect(condition)}>
                            {condition.search_name} 
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ConditionInfo;
