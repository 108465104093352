import React, {useEffect, useState} from 'react';
import '../../css/conversation.css';
import { SuccessAlert } from '../successAlert';
import ContactInfo from '../callQueue/elements/sections/contactInfo';
import HomeInfo from '../callQueue/elements/sections/homeInfo';
import DispositionBlock from '../callQueue/elements/sections/dispositions';
import LeadCardTopBar from '../callQueue/elements/sections/leadCardTopBar';
//import { removeTagFromLead } from '../../utilities/tagFunctions';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import Qualifier from '../qualifier/qualifier';
import EditEvent from '../callQueue/elements/sections/editEvent';
import AddEvent from '../callQueue/elements/sections/addEvent';
import AdditionalInfo from '../callQueue/elements/sections/additionalInfo';
import LeadCardHeader from '../leads/leadCardHeader';
import { updateLeadInfo } from '../../utilities/leadFunctions';


function LeadData({from, setApptSet, handleSetScripting, permission, setScripting, qualifier, selectTab, setResults,lead,makeDial, handleLeadData,setNewAppt,newAppt,setFlowData, profile, setProfile, possibleConditions, setPossibleConditions}) {
    const dispatch=useDispatch();
    const queue=useSelector((state) => state.queue.queue);
    const backUp=useSelector((state) => state.queue.originalQueue);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const agentInfo=useSelector((state) => state.agent.userData);
    const [leadData, setLeadData] = useState(false);
    //const [successTrigger, setSuccessTrigger] = useState(false);
    const [update, setUpdate] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    //const [qualifier, setQualifier] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    const [event, setEvent] = useState(false);
    const newEventPackage=[{setNewEvent:setNewEvent, setTime:setTime, setDate:setDate}]

    const calendarUsing= async (id)=>{
        setCalendar(id);
    }
    const handleSetQualifier = (link) => {
        selectTab(4)
        setScripting(true);
      //  handleScriptType('qual')
    };
    /*const updateStatusName=(name)=>{
        handleLeadData('status_name',name);
        setSuccessTrigger(true);
    }
    const removeTag= async (tagIdToRemove)=>{
        //const tagRemoved=await removeTagFromLead(tagIdToRemove, agentId, leadData[0].lead_id);

        //if(tagRemoved){
            const updatedTags = selectedTags.filter(tag => tag.id !== tagIdToRemove);
            // Update the state with the filtered array
            setSelectedTags(updatedTags);
            handleLeadData('tag_ids',updatedTags);
       // }
    }*/
    
    const setAppointment= async (event,video)=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: agentInfo.teamId,
                    leadId:leadData[0].lead_id,
                    event:event,
                    video:video
                }),
            };
            const response = await fetchWithDefaults('/google/create/event', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewAppt(jsonData.event)
            setNewEvent(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        setLeadData(lead);
    }, [lead]);
    
    const addTag= (tag) => {
        //addTagToLead(setSelectedTags, selectedTags, tag);
        let tags;
        if(selectedTags.length > 0){
            tags=[selectedTags[0],tag];
        }
        else{
            tags=[tag];
        }


        //setSelectedTags([...selectedTags, tag]);
        const newarr=[...selectedTags, tag];

        handleLeadData('tag_ids',newarr[0]);
        const formattedString = tags
        .map(item => `${item.id}*${item.name}`)
        .join(',');

        updateLeadInfo(queue,'tag_ids',formattedString,leadData[0].lead_id, dispatch, 'queue')
        updateLeadInfo(backUp,'tag_ids',formattedString,leadData[0].lead_id, dispatch, 'backup');
    }
    return (
        leadData && (
        <div className="quilityParentBlockContent leadCardContentAdjustment">
            {//<SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            }
            <span style={{textAlign: 'left'}}>
                <LeadCardHeader lead={leadData[0]} setFlowData={setFlowData}/>
                <LeadCardTopBar setApptSet={setApptSet} from={from} update={update} handleSetScripting={handleSetScripting} setQualifier={handleSetQualifier} qualifier={qualifier} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} newEventPackage={newEventPackage} setEvent={setEvent} leadData={leadData[0]} />
            </span>
            <div className="leadInfoWrapper">
                {leadData.length > 0 && leadData[0].pathway && (
                    null
                )}
                {leadData && (
                    qualifier ? (
                        <Qualifier possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} setProfile={setProfile} profile={profile} setResults={setResults} handleLeadData={handleLeadData} handleSetQualifier={handleSetQualifier} lead={leadData} agentId={agentId}/>
                    ) : (
                        <>
                            {!permission && (
                                <ContactInfo handleLeadData={handleLeadData} makeDial={makeDial} leadData={leadData}  />
                            )}
                            {leadData[0]['custom_fields'] && (
                                <AdditionalInfo handleLeadData={handleLeadData} leadData={leadData} />
                            )}
                            <HomeInfo handleLeadData={handleLeadData} leadData={leadData} />     
                            {
                                //<DispositionBlock type={leadData[0].parent_status} handleLeadData={handleLeadData} removeTag={removeTag} addTag={addTag} tags={leadData[0].tag_ids} from="dash" leadId={leadData[0].lead_id} updateStatusName={updateStatusName}/>
                            }
                        </>
                    )
                )}
                {event && (
                    <EditEvent from={from} setUpdate={setUpdate} setEvent={setEvent} calendar={calendar} event={event} />
                )}
                {newEvent && (
                    <AddEvent from={from} setAppointment={setAppointment} calendar={calendar} setNewEvent={setNewEvent} agentTimezone={agentInfo.timezone} date={date} time={time} currentLead={leadData[0]} />
                )}
            </div>
        </div>
        )
    );
}

export default LeadData;
