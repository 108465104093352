import { fetchWithDefaults } from '../config/fetchDefault';
import { removeFilter } from '../features/filterSlice';


const deleteFilter = async (filter, dispatch) => {
    try {        
        const requestOptions = {
            method: 'DELETE'
        };
        const response = await fetchWithDefaults(`/filters?filter=${filter}`, requestOptions);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
       
        const jsonData = await response.json();
        if(jsonData){
            dispatch(removeFilter(filter));
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export { deleteFilter};