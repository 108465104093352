import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from "./draggingElement";

function DraggableBlock({ data, type, action, title }) {
    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }
   
    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
               {title}
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {data && data.map((item, index) => (    
                    <DraggableElement name={item.name} key={`${type}Drag${index}`} id={item.id} type={`${action}`}/>
                ))}
            </div>
        </div>

    );
}

export default DraggableBlock;

