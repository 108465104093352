import React from "react";


function TutorialBlock({title, tip, video, toggleShowComponent, toggleShowVideo, path}) {
    return (
        <div className="tutorialBlockWrapper">
            <div className="toolTipTutorial">i</div>
            <div className="toolTipTutorialTip">
                {tip}
            </div>
            <div className="tutorialBlockTitle">
                {title}
            </div>
            <div className="hiddenContent">
                <div className="tutorialBlockButtonLeft" onClick={()=>toggleShowVideo(video)}>Video</div>
                <div className="tutorialBlockButtonRight" onClick={()=>toggleShowComponent(path)}>Set Up</div>
            </div>
        </div>
    );
}

export default TutorialBlock;

