import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowText({ texts}) {
    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }
   
    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
                Text Messages
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {texts && texts.map((item, index) => (    
                    <DraggableElement name={item.name} key={`textDrag${index}`} id={item.id} type="Send Text"/>
                ))}
            </div>
        </div>

    );
}

export default WorkflowText;

