import { createSlice } from '@reduxjs/toolkit'

export const scopeSlice = createSlice({
    name: 'scopes',
    initialState: {
        scopes: []
    },
    reducers: {
        setScopes(state, action) {
            state.scopes = action.payload;
        },
    },
})
export const { setScopes } = scopeSlice.actions;

export default scopeSlice.reducer;

