import React, { useState, useEffect } from 'react';
import '../../css/phone.css';
import Icon from '../../assets/icons/icon';
import { useSelector } from 'react-redux';
import { recording, getVoiceDrops, sendVoiceDrop } from '../../utilities/callFunctions';


function StaticPhone({theCall,selectedNumber, callState, currentLead, expanded}) {
    const ringState=useSelector((state) => state.call.ringState);
    const agentInfo=useSelector((state) => state.agent.userData);
    const [recordingStatus, setRecordingStatus] = useState(true);
    const [voiceDrops, setVoiceDrops] = useState([]);
    const [openDrops, setOpenDrops] = useState(false);
    const [muted, setMuted] = useState(false);
    const [openKeypad, setOpenKeypad] = useState(false);


    const hangup= async ()=>{
        theCall.disconnect();
    }
    const handleKeypad=()=>{
        setOpenKeypad(!openKeypad);
    }
    const mute= async ()=>{
        setMuted(!muted);
        if(muted===true){
            theCall.mute(false);
        }
        else{
            theCall.mute(true);
        }
    }
    const sendDigit=(digit)=>{
        theCall.sendDigits(digit)
    }
    const handleRecording=()=>{
        const callSid = theCall.parameters.CallSid;
        setRecordingStatus(!recordingStatus)
        recording(callSid,recordingStatus,agentInfo.agentId,currentLead.lead_id);
    }
    useEffect(() => {
        getVoiceDrops(agentInfo.agentId, setVoiceDrops);

    },[])
    useEffect(() => {
        if(theCall){

        }
    },[theCall])
    
    const sendDrop=(id,ext)=>{
        const callSid = theCall.parameters.CallSid;
        sendVoiceDrop(id, ext,callSid,agentInfo.agentId)
    }
    return (
       <>
        { currentLead!=null ? (
                <div className={`staticPhoneWrapper ${expanded ? 'openPhone' : ''}`}>
                    <div className="wrapperTopBar">
                        <div className="wrapperTopBarTitle">Session Active</div>
                    </div>
                    <div className="softPhoneLine">
                        <span className="lineTitle">Call With: </span>
                        <span className="callStatus">{currentLead.borrower_first} {currentLead.borrower_last}</span>
                    </div>
                    <div className="softPhoneLine">
                        <span className="lineTitle">Call Status: </span>
                        <span className="callStatus">
                            {ringState}
                        </span>
                    </div>
                    <div className="softPhoneLine">
                        <span className="lineTitle">Last Contact: </span>
                        <span className="callStatus">{currentLead.last_contacted ? currentLead.last_contacted : 'Never Contacted'}</span>
                    </div>
                    <div className="softPhoneButtonWrapper">
                        <div className="phoneButtons">
                            <span className={`queuePhoneButton ${openKeypad ? 'activePhoneButtonQuility' : ''}`} onClick={()=>handleKeypad()}><span className="fontello" style={{height: '27px'}}><Icon name={'q-keypad'} size={20} className="" adjust="fontello" /></span></span>
                            {openKeypad &&(
                                <div className="keypadHolder">
                                    <div className="keypadHeader">
                                        Keypad
                                    </div>
                                    <div className="keypadContent">
                                    <div className="keypadNumber" onClick={()=>sendDigit('1')}><span className="innerKeypad">1</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('2')}><span className="innerKeypad">2</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('3')}><span className="innerKeypad">3</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('4')}><span className="innerKeypad">4</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('5')}><span className="innerKeypad">5</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('6')}><span className="innerKeypad">6</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('7')}><span className="innerKeypad">7</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('8')}><span className="innerKeypad">8</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('9')}><span className="innerKeypad">9</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('#')}><span className="innerKeypad">#</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('0')}><span className="innerKeypad">0</span></div>
                                    <div className="keypadNumber" onClick={()=>sendDigit('*')}><span className="innerKeypad">*</span></div>
                                    </div>
                                </div>
                            )}
                            <span className={`queuePhoneButton ${openDrops ? 'activePhoneButtonQuility' : ''}`} onClick={()=>setOpenDrops(!openDrops)}><span className="fontello" style={{height: '27px'}}><Icon name={'q-voicemail'} size={20} className="" adjust="fontello" /></span></span>
                            {openDrops &&(
                                <div className="dropWrapper">
                                    {voiceDrops.length > 0 ? (
                                        <>
                                            <div className="VoiceDropTitle">Voicemail Drops</div>
                                            {voiceDrops.map((drop, index) => (
                                                <div className="dropItem" onClick={()=>sendDrop(drop.id,drop.ext)}>{drop.name}</div>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="noDrop">No voicemail drops recorded</div>
                                    )}
                                </div>
                            )}
                            <span className={`queuePhoneButton ${recordingStatus ? '' : 'activePhoneButtonQuility'}`} onClick={handleRecording}><span className="fontello" style={{height: '27px'}}><Icon name={'q-microphone-on'} size={20} className="" adjust="fontello" /></span></span>
                            <span className={`queuePhoneButton ${muted ? 'activePhoneButtonQuility' : ''}`} onClick={()=>mute()}><span className="fontello" style={{height: '27px'}}><Icon name={'q-volume-2'} size={20} className="" adjust="fontello" /></span></span>
                            <span className="queuePhoneButton" onClick={()=>hangup()}><span className="fontello" style={{height: '27px'}}><Icon name={'q-phone-call-end'} size={20} className="" adjust="fontello" /></span></span>
                        </div>
                    </div>
                </div>
            ):(
                null
            )}

        </>
    );

}

export default StaticPhone;