import { createSlice } from '@reduxjs/toolkit'

export const statesSlice = createSlice({
    name: 'states',
    initialState: {
        states: []
    },
    reducers: {
        setStates(state, action) {
            state.states = action.payload.slice().sort((a, b) => 
                a.states.localeCompare(b.states)
            );
        },
    },
})

export const { setStates } = statesSlice.actions;

export default statesSlice.reducer

