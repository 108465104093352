import React, { useState } from "react";
import '../../css/workflow.css';
import DraggableElement from './draggingElement';

function WorkflowStatus({status}) {

    const [open, setOpen] = useState(false);
    const openUp= ()=>{
        
        setOpen(!open);
    }
   
    return (
        <div className="workflowBuilderSelectionBoxWrapper" >
            <div className="workflowBuilderSelectionBoxHeader" onClick={()=>openUp()}>
                Lead Statuses
            </div>
            <div className={`workflowBuilderSelectionContent ${open && 'expandWorkflowBlock'}`}>
                {status && status.map((item, index) => (    
                    <DraggableElement name={item.name} key={`statusDrag${index}`} id={item.id} type="Status Lead"/>
                ))}
            </div>
        </div>

    );
}

export default WorkflowStatus;

