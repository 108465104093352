import React, { useEffect, useState } from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { format, startOfWeek, endOfWeek, addDays, addWeeks, subWeeks, getHours, getMinutes, differenceInMinutes } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveCalendar } from '../../../../features/agentSlice';
import '../../../../css/calendar2.css';
import { openAuthPopup, reAuth } from '../../../../utilities/calendar';
import Icon from '../../../../assets/icons/icon';
import { triggerCalendar } from '../../../../features/appointmentSlice';

const Calendar2 = ({newEventPackage, defaultCalendar, setApptSet, setEvent, calendarChange, setIsOpen, update, newAppt}) => {
    const [events, setEvents] = useState([]);
    const dispatch = useDispatch();
    const agentInfo = useSelector((state) => state.agent.userData);
    const [activeCal, setActiveCal] = useState(agentInfo.defaultCalendar);
    const today = new Date();
    const [cals, setCals] = useState([]);
    const [currentWeek, setCurrentWeek] = useState(today);

    const startOfWeekDate = startOfWeek(currentWeek);
    const endOfWeekDate = endOfWeek(currentWeek);

    const fetchEvents = async () => {
        try {
            const response = await fetchWithDefaults(`/google/web/get/events?agent=${agentInfo.agentId}&calendar=${activeCal}&start=${startOfWeekDate.toISOString()}&end=${endOfWeekDate.toISOString()}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const jsonData = await response.json();
            setEvents(jsonData);
        } 
        catch (error) {
            console.error('Error fetching data:', error); 
        }
    };

    useEffect(() => {
        fetchEvents();
    }, [currentWeek, activeCal,update,newAppt]);

    useEffect(() => {
        setActiveCal(agentInfo.defaultCalendar)
    }, []);


    const renderTimeSlots = (label, currentDate) => {
        const slots = [];
        for (let i = 0; i < 48; i++) {
            const hour = Math.floor(i / 2);
            const minute = (i % 2) * 30;
            const timeLabel = format(new Date(2021, 0, 1, hour, minute), 'HH:mm');
            slots.push(
                <div
                    key={i}
                    className={`time-slot ${minute === 0 && 'hourSlot'}`}
                    onClick={() => selectedDate(currentDate, timeLabel)}
                    data-time={timeLabel}
                >
                    {/* Display only the hour, hiding half-hours */}
                    {label ? (minute === 0 ? timeLabel : '') : ''}
                </div>
            );
        }
        return slots;
    };

    const selectedDate = (date, time) => {   
        setApptSet(true);
        newEventPackage[0].setNewEvent(true);
        newEventPackage[0].setDate(date);
        newEventPackage[0].setTime(time);
    };

    const renderDays = () => {
        const days = [];
        const startOfWeekDate = startOfWeek(currentWeek);
        const today = new Date(); // Get today's date
    
        for (let i = 0; i < 7; i++) {
            const currentDate = addDays(startOfWeekDate, i);
            const formattedDate = currentDate.toLocaleDateString('en-US'); // Format date as MM/DD/YYYY
    
            const dayEvents = events.filter(event => {
                const eventDate = new Date(event.start.dateTime || event.start.date);
                return eventDate.toDateString() === currentDate.toDateString();
            });
    
            // Determine if the current date is today
            const isToday = currentDate.toDateString() === today.toDateString();
    
            days.push(
                <div key={i} className="day-column">
                    <div className={`day-header ${isToday ? 'today' : ''}`}>{format(currentDate, 'EEE, MMM d')}</div>
                    <div className="day-events">
                        {renderTimeSlots('', formattedDate)}
                        {dayEvents.map(event => renderEvent(event, currentDate))}
                    </div>
                </div>
            );
        }
        return days;
    };
    

    const makeDefault = async (calendar) => {
        try {
            const response = await fetchWithDefaults(`/setDefaultCal?agent=${agentInfo.agentId}&calendar=${calendar}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSpecificEvent = async (event) => {
        try {
            const response = await fetchWithDefaults(`/google/get/event?agent=${agentInfo.agentId}&calendar=${activeCal}&event=${event}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setEvent(jsonData);
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const renderEvent = (event, day) => {
        const start = new Date(event.start.dateTime || event.start.date);
        const end = new Date(event.end.dateTime || event.end.date);
        const startHour = getHours(start);
        const startMinute = getMinutes(start);
        const durationMinutes = differenceInMinutes(end, start);
        const top = (startHour * 60 + startMinute) / (24 * 60) * 107;
        const height = durationMinutes / (24 * 60) * 100;

        return (
            <div
                key={event.id}
                className="event"
                onClick={() => fetchSpecificEvent(event.id)} 
                style={{ top: `${top}%`, height: `${height}%` }}
            >
                <div className="event-title">{event.summary}</div>
                <div className="event-time">
                    {format(start, 'p')} - {format(end, 'p')}
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchWithDefaults(`/google/calendars?agent=${agentInfo.agentId}`);
                if (!response.ok) {
                    if (response.status) {
                        openAuthPopup(agentInfo.agentId, 'Google Auth',dispatch);
                    }
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData[0] === false) {
                    // Handle case where no calendars are found
                } else {
                    setCals(jsonData);
                    calendarChange(defaultCalendar);
                    if (!agentInfo.defaultCalendar) {
                        calendarChange(jsonData[0].id);
                        setActiveCal(jsonData[0].id);
                        dispatch(setActiveCalendar(jsonData[0].id));
                    }
                }
            } 
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleCalendarChange = (event) => {
        const selectedCalendarId = event.target.value;
        makeDefault(selectedCalendarId);
        setActiveCal(selectedCalendarId);
        dispatch(setActiveCalendar(selectedCalendarId));
    };

    const handleNextWeek = () => {
        setCurrentWeek(addWeeks(currentWeek, 1));
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(subWeeks(currentWeek, 1));
    };
    const handleCloseCalendar=()=>{
        setIsOpen(false)
        dispatch(triggerCalendar(false));
    }
    return (
        <div className="calendar2">
            <div className="calendarHeader">
            <button className="calendarButton" onClick={handlePreviousWeek}>Previous Week</button>
                <select className="calendarSelect" onChange={handleCalendarChange}>
                    {cals.map(cal => (
                        !cal.id.includes('holiday@group') && !cal.id.includes('addressbook#contacts@group.v.calendar.google.com') && !cal.id.includes('p#weather@group.v.calendar.google.com') && (
                            <option key={cal.id} value={cal.id} selected={cal.id === activeCal}>{cal.summary}</option>
                        ) 
                    ))}
                </select>
                <button className="calendarButton" onClick={handleNextWeek}>Next Week</button>
                <div className="quilityCloseWindow" style={{top: '5px'}}><span className="fontello" style={{marginTop: '-7px'}} onClick={()=>handleCloseCalendar(false)}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            </div>
            <div className="week-timeline">
                <div className="day-column">
                    <div className="day-header">Time</div>
                    <div className="day-events">
                        {renderTimeSlots('side', '')}
                    </div> 
                </div>
                {renderDays()}
            </div>
        </div>
    );
};

export default Calendar2;
