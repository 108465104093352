

import React from 'react';
import { useSelector } from 'react-redux';



function PhoneNumbers({selectedNumber, setSelectedNumber}) {
    const numbers=useSelector((state) => state.agent.phoneNumbers);
    
    const handleSetSelectedNumber=(event)=>{
        setSelectedNumber(event.target.value)
    }

    return (
        <div className="phoneNumberHolder" style={{display:'inline-block'}}>
            <select className="quilityDropDown" value={selectedNumber} onChange={handleSetSelectedNumber}>
                {numbers.map((number, index) => (
                    <option value={number.number} key={`numberSelect${index}`}>{number.friendlyName}</option>
                ))}
            </select>
        </div>
    );
}

export default PhoneNumbers;
