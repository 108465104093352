import React, { useState, useEffect } from 'react';
import '../css/header.css';
import { fetchWithDefaults } from '../config/fetchDefault';
import {setOpenLeaderBoard, setOpenMessages, setOpenCalls,setOpenVoicemails} from '../features/topBarSlice';
import BugReport from './bugReport';
//import image from '../assets/icons/funnel.png';
import logo from '../assets/icons/QuilitySwitchboardFunnel.svg';
import { useSelector, useDispatch } from 'react-redux';
import AgentInfo from './header/agentInfo';
import Icon from '../assets/icons/icon';



function HeaderBar({unreadMessages,changeBarState, barState, signOut, openLead, fetchPage}) {
	const dispatch= useDispatch();
	const agentId=useSelector((state) => state.agent.userData).agentId;
	const agentInfo=useSelector((state) => state.agent.userData);
	const dynamicLogo=useSelector((state) => state.agent).logo;
	const unheardVoicemails=useSelector((state) => state.voicemails.unheard);
	const [searchTerm, setSearchTerm] = useState('');
	const [bugReport, setBugReport] = useState(false);
	const [isBentoOpen, setIsBentoOpen] = useState(false);
	const [search, setSearch] = useState(false);
	const [typingTimeout, setTypingTimeout] = useState(null);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [results, setResults] = useState(false);
	const [bento, setBento] = useState(false);

	//console.log(agentInfo.organization);
	useEffect(() => {
		return () => {
		// Clear the typing timeout when component unmounts
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		};
	}, [typingTimeout]);

	const handleInputChange = async (event) => {
		const { value } = event.target;
		setSearchTerm(value);

		// If a request is pending, don't initiate a new one
		if (isRequestPending) {
		return;
		}

		// Clear the previous timeout
		if (typingTimeout) {
		clearTimeout(typingTimeout);
		}

		// Set a new timeout
		setTypingTimeout(
		setTimeout(async () => {
			setIsRequestPending(true);

			try {
				const response = await fetchWithDefaults(`/leadSearch?search=${value}&agent=${agentId}`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const jsonData = await response.json();
				if(jsonData.status===false){
					setResults([{borrower_first:'No Results'}]);
					setIsRequestPending(false);
				}
				else{
					setResults(jsonData);
					setIsRequestPending(false);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}, 500)
		);
	};

	const openBugReport=()=>{
		setBugReport(!bugReport);
	};
	const openMessages=()=>{
		dispatch(setOpenMessages(true));
	}
  	const openLeaderBoard=()=>{
		dispatch(setOpenLeaderBoard(true));
	}
	const openMissedCalls=()=>{
		dispatch(setOpenCalls(true));
	}
	const openVoicemails=()=>{
		dispatch(setOpenVoicemails(true));
	}
	const openSearch=()=>{
		setSearch(!search)
	}
	const handleCloseSearch=()=>{
		setSearch(!search)
	}
	const fetchNewPage=(page)=>{
		fetchPage(page)
	}
	useEffect(() => {
		return () => {
		// Clear the typing timeout when component unmounts
			if (typingTimeout) {
				clearTimeout(typingTimeout);
			}
		};
	}, [typingTimeout]);
	const gatherBento=async ()=>{
		try {
			const response = await fetchWithDefaults(`/quility/appMenu?agent=${agentId}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const jsonData = await response.json();
			if(jsonData.status===false){
				
			}
			else{
				setBento(jsonData);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}
	const openBento=()=>{
		setIsBentoOpen(!isBentoOpen)
	}
	useEffect(()=>{
		gatherBento()
	},[])
	const goTo=(where)=>{
 		window.location.href=where
	}
	
  	return (
		<div id="barWrapper">
			<div className="bento">
				{agentInfo.organization==='master' && (
					<span  onClick={()=>openBento()} className="fontello" style={{marginTop: '3px'}}><Icon name={'grid-q'} size={22} className="topBarIcon" adjust="fontello" /></span>
				)}
			</div>
			{isBentoOpen && (
				<div className="bentoBox">
					{bento && bento.map((item, index) => (
						<div className="bentoBlock" onClick={()=>goTo(item.href)}>
							<span className="fontello" style={{margin: '20px 15px',marginTop: '3px'}}><Icon name={`${item.icon.split('icon-')[1]}`} size={22} className="topBarIcon" adjust="fontello" /></span>
							<div className="bentoName">{item.title}</div>
						</div>
					))}
				</div>
			)}
			
			<div className="imageWrapper">
				<img src={dynamicLogo ? dynamicLogo : logo} alt="Main Logo" className="mainLogo"/>
			</div>
			
			<div className="otherBarOptions">
				<span  onClick={()=>openSearch()} className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-search'} size={22} className="topBarIcon" adjust="fontello" /></span>
				
				{search &&(
					<div className="searchModal">
						<div className="extraLayer">
							<div className="searchInner">
								<div className="quilityCloseWindow" onClick={()=>handleCloseSearch()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
								<div className="searchTitle">Search for leads</div>
								<div className="subTitle">Search by name, number, or email</div>
								<input className="searchModalBar" 
									value={searchTerm}
									onChange={handleInputChange}
								/> 
								<span className="searchModalSearchButton">
									<span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-search'} size={22} className="closeIcon" adjust="fontello" /></span>
								</span>
								<div className="searchResults">
									{results  && results.map((item, index) => (
										<div className="searchResult" onClick={()=>[setSearchTerm(''),setResults(false),openLead(item.lead_id)]} key={index}>
											<div className="searchHeader">
												<span className="searchName">{item.borrower_first} {item.borrower_last}</span>
											</div>
											<div className="resultLine">
												<div className="lineBlock">
													<div className="resultLineTitle">State</div>
													<span className="searchResultValue">{item.state}</span>
												</div>
												<div className="lineBlock">
													<div className="resultLineTitle">Type</div>
													<span className="searchResultValue">{item.lead_type}</span>
												</div>
											</div>
											<div className="resultLine">
												<div className="lineBlock">
													<div className="resultLineTitle">Status</div>
													<span className="searchResultValue">{item.statusName ? item.statusName : 'No Status'}</span>
												</div>
												<div className="lineBlock">
													<div className="resultLineTitle">Contacted</div>
													<span className="searchResultValue">{item.last_contacted ? item.last_contacted : 'Never Contacted'}</span>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				)}
				
				<span className="fontello" onClick={()=>openMissedCalls()}><Icon name="q-phone-call-missed" size={22} className="topBarIcon" adjust="fontello" /></span> 
				<span className="topBarIconWrapper">
					<span className="fontello" onClick={()=>openVoicemails()}><Icon name="q-voicemail" size={22} className="topBarIcon" adjust="fontello" /></span> 
					{parseInt(unheardVoicemails) > 0 &&(
						<span className="alertBadge">{unheardVoicemails}</span>
					)}
					
				</span>
				
				<span className="fontello" onClick={()=>openLeaderBoard()}><Icon name="q-award-medal" size={22} className="topBarIcon" adjust="fontello" /></span> 
				<span className="topBarIconWrapper">
					<span className="fontello" onClick={()=>openMessages()}><Icon name="message-circle-q" size={22} className="topBarIcon" adjust="fontello" /></span> 
					{parseInt(unreadMessages) > 0 &&(
						<span className="alertBadge">{unreadMessages}</span>
					) }
					
				</span>
				
			</div>
			{/*bugReport && (
				<div className="bugReportWrapper">
					<BugReport agentId={agentId} openBugReport={openBugReport}/>
				</div>
			)*/}
			<div className="barMenu">
				<AgentInfo fetchNewPage={fetchNewPage} signOut={signOut}/>
			</div>
		</div>
	);
}

export default HeaderBar;

