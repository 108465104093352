import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment/moment';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { SuccessAlert } from '../../successAlert';
import { useSelector } from 'react-redux';

function QueueNotes({handleLeadData, plan, notes, leadId, agentId }) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [active, setActive] = useState('notes');
    const [policyPlan, setPolicyPlan] = useState(false);
    const [leadNotes, setLeadNotes] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const leadContentEditableRef = useRef(null);
    const policyContentEditableRef = useRef(null);
    const [successTrigger, setSuccessTrigger] = useState(false);

    const handleContentClick = () => {
        setIsEditing(true);
    };
    const getCurrentTimestamp = (timezone) => {
        const timestamp = moment.tz(timezone).format('MM/DD/YY - h:mma -');
        return timestamp;
    };

    const saveLeadNotes=async(notes, type)=>{
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentId,
                    lead:leadId,
                    content:notes,
                    type:type
                }) 
            };
          
            const response = await fetchWithDefaults('/updateNotes', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            handleLeadData('notes',notes)
            setSuccessTrigger(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleContentBlur = (content, setContent, contentEditableRef,type) => {
        setIsEditing(false);
        setContent(contentEditableRef.current.innerHTML);
        saveLeadNotes(contentEditableRef.current.innerHTML, type);
    };
    const handleSetActive = (name) => {
        setActive(name);
    };

    useEffect(() => {
       setLeadNotes(notes)
       setPolicyPlan(plan)
    }, [leadId]);
  
    const handleTimestamp=()=>{

        const timestamp = getCurrentTimestamp(agentInfo.timezone);
        const contentDiv = active==='notes' ? leadContentEditableRef.current :policyContentEditableRef.current;
        if (contentDiv) {
            contentDiv.innerHTML = `${timestamp}<br/>` + contentDiv.innerHTML; // Prepend the timestamp with a line break
            setLeadNotes(contentDiv.innerHTML); // Update state with the new content
        }

    }
    return (
        <div className="entireNotesWrapper">
            <SuccessAlert message={'Notes Saved!'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            <div className="">
                <div className="headerTitle">Notes <span className="timestamp solidQuilityActiveButton" onClick={()=>handleTimestamp()}>Timestamp +</span></div>
            </div>
            <div className="blockContent" style={{height: '90%'}}>
                <div className="notesButtons">
                    <div className={`notesButton ${active === 'notes' ? 'selectedTab' : ''}`} onClick={() => handleSetActive('notes')}>Notes</div>
                    <div className={`notesButton ${active === 'planning' ? 'selectedTab' : ''}`} onClick={() => handleSetActive('planning')}>Policy Planning</div>
                </div>
                <div className={`notesWrapper ${active==='notes' ? 'display' : ''}`}>
                    <div
                        ref={leadContentEditableRef}
                        className="notes"
                        contentEditable={isEditing}
                        suppressContentEditableWarning={true}
                        onClick={handleContentClick}
                        onBlur={() => handleContentBlur(leadNotes, setLeadNotes, leadContentEditableRef, 'notes')}
                        dangerouslySetInnerHTML={{ __html: leadNotes }}
                    />
                    <div className="standardButton solidQuilityActiveButton">Save</div>
                </div>
                <div className={`notesWrapper ${active==='planning' ? 'display' : ''}`}>
                    <div
                        ref={policyContentEditableRef}
                        className="notes"
                        contentEditable={isEditing}
                        suppressContentEditableWarning={true}
                        onClick={handleContentClick}
                        onBlur={() => handleContentBlur(policyPlan, setPolicyPlan, policyContentEditableRef, 'plan')}
                        dangerouslySetInnerHTML={{ __html: policyPlan }}
                    />
                    <div className="standardButton solidQuilityActiveButton">Save</div>
                </div>
            </div>
        </div>
    );

}

export default QueueNotes;
