import { createSlice } from '@reduxjs/toolkit'

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState: {
        triggerCalendar:false
    },
    reducers: {
        triggerCalendar(state, action) {
            state.triggerCalendar = action.payload;
        }
    },
})
export const { triggerCalendar} = appointmentSlice.actions;

export default appointmentSlice.reducer

