import React from "react";
import '../../css/elements.css';



function DataLine({ title, value, width }) {

    return (
        <div className="dataInfoLine">
            <span className="dataInfoTitle">{title}</span>
            <span className="dataInfoText">{value}</span>
        </div>
    );
}

export default DataLine;

