import React, { useEffect, useState } from "react";
import '../../css/trust.css';


function CustomerBrand({data, fetchBrand,agentId, brandInfo,formatString}) {
    const [brands, setBrands] = useState(false);
    useEffect(() => {
        fetchBrand(agentId)
    },[])
    useEffect(() => {
        if(brandInfo.length > 0){
            let profiles=brandInfo
            profiles.sort((a, b) => {
                // If status is "twilio-approved", it comes first
                if (a.status === "APPROVED") return -1;
                if (b.status === "APPROVED") return 1;
                // Otherwise, maintain the order
                return 0;
            });
            setBrands(profiles)
        }
    },[brandInfo])
    
    return (
        <div className="mainBlockWrapper twoX">
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">Brand Profile</div>

            </div>
            <div className="mainBlockContent">
                {brands && brands.map((item, index) => (
                    <div key={`brand${index}`} className={`bigBlock`} style={{marginTop: '25px'}}>
                        <div className="leadCardBlock">
                            <div className="innerWrapper">
                                <div className={`leadInfoLine a2p${item.status}`}>
                                    <span className="dataFieldTitle">Status: </span>
                                    <span className="leadData">{formatString(item.status)}</span>
                                </div>
                                {item.status==='FAILED' && (
                                    <div className="leadInfoLine">
                                        <span className="dataFieldTitle">Failure Reason: </span>
                                        <span className="leadData" style={{whiteSpace: 'unset'}}>{item.failureReason}</span>
                                    </div>
                                )}
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Type: </span>
                                    <span className="leadData">{formatString(item.brandType)}</span>
                                </div>
                                <div className="leadInfoLine">
                                    <span className="dataFieldTitle">Date: </span>
                                    <span className="leadData">{item.dateCreated}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))} 
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{marginLeft: "10px" }}
                >
                    <div className="dataButtonBlock">Profiles: {brandInfo.length}</div>
                    <div className="actionButtonBlock" style={{width: '150px'}}>
                       
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default CustomerBrand;
