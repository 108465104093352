import React, { useState } from "react";
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import { setScopes } from '../../features/agentSlice';

function IntegrationBlock({title, logo, button, status, link, removeLink }) {
    const agentInfo=useSelector((state) => state.agent);
    const dispatch=useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const authorize = async (link)=>{
        window.location.href = link;
    }
    const remove=async(removeLink)=>{

        try {
            const response = await fetchWithDefaults(`${removeLink}?agent=${agentInfo.userData.agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            window.location.href=`https://${window.location.hostname}/auth`;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <div className="integrationBlock">
            <span className="integrationLogo"><img src={logo} alt="Google Calendar Logo" style={{width:'100%'}}/></span>
            <span className="integrationTitle">{title}</span>
            <span className={`integrationStatus ${status ? 'integrationConnected' : 'integrationDisconnected'}`}>
                {status ? (
                    'Connected'
                ) : (
                    'Disconnected'
                )}
            </span>
            <span className="integrationToggleHolder">
                {button !=='toggle' ? (
                    status ? (
                        <div className="removeButton solidQuilityDestructiveButton" onClick={()=>remove(removeLink)}>Remove Authorization</div>
                    ) : !status && button==='quility' ? (
                        <div className="quilitySignInButton solidQuilityActiveButton" onClick={()=>authorize(link)}>Sign In With Quility</div>
                    ) : (
                        <img src={button} alt="Google Calendar Logo" style={{height:'100%'}} onClick={()=>authorize(link)}/>
                    )
                    
                ) : (
                    null
                )}
            </span>
        </div>
    );
}

export default IntegrationBlock;
