import { fetchWithDefaults } from '../config/fetchDefault';

const checkEligibility= async (agentCode, setEligibilityChecked, setEligibility, setAgentId)=>{

    try {
        const response = await fetchWithDefaults(`/migrate/initiate?agent=${agentCode}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        //"FunnelWaitlist": false,
        //"FunnelCertificationCourse": true,
        //if(json.data)
        console.log(agentCode);
        console.log(jsonData);
        if(jsonData.account){
            setAgentId(jsonData.agentId);
            setEligibility('account');
        }
        else{
            const waitlist=jsonData.FunnelWaitlist
            const course=jsonData.FunnelCertificationCourse
            const eligibility = waitlist && course ? true 
                    : !waitlist && !course ? 'both' 
                    : !waitlist && course ? 'signup' 
                    : 'course';
            setEligibility(eligibility);
        }
        setEligibilityChecked(true)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const migrate= async (agentId, eligibility,setLoading)=>{
    setLoading(true);
    const endpoint=eligibility==='account' ? 'sync' : 'migrate';
    try {
        const response = await fetchWithDefaults(`/migrate/${endpoint}?agent=${agentId}`);
        console.log(response);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setLoading(false);
        return jsonData

    } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
    }
}
export { checkEligibility, migrate };