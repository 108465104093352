import React from 'react';
import Icon from '../../assets/icons/icon';
import parse, { domToReact } from 'html-react-parser';


function ExtraInfo({extraInfo, closePopup}) {
    const options= [{"name": "Fully Underwritten","id": 20},{"name": "Simplified Issue","id": 19},{"name": "Term","id": 7},{"name": "Universal Life","id": 8},{"name": "Whole Life","id": 9},{"name": "Debt Free Life","id": 18},{"name": "Final Expense","id": 6},{"name": "Mortgage Protection","id": 5}];
    function findNamesByIds(ids) {
        return ids.map(id => {
            const item = options.find(item => item.id === id);
            return item ? <div key={id} className="recBubble">{item.name}</div> : null;
        }).filter(name => name !== null);
    }
    const transform = (node) => {
        if (node.type === 'tag' && node.name === 'strong') {
          return <div className="recDescriptionTitle">{domToReact(node.children, { transform })}</div>;
        }

        if (node.type === 'tag' && node.name === 'br') {
          return <br />;
        }

        return node;
    };
    return (
        <div className="extraPopup">
            <div className="extraPopupContent">
                <div className="extraPopupTitle">{extraInfo.name}</div>
                <div className="quilityCloseWindow" style={{top:'5px'}} onClick={()=>closePopup()}><span className="fontello" style={{lineHeight: '12px',color:'grey'}}><Icon name={'x-close-q'} size={16} height="22" adjust="fontello" /></span></div>
                <div className="optionBubbles">
                    {findNamesByIds(extraInfo.product_options)}
                </div>
                <div className="recDescription">{parse(extraInfo.description, { transform })}</div>
                <div className="bottomLinks">
                    <div className="recBottomLink">
                        <a className="noDeco" href={extraInfo.guide_file} target='_blank'>Product Guide <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></a>
                    </div>
                    <div className="recBottomLink">
                        <a className="noDeco" href={extraInfo.url} target='_blank'>Company Website <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></a>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ExtraInfo;