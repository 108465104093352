import React, {useState, useRef, useEffect} from 'react';
import '../../css/teams.css';
import Icon from '../../assets/icons/icon';
import { getTeamInfo, inviteResponse } from '../../utilities/teamFunctions';
import { useSelector } from 'react-redux';

function TeamInvite({title, invite}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [isOpen, setIsOpen] = useState(false);
    const [teamData, setTeamData] = useState(false);
    const [goals, setGoals] = useState([]);
    const [openResponse, setOpenResponse] = useState(false);
    const [errors, setErrors] = useState([]);
    
    const handleInputChange = (index, event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) {
            const newGoals = [...goals];
            newGoals[index].response = input; // Update the value with the filtered input
            setGoals(newGoals);
      
            // Clear error for this input if it's now valid
            const newErrors = [...errors];
            if (input.trim() !== "") {
              newErrors[index] = false;
            }
            setErrors(newErrors);
        }
    };
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const innerWrapperRef = useRef(290);

 
    function removeBackslashes(inputString) {
        if (typeof inputString !== 'string') {
          return inputString;
        }
        return inputString.replace(/\\/g, '');
    }
    const respond =(teamId)=>{
        getTeamInfo(teamId,setTeamData)
        setOpenResponse(true);
    }
    const handleResponse= async (answer, inviteId, teamId)=>{
        const newErrors = goals.map(goalObj => goalObj.response.trim() === "");
        setErrors(newErrors);

        if (!newErrors.includes(true)) {
            const inviteResp=await inviteResponse(answer,inviteId, agentInfo.agentId, teamId, goals);
            if(!inviteResp ){
                alert("there was an issue")
            }
            else{
                window.location.reload();
            }
        }
    }
    useEffect(() => {
        if (teamData && teamData.length > 0 && teamData[0].goals) {
            console.log(teamData[0].goals);
          const parsedGoals = JSON.parse(teamData[0].goals).map(goal => ({ name:goal, response: "" }));
          setGoals(parsedGoals);
          setErrors(new Array(parsedGoals.length).fill(false));
        }
    }, [teamData]);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} style={{ height: isOpen && getHeight() !==0 ? getHeight()+35 : '45px',borderColor: 'var(--quility-color)' }} >
            {openResponse && (
                <div className="invitePopup">
                <div className="inviteContent" style={{maxHeight:'700px', height:'fit-content'}}>
                    <div className="quilityCloseWindow" onClick={()=>setOpenResponse(false)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                    <div className="quilityParentBlockHeader quilityParentBlockTitle" style={{backgroundColor:'unset'}}>Respond To {invite.name}</div>
                    {teamData && teamData.map((data, index) => (
                        <div className="quilityParentBlockContent" key={`invite${index}`}>
                            <div className="quilityNestedBlockWrapper">
                                <div className="quilityNestedBlockHeader">
                                    <div className="quilityParentBlockTitle">Team Info</div>
                                </div>
                                <div className="quilityNestedBlockContent">
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle">Team Owner</span>
                                        <span className="quilityDataLineValue">{data.first_name} {data.last_name}</span>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="quilityDataLineTitle">Team Size</span>
                                        <span className="quilityDataLineValue">{data.teamCount}</span>
                                    </div>
                                </div>
                            </div>
                            {data.goals.length > 0 && (
                                <div className="quilityNestedBlockWrapper">
                                    <div className="quilityNestedBlockHeader">
                                        <div className="quilityParentBlockTitle">Team's Goals</div>
                                    </div>
                                    <div className="quilityNestedBlockContent">
                                        {goals.map((goalObj, index) => (
                                            <div className="quilityDataLine" key={`goals${index}`}>
                                                <span className="quilityDataLineTitle">{goalObj.name}</span>
                                                <span className="quilityDataLineValue" style={{width: '50%',display: 'inline-block'}}>
                                                    <input 
                                                        class="qualifierFieldInput" 
                                                        type="text" 
                                                        value={goalObj.response}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                    />
                                                    {errors[index] && <span className="error">This field is required</span>}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                     <div className="buttonWrapper" style={{paddingBottom: '10px', textAlign:'center'}}>
                        <div className="response solidQuilityDestructiveButton" onClick={()=>handleResponse('no', invite.inviteId, invite.teamId)}>Decline Invite</div>
                        <div className="response solidQuilityActiveButton" onClick={()=>handleResponse('yes', invite.inviteId, invite.teamId)}>Join Team</div>
                    </div>
                </div>
            </div>

            )}
            <div className="quilityNestedBlockHeader invite">
               
                <div className="quilityParentBlockTitle" style={{width:'25%',maxWidth:'200px'}}>{invite.name==='undefined' ? 'My Agency' : removeBackslashes(invite.name)}</div>
                
                <span className="quilityBlockIcon">

                </span>
                <div className="typeWrapper">
                    <span className="typeBlock" style={{cursor:'pointer'}} onClick={()=> respond(invite.teamId)}>Repsond To Invite</span>
                </div>
                
            </div>
            
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef}>
              
            </div>
        </div>
    );
}

export default TeamInvite;


