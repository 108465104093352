import { fetchWithDefaults } from '../config/fetchDefault';

const gatherConversation= async (currentLeadId, setConversations)=>{
    if(currentLeadId){
        setConversations([]);
        try {
            const response = await fetchWithDefaults(`/getConversationForLead?lead=${currentLeadId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
           /* if(jsonData.status===true){
                //setConversations([newItem, ...conversation])
            }
            */
            setConversations(jsonData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
}
const travelPathway= async (lead, path, handleLeadData) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                lead: lead,
                path: path
            }) 
        };

        const response = await fetchWithDefaults(`/conversation/travelPath`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        handleLeadData('convoFlowName',jsonData[0].title);
        handleLeadData('convoFlowItem',jsonData[0].content_id);
        handleLeadData('convoFlowContent',jsonData[0].content);
        return jsonData;

    } catch (error) {
        console.error('Error fetching data:', error);
    }

}
export {gatherConversation, travelPathway};