import React from 'react';

function CardBlock({card, handleSelectCard}) {
   
    return (
        <div className="subscriptionOptionWrapper">
            <div class="quilityNestedBlockHeader subscriptionOptionHeader">
                <div class="subscriptionTitle">{card.card.brand} Ending in {card.card.last4}</div>
            </div>
            <div className="subcriptionOptionContent">
                <div className="quilityDataLine">
                    <div className="quilityDataLineTitle">Expires</div>
                    <span className="quilityDataLineValue">{card.card.exp_month}/{card.card.exp_year}</span>
                </div>
                <div className="quilityDataLine">
                    <div className="statusItem solidQuilityActiveButton" onClick={()=>handleSelectCard(card.id)}>Use This Card</div>
                </div>
            </div>
        </div>
    );
}

export default CardBlock;
