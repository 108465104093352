import { createSlice } from '@reduxjs/toolkit'

export const workflowSlice = createSlice({
    name: 'workflows',
    initialState: {
        workflows: []
    },
    reducers: {
        setWorkflows(state, action) {
            state.workflows = action.payload.slice().sort((a, b) => 
                a.name.localeCompare(b.name)
            );
        },
    },
})

export const { setWorkflows } = workflowSlice.actions;

export default workflowSlice.reducer;