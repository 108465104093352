import React, { useState, useEffect } from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import TermsOfService from '../../terms/tos';

function Payment({ formData, imoInfo, setLoading, terms, setTerms, usernameCheck }) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        setLoading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const result = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                //return_url: 'https://example.com',
            },
        })
            .then(async function (results) {
                if (results.error) {
                    setLoading(false);
                    console.log(results);
                    // Inform the customer that there was an error.
                }
                else {
                    if (results.setupIntent.status === 'succeeded') {
                        try {
                            const requestOptions = {
                                method: 'POST',
                                body: JSON.stringify({
                                    form: formData,
                                    method: results.setupIntent.payment_method,
                                    imoInfo: imoInfo
                                })
                            };

                            const response = await fetchWithDefaults('/registration/createCustomer', requestOptions);
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            const jsonData = await response.json();
                            if (jsonData === true) {
                                window.location.href = window.location.origin;
                            }
                        } catch (error) {
                            setLoading(false);
                            console.error("Error fetching data:", error);
                        }
                    }
                }
            });

    };

    const [canSubmit, setCanSubmit] = useState(false)
    const [submitIssues, setSubmitIssues] = useState([
        {
            active: formData['Username'] === '',
            message: 'Enter a user name.'
        },
        {
            active: usernameCheck === true,
            message: 'User name needs to be unique.'
        },
        {
            active: terms === false,
            message: 'Click Terms of Service link.'
        },
        {
            active: formData['Password'] === '',
            message: 'Enter a password.'
        },
        {
            active: formData['Password'] !=='' && formData['Confirm_Password'] !== formData['Password'],
            message: 'Confirm that password is correct.'
        },
    ])
    useEffect(() => {
        // check if submission button can be activated
        const valid = formData['Username'] !== '' && usernameCheck === false && terms === true && formData['Password'] !== '' && formData['Confirm_Password'] === formData['Password']
        setCanSubmit(valid)

        // check if there are any submission issues
        setSubmitIssues([
            {
                active: formData['Username'] === '',
                message: 'Enter a user name.'
            },
            {
                active: usernameCheck === true,
                message: 'User name needs to be unique.'
            },
            {
                active: terms === false,
                message: 'Click Terms of Service link to view the term of service.'
            },
            {
                active: formData['Password'] === '',
                message: 'Enter a password.'
            },
            {
                active: formData['Password'] !=='' && formData['Confirm_Password'] !== formData['Password'],
                message: 'Confirm that password is correct.'
            },
        ])
    }, [formData, usernameCheck, terms])
    return (
        <span>
            <div className="quilityDataLine">
                <PaymentElement />
            </div>
            <div className="quilityDataLine">
                <TermsOfService terms={terms} setTerms={setTerms} />
            </div>
            <div className="quilityDataLine">
                <div className={canSubmit === true ? "submitSubscription solidQuilityActiveButton" : "submitSubscription solidQuilityInactiveButton"} onClick={(event) => canSubmit === true ? handleSubmit(event) : undefined}>Submit and Subscribe</div>
                    {canSubmit === false && (
                        <div>
                            <div className='submissionHelperHeader'>Before you can submit and subcribe:</div>
                            <ul>
                                {submitIssues.filter(s => s.active === true).map((issue, index) => (
                                    <li className='submissionHelperMessage' key={`issue_${index}`}>{issue.message}</li>
                                ))}
                            </ul>
                        </div>
                    )}
            </div>
        </span>
    );
}

export default Payment;