import React from 'react';
import '../../../css/scheduledCalls.css';

function MarketBlock({ data, adjustClass, title,handleNavigation}) {
    return (
        <div className={`mainBlockWrapper `+adjustClass} style={{width: '45%',height: 'fit-content'}}>
            <div className="mainBlockHeader">
                <div className="mainBlockTitle">{title}</div>

            </div>
            
            <div className="mainBlockContent">
                {data[0] != undefined && data[0].map((item,index)=>(
                    <div className="previewBlock selectable" key={`market${index}`} onClick={()=>{handleNavigation(item.name)}}>
                        <div className="previewBlockHeader">
                            <div className="previewBlockTitle">{item.name}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{ paddingTop: " 5px", marginLeft: "10px" }}
                >
                    <div className="dataButtonBlock">{title}: {data[0].length}</div>
                    <div className="actionButtonBlock" style={{width: '150px'}}>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarketBlock;
