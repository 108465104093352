const sortIntoTiers = async (sortedArrays) => {
    const currentDate = new Date();

    // Define the tier parameters
    const tiers = [
        { name: 'tier1', days: 7, reloadTime: 24 },
        { name: 'tier2', days: 14, reloadTime: 48 },
        { name: 'tier3', days: 21, reloadTime: 72 },
        { name: 'tier4', days: 30, reloadTime: 120 },
        { name: 'tier5', days: 60, reloadTime: 240 },
        { name: 'tier6', days: 90, reloadTime: 336 }
    ];

    for (const key in sortedArrays) {
        const category = sortedArrays[key];
        tiers.forEach(tier => {
            category[tier.name] = [];
        });
        category['unused'] = []; // Initialize unused array

        let i = 0;
        while (i < category.length) {
            const item = category[i];
            if (item.date_uploaded) {
                const uploadedDate = new Date(item.date_uploaded);
                const diffInDays = Math.floor((currentDate - uploadedDate) / (1000 * 60 * 60 * 24));

                // Find the appropriate tier for the item
                let tierIndex = tiers.findIndex(tier => diffInDays <= tier.days);
                if (tierIndex === -1) {
                    tierIndex = tiers.length - 1; // If not found, assign the last tier
                }

                const tier = tiers[tierIndex];
                const reloadTimeInDays = tier.reloadTime / 24;

                // Check last contacted
                if (item.last_contacted) {
                    const lastContactedDate = new Date(item.last_contacted);
                    const diffLastContacted = Math.floor((currentDate - lastContactedDate) / (1000 * 60 * 60 * 24));

                    if (diffLastContacted < reloadTimeInDays) {
                        // Last contacted falls outside the reload time, add to unused array
                        category['unused'].push(item);
                        category.splice(i, 1); // Remove item from original position
                        continue; // Move to the next item
                    }
                }

                // Last contacted within reload time or last_contacted is null, add to the appropriate tier
                category[tier.name].push(item);
                category.splice(i, 1); // Remove item from original position
            } else {
                i++; // Move to the next item if it doesn't have a date_uploaded
            }
        }
    }
    return sortedArrays;
}

const sortObjectIntoArrays = async (data, sortBy) => {
    const sortedArrays = {};

    data.forEach(item => {
        const key = item[sortBy];

        if (!sortedArrays[key]) {
            sortedArrays[key] = [];
        }

        sortedArrays[key].push(item);
    });

    return sortedArrays;
};
async function createMasterArray(sortedArrays) {
    let masterArray = [];

    // Iterate through each category in sortedArrays
    for (const key in sortedArrays) {
        if (key === 'unused') continue; // Skip the 'unused' tier
        const category = sortedArrays[key];
    
        // Iterate through each tier in the category
        for (const tierName in category) {
            if (tierName === 'unused') continue; // Skip the 'unused' tier
            let leads = category[tierName];
    
            // Iterate through leads in the current tier
            leads.forEach(lead => {
                // Clone the lead object to avoid modifying the original
                const leadWithTier = { ...lead, tier: tierName };
                
                // Push the cloned lead to the master array
                masterArray.push(leadWithTier);
            });
        }
    }
    
    // Sort masterArray by tiers
    masterArray.sort((a, b) => {
        const tierOrder = { 'tier1': 1, 'tier2': 2, 'tier3': 3, 'tier4': 4, 'tier5': 5, 'tier6': 6 };
        return tierOrder[a.tier] - tierOrder[b.tier];
    });
    
    return masterArray;

}
function getLast12MonthsTimestamps() {
    const currentDate = new Date();
    const last12MonthsDates = [];
    const last3YearsDates = [];
  
    for (let i = 0; i < 12; i++) {
        const firstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const lastOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - i + 1, 0);
  
        last12MonthsDates.push({
            name: firstOfMonth.toLocaleString('default', { month: 'long' }),
            first: formatDate(firstOfMonth),
            last: formatDate(lastOfMonth),
            params:'date'
        });
    }
    for (let j = 0; j < 3; j++) {
        const year = currentDate.getFullYear() - j;
        const firstOfYear = new Date(year, 0, 1);
        const lastOfYear = new Date(year, 11, 31);
        
        last3YearsDates.push({
            name: String(year),
            first: formatDate(firstOfYear),
            last: formatDate(lastOfYear),
            params: 'date'
        });
    }
    const combinedDates = last12MonthsDates.concat(last3YearsDates);

    return combinedDates;
}
function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export { sortIntoTiers,createMasterArray,sortObjectIntoArrays,getLast12MonthsTimestamps};
/*
const currentDate = new Date();
    const last12MonthsDates = [];
    const last3YearsDates = [];
  
    // Function to format dates
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Last 12 months
    for (let i = 0; i < 12; i++) {
        const firstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const lastOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - i + 1, 0);
  
        last12MonthsDates.push({
            name: firstOfMonth.toLocaleString('default', { month: 'long' }),
            first: formatDate(firstOfMonth),
            last: formatDate(lastOfMonth),
            params: 'date'
        });
    }
  
    // Last 3 years
    for (let j = 0; j < 3; j++) {
        const year = currentDate.getFullYear() - j;
        const firstOfYear = new Date(year, 0, 1);
        const lastOfYear = new Date(year, 11, 31);
        
        last3YearsDates.push({
            name: year,
            first: formatDate(firstOfYear),
            last: formatDate(lastOfYear),
            params: 'year'
        });
    }
  
    return {
        last12MonthsDates,
        last3YearsDates
    };*/