import React, { useState } from "react";
import '../../css/workflow.css';
import WorkflowStatus from './status';
import WorkflowTag from './tags';
import WorkflowWait from './waits';
import WorkflowText from './texts';
import NameFlow from './nameFlow';
import WorkflowSpecTime from './specificTime';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropTarget from './dropZone';
import FinishFlow from "./finishFlow";
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import DraggableBlock from "./draggableBlock";
import Icon from "../../assets/icons/icon";


function CreateWorkflow({addAFlow,handleNewFlow}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const texts=useSelector((state) => state.texts.texts);
    const status=useSelector((state) => state.status.status);
    const tags=useSelector((state) => state.tags.tags);
    const emails=useSelector((state) => state.email.email);
    const [flow, setFlow] = useState([]);
    const [flowName, setFlowName] = useState('');
    const [showName, setShowName] = useState(false);
    const [exitConditions, setExitConditions] = useState([]);
    const [showExitCondition, setShowExitConditions] = useState(false);
    const [flowNameError, setFlowNameError] = useState('');
    const [attachedStatus, setAttachedStatus] = useState(null);
    const agentId=agentInfo.agentId;
    const teamId=agentInfo.teamId;
    
    const handleDrop = (item) => {
        setFlow((prevFlow) => [...prevFlow, item]);
    };
    const handleExitDrop = (item) => {
        setExitConditions((prevExit) => [...prevExit, item]);
    };
    const removeFromExit = (itemId) => {
        setExitConditions((prevExit) => prevExit.filter((exitItem) => exitItem.id !== itemId));
    };
    const removeFromFlow = (itemId) => {
        setFlow((prevFlow) => prevFlow.filter((flowItem) => flowItem.id !== itemId));
    };
    
    const showFlowName = () => {
        if(flow.length > 0){
            setShowName(!showName)
        }
        else{
            alert("You need to add actions to your flow!")
        }
    };
    const handleFlowName = (value) => {
        setFlowName(value);
        setFlowNameError(false);
    };
    
    const showExitConditions = () => {
        if(flowName != ""){
            setShowExitConditions(!showExitCondition);
        }
        else{
            setFlowNameError("You need to give a name to your workflow")
        }
    };
    const saveWorkflow = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    flow: flow,
                    name: flowName,
                    tag: attachedStatus,
                    exit: exitConditions,
                    agent: agentId,
                    team: teamId,
                }) 
            };
          
            const response = await fetchWithDefaults('/workflow/saveNewWorkflow', requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            if(jsonData.status=='results'){
                handleNewFlow(jsonData.workflowResults[0]);
                addAFlow();
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    
    return (
        <div className="frontPageQuilityParentBlockWrapper" style={{ width: '75%',position: 'relative'}}>
            <div className="quilityCloseWindow" onClick={()=>addAFlow()} style={{zIndex: '2',marginTop: '-7px'}}><span className="fontello"><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div> 
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">Create Workflow</div>
            </div>
            <div className="quilityParentBlockContent" style={{height: '600px',overflow: 'auto'}}>
                {showName & showExitCondition==false ? (
                    <NameFlow setAttachedStatus={setAttachedStatus} attachedStatus={attachedStatus} flowNameError={flowNameError} flowName={flowName} handleFlowName={handleFlowName} tags={tags}/>
                ) : showExitCondition ? (
                   <FinishFlow removeFromExit={removeFromExit} exitConditions={exitConditions} status={status} handleExitDrop={handleExitDrop}/>
                ) : (
                    <DndProvider backend={HTML5Backend}>
                        <div style={{display: 'flex',width: '100%',height: '100%'}}>
                            <div className="quilityNestedBlockWrapper workflowBuilderLeft editWorkflowBuilderLeft" style={{overflow: 'scroll'}}>
                                <div className="workflowStartBlock">
                                    <div className="workflowStartBlockTitle">Lead Put In Workflow</div>
                                </div>
                                <div className="dottedLine"></div>
                                {flow && flow.map((item, index) => ( 
                                    <>
                                        <div className='draggedElement'><div className="removeFromFlow" onClick={()=>removeFromFlow(item.id)}>X</div><div className="draggedTitle" >{item.type}</div><div>{item.name}</div></div>
                                        <div className="dottedLine"></div>
                                    </>   
                                ))}
                                
                                <DropTarget onDrop={handleDrop} internalText={"Drop Actions Here"}>

                                </DropTarget>
                            </div>
                            <div className="quilityNestedBlockWrapper workflowBuilderRight editWorkflowBuilderRight" style={{overflow: 'scroll'}}>
                                <WorkflowStatus status={status} />
                                <WorkflowTag tags={tags} />
                                <WorkflowWait />
                                <WorkflowSpecTime />
                                <WorkflowText texts={texts}/>
                                <DraggableBlock data={emails} type={'Email'} action={'Send Email'} title={'Email Templates'} />
                            </div>
                        </div>
                    </DndProvider>
                )}
                
            </div>
            <div className="blockFooter">
                <div
                    className="buttonWrapper"
                    style={{ marginTop: " 5px", marginLeft: "10px" }}
                >
                   
                   {showName & showExitCondition==false ? (
                        <div className="accentButton solidQuilityActiveButton" style={{display: "inline-block"}} onClick={()=>showFlowName()}>
                            Previous Step!
                        </div>
                    ) : showExitCondition ? (
                        <div className="accentButton solidQuilityActiveButton" style={{display: "inline-block"}} onClick={()=>showExitConditions()}>
                        Previous Step!
                    </div>
                    ) : (
                        <div className="dataButtonBlock">Actions: {flow.length}</div>
                    )} 
                    <div className="actionButtonBlock" style={{width: 'fit-content', marginRight:'20px'}}>
                        {showName & showExitCondition==false ? (
                            <div className="accentButton solidQuilityActiveButton" style={{display: "inline-block"}} onClick={()=>showExitConditions()}>
                                Next Step!
                            </div>
                        ) : showExitCondition ? (
                            <div className="accentButton solidQuilityActiveButton" style={{display: "inline-block"}} onClick={()=>saveWorkflow()}>
                                Save Workflow
                            </div>
                        ) : (
                            <div className="accentButton solidQuilityActiveButton" style={{display: "inline-block"}} onClick={()=>showFlowName()}>
                                Next Step!
                            </div>
                        )}
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default CreateWorkflow;

