import React, { useState, useEffect } from "react";
import { fetchWithDefaults } from '../../../config/fetchDefault';
import Icon from "../../../assets/icons/icon";

function AddNumberToService({type,service,agent, setUpdate, setNumberChoice, fetchServices}) {
    const [numbers, setNumbers] = useState(false);
    const [error, setError] = useState(false);
    
    const fetchNumbers= async(fields, brand, type)=>{
        try {
            const response = await fetchWithDefaults(`/trustHub/getNumbers?agent=${agent}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNumbers(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const addToService= async(service, number)=>{
        setError(false)
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agent,
                    service:service,
                    number:number
                }) 
            };
          
            const response = await fetchWithDefaults(`/trustHub/addToService`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status){
                fetchServices(); 
                setNumberChoice(false);
            }
            else{
                setError(jsonData.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchNumbers();
    },[])
    return (
        <div className="quilityPopupFormWrapper">
            <div className="quilityPopupFormContent">
                <div className="quilityCloseWindow" onClick={()=>setNumberChoice(false)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                <div className="title" style={{padding:'20px', marginTop:'unset'}}>Choose a number to enable text messaging on! </div>
                {type==='STANDARD' ? (
                    <div className='subTitle' style={{fontWeight:'700', marginBottom:"10px"}}>You can add as many numbers as you'd like</div>
                ) : (
                    <div className='subTitle' style={{fontWeight:'700', marginBottom:"10px"}}>You can add only add one number to your service</div>
                )}
                {error && (
                    <div className="errorMessage" style={{textAlign:'center'}}>{error}</div>
                )}
                {numbers && numbers.map((number, index) => (
                    <div className="brandSelection solidQuilityActiveButton" style={{minWidth:'200px', textAlign:'center'}} onClick={()=>addToService(service,number.id)}>{number.name}</div>
                ))}
            </div>
        </div>
    );
}
export default AddNumberToService;

