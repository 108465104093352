import React, { useState } from "react";
import '../../css/templates.css';
import Warning from '../warning';
import { fetchWithDefaults } from '../../config/fetchDefault';
import WorkflowBlock from "./elements/workflowBlock";



function Workflow({ workflows,formatDate, newFlow, addFlow, addAFlow, setEditFlow, setWorkflows }) {
    const [editingTemplate, setEditTemplate] = useState(false);
    const [warning, setWarning] = useState(false);

    const cancel = () => {
        setWarning(false);
    };
    const confirm = async () => {
        try {

            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    flowId: warning,
                }) 
            };
      
            const response = await fetchWithDefaults('/deleteWorkflow', requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
         
            if(jsonData.status==true){
                const newArray = [...workflows];

                // Find the index of the object with the specified ID
                const indexToRemove = newArray.findIndex(obj => obj.id === warning);

                // Remove the object at the found index
                if (indexToRemove !== -1) {
                    newArray.splice(indexToRemove, 1);
                    setWorkflows(newArray);
                }
                setWarning(false);
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    
    return (
       <>
            {warning && 
                <Warning title={"Delete Workflow"} cancel={cancel} confirm={confirm} message={"Are you sure you want to remove this workflow?"} buttons={[{name:'Don\'t Delete',action:'cancel'},{name:"Confirm Delete", action:'confirm'}]} />
            }
            <div className={`frontPageQuilityParentBlockWrapper twoBlock ${addFlow ? 'closeMainBlock' : editingTemplate ? 'closeMainBlock' : ''}`} style={{flex:'1'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Workfow</div>
                </div>
                <div className="quilityParentBlockContent" style={{height: '600px',overflow: 'auto'}}>
                    {newFlow && newFlow.map((item, index) => (  
                        <WorkflowBlock setWorkflows={setWorkflows} setWarning={setWarning} item={item} setEditFlow={setEditFlow} index={index} formatDate={formatDate}/>
                    ))}
                    {workflows && workflows.map((item, index) => (    
                        <WorkflowBlock setWorkflows={setWorkflows} setWarning={setWarning} item={item} setEditFlow={setEditFlow} index={index} formatDate={formatDate}/>
                    ))}
                </div>
                <div className="blockFooter">
                    <div
                        className="buttonWrapper"
                        style={{ marginTop: " 5px", marginLeft: "10px" }}
                    >
                        <div className="dataButtonBlock">Workflows: {workflows.length}</div>
                        <div className="actionButtonBlock" >
                            <div className="accentButton solidQuilityActiveButton" onClick={()=>addAFlow('Workflow')}>
                            +  New Workflow
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>

    );
}

export default Workflow;

/*
<div className={`bigBlock ${editingItemId === item.id ? 'focusBigBlock' : ''}`} key={`workflowEdit${index}`} style={{marginTop: '25px'}}>
                            <div className="removeItem" onClick={()=> removeItem(item.id, index)}>X</div>
                            <div className="leadCardSubTitle">
                                {editedText[index] ?? item.name}
                            </div>
                            <div className="infoWrapper">
                                <div className="systemInfoItem">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Workflow Name: </span>
                                    {editedIndex === index ? (
                                        <input
                                            type="text"
                                            className="standardInputBox"
                                            value={editedText[index] || item.name}
                                            onChange={(e) => handleInputChange(e, index)}
                                            onBlur={() => setStatusOption('name',editedText[index], item.id)}
                                            autoFocus
                                        />
                                        ) : (
                                        <>
                                            <span style={{ marginLeft: "5px" }} onClick={() =>
                                                handleEditName(index, editedText[index])
                                            }>
                                            {editedText[index] || item.name}
                                            </span>
                                            <span
                                            className="hoverIcon"
                                            onClick={() =>
                                                handleEditName(index, editedText[index])
                                            }
                                            >

                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className="systemInfoItem">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Total Actions: </span>
                                    {item.length}
                                </div>
                                <div className="systemInfoItem">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Total Days: </span>
                                    {calculateTime(item.order)}
                                </div> 
                                <div className="systemInfoItem">
                                    <span className="itemTitle" style={{display: 'inline-block',width: '130px'}}>Date Created: </span>
                                    {item.created==null ? " Uknown" : formatDate(item.created)}
                                </div>
                            
                            </div>
                        </div>
                        */

