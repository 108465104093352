import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';



function Applications({details,formatDate, openLead}) {

    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    } 
    return (
        <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
            <div className='quilityNestedBlockHeader'>
                <div className="quilityParentBlockTitle" style={{width: '170px'}}>Applications</div>
                <span style={{marginLeft:'20px'}}>
                    <span className="typeBlock">{details.length} Apps</span>
                </span>
                <span className='quilityBlockIcon'><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>

            <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                {details.map((detail, index) => (
                    <div className="detailItem" key={"callback"+index}>
                        <span className="detailTitle">{detail.statusChangeFirst} {detail.statusChangeLast}</span>
                        <span className="detailDate">{formatDate(detail.statusDate)}</span>
                        <span className="detailTimezone">{detail.statusName}</span>
                        <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.statusChangeLead); }}>View Lead</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Applications;