import { createSlice } from '@reduxjs/toolkit'

export const queueSlice = createSlice({
    name: 'queue',
    initialState: {
        queue: [],
        wholeQueue: [],
        originalQueue: [],
        calledQueue: [],
        currentLead:null,
    },
    reducers: {
        setQueue(state, action) {
            state.queue = action.payload.slice().sort((b, a) => {
                // Handle cases where date_assigned is null or undefined
                const dateA = a.date_assigned || a.date_uploaded;
                const dateB = b.date_assigned || b.date_uploaded;
                return dateA.localeCompare(dateB);
            });
        },
        setQueueSort(state, action) {
            state.queue = action.payload;
        },
        setWholeQueue(state, action) {
            state.wholeQueue = action.payload;
        },
        addQueue(state, action) {
            state.queue.unshift(action.payload);
        },
        addToBackup(state, action) {
            const combinedArray = [...action.payload, ...state.wholeQueue];
            const uniqueArray = combinedArray.reduce((acc, current) => {
                const x = acc.find(item => item.lead_id === current.lead_id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            state.wholeQueue = uniqueArray;
        },
        setBackup(state, action) {
            state.originalQueue = action.payload;
        },
        addCurrentLead(state, action) {
            state.currentLead = action.payload;
        },
        updateSpecificLead(state, action){
            const { id, field, value } = action.payload;
        
            // Find the lead by id
            const lead = state.queue.find((lead) => lead.lead_id === id);
        
            if (lead) {
                lead[field] = value;
            }

        },
        incrementQueue(state,action){
            if (state.queue.length >= 1) {
                const itemToMove = state.queue.shift(); // Remove the first item from firstArray
                state.calledQueue.push(itemToMove);   
                if (state.queue.length === 0) {
                    state.calledQueue=[];
                }
            }
        }
    },
})
export const { setQueue, addQueue, setBackup, setWholeQueue, addToBackup,addCurrentLead,incrementQueue, setQueueSort,updateSpecificLead } = queueSlice.actions;

export default queueSlice.reducer;