import { createSlice } from '@reduxjs/toolkit'

export const tagSlice = createSlice({
    name: 'tags',
    initialState: {
        tags: []
    },
    reducers: {
        setTags(state, action) {
            //state.tags = action.payload;
            state.tags = action.payload.slice().sort((a, b) => 
                a.name.localeCompare(b.name)
            );
        },
        addTag(state, action) {
            state.tags.push(action.payload);
        },
    },
})

export const { setTags, addTag } = tagSlice.actions;

export default tagSlice.reducer;