import React, {useState} from 'react';
import '../../css/login.css';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import { setPopups } from '../../features/popupSlice';
import { fetchWithDefaults } from '../../config/fetchDefault';

function PopupItem({popup, setData, data}) {
    

    const handleSelectChange = (event) => {
        setData(event.target.value);
    };
    return (
        <div className="popupItemWrapper">
            We are missing your {popup}
            <div class="leadInfoLine" style={{width: '80%',margin: 'auto'}}>
                <div class="dataFieldTitle" style={{textAlign: 'left',textIndent: '10px'}}>{popup}:  </div>
                <span class="leadData">
                    <select
                        className="qualifierFieldInput"
                        style={{ height: '35px' }}
                        onChange={handleSelectChange}
                        value={data}
                    >
                        <option value="America/New_York">Eastern</option>
                        <option value="America/Chicago">Central</option>
                        <option value="America/Denver">Mountain</option>
                        <option value="America/Phoenix">Arizona</option>
                        <option value="America/Los_Angeles">Pacific</option>
                        <option value="America/Anchorage">Alaska</option>
                        <option value="America/Honolulu">Hawaii</option>
                        <option value="America/Puerto_Rico">Puerto Rico</option>
                    </select>
                </span>
            </div>
        </div>
    );
}

export default PopupItem;