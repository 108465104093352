
import { createSlice } from '@reduxjs/toolkit'

export const voicemailSlice = createSlice({
    name: 'voicemails',
    initialState: {
        voicemails: [],
        unheard:0
    },
    reducers: {
        setVoicemails(state, action) {
            state.voicemails = action.payload;
        },
        addVoicemail(state, action) {
            if (state.voicemails.length > 0) {
                state.voicemails.unshift(action.payload); // Adds to the beginning of the array
            }
        },
        setUnheard(state, action) {
            state.unheard += action.payload;
        },
        deleteVoicemailRecording(state, action) {
            state.voicemails = state.voicemails.filter(
                voicemail => voicemail.id !== action.payload
            );
        },
    },
})
export const { setVoicemails, setUnheard, addVoicemail, deleteVoicemailRecording } = voicemailSlice.actions;

export default voicemailSlice.reducer

