import { useDrop } from 'react-dnd';

const DropTarget = ({ onDrop, internalText }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'ELEMENT',
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
  
    return (
        <div
            ref={drop}
            style={{
                border: isOver ? '2px solid #0078d4' : '1px solid #ccc',
                padding: '16px',
                minHeight: '100px',
                width:'250px'
            }}
            className='dropZone'
        >
            <span className="dropZoneTitle">{internalText}</span>
        </div>
    );
};
export default DropTarget;