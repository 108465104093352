import { createSlice } from '@reduxjs/toolkit'

export const missedCallSlice = createSlice({
    name: 'missedCall',
    initialState: {
        missedCall: 0
    },
    reducers: {
        setMissedCall(state, action) {
            state.missedCall = action.payload;
        },
    },
})

export const { setMissedCall } = missedCallSlice.actions;

export default missedCallSlice.reducer

