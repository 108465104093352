import { createSlice } from '@reduxjs/toolkit'

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        subscription: []
    },
    reducers: {
        setSubscription(state, action) {
            state.subscription = action.payload;
        },
    },
})
export const { setSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;