import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';
import InviteSearch from './inviteSearch';

function Invite({members,setInvitePopup, title, teamId}) {
    
    console.log(teamId)
    return (
        <div className="invitePopup">
            <div className="inviteContent">
                <div className="quilityCloseWindow" onClick={()=>setInvitePopup(false)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                <div className="quilityParentBlockHeader quilityParentBlockTitle" style={{backgroundColor:'unset'}}>Manage Members for {title}</div>
                <div className="invitePopupContent">
                    <InviteSearch members={members} teamId={teamId}/>
                </div>
            </div>
        </div>
    );
}

export default Invite;
