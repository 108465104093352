

import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';
import { searchAgents } from '../../../utilities/teamFunctions';
import { useSelector } from 'react-redux';
import { removeMember, inviteMembers } from '../../../utilities/teamFunctions';

function InviteSearch({members, teamId}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [agentSearch, setAgentSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const handleAgentSearch = (event) => {
        setAgentSearch(event.target.value);
    };
    const addToInvite= (invitee)=>{

        setInvitees((prevInvitee) => {
            // Check if the goal with the same name already exists
            const exists = prevInvitee.some(invitees => invitees.id === invitee.id);
      
            if (exists) {
              // If it exists, remove it
              return prevInvitee.filter(invitees => invitees.id !== invitee.id);
            } else {
                console.log(invitee)
              // If it does not exist, add it
              return [...prevInvitee, invitee];
            }
        });
    }
    const invite = ()=>{
        inviteMembers(invitees, teamId, agentInfo.agentId);
    }
    const handleRemoveMember= async(member, teamId)=>{
        const remove=await removeMember(member,teamId);
        if(remove){
            window.location.reload();
        }
    }
    return (
        <div style={{width:'90%',margin:'auto',position:'relative'}}>
            <input 
                type="text" 
                class="addTeamFieldInput" 
                placeholder="Search for Agents to Add"
                value={agentSearch} 
                onChange={handleAgentSearch}
                style={{maxWidth: 'unset',width: '87%'}}
            /> 
            <span className="teamSearch" onClick={()=>searchAgents(agentSearch, agentInfo.agentId, setSearchResults,agentInfo.organization)} style={{right:'unset'}}>
                <span className={`fontello`} style={{marginTop: '-7px'}}><Icon name={'q-search'} size={20} className={`selectBoxIcon`} adjust="fontello" /></span>
            </span>
            {members.length > 0 && (
                <div class="teamFieldWrapper">
                    <div class="teamFieldTitle" style={{width:'unset',fontSize:'16px'}}>Team Members</div>
                    {members.map((member, index) => (
                        member.id !== agentInfo.agentId ? (
                            <div className="invitee">{member.first_name} {member.last_name} 
                                <span className={`fontello quilityCloseWindow`} style={{marginTop: '-7px'}} onClick={()=>handleRemoveMember(member.id,teamId)}><Icon name={'x-circle-q'} size={24} className={``} adjust="fontello" /></span>
                            </div>
                        ) : (
                            <div className="invitee">You</div>
                        )
                    ))}
                </div>
            )}
            {searchResults.length > 0 && (
                <div className="resultsBox" style={{width:'100%'}}>
                    {searchResults.map((result, index) => (
                        <div className={`teamResult ${invitees.find(invitee => invitee.id === result.id) ? 'hideMe' : ''}`} onClick={()=>addToInvite(result)}>
                            {result.first_name} {result.last_name}
                        </div>
                    ))}
                </div>
            )}
           
            {invitees.length > 0 && (
                <>
                    <div class="teamFieldWrapper">
                        <div class="teamFieldTitle" style={{width:'unset',fontSize:'16px'}}>Invited Agents</div>
                        {invitees.map((invitee, index) => (
                            <div className="invitee">{invitee.first_name} {invitee.last_name} 
                                <span className={`fontello quilityCloseWindow`} style={{marginTop: '-7px'}} onClick={()=>addToInvite(invitee)}><Icon name={'x-circle-q'} size={24} className={``} adjust="fontello" /></span>
                            </div>
                        ))}
                    </div>
                    <div className="accentButton solidQuilityActiveButton" onClick={()=>invite()}>
                        Add Invitee's
                    </div>
                </>
            )}
            
        </div>
    );
}

export default InviteSearch;
