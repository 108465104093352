import { createSlice } from '@reduxjs/toolkit'

export const popupSlice = createSlice({
    name: 'popups',
    initialState: {
        popups: []
    },
    reducers: {
        setPopups(state, action) {
            state.popups = action.payload;
        },
    },
})
export const { setPopups } = popupSlice.actions;

export default popupSlice.reducer

