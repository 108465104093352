

import React, { useState, useEffect } from 'react';


function RightQueueHeader({recording,selected, handleSelectTab, setQualifier}) {
    const [items, setItems] = useState(['Script', 'Text', 'Email', 'Notes', 'Quote']);

    useEffect(() => {
        if (recording > 0) {
            const quoteIndex = items.indexOf('Quote');
            if (quoteIndex !== -1) {
                const newItems = [...items];
                newItems.splice(quoteIndex, 0, `Recordings (${recording})`);
                setItems(newItems);
            }
        }
    }, [recording]);
    return (
        <div className="rightHeaderWrapper">
            {items.map((item, index) => ( 
                <div
                    className={`queueHeaderBlock ${item.split(' ')[0] === 'Recordings' && selected===6 ? 'quilityActiveTab' : selected === index ? 'quilityActiveTab' : ''}`}
                    onClick={() => handleSelectTab(item.split(' ')[0] ==='Recordings' ? 6 : index)}
                    key={`rightHeaderInfo${index}`}
                >
                    {item}
                </div>
            ))}
        </div>
    );
    
}

export default RightQueueHeader;