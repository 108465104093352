import React, {useState, useRef, useEffect} from 'react';
import '../../css/teams.css';
import Member from './detailComponents/member';
import Icon from '../../assets/icons/icon';
import Invite from './detailComponents/invite';
import { useSelector } from 'react-redux';

function TeamSection({title, color, team, details}) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const [invitePopup, setInvitePopup] = useState(false);

    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const innerWrapperRef = useRef(290);

    const openUp = () => {
        setIsOpen(!isOpen);
    };
    function removeBackslashes(inputString) {
        if (typeof inputString !== 'string') {
          return inputString;
        }
        return inputString.replace(/\\/g, '');
    }
    useEffect(() => { 

        const timeoutId = setTimeout(() => {
            if (innerWrapperRef.current) {
                setInnerWrapperHeight(innerWrapperRef.current.clientHeight);   
            }
        }, 250);
        return () => clearTimeout(timeoutId);
        
    }, [isOpen]);

    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} style={{ height: isOpen && getHeight() !==0 ? getHeight()+35 : '45px' }} >
            {invitePopup && (
                <Invite setInvitePopup={setInvitePopup} title={removeBackslashes(team.name)} members={team.members} teamId={team.id}/>
            )}
            <div className="quilityNestedBlockHeader" onClick={()=>openUp()}>
               
                <div className="quilityParentBlockTitle" style={{width:'25%',maxWidth:'200px'}}>{team.name==='undefined' ? 'My Agency' : removeBackslashes(team.name)}</div>
                <span className="quilityBlockIcon">
                    <span className={`fontello`} style={{marginTop: '-7px'}}><Icon name={'q-up'} size={20} className={`selectBoxIcon`} adjust="fontello" /></span>
                </span>

                {team && (
                    <div className="typeWrapper">
                        <span className="typeBlock">Agents 
                            <span className="dataBlock">{team.members.length}</span>
                        </span>
                        {title==='Your Teams' && team.owner === agentInfo.agentId && (
                            <span className="typeBlock" style={{cursor:'pointer'}} onClick={()=>setInvitePopup(true)}>Manage Members
                            </span>
                        )}
                    </div>
                )}
            </div>
            
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef}>
                {team.members.map((member, index) => (
                    <Member teamName={team.name} details={details} key={`teamMembers${index}`} member={member}/>
                ))}
            </div>
        </div>
    );
}

export default TeamSection;


