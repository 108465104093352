import { createSlice } from '@reduxjs/toolkit'

export const topBarSlice = createSlice({
    name: 'topBar',
    initialState: {
        messages: false,
        leaderBoard: false,
        calls: false,
        voicemails: false,
    },
    reducers: {
        setOpenMessages(state, action) {
            state.messages = action.payload;
        },
        setOpenLeaderBoard(state, action) {
            state.leaderBoard = action.payload;
        },
        setOpenCalls(state, action) {
            state.calls = action.payload;
        },
        setOpenVoicemails(state, action) {
            state.voicemails = action.payload;
        },
    },
})

export const { setOpenMessages,setOpenLeaderBoard,setOpenCalls,setOpenVoicemails } = topBarSlice.actions;

export default topBarSlice.reducer;