import React, {useEffect, useState} from 'react';
import { getLeadOrders } from '../../utilities/marketUtil';
import { useSelector, useDispatch } from 'react-redux';


function PastOrders({ }) {    
    const agentInfo=useSelector((state) => state.agent.userData);
    const [pastOrders, setPastOrders] = useState(false);
    useEffect(() => {
        getLeadOrders(setPastOrders,agentInfo.agentId)
    },[])
    return (
        <div className={`quilityParentBlockWrapper twoX selectable`}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">Previous Orders</div>
            </div>
            
            <div className="quilityParentBlockContent">
                {pastOrders && pastOrders.length > 0 ? (
                    <div className={`quilityNestedBlockWrapper `} style={{marginTop: '25px'}}>
                        {pastOrders.map((item, index) =>(
                            <div className="quilityNestedBlockContent">
                                <div className="quilityDataLine">
                                    <div className="quilityDataLineTitle" style={{display: 'inline-block',width: '200px'}}>{item.vendorName}</div>
                                    <div className="quilityDataLineValue marketLine" style={{maxWidth: 'fit-content'}}>{item.order_type}</div>
                                    <div className="quilityDataLineValue marketLine" style={{maxWidth: 'fit-content'}}>{item.states}</div>
                                </div>
                            </div> 
                        ))}
                    </div>
                ) : (
                    <div className="title">You have no previous orders</div>
                ) }   
            </div>
            <div className="clearFooter">
                
            </div>
        </div>
    );
}

export default PastOrders;

/*<div className="quilityDataLine" key={`leadPreview${index}`}>
                            <span className="quilityDataLineTitle extraIndent" style={{display: 'inline-block',width: '200px'}}></span>
                        </div>*/
