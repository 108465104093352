import { createSlice } from '@reduxjs/toolkit'

export const leadTypeSlice = createSlice({
    name: 'leadType',
    initialState: {
        leadType: []
    },
    reducers: {
        setLeadType(state, action) {
            state.leadType = action.payload.slice().sort((a, b) => 
                a.name.localeCompare(b.name)
            );
        },
    },
})
export const { setLeadType } = leadTypeSlice.actions;

export default leadTypeSlice.reducer

