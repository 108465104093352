import { createSlice } from '@reduxjs/toolkit'

export const agentSlice = createSlice({
    name: 'agent',
    initialState: {
        userData: {
            firstName: '',
            lastName: '',
            email: '',
            timezone: '',
            agentId: '',
            teamId: '',
            defaultCalendar: null,
            numbers: '',
            admin:false,
            organization:'',
            permissions:[],
            organizationName:'',
            agentCode:null,
            beta:false,
            status: false,
        },
        phoneNumbers: [],
        textingNumbers: [],
        activeCalendar:false,
        logo:null,
        refreshLogin:false,
    },
    reducers: {
        /*setUserData(state, action) {
            state.userData = action.payload;
        },*/
        setUserData(state, action) {
            state.userData = {
                ...state.userData,
                ...action.payload
            };
        },
        setPhoneNumbers(state, action) {
            state.phoneNumbers = action.payload;
        },
        setLogo(state, action) {
            state.logo = action.payload;
        },
        setTextNumbers(state, action) {
            state.textingNumbers = action.payload;
        },
        setActiveCalendar(state, action) {
            state.activeCalendar = action.payload;
        },
        setScopes(state, action) {
            state.userData.permissions = action.payload;
        },
        setRefreshLogin(state, action) {
            state.refreshLogin = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserData, setPhoneNumbers, setActiveCalendar, setTextNumbers, setScopes, setLogo, setRefreshLogin } = agentSlice.actions;

export default agentSlice.reducer
