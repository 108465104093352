import React, { useState, useRef,useEffect } from "react";
import '../../css/elements.css';
import { useSelector } from 'react-redux';
import Icon from "../../assets/icons/icon";



function QuilityNestedBlock({ unixTommddyyyy, title, index, data, addToQueue, openLead}) {
    const queue=useSelector((state) => state.queue.originalQueue);
    const [isOpen, setIsOpen] = useState(false);
    const [manipulatedData, setManipulatedData] = useState('');
    const innerWrapperRef = useRef(null);
    const handleOptionClick = (option) => {
        setIsOpen(!isOpen);
    };
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const parseUnixTimestamp = (value) => {
        //this checks if it is a unix timestamp, and if it is, it will format it, and if not, it will just return the original value
        const timestamp = parseInt(value, 10);
        if (!isNaN(timestamp)) {
            const date = new Date(timestamp * 1000); 
            const formattedDate = date.toLocaleString('en-US', {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            return formattedDate;
        } else {
            return value;
        }
    }
    useEffect( () => {
        if(data && title === 'Appointments'){
            const newArray=[];
            data[index].map((item, index) => (
                newArray.push({
                    'Time': item.apptDate,
                    "Meeting" : item.apptTitle,
                    "lead_id": item.apptLead
                })
            ))
            setManipulatedData(newArray);
        }
    },[])
    return (
        <span className="quilityNestedBlockWrapper" style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : title==='Activity' || title==='Marketplace' ? 'fit-content' : '45px' }}>
            <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick()}>
                <div className="quilityParentBlockTitle">{title}</div>
                <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef} style={{padding: '0px'}}>
                {manipulatedData && title !== 'Custom Filters' && manipulatedData.map((item, index2) => (
                    <div className="quilityDataLine clickable" style={{padding: '10px 25px'}} key={`filtersBlocks${index2}z`} onClick={title === "Appointments" ? () => openLead(item.lead_id) : undefined}>
                        <div className="infoDataBlockWrapper">
                            {Object.keys(item).map(key => (
                                !key.includes("_") && (
                                    <div className="infoDataBlock" key={`infodatablock${key}`}>
                                        <span className="quilityDataLineTitleShort">{key}</span> 
                                        <div className="quilityDataLineValue">{parseUnixTimestamp(item[key])}</div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                ))}
                {data && title === 'Custom Filters' && data[index].map((item, index2) => (
                    <div className="quilityDataLine" key={`filtersBlocks${index2}`} style={{display: 'flex'}}>
                        <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>{item.name}</span> 
                        <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>{item.name}</span>
                        <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>
                            <span className="addToQueueButton" onClick={()=>{addToQueue(item.params.includes('WHERE') ? item.params : `WHERE (\`state\`= '${item.params}')`)}}>Add To Queue</span>
                        </span>
                    </div>
                ))}
                {data && title === 'Activity' &&(
                    <>
                        
                    </>
                )}
                {data[index] && title === 'Marketplace' && data[index].map((item, index2) => (
                    <div className="quilityDataLine" key={`filtersBlockz${index}`} style={{display: 'flex'}}>
                        <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>{item.name}</span> 
                        <span className="quilityDataLineValue marketLine">{unixTommddyyyy(item.order_date)}</span>
                        <span className="quilityDataLineValue marketLine" style={{flex:'.5'}}>{item.order_size}</span>
                        <span className="quilityDataLineValue marketLine" style={{flex:'.5'}}>{item.fulfilled}</span>
                        <span className="quilityDataLineValue marketLine" style={{flex:'.75'}}>$ {(item.total_amount/100)}</span>
                        <span className="quilityDataLineValue marketLine">{item.order_type}</span>
                    </div>
                ))}
            </div>
        </span>
    );
}

export default QuilityNestedBlock;
/*<div className="timeOptions">
                            <span className="timeTitle">Work Week</span>
                            <ToggleSwitch isToggled={isToggled} handleToggle={handleToggle}/>
                            <span className="timeTitle">This Month</span>
                        </div>
                        <div className="quilityDataLine" key={`filtersBlocks${index}1`} style={{display: 'flex'}}>
                            <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>Dials</span> 
                            <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>
                                {data[2][0].totalDials !== null ? data[2][0].totalDials : 0}
                            </span>
                        </div>
                        <div className="quilityDataLine" key={`filtersBlocks${index}2`} style={{display: 'flex'}}>
                            <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>Contacts</span> 
                            <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>
                                {data[2][0].total_contact !== null ? data[2][0].total_contact : 0}
                            </span>
                        </div>
                        <div className="quilityDataLine" key={`filtersBlocks${index}3`} style={{display: 'flex'}}>
                            <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>Appointments</span> 
                            <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>
                                {data[2][0].total_appointment !== null ? data[2][0].total_appointment : 0}
                            </span>
                        </div>
                        <div className="quilityDataLine" key={`filtersBlocks${index}4`} style={{display: 'flex'}}>
                            <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>Applications</span> 
                            <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>
                                {data[2][0].total_application !== null ? data[2][0].total_application : 0}
                            </span>
                        </div>
                        <div className="quilityDataLine" key={`filtersBlocks${index}5`} style={{display: 'flex'}}>
                            <span className="quilityDataLineTitle" style={{display: 'inline-block',flex: '1'}}>Talk Time</span> 
                            <span className="quilityDataLineValue" style={{display: 'inline-block',flex: '1'}}>
                                {data[2][0].talkTime !== null ? Math.round(data[2][0].talkTime / 60) : 0} Minutes
                            </span>
                        </div>*/