import React, { useState, useEffect } from "react";
import NumberSearch from "./search";
import '../../css/numbers.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import QuilityNestedEditBlock from "../elements/quilityNestedEditBlock";
import { useSelector } from 'react-redux';
import { formatPhoneNumber} from '../../utilities/dashFunction';
import Icon from "../../assets/icons/icon";


function Numbers({ data }) {
  const agentId=useSelector((state) => state.agent.userData).agentId;
  const numberCount = data ? data[0]["twilio"].length : '0';
  const [editedText, setEditedText] = useState({});
  const [yourNumbers, setYourNumbers] = useState(data);
  const [editedIndex, setEditedIndex] = useState(null);
  const [addNumber, setAddNumber] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);


  const addANumber = () => {
    setAddNumber(!addNumber);
  };
  const provisionNumber = async () => {
    try {
      const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 
              agent: agentId,
              numbers:selectedItems,
          }) 
      };
      const response = await fetchWithDefaults('/twilio/provisionNumber', requestOptions);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setAddNumber(false);
      setSelectedItems([]);
      refreshNumbers();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const refreshNumbers = async () => {
    try {
      const response = await fetchWithDefaults(`/Number?agent=${agentId}`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setYourNumbers(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCheckboxChange = (item) => {

    const isSelected = selectedItems.includes(item);

    // Update the selectedItems array based on the checkbox state
    if (isSelected) {
    setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
    } else {
    setSelectedItems([...selectedItems, item]);
    }

  };
  const handleRemove = (numberToRemove)=>{
    const updatedNumbers = selectedItems.filter(number => number !== numberToRemove);
    setSelectedItems(updatedNumbers);
  }
  const handleDeleteNumber = async (id)=>{
    try {
      const requestOptions = {
          method: 'DELETE',
          body: JSON.stringify({ 
              agent: agentId,
              numberSid:id,
          }) 
      };
      const response = await fetchWithDefaults('/deleteNumber', requestOptions);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      const updatedNumbers = yourNumbers[0].twilio.filter(number => number.sid !== id);
      const finalUpdate = [{ twilio: updatedNumbers }];
      setYourNumbers(finalUpdate)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const handleEditClick = (index, text) => {
    setEditedIndex(index);
    //setEditedText({ [index]: text });
    setEditedText({ ...editedText, [index]: text });
  };

  const handleSaveClick = (index, sid) => {
      // Save the edited text or perform any necessary action
      updateName(sid, editedText[index]);
      setEditedIndex(null);
  };

  const handleInputChange = (e, index) => {
    setEditedText({ ...editedText, [index]: e.target.value });
  };
  const formatDate = (dateString) => {
    const inputDate = new Date(dateString);

    // Ensure the inputDate is a valid Date object
    if (isNaN(inputDate.getTime())) {
      return dateString; // Return the original string if parsing fails
    }

    // Extract the date components
    const year = inputDate.getFullYear().toString().substring(2);
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
 
    // Format the date as MM/DD/YY
    return `${month}/${day}/${year}`;
  };
  const updateName = async (sid, friendly) => {
    try {
      const requestOptions = {
          method: 'PATCH',
          body: JSON.stringify({ 
              agent: agentId,
              number:sid,
              friendly:friendly
          }) 
      };
  
      const response = await fetchWithDefaults('/updateNumberFriendly', requestOptions);
      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect( () => {
    refreshNumbers()
  },[addNumber])
  return (
    <div className="outerWrapper">
      <span className="outerWrapperTitle">
        <span className="outerWrapperIcon">

        </span>
        Outgoing Numbers
      </span>
      
      <div
        className="mainBlockOuterWrapper"
        style={{ justifyContent: "space-around" }}
      >
        {addNumber ? (
          <div className="quilityParentBlockWrapper" style={{position: 'relative'}}>
            <div className="quilityParentBlockHeader">
              <div className="quilityParentBlockTitle">Add a New Number</div>
              <div className="quilityCloseWindow" onClick={()=>addANumber()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-10px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            </div>
              <div className="quilityParentBlockContent">
                <div className="selectedHolder">
                  {selectedItems.length > 0 && (
                    <>
                      <div className="subTitle" style={{fontWeight:'700'}}>Numbers To Add</div>
                      <div className="provisionNumbers solidQuilityActiveButton" onClick={()=>provisionNumber()}>Finalize Adding Numbers</div>
                    </>
                  )}
                  {selectedItems.length > 0 && (
                    selectedItems.map((item, index) => (
                      <div className='removeableOption'>
                        <span class="removeableRemove" onClick={()=>handleRemove(item)}>X</span>
                        {item}
                      </div>
                    ))
                  )}
                </div>
                <NumberSearch agentId={agentId} selectedItems={selectedItems} handleCheckboxChange={handleCheckboxChange} type='State'/>
                <NumberSearch agentId={agentId} selectedItems={selectedItems} handleCheckboxChange={handleCheckboxChange} type='Area Code'/>
                <NumberSearch agentId={agentId} selectedItems={selectedItems} handleCheckboxChange={handleCheckboxChange} type='Pattern'/>
              </div>
              <div className="blockFooter">
              <div
                className="buttonWrapper"
                style={{ marginTop: " 5px", marginLeft: "10px" }}
              >
                <div className="dataButtonBlock">Selected: {selectedItems.length}</div>
                {selectedItems.length > 0 ? (
                  <div className="actionButtonBlock" style={{width: '200px',marginTop: '2px'}}>
                    
                  </div>
                ) :(
                  null
                )}
              </div>
              </div>
          </div>
        ):(
          null
        )}
        <div className="quilityParentBlockWrapper" style={{height: addNumber ? '45px' : '', lineHeight: addNumber ? '45px' : '', marginTop: addNumber ? '20px' : ''}}>
            <div className="quilityParentBlockHeader" style={{height: addNumber ? '45px' : ''}} onClick={addNumber ? () => addANumber() : null}>
                <div class="quilityParentBlockTitle" style={{lineHeight: addNumber ? '45px' : ''}}>Numbers </div>
            </div>

            <div className="quilityParentBlockContent">
              {yourNumbers && yourNumbers[0]["twilio"].map((item, index) => (
                <QuilityNestedEditBlock itemId={item.sid} handleRemove={handleDeleteNumber} key={`numberBlock${index}`} title={item.friendlyName} values={{'Number':formatPhoneNumber(item.phoneNumber),'Date Created': formatDate(item.dateCreated),'Friendly Name':item.friendlyName}}/>
              ))}
              <div className="blockFooter">
              <div
                className="buttonWrapper"
                style={{ marginTop: " 5px", marginLeft: "10px" }}
              >
                <div className="dataButtonBlock">Total: {numberCount}</div>
                <div className="actionButtonBlock">
                  <div className="accentButton solidQuilityActiveButton" onClick={() => addANumber()}>
                    Add Number +
                  </div>
                </div>
              </div>
            </div>
            </div>
            

        </div>
      </div>
    </div>
  );
}

export default Numbers;