import { createSlice } from '@reduxjs/toolkit'

export const statusSlice = createSlice({
    name: 'status',
    initialState: {
        status: []
    },
    reducers: {
        setStatus(state, action) {
            state.status = action.payload.slice().sort((a, b) => 
                a.name.localeCompare(b.name)
            );
        },
    },
})

export const { setStatus } = statusSlice.actions;

export default statusSlice.reducer;