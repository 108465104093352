import React from "react";
import '../../css/workflow.css';
import WorkflowExitCondition from "./exitConditions";
import DropTarget from './dropZone';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function FinishFlow({status, handleExitDrop, exitConditions, removeFromExit}) {
    
    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{display: 'flex',height: '100%',width: '100%'}}>
                <div className="quilityNestedBlockWrapper workflowBuilderLeft editWorkflowBuilderLeft" style={{overflow: "scroll"}}>
                    <div className="workflowStartBlock">
                        <div className="workflowStartBlockTitle">Choose Exit Conditions</div>
                    </div>
                    <div className="dottedLine"></div>
                    {exitConditions.length > 0 && exitConditions.map((item, index) => ( 
                        <div className='draggedElement' key={`exitRemove${index}`}>
                            <div className="removeFromFlow" onClick={()=>removeFromExit(item.id)}>X</div>
                            <div className="draggedTitle" >{item.type}</div>
                            <div>{item.name}</div>
                        </div>
                    ))}
                    <div className="dottedLine"></div>      
                    <DropTarget onDrop={handleExitDrop} internalText="Drop Exit Conditions Here">

                    </DropTarget>
                </div>
                <div className="quilityNestedBlockWrapper workflowBuilderRight editWorkflowBuilderRight" style={{overflow: "scroll"}}>
                    <WorkflowExitCondition status={status} />
                </div>
            </div>
        </DndProvider>
    );
}

export default FinishFlow;

