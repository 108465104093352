import React, { useState } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';

const CSVParser = ({setAmountUploaded, scriptValue, workflowValue, setSelectedFile, selectedFile, agent, team, setFileImport}) => {  
  const [columnNames, setColumnNames] = useState([]);
  const [matchedNames, setMatchedNames] = useState([]);
  const [theMatches, setTheMatches] = useState([]);
  const [ninjaColumns, setNinjaColumns] = useState([
    {id:'none',name:"Do Not Import"},
    {id:'borrower_first',name:"Lead First Name"},
    {id:'borrower_last',name:"Lead Last Name"},
    {id:'borrower_sex',name:"Lead Sex"},
    {id:'borrower_dob',name:"Lead DOB"},
    {id:'borrower_age',name:"Lead Age"},
    {id:'borrower_home',name:"Lead Home #"},
    {id:'borrower_cell',name:"Lead Cell #"},
    {id:'borrower_work',name:"Lead Work #"},
    {id:'email',name:"Email"},
    {id:'address',name:"Address"},
    {id:'city',name:"City"},
    {id:'state',name:"State"},
    {id:'zip',name:"Zip Code"},
    {id:'county',name:"County"},
    {id:'tobacco',name:"Tobacco"},
    {id:'height',name:"Height"},
    {id:'weight',name:"Weight"},
    {id:'lender',name:"Lendor"},
    {id:'mortgage',name:"Mortgage Amount"},
    {id:'loan_type',name:"Loan Type"},
    {id:'payment',name:"Mortgage Payment"},
    {id:'home_value',name:"Home Value"},
    {id:'term_length',name:"Mortgage Term Length"},
    {id:'beneficiary',name:"Beneficiary"},
    {id:'ben_type',name:"Benificiary Type"},
    {id:'coverage_amount',name:"Coverage Amount Desired"},
    {id:'household_income',name:"Household Income"},
    {id:'hobby',name:"Lead Hobby"},
    {id:'motivation',name:"Lead Motivation"},
    {id:'investments',name:"Investments"},
    {id:'investments_value',name:"Investments Value"},
    {id:'concerns',name:"Concerns"},
    {id:'pdf',name:"PDF"},
    {id:'lead_type',name:"lead type"},
    {id:'co_borrower_first',name:"Co Lead First Name"},
    {id:'co_borrower_last',name:"Co Lead Last Name"},
    {id:'co_borrower_sex',name:"Co Lead Sex"},
    {id:'co_borrower_dob',name:"Co Lead DOB"},
    {id:'co_borrower_age',name:"Co Lead Age"},
    {id:'co_borrower_home',name:"Co Lead Home #"},
    {id:'co_borrower_cell',name:"Co Lead Cell #"},
    {id:'co_borrower_work',name:"Co Lead Work #"},
    {id:'co_borrower_email',name:"Co Lead Email"}
  ]);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setSelectedFile(file);
        Papa.parse(file, {
        complete: (result) => {
            // Assuming first row contains column headers
            const headers = result.data[0];
            setColumnNames(headers);
            // Now you can match the column names with your system's set of names
            const matched = matchColumnNames(headers);
            setMatchedNames(matched);
        },
        });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const matchColumnNames = (csvColumnNames) => {
        // Your matching logic here
        // This function should return an array of matched names
    };
    const handleMatchChange = (index, selectedValue) => {
       
        setTheMatches([...theMatches,{[columnNames[index]]:selectedValue}])
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('agent', agent);
        formData.append('team', team);
        formData.append('script', scriptValue);
        formData.append('workflow', workflowValue);
    
        formData.append('matchedData', JSON.stringify(theMatches));

        try {
        const response = await fetch('https://v2.ninjadialer.com:3333/matchedImport', {
            method: 'POST',
            body: formData,
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setAmountUploaded(jsonData);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
            <div {...getRootProps()} style={dropzoneStyle}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a csv file here, or click to select files</p>
            </div>
            <div className="fileMatchHolder">
                <div className="matchSide">
                {columnNames.map((name, index) => (
                    <div className="quilityDataLine" key={index} style={{ padding: '13px' }}>
                    <span className="">{name}</span>
                    </div>
                ))}
                </div>
                <div className="matchSide">
                {columnNames.map((name, index) => (
                    <div className="quilityDataLine" key={index} style={{ padding: '8.75px' }}>
                        <select
                            className="quilitySelect"

                            onChange={(e) => handleMatchChange(index, e.target.value)}
                        >
                            {ninjaColumns.map((column, index) => (
                                <option key={index} value={column.id}>
                                {column.name}
                                </option>
                            ))}
                        </select>
                    </div>
                ))}
                </div>
            </div>
            </div>
            {selectedFile && (
                <div style={{textAlign:'center',marginTop:'20px'}}>
                    <div className="saveAsQueue solidQuilityActiveButton" style={{marginRight:'20px'}} onClick={()=>{setFileImport(false);setSelectedFile(false)}}>Cancel</div>
                    <button type="submit" className="selectionButton" style={{fontSize: '15px',lineHeight: '30px',height: '40px',width: '90px',fontSize: '13px'}}>Submit</button>
                </div>
            )}
        </form>
    );
};

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default CSVParser;
