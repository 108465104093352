import React from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

const CheckoutForm = ({addACardDone}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }
        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            //handleError(submitError);
            return;
        }
       
        stripe.confirmSetup({
            elements,
            confirmParams: {
                // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                return_url: 'https://example.com',
            },
            redirect: "if_required",
            })
            .then(function(result) {
              if (result.error) {
                  // Inform the customer that there was an error.
              }
              addACardDone();
        });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <div className="buttonWrapper" style={{textAlign:'center'}}><button className="accentButton solidQuilityActiveButton" disabled={!stripe} style={{border: 'none',padding: '0px 10px',fontSize: '16px'}}>Submit</button></div>
    </form>
  )
};

export default CheckoutForm;