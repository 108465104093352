import React, { useState, useEffect, useRef } from 'react';
import ContactField from './field';
import { useSelector } from 'react-redux';
import Icon from '../../../../assets/icons/icon';

function ContactInfo({leadData, handleLeadData, makeDial}) {
    const numberList=useSelector((state) => state.agent.phoneNumbers);
    const [isOpen, setIsOpen] = useState(true);
    const numbers=['borrower_cell', 'borrower_home', 'borrower_work']
    const names=['borrower_first', 'borrower_last']
    const Conumbers=['co_borrower_cell', 'co_borrower_home', 'co_borrower_work']
    const Conames=[ 'co_borrower_first', 'co_borrower_last']
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const [numberLists, setNumberList] = useState([]);
    
    const innerWrapperRef = useRef(290);
    const openUp = () => {
        setIsOpen(!isOpen);
    };
    const startDial=(toNum,callee,caller)=>{
        //forcing the call logging int othe notes - reason is because if the lead connects, and the agent adds notes, it will overwrite the backend call logging. and if nothign happens, then the backend will be used.
        const timestamp = new Date().toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(',', '');
        const newNotes=`Outbound Call - ${timestamp} <br> ${leadData[0].notes}`;
        handleLeadData('notes', newNotes);
        const foundNumber = numberLists.find(item => {
            return item.number === caller;
        });
        if(foundNumber){
            makeDial(toNum,callee,caller)
        }
        else{
            caller=numberLists[0].number;
            makeDial(toNum,callee,caller)
        }
    }
      
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (innerWrapperRef.current) {
                setInnerWrapperHeight(innerWrapperRef.current.clientHeight);   
            }
        }, 250);
        return () => clearTimeout(timeoutId);
        
    }, [isOpen]);
    useEffect(() => {
        setNumberList(numberList);
    }, []);
    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} >
            <div className="quilityNestedBlockHeader " onClick={()=>openUp()}>
                <div className="quilityParentBlockTitle">Contact Information </div>
                <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    
                        {names.map((field, index) => (
                            <div className="leadCardBlock doubleLine" key={`contactInfoNames${index}`}>
                                <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData} key={`fieldInfo${index}`}/>
                            </div>
                        ))}
                        {numbers.map((field, index) => (
                            <span key={`numbersInfo${index}`}>
                                {leadData[0][field] !== null ? (
                                    <div className="leadCardBlock doubleLine">
                                        <span className="callIcon" onClick={(event)=>{startDial(leadData[0][field],leadData[0].borrower_first+' '+leadData[0].borrower_last,leadData[0].call_from);event.stopPropagation();}}>
                                            <span className="fontello" style={{height: '27px'}}><Icon name={'q-phone-call-outgoing'} size={18} className="" adjust="fontello" /></span>
                                            <div className="numberOptions">
                                                {numberLists.map((number, index) => (
                                                    <span key={`availableNumbers${index}`}>
                                                    {leadData[0][field]===leadData[0].callFrom ? (
                                                        <div className="numberChoice" onClick={(event)=>{startDial(leadData[0][field],leadData[0].borrower_first+' '+leadData[0].borrower_last,number.number);event.stopPropagation();}}>{number.friendlyName}</div>
                                                    ) : (
                                                        <div className="numberChoice" onClick={(event)=>{startDial(leadData[0][field],leadData[0].borrower_first+' '+leadData[0].borrower_last,number.number);event.stopPropagation();}}>{number.friendlyName}</div>
                                                    )}
                                                    </span>
                                                
                                                ))}
                                            </div>
                                        </span>
                                        <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData}/>
                                    </div>
                                ) : (
                                    <div className="leadCardBlock doubleLine">
                                        <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData}/>
                                    </div>
                                )}
                            </span>
                        ))}
                         <div className="leadCardBlock doubleLine">
                            <ContactField handleLeadData={handleLeadData} field='email' leadData={leadData} index='1'/>
                        </div>

                        {Conames.map((field, index) => (
                            <div className="leadCardBlock doubleLine" key={`contactInfoCoName${index}`}>
                                <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData} key={`coNameFieldInfo${index}`}/>
                            </div>
                        ))}
                        {Conumbers.map((field, index) => (
                            <div className="leadCardBlock doubleLine" key={`contactInfoCoNumber${index}`}>
                                <ContactField handleLeadData={handleLeadData} field={field} leadData={leadData} key={`coNumberFieldInfo${index}`}/>
                            </div>
                        ))}
                        <div className="leadCardBlock doubleLine">
                            <ContactField handleLeadData={handleLeadData} field='co_borrower_email' leadData={leadData} index='1'/>
                        </div>
                </div>
            </div>
        </div>
    );
    
}

export default ContactInfo;