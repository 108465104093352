import React, {useState} from 'react';
import mail from '../../assets/icons/mail.svg';
import close from '../../assets/icons/x-close.svg';

function ActiveBottom({handleSetMigration}) { 
    const [consent, setConsent] = useState(false);    
    const [acknowledge, setAcknowledge] = useState(false);
    const [terms, setTerms] = useState(false);

    const ack=()=>{
        setAcknowledge(!acknowledge);
    }
    const cons=()=>{
        setConsent(!consent);
    }
    const term=()=>{
        setTerms(!terms);
    }
    return (
        <div className="activeBottomWrapper">
            <div className="activeBottomBlock">
                <div className="activeBlockInnerContent">
                    <div className="blockInnerContentTitle">Getting Started</div>
                    <div className="checkWrapper">
                        <div className="actualCheck" onClick={()=>{term()}}>
                            {terms ? (
                                <div className="theCheck2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 36 36" fill="none">
                                        <path d="M30 9L13.5 25.5L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            ) : (
                                <div className="theCheck">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                        <rect x="1" y="1.5" width="26" height="26" rx="3" fill="#E4E4E4"/>
                                        <rect x="1" y="1.5" width="26" height="26" rx="3" stroke="#D0D0D0" stroke-width="2"/>
                                    </svg>
                                </div>
                            )}
                            <div className="checkText">
                                I agree to and have reviewed the <span className="linkStyle">Terms and Conditions</span> and <span className="linkStyle">Privacy Policy</span>.
                            </div>
                        </div>
                        <div className="actualCheck" onClick={()=>{cons()}}>
                            {consent ? (
                                <div className="theCheck2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 36 36" fill="none">
                                        <path d="M30 9L13.5 25.5L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            ) : (
                                <div className="theCheck">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                        <rect x="1" y="1.5" width="26" height="26" rx="3" fill="#E4E4E4"/>
                                        <rect x="1" y="1.5" width="26" height="26" rx="3" stroke="#D0D0D0" stroke-width="2"/>
                                    </svg>
                                </div>
                            )}
                            <div className="checkText">
                                I agree to the transfer of lead data and any other information? Switchboard® Activate to Funnel.
                            </div>
                        </div>
                        <div className="actualCheck" onClick={()=>{ack()}}>
                            {acknowledge ? (
                                <div className="theCheck2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 36 36" fill="none">
                                        <path d="M30 9L13.5 25.5L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            ) : (
                                <div className="theCheck">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                        <rect x="1" y="1.5" width="26" height="26" rx="3" fill="#E4E4E4"/>
                                        <rect x="1" y="1.5" width="26" height="26" rx="3" stroke="#D0D0D0" stroke-width="2"/>
                                    </svg>
                                </div>
                            )}
                            <div className="checkText">
                            I acknowledge that my Switchboard® Activate account will be canceled and that after 30 days, I will no longer have access to my account. <i>Please note that any changes made in your Activate account after the migration will not appear in your Funnel account.</i>
                            </div>
                        </div>
                        <div className="migrationButtonWrapper">
                            <div className={`${consent && acknowledge && terms ? 'actualMigrationButton' : 'actualMigrationButtonDis'} `}>
                                    {consent && acknowledge && terms ? (
                                        <div className="migrateButtonText" onClick={()=>handleSetMigration(true)}>Migrate Now</div>
                                    ) : (
                                        <div className="migrateButtonText">Migrate Now</div>
                                    )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="activeBottomBlock">
                <div className="activeBlockInnerContent">
                    <div className="graphicLineWrapper">
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                        <div className="graphicLine"></div>
                    </div>
                    <div className="graphicLineWrapper2">
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                        <div className="graphicLine2"></div>
                    </div>
                    <div className="yellowSquare"></div>
                    <div className="redSquare"></div>
                    <div className="largeBottomIconWrapper">
                        <div className="largeBottomIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
                                <path d="M72 140.571C109.871 140.571 140.571 109.871 140.571 71.9999C140.571 34.1289 109.871 3.42847 72 3.42847C34.1291 3.42847 3.42859 34.1289 3.42859 71.9999C3.42859 109.871 34.1291 140.571 72 140.571Z" stroke="white" stroke-width="4" stroke-linejoin="round"/>
                                <path d="M17.5447 65.3267C20.3839 41.9161 37.9694 23.0521 60.7354 18.3008" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M120.962 96.7651C115.626 107.294 106.993 115.87 96.422 121.134" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M99.4286 41.1428L61.7143 78.8571L48.0001 65.1428L34.2858 78.8571L61.7143 106.286L113.143 54.8571L99.4286 41.1428Z" stroke="white" stroke-width="4" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActiveBottom;
