import React, {useState} from 'react';
import '../../css/conversation.css';
import Icon from '../../assets/icons/icon';

function MessageBlock({message,index}) {
    const [timeOpen, setTimeOpen] = useState(false);
    function formatUnixTimestamp(unixTimestamp) {
        const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate;
    }
    const revealTime= ()=>{
        setTimeOpen(!timeOpen)
    }
    return (
        message.pending ? (
            <span key={`conversationMap${index}`}>
                <div className={`time message${message.sent_from}`}></div>
                <div className={`messageWrapper message${message.sent_from}`}>
                <div className={`convoBubble background${message.sent_from} ${message.pending ? 'pending': ''}`}>
                        {message.image && message.image != 0 && (
                            <div className="sentImageWrapper">
                                <img src={message.image} alt="Preview" className="sendingImage"/>
                            </div>
                        )}
                        {message.message}
                    </div>
                    <span className="fontello"><Icon name={'clock-q'} size={16} className="" adjust="fontello" /></span>
                </div>  
            </span>

    ) : (
        <span key={`conversationMap${index}`}>
            {timeOpen && (
                <div className={`time message${message.sent_from} ${message.pending ? 'pending': ''}`}>
                    {formatUnixTimestamp(message.time)}
                </div>
            )}
            
            <div className={`messageWrapper message${message.sent_from}`} onClick={()=>revealTime()}>
                <div className={`convoBubble background${message.sent_from}`}>
                    {message.image && message.image != 0 && (
                        <div className="sentImageWrapper">
                            <img src={message.image} alt="Preview" className="sendingImage"/>
                        </div>
                    )}
                {message.message}
                </div>
            </div>  
        </span>
    )
    );
}

export default MessageBlock;


