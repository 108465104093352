import React, {useEffect, useState} from 'react';
import '../../css/leadCard.css';
import { removeTagFromLead } from '../../utilities/tagFunctions';
import { useSelector } from 'react-redux';

function LeadCardTags({tagIds, leadId}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [leadsTags, setLeadsTags] = useState([]);

    const handleRemoveLead= async(tagId)=> {
        const tagRemoved=await removeTagFromLead(tagId,agentId,leadId);
        if(tagRemoved){
            const updatedTags = leadsTags.filter(tag => tag.id !== tagId);
            setLeadsTags(updatedTags);
        }
    }
    useEffect(() => {
        if(tagIds && tagIds.length > 0){
            const transformedTags = tagIds.split(',')
            .map(tag => tag.split('*'))
            .map(([id, name]) => ({ id, name }));
            setLeadsTags(transformedTags)
        }
        else{
            setLeadsTags([]);
        }
    }, [tagIds]);
    return (
        leadsTags.length > 0 && leadsTags.map((tag, index) => (
            <div className="tagWrapper" key={`taggedLead${index}`}>
                {tag.name}
                <span className="removeTag solidQuilityDestructiveButton" onClick={()=>handleRemoveLead(tag.id)}>x</span>
            </div>
        ))
    );
}

export default LeadCardTags;