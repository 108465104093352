
import { createSlice } from '@reduxjs/toolkit'

export const variableSlice = createSlice({
    name: 'variables',
    initialState: {
        variables: []
    },
    reducers: {
        setVariables(state, action) {
            state.variables = action.payload;
        },
    },
})
export const { setVariables } = variableSlice.actions;

export default variableSlice.reducer

