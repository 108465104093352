import React, { useState, useEffect } from "react";
import '../../css/callForward.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import Icon from "../../assets/icons/icon";

const ToggleSwitch = ({toggle,activateNumber, id}) => {
    
    const [isToggled, setToggled] = useState(toggle);
    
  
    const handleToggle = () => {
        setToggled(!isToggled);
        activateNumber(isToggled, id);
    };
   
    return (
      <div>
        <label className="switch">
          <input type="checkbox" id="toggleSwtich" checked={isToggled} onChange={handleToggle} />
          <span className="slider round"></span>
        </label>
        <span className="sliderDesc">{isToggled ? 'Active' : 'Off'}</span>
      </div>
    );
};
function CallForward({data}) {
    const [addNumber, setAddNumber] = useState(false);
    const [editedText, setEditedText] = useState({});
    const [editedIndex, setEditedIndex] = useState(null);
    const [forwardName, setForwardName] = useState(null);
    const [numbers, setNumbers] = useState([]);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [phoneNumber, setPhoneNumber] = useState('');

    const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        const cleaned = value.replace(/\D/g, '');

        // Limit the number of digits to 10
        const limited = cleaned.slice(0, 10);

        // Format the number
        const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            const formattedNumber = `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}`;
            return formattedNumber;
        }

        return value;
    };
    const handleSetPhoneNumber=(value)=>{

        const formattedValue = formatPhoneNumber(value);
        setPhoneNumber(formattedValue);
    }
    useEffect(() => {
        if(data[0]){
            setNumbers(data[0]);
        }
    },[])
    const activateNumber =async (active, id)=>{
        if(active=='0' || active==false){
            active='1';
        }
        else{
            active='0';
        }
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentId,
                    id:id,
                    active:active
                }) 
            };
          
            const response = await fetchWithDefaults('/activateForwarding', requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            const newNumbers=[];
            numbers.length > 0 && numbers.map((item, index) => {
                if (item.id === id) {
                  item.active = active;
                } else {
                  item.active = false;
                }
                newNumbers.push(item);
                return item; 
            });
            setNumbers([]);
            setNumbers(newNumbers);
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const handleEditClick = (index, text) => {
        setEditedIndex(index);
        //setEditedText({ [index]: text });
        setEditedText({ ...editedText, [index]: text });
    };
    const handleForwardName = (text) => {
        setForwardName(text);
    };

    const handleSaveClick = (index, id) => {
        // Save the edited text or perform any necessary action
        updateName(editedText[index], id);
        setEditedIndex(null);
    };

    const handleInputChange = (e, index) => {
        setEditedText({ ...editedText, [index]: e.target.value });
    };
  
    const addANumber = () => {
        setAddNumber(!addNumber);
    };
    const saveNumber =async ()=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    number:phoneNumber,
                    name:forwardName
                }) 
            };
          
            const response = await fetchWithDefaults(`/addForwarding`, requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            const updatedNumbers = numbers.length > 0 && numbers.map((item) => ({ ...item, active: false }));
            if(numbers.length > 0){
                setNumbers([...updatedNumbers,jsonData]);
            }
            else{
                setNumbers([jsonData]);
            }
            
            setForwardName(null);
            setPhoneNumber(null);
            addANumber();
            
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const deleteNumber =async (id)=>{
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    id: id,
                }) 
            };
          
            const response = await fetchWithDefaults(`/deleteForwarding?id=${id}`, requestOptions);
            
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            const updatedNumbers = numbers.filter((item) => item.id !== id);
            setNumbers(updatedNumbers);
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const updateName = async (friendly, id) => {
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentId,
                    id:id,
                    name:friendly
                }) 
            };
          
            const response = await fetchWithDefaults(`/updateForwarding`, requestOptions);
        
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
  return (
    <div className="outerWrapper">
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">

                </span>
                Call Forwarding
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >
           {addNumber ? (
            <div className="frontPageQuilityParentBlockWrapper" style={{width:'70%', position:'relative'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">Add a New Number</div>
                </div>
                <span className="quilityCloseWindow" onClick={()=>addANumber()}>X</span>
                <div className="quilityParentBlockContent roundedBottom" style={{height: 'fit-content'}}>
                    <div className="quilityNestedBlockWrapper" style={{marginTop: '25px'}}>
                        <div className="quilityNestedBlockHeader">
                            <div className="quilityParentBlockTitle">New Forwarding Number</div>
                        </div>
                        <div className="quilityNestedBlockContent">
                            <div className="quilityDataLine">
                                <div className="quilityDataLineTitle" style={{width: '200px'}}>Name This Number</div>
                                <input 
                                    type='text' 
                                    className="qualifierFieldInput"
                                    value={forwardName}
                                    onChange={(e) => handleForwardName(e.target.value)}
                                />
                            </div>
                            <div className="quilityDataLine">
                                <div className="quilityDataLineTitle" style={{width: '200px'}}>Number To Forward To</div>
                                <input 
                                    type='text' 
                                    className="qualifierFieldInput" 
                                    value={phoneNumber}
                                    onChange={(e) => handleSetPhoneNumber(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonWrapper"><div className="accentButton solidQuilityActiveButton" style={{textAlign:'left'}} onClick={()=>saveNumber()}>Save Number</div></div>
                </div>
                
            </div>  

        ):(
          null
        )}
        <div className={`frontPageQuilityParentBlockWrapper ${addNumber ? 'closeMainBlock' : ''}`} style={{width:'70%'}}>
            <div className="quilityParentBlockHeader">
                <div className="quilityParentBlockTitle">Call Forwarding Numbers</div>

            </div>
            <div className="quilityParentBlockContent" style={{height: 'fit-content'}}>
                {numbers.length > 0 ? numbers.map((item, index) => (    
                    <div className="quilityNestedBlockWrapper" key={`forward${index}`} style={{marginTop: '25px'}}>
                        <div className="quilityCloseWindow oulineQuilityDestructiveButton" onClick={() => deleteNumber(item.id)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                        <div className="quilityNestedBlockHeader">
                            <div className="quilityParentBlockTitle">
                                <>
                                    {editedText[index] || item.name}
                                    <span
                                    className="hoverIcon"
                                    onClick={() =>
                                        handleEditClick(index, item.name)
                                    }
                                    >
                                    {/*pencil*/}
                                    </span>
                                </>
                            </div>
                        </div>
                        <div className="quilityNestedBlockContent">
                        <div className="innerContentWrapper">
                            <div className="quilityDataLine">
                            <span className="quilityDataLineTitle">Number: </span>
                            {formatPhoneNumber(item.number)}
                            </div>
                        
                            <div className="quilityDataLine">
                            <span className="quilityDataLineTitle">Number Name: </span>
                            {editedIndex === index ? (
                                <input
                                type="text"
                                className="qualifierFieldInput"
                                value={editedText[index]}
                                onChange={(e) => handleInputChange(e, index)}
                                onBlur={() => handleSaveClick(index, item.id)}
                                autoFocus
                                />
                            ) : (
                                <>
                                    <span style={{ marginLeft: "5px" }}>
                                        {editedText[index] || item.name}
                                    </span>
                                    <span
                                        className="hoverIcon"
                                        onClick={() =>
                                        handleEditClick(index, editedText[index])
                                        }
                                    >
                                        {/*pencil*/}
                                    </span>
                                </>
                            )}
                            </div>
                            <div className="quilityDataLine">
                                <ToggleSwitch activateNumber={activateNumber} id={item.id} toggle={item.active}/>
                            </div>
                        </div>
                    </div>
                </div>
                )) : (
                    <div className='noResults'>You have no forwarding numbers</div>
                )}
            </div>
                <div className="blockFooter">
                    <div
                        className="buttonWrapper"
                        style={{ marginTop: " 5px", marginLeft: "10px" }}
                    >
                        <div className="dataButtonBlock">Numbers: {numbers.length}</div>
                        <div className="actionButtonBlock" style={{width: '150px'}}>
                            <div className="accentButton solidQuilityActiveButton" onClick={()=>addANumber()}>
                            +  New Number
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

  );
}

export default CallForward;

