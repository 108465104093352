import React, { useState, useEffect, useRef } from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../../../utilities/dashFunction';
import { calculateAge, dobFormat, validDOB } from '../../../../utilities/timeFunctions';

function ContactField({leadData, handleLeadData, field}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const numbers=['borrower_cell','borrower_home','borrower_work','co_borrower_home','co_borrower_cell','co_borrower_work','borrower_age','co_borrower_age']
    const [myLeadData, setMyLeadData] = useState(false);
    const [borrowerDate, setBorrowerDate] = useState('');
    const [borrowerError, setBorrowerError] = useState('');
    const [coBorrowerError, setCoBorrowerError] = useState('');
    const [coBorrowerDate, setCoBorrowerDate] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedValue, setEditedValue] = useState('');
    const inputRef = useRef(null);
    const handleEdit = (initialValue) => {
        setEditMode(true);
        setEditedValue(initialValue);
    };
    
    const handleBlur = (field) => {
        let data;
        if(field==='co_borrower_dob' || field==='borrower_dob'){
            data=field==='co_borrower_dob' ? coBorrowerDate : borrowerDate;
        }
        else{
            data=myLeadData;
        }
        setEditMode(false);
        saveEditedValue(field, data);
        handleLeadData(field, data)
        updateLeadData(field, data)
        //setEditedValue('');
    };
    
    const saveEditedValue = (field, value) => {
        let newValue=value;
        if(numbers.includes(field)){
            if(newValue){

                if (typeof newValue !== 'number') {
                    newValue = newValue.replace(/\D/g, '');
                }
                
            }
            if(newValue && newValue.length > 10){
                return;
            }
            else if(newValue && newValue.length === 1){
                if(newValue== '1'){
                    return;
                }
                else{
                    setMyLeadData(newValue)
                    setEditedValue(newValue);
                    //updateLeadData(field, newValue);
                    handleLeadData(field, newValue)
                }
            }
            else{
                setMyLeadData(newValue)
                setEditedValue(newValue);
                //updateLeadData(field, newValue);
                handleLeadData(field, newValue)
            }
        }
        else{
            setMyLeadData(value)
            setEditedValue(value);
            //updateLeadData(field, value);
            handleLeadData(field, value)
        }
    };
    function formatPhoneNumber(phoneNumber) {
        // Remove any non-digit characters
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        
        // Format the number
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        
        // Return original if it doesn't match the format
        return phoneNumber;
    }
    const formatDate = (value) => {
            // Automatically add slashes as the user types
        let formattedValue = value;
        if (value.length >= 5) {
            formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
        } else if (value.length >= 3) {
            formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
        } else if (value.length >= 1) {
            formattedValue = `${value.slice(0, 2)}`;
        }
        return formattedValue;
    };
    const updateLeadData = async (field, value) => {
        try {        
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    lead:leadData[0].lead_id,
                    column:field,
                    value:value
                }) 
            };
            const response = await fetchWithDefaults('/updateLead', requestOptions);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
           
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const formatField=(inputString)=>{
        if (inputString.includes('co_borrower_')) {
            // Remove "borrower_" and capitalize the remaining string
            return inputString.replace('co_borrower_', 'Co Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
       
        } else if (inputString.includes('borrower_')) {
        // Remove "co_borrower_" and capitalize the remaining string
            return inputString.replace('borrower_', 'Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else if (inputString.includes('_')) {
        // Capitalize the entire string
            return inputString.replace('_', ' ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else {
        // Capitalize the entire string
            return inputString.replace(/\b\w/g, (c) => c.toUpperCase());
        }
    }
    useEffect(() => {
        if (editMode && inputRef.current) {
          inputRef.current.focus();
        }
    }, [editMode]);
    useEffect(() => {
        if(field==='borrower_dob' || field==='co_borrower_dob' ){
            if(field==='borrower_dob'){
                setBorrowerDate(dobFormat(leadData[0][field]))
            }
            else{
                setCoBorrowerDate(dobFormat(leadData[0][field]))
            }   
        }
        else{
            setMyLeadData(leadData[0][field])
            setEditedValue(leadData[0][field])
        }
    }, [leadData]);
    const handleDateBlur=(event, column, leadId)=>{
        const valididy=validDOB(event.target.value);
        if(valididy.isValid){
            if(column==='borrower_dob'){
                setBorrowerError('');
            }
            else{
                setCoBorrowerError('');
            }
            const age=calculateAge(event.target.value);
            const newColumn= column==='borrower_dob' ? 'borrower_age' : 'co_borrower_age';
            handleBlur(column);
     
            if(valididy.date !== ''){
                saveEditedValue(newColumn, age);
                updateLeadData(newColumn, age)
            }
        }
        else{
            if(column==='borrower_dob'){
                setBorrowerError(valididy.error);
            }
            else{
                setCoBorrowerError(valididy.error);
            }
        }
    }
    const dobChange = (val, column) => {
        const value = val.replace(/\D/g, ''); // Remove non-digit characters
        const formattedDate = formatDate(value);
        if(column==='borrower_dob'){
            setBorrowerDate(formattedDate);
        }
        else{
            setCoBorrowerDate(formattedDate);
        }

    };
    return (
        leadData && (
            <div className="leadInfoLine"><div className="dataFieldTitle" style={{maxWidth: 'unset'}}>{formatField(field)+": "} {field==='borrower_dob' ? <span className="error">{borrowerError}</span> : field==='co_borrower_dob' ? <span className="error">{coBorrowerError}</span> : '' }</div>
                <span className="leadData"  onClick={() =>handleEdit(leadData[0][field])}> 
                    {field==='borrower_dob' || field==='co_borrower_dob' ? (
                        <input
                            className="qualifierFieldInput"
                            type="text"
                            placeholder='mm/dd/yyyy'
                            value={field==='borrower_dob' ? borrowerDate : coBorrowerDate}
                            onChange={(event) => dobChange(event.target.value, field)}
                            onBlur={(event) => handleDateBlur(event, field, leadData[0].lead_id)}
                            ref={inputRef}
                        />
                    ) : (
                        <input
                            className="qualifierFieldInput"
                            type="text"
                            value={numbers.includes(field) ? formatPhoneNumber(editedValue || '') : editedValue || ''}
                            onChange={(e) => saveEditedValue(field,e.target.value)}
                            onBlur={() => handleBlur(field)}
                            ref={inputRef}
                        />
                    )}
                   
                </span>
            </div>
        )       
    );
    
}

export default ContactField;
