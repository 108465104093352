import React, {useState} from 'react';
import '../css/login.css';
import image from '../assets/icons/QuilitySwitchboardFunnel.svg';
import { useDispatch } from 'react-redux';
import { setRefreshLogin } from '../features/agentSlice';
import { fetchWithDefaults } from '../config/fetchDefault';

function LoginPopup({}) {
    const [loginError, setLoginError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const saveData = async (cipher) => {
        localStorage.setItem('keepMeAlive', cipher);
        return;
    };
    const loginUser=async ()=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    username: username,
                    password: password,
                    source:'web'
                }) 
            };
            
            const response = await fetchWithDefaults('/login/loginUser', requestOptions);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            if(jsonData[0].status===true){
                await saveData(jsonData[0].cipher);
                dispatch(setRefreshLogin(false));
            }
            else {
                setLoginError('Your username or password is incorrect!');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loginUser();
        }
    };
    return (
        <div className="signInWrapper">
            <div className="signInContent">
            <div className="loginWrapper">
                    <div className="logo"><img src={image} className="imageStyle" alt="Logo" /></div>
                    <div className="error" style={{ color: '#ff4949', fontWeight: '400', fontSize: '18px' }}>{loginError}</div>

                    <div className="errorHolder" style={{fontSize: '15px'}}>Another device has logged into your account</div>
                    <div className="usernameWrapper">
                        <div className="loginInputTitle">Username</div>
                        <input
                            type="text"
                            className="inputBox"
                            value={username}
                            onFocus={() => setLoginError(false)}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="passwordWrapper">
                        <div className="loginInputTitle">Password</div>
                        <input
                            type="password"
                            className="inputBox"
                            value={password}
                            onFocus={() => setLoginError(false)}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div className="buttonWrapper" style={{marginTop: '20px'}}>
                        <button type="submit" className="loginButton" name="submitSignin" onClick={() => loginUser()}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPopup;